import { Component, Input, OnInit } from '@angular/core';
import { ElectricConsumption, ElectricHomeService } from 'src/app/api';

@Component({
  selector: 'app-electricity-savings',
  templateUrl: './electricity-savings.component.html',
  styleUrls: ['./electricity-savings.component.scss'],
})
export class ElectricitySavingsComponent implements OnInit {
  constructor(private homeApi: ElectricHomeService) {}
  @Input() homeId!: string;
  homeCostResponse!: ElectricConsumption | any;
  homeConsumptionResponse!: ElectricConsumption | any;
  plugsConsumptionResponse!: ElectricConsumption | any;
  ngOnInit(): void {
    this.getData();
    this.homeConsumptionResponse = {
      utcStartTime: '2021-08-03T02:00:00Z',
      utcEndTime: '2021-08-30T13:00:00Z',
      homeStartTime: '2021-08-03T04:00:00',
      homeEndTime: '2021-08-30T15:00:00',
      value: 0.0,
    };
    this.homeCostResponse = {
      utcStartTime: '2021-08-03T02:00:00Z',
      utcEndTime: '2021-08-30T13:00:00Z',
      homeStartTime: '2021-08-03T04:00:00',
      homeEndTime: '2021-08-30T15:00:00',
      value: 0.0,
    };
    this.plugsConsumptionResponse = {
      utcStartTime: '2021-08-03T02:00:00Z',
      utcEndTime: '2021-08-30T13:00:00Z',
      homeStartTime: '2021-08-03T04:00:00',
      homeEndTime: '2021-08-30T15:00:00',
      value: 0.0,
    };
  }

  getData(): void {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const endDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );
    this.homeApi
      .electricHomeHomeIdEnergyAbsoluteGet(
        this.homeId,
        new Date(startDate.toISOString().replace('Z', '')),
        new Date(endDate.toISOString().replace('Z', ''))
      )
      .subscribe((response) => (this.homeCostResponse = response));
    this.homeApi
      .electricHomeHomeIdEnergyAbsoluteGet(
        this.homeId,
        new Date(startDate.toISOString().replace('Z', '')),
        new Date(endDate.toISOString().replace('Z', ''))
      )
      .subscribe((response) => (this.homeConsumptionResponse = response));
  }
}
