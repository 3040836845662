/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElectricMarketer } from './electricMarketer';
import { ElectricTarifa } from './electricTarifa';
import { Holidays } from './holidays';

export interface AirPrice { 
    id?: string;
    homeId?: string;
    tariff?: ElectricTarifa;
    comer?: ElectricMarketer;
    counterType?: number;
    nonFixedPrice?: boolean;
    reactive?: boolean;
    powerExcess?: boolean;
    gas?: boolean;
    energyDtoPercentage?: number;
    powerDtoPercentage?: number;
    socialBonusPercentage?: number;
    energyPrices?: Array<number>;
    hiredPower?: Array<number>;
    powerPrices?: Array<number>;
    rentByDay?: number;
    otherByDay?: number;
    priceStartTime?: Date;
    priceEndTime?: Date;
    billStartTime?: Date;
    billEndTime?: Date;
    billPeriod?: string;
    holidays?: Holidays;
    contractDeadline?: Date;
    configId?: string;
    deviceId?: string;
    heatPower?: number;
    coolPower?: number;
}