<div class="popup">
    <h1 class="primary">Política de Privacidad</h1>

    <div class="text-box body-1" [innerHTML]="text" *ngIf="!loading" lang="ES">

    </div>
    <div class="text-box body-1" [innerHTML]="text" *ngIf="loading" lang="ES">Cargando...</div>



    <button class="button-secondary caption" [mat-dialog-close]="true">Salir</button>
</div>