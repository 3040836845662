<div class="widget" *ngIf="!error">
  <div
    class="chart"
    echarts
    [options]="options"
    [loading]="loading"
    [merge]="options"
    *ngIf="!loading && !noContent"
  ></div>
  <div class="chart" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>
  <div class="last" *ngIf="!loading">
    <ng-container *ngIf="noContent; else contentOk">
      <div class="no-data">No se han encontrado datos disponibles.</div>
    </ng-container>
    <ng-template #contentOk
      ><div class="header">
        <h3 class="title">
          Estado
          <div *ngIf="online" class="status-icon online"></div>
          <div *ngIf="!online" class="status-icon offline"></div>
        </h3>
      </div>
      <hr />
      <div class="bottom" *ngIf="period == 1 || period == 3">
        <div class="element power">
          <h5 class="name">Pot. Contratada (P1)</h5>
          <div class="value">
            <h3 class="number">
              {{
                this.response.p1?.absoluteMaxPower?.hiredPower || 0
                  | number : "1.2-2" : "es"
              }}
            </h3>
            <h3 class="units">kW</h3>
          </div>
        </div>
        <div class="element power">
          <h5 class="name">Potencia máxima</h5>
          <div class="value">
            <h5 class="number">
              {{
                this.response.p1?.absoluteMaxPower?.power || 0
                  | number : "1.2-2" : "es"
              }}
            </h5>
            <h5 class="units">kW</h5>
          </div>
        </div>
      </div>
      <ng-container *ngIf="period == 3"> <hr /></ng-container>

      <div class="bottom" *ngIf="period == 2 || period == 3">
        <div class="element power">
          <h5 class="name">Pot. Contratada (P2)</h5>
          <div class="value">
            <h3 class="number">
              {{
                this.response.p2?.absoluteMaxPower?.hiredPower || 0
                  | number : "1.2-2" : "es"
              }}
            </h3>
            <h3 class="units">kW</h3>
          </div>
        </div>
        <div class="element power">
          <h5 class="name">Potencia máxima</h5>
          <div class="value">
            <h5 class="number">
              {{
                this.response.p2?.absoluteMaxPower?.power || 0
                  | number : "1.2-2" : "es"
              }}
            </h5>
            <h5 class="units">kW</h5>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
