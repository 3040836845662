// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createParamString(...params: [string, any][]): string{
    const result: string[]=[];
    for(const [name, value] of params){
        const stringValue = value?.toString();
        if(stringValue !== undefined){
            result.push(`${name}=${encodeURIComponent(stringValue)}`);
        }
    }
    return result.join('&');
}
