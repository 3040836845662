import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  ComparedWaterConsumption,
  WaterConsumptionList,
  WaterDataService,
} from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-water',
  templateUrl: './water.component.html',
  styleUrls: ['./water.component.scss'],
})
export class WaterComponent implements OnInit, OnChanges {
  constructor(private route: ActivatedRoute, private api: WaterDataService) {
    this.error = 0;
  }
  response!: ComparedWaterConsumption;
  response2!: ComparedWaterConsumption;
  loading!: boolean;
  error!: number | null;
  homeId!: string;
  dateRange!: FormGroup;
  ngOnInit(): void {
    this.loading = true;
    environment.current = 'Agua';
    environment.currentRoute = this.route;
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    environment.homeId = this.homeId;

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });

    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
  }
  changeDate(event: any): void {
    this.dateRange = event;
  }

  getData(): void {
    this.api
      .waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(this.homeId, 'cold')
      .subscribe(
        (response) => {
          this.response = response;
          if (this.response2) this.loading = false;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.api
              .waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(
                this.homeId,
                'cold'
              )
              .subscribe(
                (response) => {
                  this.response = response;
                  if (this.response2) this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );

    this.api
      .waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(this.homeId, 'hot')
      .subscribe(
        (response) => {
          this.response2 = response;
          if (this.response) this.loading = false;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.api
              .waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(
                this.homeId,
                'hot'
              )
              .subscribe(
                (response) => {
                  this.response2 = response;
                  if (this.response) this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
}
