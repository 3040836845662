/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AirPrice } from '../model/airPrice';
import { AirPriceList } from '../model/airPriceList';
import { HourAndConsumption } from '../model/hourAndConsumption';
import { HourAndConsumptionList } from '../model/hourAndConsumptionList';
import { HourWeekCalc } from '../model/hourWeekCalc';
import { TempHumList } from '../model/tempHumList';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AirDataService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionAbsoluteGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaDeviceDeviceIdAirConsumptionAbsoluteGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaDeviceDeviceIdAirConsumptionAbsoluteGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaDeviceDeviceIdAirConsumptionAbsoluteGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionAbsoluteGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/absolute`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionAvgDayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaDeviceDeviceIdAirConsumptionAvgDayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaDeviceDeviceIdAirConsumptionAvgDayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaDeviceDeviceIdAirConsumptionAvgDayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionAvgDayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/avg/day`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionAvgHourweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourWeekCalc>;
    public climaDeviceDeviceIdAirConsumptionAvgHourweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourWeekCalc>>;
    public climaDeviceDeviceIdAirConsumptionAvgHourweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourWeekCalc>>;
    public climaDeviceDeviceIdAirConsumptionAvgHourweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionAvgHourweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourWeekCalc>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/avg/hourweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionBydayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaDeviceDeviceIdAirConsumptionBydayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionBydayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionBydayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionBydayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/byday`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionByhourGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaDeviceDeviceIdAirConsumptionByhourGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionByhourGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionByhourGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionByhourGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/byhour`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionBymonthGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaDeviceDeviceIdAirConsumptionBymonthGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionBymonthGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionBymonthGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionBymonthGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/bymonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionByweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaDeviceDeviceIdAirConsumptionByweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionByweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionByweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionByweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/byweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionByyearGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaDeviceDeviceIdAirConsumptionByyearGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionByyearGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionByyearGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionByyearGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/byyear`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionLast6monthGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaDeviceDeviceIdAirConsumptionLast6monthGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionLast6monthGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionLast6monthGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionLast6monthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/last6month`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionLastMonthGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaDeviceDeviceIdAirConsumptionLastMonthGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionLastMonthGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaDeviceDeviceIdAirConsumptionLastMonthGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionLastMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/lastMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionThisMonthGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaDeviceDeviceIdAirConsumptionThisMonthGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaDeviceDeviceIdAirConsumptionThisMonthGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaDeviceDeviceIdAirConsumptionThisMonthGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionThisMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/thisMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirConsumptionTodayGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaDeviceDeviceIdAirConsumptionTodayGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaDeviceDeviceIdAirConsumptionTodayGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaDeviceDeviceIdAirConsumptionTodayGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirConsumptionTodayGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/consumption/today`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirPriceGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<AirPriceList>;
    public climaDeviceDeviceIdAirPriceGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirPriceList>>;
    public climaDeviceDeviceIdAirPriceGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirPriceList>>;
    public climaDeviceDeviceIdAirPriceGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirPriceGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AirPriceList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/price`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirPricePost(deviceId: string, body?: AirPrice, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaDeviceDeviceIdAirPricePost(deviceId: string, body?: AirPrice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaDeviceDeviceIdAirPricePost(deviceId: string, body?: AirPrice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaDeviceDeviceIdAirPricePost(deviceId: string, body?: AirPrice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirPricePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/price`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirTempGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<TempHumList>;
    public climaDeviceDeviceIdAirTempGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TempHumList>>;
    public climaDeviceDeviceIdAirTempGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TempHumList>>;
    public climaDeviceDeviceIdAirTempGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirTempGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TempHumList>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/temp`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdAirConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaHomeHomeIdAirConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaHomeHomeIdAirConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaHomeHomeIdAirConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdAirConsumptionAvgDayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/air/consumption/avg/day`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdAirConsumptionThisMonthGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaHomeHomeIdAirConsumptionThisMonthGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaHomeHomeIdAirConsumptionThisMonthGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaHomeHomeIdAirConsumptionThisMonthGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdAirConsumptionThisMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/air/consumption/thisMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdAirConsumptionTodayGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaHomeHomeIdAirConsumptionTodayGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaHomeHomeIdAirConsumptionTodayGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaHomeHomeIdAirConsumptionTodayGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdAirConsumptionTodayGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/air/consumption/today`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
