<div class="widget extend" *ngIf="!error">
    <div class="widget-content" *ngIf="!loading">
        <div class="header caption">Este mes</div>
        <div class="body">
            <h2 class="cost secondary">
                {{ response?.cost || 0 | number: "1.2-2" }} €
            </h2>
            <b class="label">(exc. Imp)</b>
            <div class="label light consumption">
                {{ response?.energy || 0 | number: "1.0-2" }} kWh
            </div>
            <div class="label light time">
                {{ response?.hours || 0 | number: "1.0-2" }} horas
            </div>
        </div>
    </div>
    <div class="widget-content" *ngIf="loading">
        <div class="header caption">Este mes</div>

        <div class="loading-spinner" *ngIf="loading"></div>
    </div>
</div>

<div class="widget error" *ngIf="error">
    <app-error [error]="error"></app-error>
</div>