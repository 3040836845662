import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GlobalService {
  itemValue = new BehaviorSubject(this.userHomes);

  set userHomes(value) {
    const jsonValue = JSON.stringify(value);
    this.itemValue.next(jsonValue); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('userHomes', jsonValue);
  }

  get userHomes(): any {
    try {
      return JSON.parse(localStorage.getItem('userHomes')!);
    } catch {}
  }
}
