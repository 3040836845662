<div class="widget">
  <div class="page-content" *ngIf="currentPage == 0">
    <div *ngIf="true" class="primary h0">
      Vamos a configurar tu rutina semanal
    </div>

    <div class="nav-up">
      Con los datos que nos des actualizaremos las programaciones diarias. Si
      quieres cambiarlas podrás hacerlo sin problema en el programador
    </div>
    <div class="buttons">
      <div class="buttons-row">
        <div class="button-compact-secondary caption" (click)="closeDialog()">
          Salir
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">
          Empezar
        </div>
      </div>
    </div>
  </div>
  <div class="page-content" *ngIf="currentPage == 1">
    <div *ngIf="true" class="primary h0">Días laborables</div>

    <div class="nav-up">¿A qué hora te levantas?</div>
    <div class="selector">
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWakeUpTimeHour">
          <ng-container *ngFor="let hour of hours">
            <mat-option
              *ngIf="hour < 18 && hour < requestObject.dayBedTimeHour"
              [value]="hour"
            >
              {{ hour | number : "2.0-0" }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      :
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWakeUpTimeMinute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number : "2.0-0" }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="nav-up">¿A qué hora te acuestas?</div>
    <div class="selector">
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayBedTimeHour">
          <ng-container *ngFor="let hour of hours">
            <mat-option
              *ngIf="hour > requestObject.dayWakeUpTimeHour"
              [value]="hour"
            >
              {{ hour | number : "2.0-0" }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      :
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayBedTimeMinute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number : "2.0-0" }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          ←
        </div>
        <button
          class="button-compact-primary caption"
          (click)="pageUp()"
          [disabled]="
            requestObject.dayBedTimeHour < requestObject.dayWakeUpTimeHour
          "
        >
          ➝
        </button>
      </div>
    </div>
  </div>

  <div class="page-content" *ngIf="currentPage == 2">
    <div *ngIf="true" class="primary h0">
      En horario laboral, ¿Dónde te encuentras?
    </div>

    <div class="selector">
      <mat-button-toggle-group
        class="right"
        [(value)]="requestObject.dayAtWork"
      >
        <mat-button-toggle [value]="false" class="nav-up bold"
          >Casa</mat-button-toggle
        >
        <mat-button-toggle [value]="true" class="nav-up bold"
          >Trabajo</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>

    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          ←
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">➝</div>
      </div>
    </div>
  </div>
  <div class="page-content" *ngIf="currentPage == 3 && requestObject.dayAtWork">
    <div *ngIf="true" class="primary h0">Días laborables</div>

    <div class="nav-up">¿A qué hora vas al trabajo?</div>
    <div class="selector">
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWorkDepartureHour">
          <ng-container *ngFor="let hour of hours">
            <mat-option
              *ngIf="
                hour > requestObject.dayWakeUpTimeHour &&
                hour < requestObject.dayWorkArrivalHour
              "
              [value]="hour"
            >
              {{ hour | number : "2.0-0" }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      :
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWorkDepartureMinute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number : "2.0-0" }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="nav-up">¿A qué hora vuelves del trabajo?</div>
    <div class="selector">
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWorkArrivalHour">
          <ng-container *ngFor="let hour of hours">
            <mat-option
              *ngIf="
                hour > requestObject.dayWorkDepartureHour &&
                hour < requestObject.dayBedTimeHour
              "
              [value]="hour"
            >
              {{ hour | number : "2.0-0" }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      :
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWorkArrivalMinute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number : "2.0-0" }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          ←
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">➝</div>
      </div>
    </div>
  </div>
  <div class="page-content" *ngIf="currentPage == 4 && requestObject.dayAtWork">
    <div *ngIf="true" class="primary h0">
      ¿Vuelves a casa a mediodía para comer?
    </div>

    <div class="selector">
      <mat-button-toggle-group
        class="right"
        [(value)]="requestObject.dayWorkLunch"
      >
        <mat-button-toggle [value]="true" class="nav-up bold"
          >Sí</mat-button-toggle
        >
        <mat-button-toggle [value]="false" class="nav-up bold"
          >No</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>

    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          ←
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">➝</div>
      </div>
    </div>
  </div>
  <div
    class="page-content"
    *ngIf="
      currentPage == 5 && requestObject.dayAtWork && requestObject.dayWorkLunch
    "
  >
    <div *ngIf="true" class="primary h0">Días laborables</div>

    <div class="nav-up">¿A qué hora llegas a casa a mediodía?</div>
    <div class="selector">
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWorkLunchArrivalHour">
          <ng-container *ngFor="let hour of hours">
            <mat-option
              *ngIf="
                hour > requestObject.dayWorkDepartureHour &&
                hour < requestObject.dayWorkLunchDepartureHour
              "
              [value]="hour"
            >
              {{ hour | number : "2.0-0" }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      :
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWorkLunchArrivalMinute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number : "2.0-0" }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="nav-up">¿A qué hora vuelves al trabajo?</div>
    <div class="selector">
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWorkLunchDepartureHour">
          <ng-container *ngFor="let hour of hours">
            <mat-option
              *ngIf="
                hour > requestObject.dayWorkLunchArrivalHour &&
                hour < requestObject.dayWorkArrivalHour
              "
              [value]="hour"
            >
              {{ hour | number : "2.0-0" }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      :
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.dayWorkLunchDepartureMinute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number : "2.0-0" }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          ←
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">➝</div>
      </div>
    </div>
  </div>

  <div
    class="page-content"
    *ngIf="
      (currentPage == 3 && !requestObject.dayAtWork) ||
      (currentPage == 5 &&
        requestObject.dayAtWork &&
        !requestObject.dayWorkLunch) ||
      (currentPage == 6 &&
        requestObject.dayAtWork &&
        requestObject.dayWorkLunch)
    "
  >
    <div *ngIf="true" class="primary h0">Fin de semana</div>

    <div class="nav-up">¿A qué hora te levantas los fines de semana?</div>
    <div class="selector">
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.weekendWakeupHour">
          <ng-container *ngFor="let hour of hours">
            <mat-option [value]="hour">
              {{ hour | number : "2.0-0" }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      :
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.weekendWakeupMinute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number : "2.0-0" }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="nav-up">¿A qué hora te acuestas los fines de semana?</div>
    <div class="selector">
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.weekendBedHour">
          <ng-container *ngFor="let hour of hours">
            <mat-option [value]="hour">
              {{ hour | number : "2.0-0" }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
      :
      <mat-form-field
        appearance="outline"
        class="select"
        style="max-width: 75px"
      >
        <mat-select [(value)]="requestObject.weekendBedMinute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number : "2.0-0" }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          ←
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">➝</div>
      </div>
    </div>
  </div>

  <div
    class="page-content"
    *ngIf="
      (currentPage == 4 && !requestObject.dayAtWork) ||
      (currentPage == 6 &&
        requestObject.dayAtWork &&
        !requestObject.dayWorkLunch) ||
      (currentPage == 7 &&
        requestObject.dayAtWork &&
        requestObject.dayWorkLunch)
    "
  >
    <div *ngIf="true" class="primary h0">Sábado</div>

    <div class="nav-up">
      Selecciona si sales de casa habitualmente los sábados
    </div>
    <div class="selector">
      <mat-button-toggle-group
        class="right"
        [(value)]="requestObject.saturdayDeparture"
      >
        <mat-button-toggle [value]="true" class="nav-up bold"
          >Sí</mat-button-toggle
        >
        <mat-button-toggle [value]="false" class="nav-up bold"
          >No</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div class="content" *ngIf="requestObject.saturdayDeparture">
      <div class="nav-up">¿A qué hora sales de casa?</div>
      <div class="selector">
        <mat-form-field
          appearance="outline"
          class="select"
          style="max-width: 75px"
        >
          <mat-select [(value)]="requestObject.saturdayDepartureHour">
            <ng-container *ngFor="let hour of hours">
              <mat-option
                *ngIf="hour < 18 && hour < requestObject.saturdayArrivalHour"
                [value]="hour"
              >
                {{ hour | number : "2.0-0" }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        :
        <mat-form-field
          appearance="outline"
          class="select"
          style="max-width: 75px"
        >
          <mat-select [(value)]="requestObject.saturdayDepartureMinute">
            <mat-option *ngFor="let minute of minutes" [value]="minute">
              {{ minute | number : "2.0-0" }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="nav-up">¿A qué hora vuelves?</div>
      <div class="selector">
        <mat-form-field
          appearance="outline"
          class="select"
          style="max-width: 75px"
        >
          <mat-select [(value)]="requestObject.saturdayArrivalHour">
            <ng-container *ngFor="let hour of hours">
              <mat-option
                *ngIf="hour > requestObject.saturdayDepartureHour"
                [value]="hour"
              >
                {{ hour | number : "2.0-0" }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        :
        <mat-form-field
          appearance="outline"
          class="select"
          style="max-width: 75px"
        >
          <mat-select [(value)]="requestObject.saturdayArrivalMinute">
            <mat-option *ngFor="let minute of minutes" [value]="minute">
              {{ minute | number : "2.0-0" }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          ←
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">➝</div>
      </div>
    </div>
  </div>
  <div
    class="page-content"
    *ngIf="
      (currentPage == 5 && !requestObject.dayAtWork) ||
      (currentPage == 7 &&
        requestObject.dayAtWork &&
        !requestObject.dayWorkLunch) ||
      (currentPage == 8 &&
        requestObject.dayAtWork &&
        requestObject.dayWorkLunch)
    "
  >
    <div *ngIf="true" class="primary h0">Domingo</div>

    <div class="nav-up">
      Selecciona si sales de casa habitualmente los domingos
    </div>
    <div class="selector">
      <mat-button-toggle-group
        class="right"
        [(value)]="requestObject.sundayDeparture"
      >
        <mat-button-toggle [value]="true" class="nav-up bold"
          >Sí</mat-button-toggle
        >
        <mat-button-toggle [value]="false" class="nav-up bold"
          >No</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div class="content" *ngIf="requestObject.sundayDeparture">
      <div class="nav-up">¿A qué hora sales de casa?</div>
      <div class="selector">
        <mat-form-field
          appearance="outline"
          class="select"
          style="max-width: 75px"
        >
          <mat-select [(value)]="requestObject.sundayDepartureHour">
            <ng-container *ngFor="let hour of hours">
              <mat-option
                *ngIf="hour < 18 && hour < requestObject.sundayArrivalHour"
                [value]="hour"
              >
                {{ hour | number : "2.0-0" }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        :
        <mat-form-field
          appearance="outline"
          class="select"
          style="max-width: 75px"
        >
          <mat-select [(value)]="requestObject.sundayDepartureMinute">
            <mat-option *ngFor="let minute of minutes" [value]="minute">
              {{ minute | number : "2.0-0" }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="nav-up">¿A qué hora vuelves?</div>
      <div class="selector">
        <mat-form-field
          appearance="outline"
          class="select"
          style="max-width: 75px"
        >
          <mat-select [(value)]="requestObject.sundayArrivalHour">
            <ng-container *ngFor="let hour of hours">
              <mat-option
                *ngIf="hour > requestObject.sundayDepartureHour"
                [value]="hour"
              >
                {{ hour | number : "2.0-0" }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        :
        <mat-form-field
          appearance="outline"
          class="select"
          style="max-width: 75px"
        >
          <mat-select [(value)]="requestObject.sundayArrivalMinute">
            <mat-option *ngFor="let minute of minutes" [value]="minute">
              {{ minute | number : "2.0-0" }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          ←
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">➝</div>
      </div>
    </div>
  </div>
  <div
    class="page-content"
    *ngIf="
      (currentPage == 6 && !requestObject.dayAtWork) ||
      (currentPage == 8 &&
        requestObject.dayAtWork &&
        !requestObject.dayWorkLunch) ||
      (currentPage == 9 &&
        requestObject.dayAtWork &&
        requestObject.dayWorkLunch)
    "
  >
    <div *ngIf="true" class="primary h0">Temperatura</div>

    <div class="nav-up">Selecciona tu temperatura ideal</div>

    <mat-form-field appearance="outline" class="select">
      <mat-label>Temperatura ideal</mat-label>
      <mat-select [(value)]="requestObject.idealTemperature">
        <mat-option *ngFor="let minute of temperatures" [value]="minute">
          {{ minute | number : "2.0-0" }}ºC</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div class="nav-up">
      Una vez guardes tu configuración, espera unos minutos para visualizar la
      programación que te hemos propuesto en base a tu rutina.
    </div>
    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageDown()">
          Volver
        </div>
        <div class="button-compact-primary caption" (click)="saveChanges()">
          Guardar
        </div>
      </div>
    </div>
  </div>
</div>
