import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceTypesCount, ManagmentService } from 'src/app/api';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss'],
})
export class DeviceInfoComponent implements OnInit {
  constructor(
    private api: ManagmentService,
    private route: ActivatedRoute,
    public common: CommonService
  ) {}

  homeId!: string;
  response!: DeviceTypesCount | undefined;
  loading!: boolean;
  error: any;
  environment = environment;

  ngOnInit(): void {
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    this.getData();
  }

  getData(): void {
    this.api.homeHomeIdDevicetypesGet(this.homeId).subscribe(
      (response) => {
        this.response = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api.homeHomeIdDevicetypesGet(this.homeId).subscribe(
            (response) => {
              this.response = response;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }
}
