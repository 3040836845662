import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  DatadisClassifiedMaxPowerList,
  ElectricDeviceService,
} from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity-counter-realtime-chart',
  templateUrl: './counter-realtime-chart.component.html',
  styleUrls: ['./counter-realtime-chart.component.scss'],
})
export class CounterRealtimeChartComponent implements OnInit, OnChanges {
  @Input() deviceId!: string;
  @Input() type!: string;
  @Input() period!: number;
  options: any;
  loading!: boolean;
  online!: boolean;
  offline!: boolean;
  error: any;
  activePower: any = [];
  hiredPower: any = [];
  activePower2: any = [];
  hiredPower2: any = [];

  response!: DatadisClassifiedMaxPowerList;

  noContent: boolean = false;

  constructor(private electricDevice: ElectricDeviceService) {
    this.error = 0;
    this.loading = true;
  }

  ngOnInit(): void {
    this.online = true;
    this.offline = false;
    //  this.getData();
  }

  ngOnChanges(): void {
    this.loading = true;
    this.activePower = [];
    this.hiredPower = [];
    this.activePower2 = [];
    this.hiredPower2 = [];
    this.getData();
  }

  getData(): void {
    this.noContent = false;

    if (!!this.response) {
      this.changePeriod();
    } else {
      this.electricDevice
        .electricDeviceDeviceIdDatadisMaxpowerLast12monthsGet(this.deviceId)
        .subscribe(
          (responseData) => {
            this.response = responseData;
            let periodResponse;
            if (this.period == 1) {
              periodResponse = this.response.p1;
            } else if (this.period == 2) {
              periodResponse = this.response.p2;
            } else {
              periodResponse = this.response;
            }
            this.adaptData(periodResponse);
          },
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.electricDevice
                .electricDeviceDeviceIdDatadisMaxpowerLast12monthsGet(
                  this.deviceId
                )
                .subscribe(
                  (responseData) => {
                    this.response = responseData;
                    let periodResponse;
                    if (this.period == 1) {
                      periodResponse = responseData.p1;
                    } else if (this.period == 2) {
                      periodResponse = responseData.p2;
                    } else {
                      periodResponse = responseData;
                    }
                    this.adaptData(periodResponse);
                  },
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    }
  }

  changePeriod(): void {
    this.noContent = false;
    let periodResponse;
    if (this.period == 1) {
      periodResponse = this.response.p1;
    } else if (this.period == 2) {
      periodResponse = this.response.p2;
    } else {
      periodResponse = this.response;
    }
    this.adaptData(periodResponse);
  }

  adaptData(data: any): void {
    if (!!!data) {
      this.loading = false;
      this.noContent = true;
    }
    if (data.p1) {
      for (const instance of data.p1.maxPowerList) {
        instance.power = Number(instance.power);
        if (instance.power > 0.1) {
          instance.power = instance.power.toFixed(2);
        } else if (instance.power > 0.01) {
          instance.power = instance.power.toFixed(3);
        } else if (instance.power > 0) {
          instance.power = instance.power.toFixed(4);
        } else {
          instance.power = instance.power.toFixed(0);
        }
        let element = {
          name: instance.time,
          value: [new Date(instance.time), instance.power],
        };
        this.activePower.push(element);

        element = {
          name: instance.time,
          value: [new Date(instance.time), instance.hiredPower],
        };

        this.hiredPower.push(element);
      }
      for (const instance2 of data.p2.maxPowerList) {
        instance2.power = Number(instance2.power);
        if (instance2.power > 0.1) {
          instance2.power = instance2.power.toFixed(2);
        } else if (instance2.power > 0.01) {
          instance2.power = instance2.power.toFixed(3);
        } else if (instance2.power > 0) {
          instance2.power = instance2.power.toFixed(4);
        } else {
          instance2.power = instance2.power.toFixed(0);
        }
        let element = {
          name: instance2.time,
          value: [new Date(instance2.time), instance2.power],
        };
        this.activePower2.push(element);

        element = {
          name: instance2.time,
          value: [new Date(instance2.time), instance2.hiredPower],
        };

        this.hiredPower2.push(element);
        /* if (new Date(instance.time).getTime() > new Date().getTime() - 600000) {
          this.online = true;
          this.offline = false;
        } else {
          this.offline = true;
          this.online = false;
        }*/
      }

      this.generateOptions2();
    } else {
      for (const instance of data.maxPowerList) {
        instance.power = Number(instance.power);
        if (instance.power > 0.1) {
          instance.power = instance.power.toFixed(2);
        } else if (instance.power > 0.01) {
          instance.power = instance.power.toFixed(3);
        } else if (instance.power > 0) {
          instance.power = instance.power.toFixed(4);
        } else {
          instance.power = instance.power.toFixed(0);
        }
        let element = {
          name: instance.time,
          value: [new Date(instance.time), instance.power],
        };
        this.activePower.push(element);

        element = {
          name: instance.time,
          value: [new Date(instance.time), instance.hiredPower],
        };

        this.hiredPower.push(element);
        /* if (new Date(instance.time).getTime() > new Date().getTime() - 600000) {
          this.online = true;
          this.offline = false;
        } else {
          this.offline = true;
          this.online = false;
        }*/
      }

      this.generateOptions();
    }
  }

  generateOptions(): void {
    let color;
    if (this.period == 1) {
      color = '#73C0DE';
    } else {
      color = '#EB0B0B';
    }
    const options = {
      tooltip: {
        position: 'top',
        trigger: 'axis',
        confine: true,
        formatter: function (params: any) {
          let contratada = '';
          try {
            if (params[1].value[1]) {
              contratada =
                ' <br> <span style="height: 0px; width: 5px; padding: 0px 2px; margin-right:3px; border-radius: 25px; background-color:' +
                params[1].color +
                '"></span>  <a style="font-size: 0.9rem"> Potencia contratada: ' +
                '  <a/> <b style="font-size: 0.9rem">' +
                String(params[1].value[1]).replace('.', ',') +
                'kW';
            }
          } catch (Exception) {
            console.log('Problemas al formatear la potencia contratada');
          }
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params[0].color +
            '"></span>  <a style="font-size: 1.25rem"> Potencia registrada: ' +
            ' <br> <a/>  <b style="font-size: 1.25rem">' +
            params[0].value[1].replace('.', ',') +
            'kW  </b>  (' +
            params[0].axisValueLabel +
            ') ' +
            contratada
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        name: 'Hora',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        type: 'time',
        minInterval: 60 * 1000 * 10,
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
      },
      yAxis: {
        name: 'kW',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        type: 'value',
        splitLine: {
          show: true,
        },
      },

      series: [
        {
          name: 'Potencia registrada',
          type: 'line',

          showSymbol: false,
          hoverAnimation: true,
          smooth: false,
          data: this.activePower,
          legend: {
            textStyle: {
              fontFamily: environment.fontFamily,
            },
            orient: 'horizontal',
            top: 'bottom',
          },
          itemStyle: {
            color: color,
            borderWidth: 50,
          },
          lineStyle: {
            color: color,
            width: 3,
          },
          symbolSize: 2,
        },
        ,
        {
          name: 'Potencia Contratada',
          type: 'line',

          showSymbol: false,
          hoverAnimation: false,
          smooth: false,
          data: this.hiredPower,

          itemStyle: {
            color: '#A5A5A5',
            borderWidth: 50,
          },
          lineStyle: {
            color: '#A5A5A5',
            width: 1.5,
          },
          symbolSize: 2,
        },
      ],
      grid: {
        top: '32px',
        left: '32px',
        bottom: '24px',
        right: '48px',
        containLabel: false,
      },
    };

    this.options = options;

    this.loading = false;
  }
  generateOptions2() {
    const options = {
      tooltip: {
        position: 'top',
        trigger: 'axis',
        formatter: function (params: any) {
          console.log(params);
          let contratada = '';
          try {
            if (params[1].value[1]) {
              contratada =
                ' <br> <span style="height: 0px; width: 5px; padding: 0px 2px; margin-right:3px; border-radius: 25px; background-color:' +
                params[1].color +
                '"></span>  <a style="font-size: 0.9rem"> Potencia contratada: ' +
                '  <a/> <b style="font-size: 0.9rem">' +
                String(params[1].value[1])?.replace('.', ',') +
                'kW';
            }
          } catch (Exception) {
            console.log('Problemas al formatear la potencia contratada');
          }
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params[0].color +
            '"></span>  <a style="font-size: 1.25rem"> Potencia registrada: ' +
            ' <br> <a/>  <b style="font-size: 1.25rem">' +
            params[0].value[1].replace('.', ',') +
            'kW  </b>  (' +
            params[0].axisValueLabel +
            ') ' +
            contratada
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        name: 'Hora',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        type: 'time',
        minInterval: 60 * 1000 * 10,
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
      },
      yAxis: {
        name: 'kW',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        type: 'value',
        splitLine: {
          show: true,
        },
      },

      series: [
        {
          name: 'Potencia (P1)',
          type: 'line',

          showSymbol: false,
          hoverAnimation: true,
          smooth: false,
          data: this.activePower,
          legend: {
            textStyle: {
              fontFamily: environment.fontFamily,
            },
            orient: 'horizontal',
            top: 'bottom',
          },
          itemStyle: {
            color: '#73C0DE',
            borderWidth: 50,
          },
          lineStyle: {
            color: '#73C0DE',
            width: 3,
          },
          symbolSize: 2,
        },
        ,
        {
          name: 'Potencia Contratada (P1)',
          type: 'line',

          showSymbol: false,
          hoverAnimation: false,
          smooth: false,
          data: this.hiredPower,

          itemStyle: {
            color: '#73C0DE',
            borderWidth: 50,
          },
          lineStyle: {
            color: '#73C0DE',
            width: 1.5,
          },
          symbolSize: 2,
        },
        {
          name: 'Potencia (P2)',
          type: 'line',

          showSymbol: false,
          hoverAnimation: true,
          smooth: false,
          data: this.activePower2,
          legend: {
            textStyle: {
              fontFamily: environment.fontFamily,
            },
            orient: 'horizontal',
            top: 'bottom',
          },
          itemStyle: {
            color: '#EB0B0B',
            borderWidth: 50,
          },
          lineStyle: {
            color: '#EB0B0B',
            width: 3,
          },
          symbolSize: 2,
        },
        ,
        {
          name: 'Potencia Contratada (P2)',
          type: 'line',

          showSymbol: false,
          hoverAnimation: false,
          smooth: false,
          data: this.hiredPower2,

          itemStyle: {
            color: '#EB0B0B',
            borderWidth: 50,
          },
          lineStyle: {
            color: '#EB0B0B',
            width: 1.5,
          },
          symbolSize: 2,
        },
      ],
      grid: {
        top: '32px',
        left: '32px',
        bottom: '24px',
        right: '48px',
        containLabel: false,
      },
    };

    this.options = options;

    this.loading = false;
  }
}
