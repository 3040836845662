<div class="savings extend">
  <div class="widget extend list">
    <div class="element home">
      <div class="icon">
        <img src="/assets/img/icons/enabled/electricity.svg" alt="" />
      </div>
      <h5 class="text">
        Vivienda
        <h2>{{ homeConsumptionResponse?.value | number : "1.2-2" }}kWh</h2>
      </h5>
    </div>
    <!--  <div class="element device">
            <div class="icon"><img src="/assets/img/icons/enabled/electricity.svg" alt=""></div>
            <h5 class="text">Enchufes
                <h2>{{plugsConsumptionResponse.value |number: '1.2-2'}}kWh</h2>
            </h5>
        </div> -->
  </div>
  <div class="widget extend list">
    <h1 class="secondary">Total</h1>
    <h4 class="secondary">Este mes</h4>
    <h1 class="cost">
      {{ homeConsumptionResponse?.value | number : "1.1-1" }}kWh
      <img src="/assets/img/icons/vs/same.svg" alt="" />
      {{ homeCostResponse?.value | number : "1.2-2" }} €
    </h1>
  </div>
</div>
