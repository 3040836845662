import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { HeatingControlService } from 'src/app/api';
import { NetatmoAssistantComponent } from '../netatmo-assistant/netatmo-assistant.component';
import { NetatmoScheduleViewComponent } from '../netatmo-schedule-view/netatmo-schedule-view.component';
import { NetatmoTimetableEditorComponent } from '../netatmo-timetable-editor/netatmo-timetable-editor.component';

@Component({
  selector: 'app-netatmo-day-scheduler',
  templateUrl: './netatmo-day-scheduler.component.html',
  styleUrls: ['./netatmo-day-scheduler.component.scss'],
})
export class NetatmoDaySchedulerComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,

    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<NetatmoDaySchedulerComponent>,
    private api: HeatingControlService
  ) {}

  dayWeek = [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ];
  presets: any = [];
  prevStatus!: string;
  homeId!: string;
  ngOnInit(): void {
    this.presets = this.data.tempInfo.appliedSchedule.presets;
    this.presets.find((element: number) => element > 10);
    this.prevStatus = JSON.stringify(this.data.tempInfo);
  }

  findPreset(time: any) {
    let value;
    try {
      value = this.data.tempInfo.appliedSchedule.presets.find(
        (x: any) => x.id === time.presetId
      );

      return value.color;
    } catch {
      return 'none';
    }

    return value;
  }
  findPresetTemp(time: any) {
    let value;
    try {
      value = this.data.tempInfo.appliedSchedule.presets.find(
        (x: any) => x.id === time.presetId
      );

      return value.setpointTemp;
    } catch {
      return 'none';
    }

    return value;
  }
  closeDialog() {
    this.dialogRef.close();
  }
  addTimeTable(time: any, index: number, day: string) {
    let auxTime = JSON.stringify(time);

    let newTime = JSON.parse(auxTime);
    newTime.startHour = parseInt(JSON.stringify(newTime.endHour));
    newTime.startMinute = parseInt(JSON.stringify(newTime.endMinute));
    newTime.endMinute = newTime.endMinute + 5;
    this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
      index + 1
    ].startMinute = parseInt(JSON.stringify(newTime.endMinute));

    if (
      JSON.stringify(
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          index + 1
        ].startHour
      ) ==
        JSON.stringify(
          this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
            index + 1
          ].endHour
        ) &&
      JSON.stringify(
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          index + 1
        ].startMinute
      ) ==
        JSON.stringify(
          this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
            index + 1
          ].endMinute
        )
    ) {
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day].splice(
        index + 1,
        1
      );
    }
    if (
      JSON.stringify(newTime) !=
      JSON.stringify(
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          index + 1
        ]
      )
    ) {
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day].splice(
        index + 1,
        0,
        newTime
      );
    }
    console.log(newTime);
    this.openEdit(newTime, index + 1);
  }
  noSave() {
    this.data.tempInfo.appliedSchedule.groupedTimeTable = JSON.parse(
      this.prevStatus
    ).appliedSchedule.groupedTimeTable;
    this.closeDialog();
  }
  saveChanges() {
    this.api
      .climaHomeHomeIdHeatingSchedulePost(
        this.data.homeId,
        this.data.tempInfo.appliedSchedule
      )
      .subscribe((response) => console.log(response));
    this.closeDialog();
  }

  deleteTimetable(index: number, dayTimeTable: Array<any>): void {
    if (dayTimeTable.length > 1) {
      if (index - 1 < 0) {
        dayTimeTable[index + 1].startHour = dayTimeTable[index].startHour;
        dayTimeTable[index + 1].startMinute = dayTimeTable[index].startMinute;
      } else {
        dayTimeTable[index - 1].endHour = dayTimeTable[index].endHour;
        dayTimeTable[index - 1].endMinute = dayTimeTable[index].endMinute;
      }
      dayTimeTable.splice(index, 1);
      this.snackBar.open('Programación borrada', 'ocultar', {
        duration: 5000,
      });
    } else {
      this.snackBar.open('Debe existir al menos una programación', 'ocultar', {
        duration: 5000,
      });
    }
  }
  openEdit(time: any, index: any): void {
    this.dialog.open(NetatmoTimetableEditorComponent, {
      data: {
        tempInfo: this.data.tempInfo,
        time: time,
        index: index,
        day: this.data.day,
      },
    });
  }
}
