<div class="body">
  <div class="main">
    <a class="return" [routerLink]="['../..']"
      ><img src="/assets/img/arrow-back.svg" style="padding: 0px 8px" />
      <h1 class="return-text">Volver a resumen</h1>
    </a>
    <div class="up">
      <div class="devices extend">
        <h2 class="title">Dispositivo instalado</h2>
        <div class="content">
          <app-water-status-detail
            class="extend"
            [type]="deviceType"
            [homeId]="homeId"
            [header]="
              deviceType == 'cold'
                ? 'agua fría sanitaria'
                : 'agua caliente sanitaria'
            "
          ></app-water-status-detail>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="expenses extend">
        <h2 class="title">
          <div class="text">Histórico de consumo</div>
          <div class="datepicker">
            <app-widget-datepicker
              class="extend"
              (dateRangeChange)="changeDate($event)"
            ></app-widget-datepicker>
          </div>
        </h2>
        <div class="content extend">
          <app-water-consumption-chart
            class="extend"
            [type]="deviceType"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-water-consumption-chart>
        </div>
      </div>
    </div>
  </div>
</div>
