import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  AirDataService,
  AirDeviceList,
  AirPrice,
  BoilerFuelType,
  BoilerFuelTypeList,
  Device,
  HeatingDataService,
  HeatingPriceList,
  ManagmentService,
} from 'src/app/api';

import { UserSettingsComponent } from '../user-settings/user-settings.component';

@Component({
  selector: 'app-clima-settings',
  templateUrl: './clima-settings.component.html',
  styleUrls: ['./clima-settings.component.scss'],
})
export class ClimaSettingsComponent implements OnInit {
  constructor(
    private airApi: AirDataService,
    private managementApi: ManagmentService,
    private heatingApi: HeatingDataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<UserSettingsComponent>
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }
  loading!: boolean;
  page!: number;
  type!: string;
  devicesResponse!: AirDeviceList;
  selectedDevice!: Device;
  airPrices!: AirPrice;
  combustible!: BoilerFuelType;
  fuelList!: BoilerFuelTypeList;
  heatingPrices!: HeatingPriceList;

  ngOnInit(): void {
    this.page = 0;
    this.loading = true;
    this.managementApi
      .homeHomeIdSensiboGet(this.data.id)
      .subscribe((response) => {
        this.devicesResponse = response;
        this.loading = false;
      });
  }

  loadPrices(device: any) {
    this.loading = true;
    this.selectedDevice = device.device.id;
    this.airApi
      .climaDeviceDeviceIdAirPriceGet(this.selectedDevice.id!)
      .subscribe((response) => {
        let aux: any = response;
        this.airPrices = aux.data[0];
        this.loading = false;
      });
    this.pageUp();
  }
  savePrices() {
    this.loading = true;
    this.airApi
      .climaDeviceDeviceIdAirPricePost(this.selectedDevice.id!, this.airPrices)
      .subscribe((response) => {
        console.log(response);
        this.fuelList = response;
        this.closeDialog();
      });
  }

  saveHeatPrices() {
    this.loading = true;
    this.heatingApi
      .climaHomeHomeIdHeatingPricePost(
        this.data.id,
        this.heatingPrices.data![0]
      )
      .subscribe((response) => {
        console.log(response);
        this.closeDialog();
      });
  }
  selectFuel(fuel: any) {
    this.combustible = fuel.name;
    console.log(this.heatingPrices);
    console.log(fuel);
    this.heatingPrices.data![0].boilerFuelType = fuel;
    this.pageUp();
  }
  switchMode(type: string): void {
    this.page += 1;
    this.type = type;
  }

  pageUp(): void {
    this.page += 1;
  }

  pageBack(): void {
    this.page = this.page - 1;
  }

  getFuelTypes(): void {
    this.loading = true;
    this.heatingApi
      .climaHomeHomeIdHeatingPricesGet(this.data.id)
      .subscribe((response) => (this.heatingPrices = response));
    this.heatingApi.climaHeatingFuellistGet().subscribe((response) => {
      console.log(response);
      this.fuelList = response;
      this.loading = false;
      this.pageUp();
    });
  }
}
