<div class="widget extend column">
  <h5>{{ dias[today] }}</h5>
  <div class="main">
    <div
      class="time-marker"
      [style]="'margin-left:' + (this.seconds / 86400) * 100 + '%'"
    ></div>
    <div class="day">
      <div
        class="time"
        *ngFor="let time of tempInfo.appliedSchedule.groupedTimeTable[today]"
        [style]="
          ' width: ' +
          (((time.endHour - time.startHour) * 60 +
            (time.endMinute - time.startMinute)) /
            1439) *
            100 +
          '%; background-color: ' +
          findPreset(time) +
          ';'
        "
      ></div>
    </div>
    <div class="markers">
      <div class="left body-1">00:00</div>
      <div class="right body-1">23:59</div>
    </div>
  </div>
  <div class="settings" (click)="openDialog()">
    <div class="caption">Programar</div>
    <div class="icon">
      <img
        src="/assets/img/navbar/config.svg"
        style="
          filter: invert(46%) sepia(0%) saturate(1439%) hue-rotate(176deg)
            brightness(93%) contrast(80%);
          padding-left: 4px;
        "
        alt=""
      />
    </div>
  </div>
</div>
