import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from 'src/app/api';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit, AfterViewInit {
  flavour: any;
  constructor(
    private route: ActivatedRoute,
    private client: ClientService,
    public common: CommonService
  ) {
    this.flavour = environment.flavour;
  }
  homeId!: string;
  @ViewChild('sidenav')
  private sidenav: any;
  ngOnInit(): void {
    this.common.getClientData();
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.sidenav.open();
    }, 1);
  }
}
