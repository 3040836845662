<div class="body">
  <div class="header">
    <div class="datepicker">
      <app-widget-datepicker
        class="extend"
        (dateRangeChange)="changeDate($event)"
      ></app-widget-datepicker>
    </div>
    <ng-container *ngIf="monitor; else isPlug"
      ><a class="return" [routerLink]="['../../..']"
        ><img src="/assets/img/arrow-back.svg" style="padding: 0px 8px" />
        <h1 class="return-text">Volver a resumen</h1>
      </a></ng-container
    >
    <ng-template #isPlug
      ><a class="return" [routerLink]="['../../../..']"
        ><img src="/assets/img/arrow-back.svg" style="padding: 0px 8px" />
        <h1 class="return-text">Volver a resumen</h1>
      </a></ng-template
    >
  </div>
  <div class="main">
    <div class="up">
      <div class="consumption-chart extend column">
        <h2 class="title">Evolución del consumo</h2>
        <div
          *ngIf="lastData != undefined"
          class="body-1"
          style="margin-top: -20px; margin-bottom: 8px"
        >
          Fecha del último dato recibido: {{ lastData | date : "shortDate" }}
        </div>
        <div class="content extend">
          <app-electricity-consumption-chart
            class="extend"
            [type]="'device'"
            [deviceId]="deviceId"
            [dateRange]="dateRange"
          ></app-electricity-consumption-chart>
        </div>
      </div>
      <div class="heatmap extend column">
        <h2 class="title">
          Mapa de calor
          <div class="doubts" (click)="openHeatmapHelp()">
            <div class="label light">¿Tienes dudas?</div>

            <div class="icon">
              <img src="/assets/img/help-icon.svg" alt="" />
            </div>
          </div>
        </h2>
        <div
          *ngIf="lastData != undefined"
          class="body-1"
          style="margin-top: -20px; margin-bottom: 8px"
        >
          Fecha del último dato recibido: {{ lastData | date : "shortDate" }}
        </div>
        <div class="content extend">
          <app-electricity-heatmap
            class="extend"
            [type]="'device'"
            [deviceId]="deviceId"
            [dateRange]="dateRange"
          ></app-electricity-heatmap>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="realtime-chart extend column">
        <h2 class="title">
          <ng-container *ngIf="monitor; else isPlug2"
            >Potencia activa vs potencia contratada (tiempo real)</ng-container
          >
          <ng-template #isPlug2>Potencia activa (tiempo real)</ng-template>
        </h2>
        <div class="content extend">
          <app-electricity-realtime-chart
            class="extend"
            [type]="'device'"
            [monitor]="monitor"
            [deviceId]="deviceId"
          ></app-electricity-realtime-chart>
        </div>
      </div>
      <div class="period-consumption extend column">
        <h2 class="title">Consumo por periodos horarios</h2>
        <div class="content extend">
          <app-electricity-consumption-by-period
            class="extend"
            [type]="'device'"
            [deviceId]="deviceId"
            [dateRange]="dateRange"
            [response]="response"
            [loading]="loading"
            [error]="error"
          ></app-electricity-consumption-by-period>
        </div>
      </div>
    </div>
  </div>
</div>
