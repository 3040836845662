import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeatingControlService, HomeInfo } from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-netatmo-controller',
  templateUrl: './netatmo-controller.component.html',
  styleUrls: ['./netatmo-controller.component.scss'],
})
export class NetatmoControllerComponent implements OnChanges {
  selected!: string;
  flavour: string = environment.flavour;

  @Input() tempInfo!: HomeInfo;
  constructor(
    private heatingApi: HeatingControlService,
    private route: ActivatedRoute
  ) {}
  homeId!: string;
  ngOnChanges(): void {
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    console.log(this.tempInfo);
    if (this.tempInfo) {
      if (this.tempInfo.controlMode === 'manual') {
        this.selected = 'manual';
      } else {
        this.selected = this.tempInfo.homeMode!;
      }
    }
  }

  frostWard() {
    this.heatingApi
      .climaHomeHomeIdHeatingSetcontrolmodePost(this.homeId, 'frostward')
      .subscribe();
  }
}
