/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HomeModules } from './homeModules';
import { RoomList } from './roomList';
import { ShortUser } from './shortUser';

export interface Home { 
    id?: string;
    homeName?: string;
    userId?: string;
    userName?: string;
    userEmail?: string;
    people?: number;
    surface?: string;
    year?: number;
    boilerType?: string;
    energyContract?: string;
    energyCost?: string;
    orientation?: string;
    primary?: boolean;
    netatmoHomeId?: string;
    createdAt?: Date;
    updatedAt?: Date;
    onVacations?: boolean;
    address?: string;
    city?: string;
    postalCode?: string;
    distributor?: string;
    cups?: string;
    user?: ShortUser;
    modules?: HomeModules;
    rooms?: RoomList;
}