import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnChanges,
  OnInit,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ElectricConsumption,
  ElectricDeviceService,
  ElectricHomeService,
} from 'src/app/api';
import { environment } from 'src/environments/environment';
import { HeatmapHelpComponent } from '../../components/electricity/heatmap-help/heatmap-help.component';

@Component({
  selector: 'app-mirubox',
  templateUrl: './mirubox.component.html',
  styleUrls: ['./mirubox.component.scss'],
})
export class MiruboxComponent implements OnInit, OnChanges {
  constructor(
    private route: ActivatedRoute,
    private api: ElectricDeviceService,
    public dialog: MatDialog
  ) {}
  homeId!: string;
  deviceId!: string;
  environment: any;
  dateRange!: FormGroup;
  response!: ElectricConsumption;
  error: any;
  lastData: Date | undefined;
  loading!: boolean;
  counter!: boolean;
  period: number = 1;
  ngOnInit(): void {
    environment.current = 'Contador fiscal';
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    this.deviceId = this.route.snapshot.paramMap.get('deviceId')!;

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });
    this.dateRange.valueChanges.subscribe();

    this.getData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
    this.getData();
  }
  changeDate(event: any): void {
    this.dateRange = event;
    this.getData();
  }

  openHeatmapHelp(): void {
    this.dialog.open(HeatmapHelpComponent);
  }
  getData(): void {
    this.loading = true;
    this.api
      .electricDeviceDeviceIdEnergyLastGet(this.deviceId)
      .subscribe((response) => {
        console.log(response.utcEndTime);
        if (response.utcEndTime) this.lastData = response.utcEndTime;
      });
    this.api
      .electricDeviceDeviceIdEnergyAbsoluteGet(
        this.deviceId,
        new Date(this.dateRange.value.start),
        new Date(this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000)
      )
      .subscribe(
        (response) => {
          this.response = response;
          this.loading = false;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.api
              .electricDeviceDeviceIdEnergyAbsoluteGet(
                this.deviceId,
                new Date(this.dateRange.value.start.toISOString()),
                new Date(
                  this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                )
              )
              .subscribe(
                (response) => {
                  this.response = response;
                  this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
}
