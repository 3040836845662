<div class="widget" *ngIf="!error">
  <div class="header" *ngIf="!loading">
    <div class="icon"><img src="/assets/img/settings/user.svg" alt="" /></div>
  </div>
  <div style="display: flex; flex: 1; width: 100%">
    <div class="loading" *ngIf="loading" style="min-height: 300px; flex: 1">
      <div class="loading-spinner"></div>
    </div>
  </div>
  <div class="body body-1" *ngIf="!loading">
    <div class="user">
      <div class="header">
        <div class="user-icon">
          <img src="/assets/img/settings/user.svg" alt="" />
        </div>
        <div class="name bold">{{ user.fullName }}</div>

        <div class="edit">
          <a class="icon">
            <img
              src="/assets/img/settings/edit.svg"
              (click)="openDialog()"
              alt=""
            />
          </a>
        </div>
      </div>
      <div class="content">
        <div class="list">
          <div class="element">
            <div class="element-name bold">Nombre</div>
            <div class="value">{{ user.fullName }}</div>
          </div>
          <div class="element">
            <div class="element-name bold">Correo electrónico</div>
            <div class="value">{{ user.email }}</div>
          </div>
          <div class="element">
            <div class="element-name bold">Teléfono</div>
            <div class="value">{{ user.phone }}</div>
          </div>

          <div class="element">
            <div class="element-name bold">Población</div>
            <div class="value">{{ user.city }}</div>
          </div>
          <div class="element">
            <div class="element-name bold">Código Postal</div>
            <div class="value">{{ user.postalCode }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
