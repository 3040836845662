// Automatically generated - do not modify!

import { createParamString } from '../internals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IManagmentApiService {
  /**
   * @method
   * @name  getPruebaCacheElectric
   * @description
   * @param {string} monthTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getPruebaCacheElectric(
    monthTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getPruebaCacheSensibo
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getPruebaCacheSensibo(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getPruebaCacheNetatmo
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getPruebaCacheNetatmo(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getLegaltextName
   * @description
   * @param {string} name
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getLegaltextName(
    name: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendCookiesAccept
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendCookiesAccept(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  updateUser
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  updateUser(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getUserHomes
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserHomes(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getChallenges
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getChallenges(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  updateUserHome
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  updateUserHome(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getUserHomeHomeId
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserHomeHomeId(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdWibeeeMirubox
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdWibeeeMirubox(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdWibeeePlugs
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdWibeeePlugs(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdHydrao
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdHydrao(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdDevicetypes
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdDevicetypes(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdNetatmo
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdNetatmo(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdSensibo
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdSensibo(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getUserDevices
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserDevices(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeDevices
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeDevices(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getRoomDevices
   * @description
   * @param {Record<string, unknown> | unknown} roomId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getRoomDevices(
    roomId?: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricPricesComers
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricPricesComers(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class ManagmentApiService implements IManagmentApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'ManagmentApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getPruebaCacheElectric
   * @description
   * @param {string} monthTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getPruebaCacheElectric(
    monthTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(['monthTime', monthTime]);
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/pruebaCache/electric`,
      options
    );
  }

  /**
   * @method
   * @name  getPruebaCacheSensibo
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getPruebaCacheSensibo(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/pruebaCache/sensibo`,
      options
    );
  }

  /**
   * @method
   * @name  getPruebaCacheNetatmo
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getPruebaCacheNetatmo(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/pruebaCache/netatmo`,
      options
    );
  }

  /**
   * @method
   * @name  getLegaltextName
   * @description
   * @param {string} name
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getLegaltextName(
    name: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/legaltext/${name}`,
      options
    );
  }

  /**
   * @method
   * @name  sendCookiesAccept
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendCookiesAccept(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/cookies/accept`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  updateUser
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public updateUser(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.put<Record<string, unknown> | unknown>(
      this.uri + `/user`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  getUserHomes
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserHomes(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/homes`,
      options
    );
  }

  /**
   * @method
   * @name  getChallenges
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getChallenges(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/challenges`,
      options
    );
  }

  /**
   * @method
   * @name  updateUserHome
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public updateUserHome(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.put<Record<string, unknown> | unknown>(
      this.uri + `/user/home`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  getUserHomeHomeId
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserHomeHomeId(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/home/${homeId}`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdWibeeeMirubox
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdWibeeeMirubox(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/wibeee/mirubox`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdWibeeePlugs
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdWibeeePlugs(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/wibeee/plugs`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdHydrao
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdHydrao(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/hydrao`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdDevicetypes
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdDevicetypes(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/devicetypes`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdNetatmo
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdNetatmo(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/netatmo`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdSensibo
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdSensibo(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/sensibo`,
      options
    );
  }

  /**
   * @method
   * @name  getUserDevices
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserDevices(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/devices`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeDevices
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeDevices(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/devices`,
      options
    );
  }

  /**
   * @method
   * @name  getRoomDevices
   * @description
   * @param {Record<string, unknown> | unknown} roomId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getRoomDevices(
    roomId?: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(['roomId', roomId]);
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/room/devices`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricPricesComers
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricPricesComers(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/prices/comers`,
      options
    );
  }
}
