import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-summary-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.scss'],
})
export class SavingsComponent implements OnInit, OnChanges {
  constructor() {
    this.savings = 0;
  }

  @Input() category!: string;
  @Input() loading!: boolean;
  @Input() response: any;
  @Input() error: any;
  data: any;
  options: any;

  color!: string;
  current: any;
  previous: any;
  savings!: number;
  noData: boolean = false;
  ngOnInit(): void {
    this.color = 'success';
  }
  ngOnChanges(): void {
    if (this.response && this.response.count == 0 && !!this.response)
      this.noData = true;
    if (!this.loading && !this.noData) {
      this.adaptData(this.response);
    }
  }
  adaptData(response: any): any {
    this.data = [];

    this.current = response.data[response.data.length - 1];
    this.previous = response.data[response.data.length - 2];

    this.savings = this.previous.cost - this.current.cost;
    for (const dato of response.data) {
      const elemento = {
        name: dato.utcStartTime,
        value: [new Date(dato.utcStartTime), dato.cost.toFixed(2)],
      };
      this.data.push(elemento);
    }

    this.setOptions();
  }

  setOptions(): void {
    let interval;

    interval = 60 * 60 * 1000 * 24 * 28;
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    const options = {
      tooltip: {
        position: 'top',
        trigger: 'axis',

        formatter: function (params: any) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background:' +
            params[0].color +
            '"></span>  <a style="font-size: 1rem">' +
            monthNames[new Date(params[0].name).getMonth()] +
            ' ' +
            new Date(params[0].name).getFullYear() +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params[0].value[1].replace('.', ',') +
            '€ </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },

      xAxis: {
        type: 'time',
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 600,
        },
        interval: 60 * 60 * 1000 * 24 * 90,
        minInterval: 60 * 60 * 1000 * 24 * 90,
        name: 'Mes',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 600,
        },

        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: 'Coste',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 600,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 600,
        },
        splitLine: {
          show: true,
        },
      },
      series: [
        {
          name: 'Coste',
          type: 'bar',
          data: this.data,
          color: environment.textColor,
          itemStyle: {
            borderRadius: [11, 11, 0, 0],
          },
        },
      ],
      grid: {
        top: 32,
        left: 32,
        right: 48,
        bottom: 32,
      },
    };

    this.options = options;
    this.loading = false;
  }
}
