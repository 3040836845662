import { Component, Input, OnInit } from '@angular/core';
import {
  WaterConsumption,
  WaterConsumptionList,
  WaterConsumptionToday,
  WaterDataService,
} from 'src/app/api';

@Component({
  selector: 'app-water-status-detail',
  templateUrl: './water-status-detail.component.html',
  styleUrls: ['./water-status-detail.component.scss'],
})
export class WaterStatusDetailComponent implements OnInit {
  loading: boolean = true;
  @Input() header!: string;
  @Input() type!: string;
  @Input() homeId!: string;
  @Input() error: any;
  response!: WaterConsumptionToday | undefined;
  response2!: any; //WaterConsumptionList;
  response3!: any; // no se instancia pero se una en html WaterConsumption
  // WaterConsumption | WaterConsumptionToday WaterConsumptionList no coinciden las variables
  // @ivan echar un ojo
  ahora;
  firstDate;
  lastDate;

  constructor(private homeApi: WaterDataService) {
    this.ahora = new Date();
    var date = new Date();
    this.firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  getData3(): void {
    this.homeApi
      .waterHomeHomeIdTypeConsumptionAvgDayGet(
        this.homeId,
        this.type,
        new Date(this.firstDate.toISOString().replace('Z', '')),
        new Date(this.lastDate.toISOString().replace('Z', ''))
      )
      .subscribe(
        (response) => {
          this.response3 = response;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .waterHomeHomeIdTypeConsumptionAvgDayGet(
                this.homeId,
                this.type,
                new Date(this.firstDate.toISOString().replace('Z', '')),
                new Date(this.lastDate.toISOString().replace('Z', ''))
              )
              .subscribe(
                (response) => {
                  this.response3 = response;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
  getData2(): void {
    this.homeApi
      .waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(
        this.homeId,
        this.type
      )
      .subscribe(
        (response) => {
          this.response2 = response;
          if (this.response) this.loading = false;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(
                this.homeId,
                this.type
              )
              .subscribe(
                (response) => {
                  this.response2 = response;
                  if (this.response) this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
  getData(): void {
    let hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    this.getData2();
    this.homeApi
      .waterHomeHomeIdTypeConsumptionTodayGet(this.homeId, this.type)
      .subscribe(
        (response) => {
          this.response = response;
          if (
            new Date(this.response.today!.utcEndTime!).getTime() < hoy.getTime()
          ) {
            this.response.today!.utcEndTime = hoy;
          }
          if (this.response2) this.loading = false;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .waterHomeHomeIdTypeConsumptionTodayGet(this.homeId, this.type)
              .subscribe(
                (response) => {
                  this.response = response;
                  if (this.response2) this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
  ngOnInit(): void {
    this.getData();
  }
}
