import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AirControlService } from './api/airControl.service';
import { AirDataService } from './api/airData.service';
import { AuthService } from './api/auth.service';
import { ClientService } from './api/client.service';
import { ClimaHomeService } from './api/climaHome.service';
import { ElectricDeviceService } from './api/electricDevice.service';
import { ElectricHomeService } from './api/electricHome.service';
import { HeatingControlService } from './api/heatingControl.service';
import { HeatingDataService } from './api/heatingData.service';
import { HomeService } from './api/home.service';
import { HomeConsumptionService } from './api/homeConsumption.service';
import { ManagmentService } from './api/managment.service';
import { UserConsumptionService } from './api/userConsumption.service';
import { VersionService } from './api/version.service';
import { WaterDataService } from './api/waterData.service';
import { WeatherService } from './api/weather.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AirControlService,
    AirDataService,
    AuthService,
    ClientService,
    ClimaHomeService,
    ElectricDeviceService,
    ElectricHomeService,
    HeatingControlService,
    HeatingDataService,
    HomeService,
    HomeConsumptionService,
    ManagmentService,
    UserConsumptionService,
    VersionService,
    WaterDataService,
    WeatherService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
