<div class="home">
  <div class="home-info extend widget" style="flex: 2">
    <h5 class="header" *ngIf="home">{{ home.homeName }}</h5>
    <div class="content" *ngIf="!loading && home">
      <div class="element">
        <div class="name nav-up">Dirección</div>
        <div class="value caption">{{ home.address }}</div>
      </div>
      <div class="element">
        <div class="name nav-up">Localidad</div>
        <div class="value caption">{{ home.city }}</div>
      </div>
      <div class="element">
        <div class="name nav-up">Superficie</div>
        <div class="value caption">
          {{ home.surface | number : "1.2-2" : "es" }}m2
        </div>
      </div>

      <div class="element">
        <div class="name nav-up">Año de construcción</div>
        <div class="value caption">{{ home.year }}</div>
      </div>

      <div class="element">
        <div class="name nav-up">Orientación</div>
        <div class="value caption">{{ home.orientation }}</div>
      </div>
      <div class="element">
        <div class="name nav-up" style="opacity: 00%">--</div>
        <div class="value"></div>
      </div>
      <div class="element">
        <div class="name nav-up">Tipo de caldera</div>
        <div class="value caption">{{ home.boilerType }}</div>
      </div>
      <div class="element">
        <div class="name nav-up">Número de habitantes</div>
        <div class="value caption">{{ home.people }}</div>
      </div>
      <div class="element">
        <div class="name nav-up">Fecha de alta</div>
        <div class="value caption">{{ home.createdAt | date : "short" }}</div>
      </div>
      <div class="element">
        <div class="name nav-up"></div>
        <div class="value caption"></div>
      </div>
    </div>
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
  <div class="footer">
    <a class="settings-icon-enabled" [routerLink]="'/user/settings'">
      <img src="/assets/img/icons/enabled/settings.svg" alt="" />
    </a>
  </div>
</div>
