<div class="body">
  <div class="header">
    <div class="datepicker"></div>
    <a class="return" [routerLink]="['../../../..']"
      ><img src="/assets/img/arrow-back.svg" style="padding: 0px 8px" />
      <h1 class="return-text">Volver a resumen</h1>
    </a>
  </div>
  <div class="main">
    <div class="left extend column">
      <div class="extend gap">
        <div class="climate column extend">
          <h2 class="title">Clima de la sala</h2>
          <div class="content extend">
            <app-room-climate
              class="extend"
              [deviceInfo]="deviceInfo"
              [loading]="loading"
            ></app-room-climate>
          </div>
        </div>
        <div class="consumption extend column" style="flex: 2">
          <h2 class="title">Consumo del dispositivo</h2>
          <div class="content extend">
            <app-cold-consumption
              class="extend"
              [homeId]="homeId"
              [deviceId]="deviceId"
              [type]="'device'"
              [header]="false"
              [dateRange]="dateRange"
            ></app-cold-consumption>
          </div>
        </div>
      </div>

      <div class="remote extend column">
        <h2 class="title">Control remoto</h2>
        <div class="content extend">
          <app-sensibo-control
            class="extend"
            [deviceId]="deviceId"
            [deviceInfo]="deviceInfo"
          ></app-sensibo-control>
        </div>
      </div>
      <div class="schedule extend column">
        <h2 class="title">
          <div class="text">Programador</div>
          <div class="add caption" (click)="openDialog()">
            Nueva programación
            <img src="/assets/img/icons/new_button.svg" alt="" />
          </div>
        </h2>
        <div class="content extend">
          <app-sensibo-schedule
            class="extend"
            [deviceInfo]="deviceInfo"
            [schedules]="deviceInfo?.result?.schedules"
            [deviceId]="deviceId"
          ></app-sensibo-schedule>
        </div>
      </div>
    </div>
    <div class="right extend column">
      <div class="chart extend column">
        <div class="title">
          <h2 class="text">Histórico de consumo</h2>
          <div class="datepicker">
            <app-widget-datepicker
              class="extend"
              (dateRangeChange)="changeDate($event)"
            ></app-widget-datepicker>
          </div>
        </div>
        <div class="content extend">
          <app-temperature-consumption-chart
            class="extend"
            [type]="'cold'"
            [homeId]="homeId"
            [deviceId]="deviceId"
            [dateRange]="dateRange"
          ></app-temperature-consumption-chart>
        </div>
      </div>
      <div class="schedule extend column">
        <h2 class="title">Temperatura tiempo real</h2>
        <div class="content extend">
          <app-temperature-chart
            class="extend"
            [type]="'device'"
            [deviceId]="deviceId"
          ></app-temperature-chart>
        </div>
      </div>
    </div>
  </div>
</div>
