import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Device, ElectricClassifiedDevices } from 'src/app/api';
@Component({
  selector: 'app-electricity-main-device',
  templateUrl: './main-device.component.html',
  styleUrls: ['./main-device.component.scss'],
})
export class MainDeviceComponent implements OnInit, OnChanges {
  constructor() {}
  @Input() loading!: boolean;
  @Input() homeId!: string;
  @Input() mainDevices!: ElectricClassifiedDevices | undefined;
  @Input() error: any;

  mainDevice!: Device | undefined;
  secondDevice: Device | undefined = undefined;
  noPlugs: boolean = false;
  ngOnInit(): void {}
  ngOnChanges() {
    if (
      this.mainDevices &&
      this.mainDevices.counters &&
      this.mainDevices.counters.length > 0
    ) {
      this.mainDevice = this.mainDevices.counters[0];
      if (this.mainDevices.monitors && this.mainDevices.monitors.length > 0) {
        this.secondDevice = this.mainDevices.monitors[0];
      }
    } else if (
      this.mainDevices &&
      this.mainDevices.monitors &&
      this.mainDevices.monitors.length > 0
    ) {
      this.mainDevice = this.mainDevices.monitors[0];
    } else {
      this.mainDevice = undefined;
    }
    if (
      this.mainDevices &&
      this.mainDevices.plugs &&
      this.mainDevices.plugs.length == 0
    )
      this.noPlugs = true;
  }
}
