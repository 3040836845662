<div class="widget">
  <div class="header">
    <h2 class="primary">Programar temperaturas de un día</h2>
  </div>
  <div class="body">
    <div class="text caption">
      Edita las franjas horarias de este día de la semana
    </div>

    <div class="days">
      <div class="left">
        <h5 class="dayweek">{{ dayWeek[data.day] }}</h5>
        <div class="day-contanier">
          <div class="day">
            <div
              class="time"
              *ngFor="
                let time of data.tempInfo.appliedSchedule.groupedTimeTable[
                  data.day
                ]
              "
              [style]="
                ' width: ' +
                (((time.endHour - time.startHour) * 60 +
                  (time.endMinute - time.startMinute)) /
                  1439) *
                  100 +
                '%; background-color: ' +
                findPreset(time) +
                ';'
              "
            ></div>
          </div>
        </div>
      </div>
      <div class="schedules">
        <div class="label">
          Selecciona las programaciones para rellenar el día. Puedes editarlos o
          quitarlos.
        </div>
        <div
          class="schedule-unit"
          *ngFor="
            let time of data.tempInfo.appliedSchedule.groupedTimeTable[
              data.day
            ];
            let i = index
          "
        >
          <div class="schedule label">
            <div class="icon-schedule">
              <img
                src="/assets/img/icons/schedule.svg"
                style="
                  max-height: 25px;
                  filter: invert(46%) sepia(0%) saturate(1439%)
                    hue-rotate(176deg) brightness(93%) contrast(80%);
                  padding-left: 4px;
                "
                alt=""
              />
            </div>
            <div class="startTime">
              {{ time.startHour | number: "2.0-0" }}:{{
                time.startMinute | number: "2.0-0"
              }}
            </div>
            <div class="endTime">
              {{ time.endHour | number: "2.0-0" }}:{{
                time.endMinute | number: "2.0-0"
              }}
            </div>
            <div class="preset">{{ findPresetTemp(time) }}ºC</div>
            <div
              class="preset-color"
              [style]="'max-width: 5px; background-color: ' + findPreset(time)"
            ></div>
            <div class="edit" (click)="openEdit(time, i)">
              <a class="icon" style="cursor: pointer">
                <img src="/assets/img/settings/edit.svg" alt="" />
              </a>
            </div>
            <div
              class="delete"
              (click)="
                deleteTimetable(
                  i,
                  data.tempInfo.appliedSchedule.groupedTimeTable[data.day]
                )
              "
            >
              <img
                style="cursor: pointer"
                src="/assets/img/icons/bin.svg"
                alt=""
              />
            </div>
          </div>
          <div
            *ngIf="!(time.endHour == 23 && time.endMinute == 59)"
            class="new"
            (click)="
              addTimeTable(
                time,
                i,
                data.tempInfo.appliedSchedule.groupedTimeTable[data.day]
              )
            "
          >
            +
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <button class="button-secondary caption" (click)="noSave()">
        Salir sin guardar
      </button>
      <button class="button-primary caption" (click)="saveChanges()">
        Guardar
      </button>
    </div>
  </div>
</div>
