// Automatically generated - do not modify!

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {
  AirControlApiService,
  AirDataApiService,
  AuthApiService,
  ClimaHomeApiService,
  ElectricDeviceApiService,
  ElectricHomeApiService,
  HeatingControlApiService,
  HeatingDataApiService,
  HomeApiService,
  HomeConsumptionApiService,
  ManagmentApiService,
  UserConsumptionApiService,
  VersionApiService,
  WeatherApiService,
} from './services';

@NgModule({
  imports: [HttpClientModule],
  exports: [],
  declarations: [],
  providers: [
    AirControlApiService,
    AirDataApiService,
    AuthApiService,
    ClimaHomeApiService,
    ElectricDeviceApiService,
    ElectricHomeApiService,
    HeatingControlApiService,
    HeatingDataApiService,
    HomeApiService,
    HomeConsumptionApiService,
    ManagmentApiService,
    UserConsumptionApiService,
    VersionApiService,
    WeatherApiService,
  ],
})
export class APIModule {}
