<div class="widget extend" *ngIf="!error">
  <div class="widget-content" *ngIf="!loading">
    <div class="header caption">Media</div>
    <div class="body">
      <h2 class="cost secondary">
        {{ response.cost || 0 | number : "1.2-2" }} €
      </h2>
      <b class="label">(exc. Imp)</b>
      <div class="label light time">
        {{ response.hours || 0 | number : "1.0-2" }} horas
      </div>
    </div>
  </div>
  <div class="widget-content" *ngIf="loading">
    <div class="header caption">Media</div>

    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>

<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
