import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ElectricConsumption } from 'src/app/api';
import { echartOptions } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity-consumption-by-period',
  templateUrl: './consumption-by-period.component.html',
  styleUrls: ['./consumption-by-period.component.scss'],
})
export class ConsumptionByPeriodComponent implements OnInit, OnChanges {
  @Input() homeId!: string;
  @Input() deviceId!: string;
  @Input() type!: string;
  @Input() dateRange!: FormGroup;
  @Input() response!: ElectricConsumption;
  @Input() loading!: boolean;
  @Input() error: any;
  data: any = [];
  options: any;

  demoResponseCost = {
    utcStartTime: '2021-08-03T02:00:00Z',
    utcEndTime: '2021-08-30T11:00:00Z',
    homeStartTime: '2021-08-03T04:00:00',
    homeEndTime: '2021-08-30T13:00:00',
    value: [
      0.0007522649999999978, 0.0005616224999999979, 0.0003778499999999989, 0, 0,
      0,
    ],
  };
  demoResponseEnergy = {
    utcStartTime: '2021-08-03T02:14:00Z',
    utcEndTime: '2021-08-30T10:51:00Z',
    homeStartTime: '2021-08-03T04:14:00',
    homeEndTime: '2021-08-30T12:51:00',
    value: [8.586601718333283, 11.359314410000023, 11.099299255, 0, 0, 0],
  };
  unit!: string;

  constructor() {}

  ngOnInit(): void {
    this.unit = 'energy';
    this.dateRange.valueChanges.subscribe((x) => this.loadData());
  }
  ngOnChanges(): void {
    this.loadData();
  }
  getEnergy(): void {
    this.adaptData(this.response.periodEnergy);
  }

  getCost(): void {
    this.adaptData(this.response.periodCost);
  }

  loadData(event?: any): void {
    if (event) {
      this.unit = event.value;
    }
    this.data = [];
    if (this.unit === 'energy') {
      this.getEnergy();
    }
    if (this.unit === 'cost') {
      this.getCost();
    }
  }
  adaptData(data: any): void {
    const colors = echartOptions.colorShort;
    const periodNames = ['1 (Punta)', '2 (Llano)', '3 (Valle)'];
    let index = 0;
    let datos = false;
    for (const periodo of data) {
      if (periodo !== 0) {
        datos = true;
        this.data[index] = {
          value: parseFloat(periodo),
          name: 'P' + periodNames[index],
          itemStyle: {
            color: colors[index],
          },
        };
        index++;
      }
      if (!datos) {
        this.data[0] = {
          value: 0.0,
          name: 'P1, P2, P3',
          itemStyle: {
            color: colors[0],
          },
        };
      }
    }

    this.setOptions();
  }
  setOptions(): void {
    let text;
    let suffix: string;
    if (this.unit === 'energy') {
      text = 'Consumo ';
      suffix = ' kWh';
    } else {
      text = 'Coste ';
      suffix = ' € (exc. Imp)';
    }

    this.options = {
      tooltip: {
        position: 'top',
        trigger: 'item',
      },
      legend: {
        textStyle: {
          fontFamily: environment.fontFamily,
          fontSize: '0.8rem',
        },
        padding: 0,
        position: 'bottom',

        formatter: text + 'en {name}',
      },
      series: [
        {
          name: text + 'por periodo horario',
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 3,
          },
          type: 'pie',
          radius: ['50%', '80%'],
          label: {
            show: false,
          },
          tooltip: {
            position: 'top',
            formatter: function (params: any) {
              return (
                '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
                params.color +
                '"></span>  <a style="font-size: 1rem">' +
                params.name +
                ' <br> <a/> <b style="font-size: 1.25rem"> ' +
                params.value.toFixed(2).replace('.', ',') +
                suffix +
                '  </b> (' +
                params.percent.toFixed(2).replace('.', ',') +
                '%)'
              );
            },

            textStyle: {
              fontFamily: environment.fontFamily,
            },
          },
          data: this.data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              borderWidth: 0,
            },
          },
        },
      ],
    };
  }
}
