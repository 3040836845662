import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  ComposedConsumptionList,
  Home,
  ManagmentService,
  UserConsumptionService,
} from 'src/app/api';
import { GlobalService } from 'src/app/core/services/global.service';
//import { Home } from 'src/app/shared/models/home.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homes',
  templateUrl: './homes.component.html',
  styleUrls: ['./homes.component.scss'],
})
export class HomesComponent implements OnInit {
  homesArray: Array<Home>;
  beta!: boolean;
  requested!: boolean;
  response!: ComposedConsumptionList | undefined;
  demoHome: any;
  loading!: boolean;
  error: any;

  constructor(
    private http: HttpClient,
    private global: GlobalService,
    private api: UserConsumptionService,
    private management: ManagmentService
  ) {
    this.requested = false;
    this.homesArray = new Array<Home>();
    this.error = 0;
    this.demoHome = {
      id: '73e5a1ff-1e9b-4268-9931-e4963d4c5087',
      homeName: 'EFICAE',
      userId: '3697040d-6b51-4be6-a2b0-692755e83f05',
      people: 3,
      surface: 90,
      year: 1970,
      boilerType: 'Caldera individual',
      energyContract: 'No lo sé en este momento',
      energyCost: '50 - 100 euros',
      orientation: 'No lo sé en este momento',
      primary: true,
      netatmoHomeId: null,
      createdAt: new Date('2021-05-12T16:47:16.685+00:00'),
      updatedAt: new Date('2021-05-12T16:48:57.414428+00:00'),
      onVacations: false,
      user: null,
      rooms: [],
      userExpenses: [],
    };
  }

  ngOnInit(): void {
    this.loading = true;
    environment.homeId = '';
    this.beta = environment.beta;
    environment.current = 'Mis viviendas';
    this.getData();
    setTimeout(() => {
      this.getHomes();
    }, 1);
  }

  getData(): void {
    this.api.userConsumptionLast12monthGet().subscribe(
      (response) => {
        this.response = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;

          this.api.userConsumptionLast12monthGet().subscribe(
            (response) => {
              this.response = response;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }

  getHomes(): void {
    let homes;
    if (this.global.userHomes) {
      this.homesArray = this.global.userHomes;
    }
    if (!this.requested) {
      this.requested = true;
      this.management.userHomesGet().subscribe((response) => {
        homes = response;
        this.homesArray = homes.data!;
        this.global.userHomes = homes.data;
      });
    }
  }
}
