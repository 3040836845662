<div class="widget">
  <div class="header">
    <h2 class="primary">Editar Vivienda</h2>
  </div>
  <div class="body">
    <div class="text caption">
      Aquí puedes configurar los datos de tu vivienda. Personaliza lo que
      necesites para poder identificarla correctamente en la plataforma
    </div>
    <div class="form">
      <div class="element">
        <div class="name body-1 bold primary">Nombre de tu Vivienda</div>
        <mat-form-field appearance="outline" class="input">
          <input
            matInput
            specialIsAlphaNumeric
            [(ngModel)]="editHome.homeName"
            required
          />
        </mat-form-field>
      </div>
      <div class="element">
        <div class="name body-1 bold primary">Dirección de la vivienda</div>
        <mat-form-field appearance="outline" class="input">
          <input
            specialIsAlphaNumeric
            matInput
            [(ngModel)]="editHome.address"
            required
          />
        </mat-form-field>
      </div>
      <div class="element">
        <div class="name body-1 bold primary">Localidad de la vivienda</div>
        <mat-form-field appearance="outline" class="input">
          <input
            specialIsAlphaNumeric
            matInput
            [(ngModel)]="editHome.city"
            required
          />
        </mat-form-field>
      </div>
      <div class="element">
        <div class="name body-1 bold primary">Superficie de la vivienda</div>
        <mat-form-field appearance="outline" class="input">
          <input
            matInput
            numeric
            type="number"
            [(ngModel)]="editHome.surface"
            required
          />
        </mat-form-field>
        <b class="body-1">m2</b>
      </div>

      <div class="element">
        <div class="name body-1 bold primary">Año de construcción</div>
        <mat-form-field
          appearance="outline"
          class="input"
          type="number"
          max="2030"
        >
          <input
            matInput
            numeric
            [(ngModel)]="editHome.year"
            type="number"
            required
          />
        </mat-form-field>
      </div>
      <div class="element select">
        <div class="name body-1 bold primary">Orientación de la vivienda</div>
        <mat-form-field appearance="outline">
          <mat-select
            [(value)]="editHome.orientation"
            placeholder="Orientación"
          >
            <mat-option
              *ngFor="
                let orientation of [
                  'Norte',
                  'Noreste',
                  'Noroeste',
                  'Sur',
                  'Sureste',
                  'Suroeste',
                  'Este',
                  'Oeste',
                  'No lo sé en este momento'
                ]
              "
              [value]="orientation"
            >
              {{ orientation }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="element">
        <div class="name body-1 bold primary">Número de habitantes</div>
        <mat-form-field appearance="outline" class="input" max="2030">
          <input
            matInput
            numeric
            [(ngModel)]="editHome.people"
            type="number"
            required
          />
        </mat-form-field>
      </div>

      <div class="buttons">
        <button class="button-secondary caption" (click)="closeDialog()">
          Salir sin guardar
        </button>
        <button class="button-primary caption" (click)="updateHome()">
          {{ saveText }}
        </button>
      </div>
    </div>
  </div>
</div>
