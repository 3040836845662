import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  Device,
  ElectricClassifiedDevices,
  ElectricConsumptionList,
  ElectricHomeService,
} from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity',
  templateUrl: './electricity.component.html',
  styleUrls: ['./electricity.component.scss'],
})
export class ElectricityComponent implements OnInit, OnChanges {
  constructor(private route: ActivatedRoute, private api: ElectricHomeService) {
    this.error = 0;
    this.lastData = undefined;
  }
  response!: ElectricConsumptionList;
  loading!: boolean;
  error: any;
  homeId!: string;
  dateRange!: FormGroup;

  devicesResponse!: ElectricClassifiedDevices;
  devicesLoading!: boolean;
  devicesError!: boolean;
  lastData: Date | undefined;
  ngOnInit(): void {
    this.loading = true;
    this.devicesLoading = true;

    environment.current = 'Electricidad';
    environment.currentRoute = this.route;
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    environment.homeId = this.homeId;

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });
    this.getDevicesData();
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
  }
  changeDate(event: any): void {
    this.dateRange = event;
  }

  getDevicesData(): void {
    this.api
      .electricHomeHomeIdEnergyLastGet(this.homeId)
      .subscribe((response) => {
        console.log(response.utcEndTime);
        if (response.utcEndTime) this.lastData = response.utcEndTime;
      });
    this.api.electricHomeHomeIdDevicesGet(this.homeId).subscribe(
      (response) => {
        this.devicesResponse = response;
        this.devicesLoading = false;
      },
      (error) => {
        this.devicesError = error.status;
        this.devicesLoading = false;
      }
    );
  }

  getData(): void {
    this.api.electricHomeHomeIdEnergyThisMonthGet(this.homeId).subscribe(
      (response) => {
        this.response = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api.electricHomeHomeIdEnergyThisMonthGet(this.homeId).subscribe(
            (response) => {
              this.response = response;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }
}
