<mat-drawer-container>
  <mat-drawer
    #sidenav
    class="sidebar"
    mode="side"
    position="start"
    opened
    disableClose
  >
    <app-sidebar></app-sidebar>
  </mat-drawer>

  <mat-drawer-content>
    <div class="mobile-header">
      <!-- <app-mobile-header></app-mobile-header>-->
    </div>
    <div class="header">
      <app-header></app-header>
    </div>

    <div class="content">
      <router-outlet></router-outlet>
    </div>

    <div class="mobile-footer">
      <!--<app-mobile-footer></app-mobile-footer>-->
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<div class="mobile">
  <div class="content">
    <div class="logo">
      <img [src]="common.getClientLogo()" alt="" />
    </div>
    <div class="phone"><img src="/assets/img/phone.svg" alt="" /></div>
    <div
      class="subtitle-1 phone-text"
      [ngClass]="flavour == 'hobeen' ? 'white' : ''"
    >
      Parece que estás intentando entrar desde tu dispositivo móvil <br />
      Disfruta de la experiencia
      {{ flavour === "hobeen" ? "Hobeen" : "Gestiona tu Energía" }} desde tu
      teléfono móvil descargando nuestra App en el siguiente enlace
    </div>
    <a
      class="button caption button-primary"
      [ngClass]="flavour == 'hobeen' ? 'button-secondary' : 'button.primary'"
      href="https://www.hobeen.com/get-app/"
      >Descarga la app</a
    >
  </div>
</div>
