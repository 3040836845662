<div class="widget extend column">
  <h5 class="header">Este mes <b class=""></b></h5>
  <div class="widget-content" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>
  <div class="extend" *ngIf="!loading" style="position: relative">
    <div
      class="caption"
      *ngIf="!loading"
      [attr.style]="
        ' height: 2px;width: 40%; margin-left: 10.6%; background-color: #888888; position: absolute; top:' +
        (94 -
          ((valueCold?.thisMonth.avgHomeConsume.ourConsume +
            valueHot?.thisMonth.avgHomeConsume.ourConsume) /
            ((valueCold?.thisMonth.avgHomeConsume.avgHomeConsume +
              valueHot?.thisMonth.avgHomeConsume.ourConsume) *
              2)) *
            100) +
        '%'
      "
    >
      <div style="font-size: 0.8em; line-height: 0; margin-top: -0.8em">
        Tu consumo
      </div>
    </div>
    <img
      src="
    /assets/img/home_average.svg"
      alt=""
    />
  </div>
</div>
