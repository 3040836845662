/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AirDeviceList } from '../model/airDeviceList';
import { ChallengeList } from '../model/challengeList';
import { Device } from '../model/device';
import { DeviceList } from '../model/deviceList';
import { DeviceTypesCount } from '../model/deviceTypesCount';
import { ElectricComerList } from '../model/electricComerList';
import { HeatDeviceList } from '../model/heatDeviceList';
import { Home } from '../model/home';
import { HomeList } from '../model/homeList';
import { LegalText } from '../model/legalText';
import { LoginData } from '../model/loginData';
import { UserInfoCamel } from '../model/userInfoCamel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ManagmentService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param deviceId 
     * @param adminToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cacheElectricDeviceIdPost(deviceId: string, adminToken?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cacheElectricDeviceIdPost(deviceId: string, adminToken?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cacheElectricDeviceIdPost(deviceId: string, adminToken?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cacheElectricDeviceIdPost(deviceId: string, adminToken?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling cacheElectricDeviceIdPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;
        if (adminToken !== undefined && adminToken !== null) {
            headers = headers.set('adminToken', String(adminToken));
        }

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/cache/electric/${encodeURIComponent(String(deviceId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param adminToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cacheWaterDeviceIdPost(deviceId: string, adminToken?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cacheWaterDeviceIdPost(deviceId: string, adminToken?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cacheWaterDeviceIdPost(deviceId: string, adminToken?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cacheWaterDeviceIdPost(deviceId: string, adminToken?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling cacheWaterDeviceIdPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;
        if (adminToken !== undefined && adminToken !== null) {
            headers = headers.set('adminToken', String(adminToken));
        }

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/cache/water/${encodeURIComponent(String(deviceId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public challengesGet(observe?: 'body', reportProgress?: boolean): Observable<ChallengeList>;
    public challengesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChallengeList>>;
    public challengesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChallengeList>>;
    public challengesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ChallengeList>('get',`${this.basePath}/challenges`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cookiesAcceptPost(body?: LoginData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cookiesAcceptPost(body?: LoginData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cookiesAcceptPost(body?: LoginData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cookiesAcceptPost(body?: LoginData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/cookies/accept`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricPricesComersGet(observe?: 'body', reportProgress?: boolean): Observable<ElectricComerList>;
    public electricPricesComersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricComerList>>;
    public electricPricesComersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricComerList>>;
    public electricPricesComersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricComerList>('get',`${this.basePath}/electric/prices/comers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeDevicesGet(observe?: 'body', reportProgress?: boolean): Observable<DeviceList>;
    public homeDevicesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceList>>;
    public homeDevicesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceList>>;
    public homeDevicesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeviceList>('get',`${this.basePath}/home/devices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdDevicetypesGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceTypesCount>;
    public homeHomeIdDevicetypesGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceTypesCount>>;
    public homeHomeIdDevicetypesGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceTypesCount>>;
    public homeHomeIdDevicetypesGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdDevicetypesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeviceTypesCount>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/devicetypes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdHydraoGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceList>;
    public homeHomeIdHydraoGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceList>>;
    public homeHomeIdHydraoGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceList>>;
    public homeHomeIdHydraoGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdHydraoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeviceList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/hydrao`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdNetatmoGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HeatDeviceList>;
    public homeHomeIdNetatmoGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatDeviceList>>;
    public homeHomeIdNetatmoGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatDeviceList>>;
    public homeHomeIdNetatmoGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdNetatmoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HeatDeviceList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/netatmo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdSensiboGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<AirDeviceList>;
    public homeHomeIdSensiboGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirDeviceList>>;
    public homeHomeIdSensiboGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirDeviceList>>;
    public homeHomeIdSensiboGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdSensiboGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AirDeviceList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/sensibo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdWibeeeMiruboxGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public homeHomeIdWibeeeMiruboxGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public homeHomeIdWibeeeMiruboxGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public homeHomeIdWibeeeMiruboxGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdWibeeeMiruboxGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Device>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/wibeee/mirubox`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdWibeeePlugsGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceList>;
    public homeHomeIdWibeeePlugsGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceList>>;
    public homeHomeIdWibeeePlugsGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceList>>;
    public homeHomeIdWibeeePlugsGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdWibeeePlugsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeviceList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/wibeee/plugs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public legaltextNameGet(name: string, observe?: 'body', reportProgress?: boolean): Observable<LegalText>;
    public legaltextNameGet(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LegalText>>;
    public legaltextNameGet(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LegalText>>;
    public legaltextNameGet(name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling legaltextNameGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LegalText>('get',`${this.basePath}/legaltext/${encodeURIComponent(String(name))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pruebaCacheAirGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pruebaCacheAirGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pruebaCacheAirGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pruebaCacheAirGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/pruebaCache/air`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pruebaCacheElectricGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pruebaCacheElectricGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pruebaCacheElectricGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pruebaCacheElectricGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/pruebaCache/electric`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pruebaCacheHeatingGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pruebaCacheHeatingGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pruebaCacheHeatingGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pruebaCacheHeatingGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/pruebaCache/heating`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pruebaCacheNetatmoActivityGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pruebaCacheNetatmoActivityGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pruebaCacheNetatmoActivityGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pruebaCacheNetatmoActivityGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/pruebaCache/netatmo/activity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pruebaCacheSensiboActivityGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pruebaCacheSensiboActivityGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pruebaCacheSensiboActivityGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pruebaCacheSensiboActivityGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/pruebaCache/sensibo/activity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pruebaCacheWaterGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pruebaCacheWaterGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pruebaCacheWaterGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pruebaCacheWaterGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/pruebaCache/water`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param roomId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public roomDevicesGet(roomId?: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceList>;
    public roomDevicesGet(roomId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceList>>;
    public roomDevicesGet(roomId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceList>>;
    public roomDevicesGet(roomId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (roomId !== undefined && roomId !== null) {
            queryParameters = queryParameters.set('roomId', <any>roomId);
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeviceList>('get',`${this.basePath}/room/devices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDevicesGet(observe?: 'body', reportProgress?: boolean): Observable<DeviceList>;
    public userDevicesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceList>>;
    public userDevicesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceList>>;
    public userDevicesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeviceList>('get',`${this.basePath}/user/devices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHomeHomeIdGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<Home>;
    public userHomeHomeIdGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Home>>;
    public userHomeHomeIdGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Home>>;
    public userHomeHomeIdGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling userHomeHomeIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Home>('get',`${this.basePath}/user/home/${encodeURIComponent(String(homeId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHomePut(body?: Home, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userHomePut(body?: Home, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userHomePut(body?: Home, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userHomePut(body?: Home, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/user/home`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userHomesGet(observe?: 'body', reportProgress?: boolean): Observable<HomeList>;
    public userHomesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HomeList>>;
    public userHomesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HomeList>>;
    public userHomesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HomeList>('get',`${this.basePath}/user/homes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userPut(body?: UserInfoCamel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userPut(body?: UserInfoCamel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userPut(body?: UserInfoCamel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userPut(body?: UserInfoCamel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/user`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
