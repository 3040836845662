import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ElectricConsumption, ElectricDeviceService } from 'src/app/api';
import { environment } from 'src/environments/environment';
import { HeatmapHelpComponent } from '../../components/electricity/heatmap-help/heatmap-help.component';

@Component({
  selector: 'app-wibeee',
  templateUrl: './wibeee.component.html',
  styleUrls: ['./wibeee.component.scss'],
})
export class WibeeeComponent implements OnInit, OnChanges {
  constructor(
    private route: ActivatedRoute,
    private api: ElectricDeviceService,
    public dialog: MatDialog
  ) {}
  deviceId!: string;
  loading!: boolean;
  error: any;
  response!: ElectricConsumption;
  dateRange!: FormGroup;
  monitor!: boolean;
  lastData: Date | undefined;
  ngOnInit(): void {
    this.deviceId = this.route.snapshot.paramMap.get('deviceId')!;
    if (this.route.snapshot.parent?.routeConfig?.path == 'monitor') {
      this.monitor = true;
      environment.current = 'Monitor eléctrico general';
    } else {
      this.monitor = false;
      if (this.route.snapshot.paramMap.get('deviceName') === 'null') {
        environment.current = 'Electricidad - ' + 'Enchufe Inteligente';
      } else {
        environment.current =
          'Electricidad - ' + this.route.snapshot.paramMap.get('deviceName');
      }
    }

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });
    this.dateRange.valueChanges.subscribe(() => {});
    this.getData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
    this.getData();
  }
  changeDate(event: any): void {
    this.dateRange = event;
    this.getData();
  }

  openHeatmapHelp(): void {
    this.dialog.open(HeatmapHelpComponent);
  }
  getData(): void {
    this.loading = true;
    this.api
      .electricDeviceDeviceIdEnergyLastGet(this.deviceId)
      .subscribe((response) => {
        console.log(response.utcEndTime);
        if (response.utcEndTime) this.lastData = response.utcEndTime;
      });
    this.api
      .electricDeviceDeviceIdEnergyAbsoluteGet(
        this.deviceId,
        new Date(this.dateRange.value.start),
        new Date(this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000)
      )
      .subscribe(
        (response) => {
          this.response = response;
          this.loading = false;
        },
        (error) => {
          this.error = error.status;
          setTimeout(() => {
            this.loading = true;
            this.error = null;
            this.api
              .electricDeviceDeviceIdEnergyAbsoluteGet(
                this.deviceId,
                new Date(this.dateRange.value.start),
                new Date(
                  this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                )
              )
              .subscribe(
                (response) => {
                  this.response = response;
                  this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          });
        }
      );
  }
}
