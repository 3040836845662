import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ElectricComerList,
  ElectricHomeService,
  ManagmentService,
} from 'src/app/api';

@Component({
  selector: 'app-bill-settings',
  templateUrl: './bill-settings.component.html',
  styleUrls: ['./bill-settings.component.scss'],
})
export class BillSettingsComponent implements OnInit {
  constructor(
    private api: ElectricHomeService,
    private management: ManagmentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BillSettingsComponent>
  ) {
    this.comers = {
      count: 8,
      data: [
        {
          id: 0,
          name: 'Sin definir',
          lowRegulatedConsumer: true,
          lowIndexedConsumer: false,
          indexedFormula: '',
        },
        {
          id: 1,
          name: 'WeGo',
          lowRegulatedConsumer: false,
          lowIndexedConsumer: true,
          indexedFormula: '[pvpc]+[0.35][€/dia]',
        },
        {
          id: 2,
          name: 'Unieléctrica',
          lowRegulatedConsumer: false,
          lowIndexedConsumer: true,
          indexedFormula: '',
        },
        {
          id: 3,
          name: 'Iberdrola',
          lowRegulatedConsumer: false,
          lowIndexedConsumer: false,
          indexedFormula: null,
        },
        {
          id: 4,
          name: 'Holaluz',
          lowRegulatedConsumer: false,
          lowIndexedConsumer: false,
          indexedFormula: null,
        },
        {
          id: 5,
          name: 'ASAL',
          lowRegulatedConsumer: false,
          lowIndexedConsumer: false,
          indexedFormula: null,
        },
        {
          id: 6,
          name: 'Energya VM',
          lowRegulatedConsumer: false,
          lowIndexedConsumer: false,
          indexedFormula: null,
        },
        {
          id: 7,
          name: 'Energía XXI',
          lowRegulatedConsumer: true,
          lowIndexedConsumer: false,
          indexedFormula: null,
        },
        {
          id: 8,
          name: 'MIW Energía',
          lowRegulatedConsumer: false,
          lowIndexedConsumer: false,
          indexedFormula: null,
        },
      ],
    };

    this.demoPrice = {
      id: 'd4ab3d45-23cb-4a80-a00b-dc5583b9cc3d',
      homeId: '4af47102-32a4-435c-ada9-875afc9399c1',
      tariff: {
        id: 7,
        name: '2.0 TD',
        voltageLevel: 'BT',
        energyPeriods: 3,
        powerPeriods: 2,
        reactive: false,
        powerExcess: false,
      },
      comer: {
        id: 1,
        name: 'WeGo',
        lowRegulatedConsumer: false,
        lowIndexedConsumer: true,
        indexedFormula: '[pvpc]+[0.35][€/dia]',
      },
      counterType: 5,
      nonFixedPrice: true,
      reactive: false,
      powerExcess: false,
      energyDtoPercentage: 0,
      powerDtoPercentage: 0,
      socialBonusPercentage: 0,
      energyPrices: [0.2, 0.15, 0.1, null, null, null],
      hiredPower: [3.3, 3.3, null, null, null, null],
      powerPrices: [0.15, 0.1, null, null, null, null],
      rentByDay: 0,
      otherByDay: 0,
      priceStartTime: '2021-06-01T04:00:00',
      priceEndTime: '2100-01-01T02:00:00',
      billStartTime: '2021-10-01T03:00:00',
      billEndTime: '2021-11-01T03:00:00',
      billPeriod: '1M',
      holidays: null,
      contractDeadline: null,
    };
  }

  closeDialog() {
    this.dialogRef.close();
  }
  demoPrice: any;
  price!: any;
  loading!: boolean;
  error: any;
  comers!: any;
  ahora!: Date;
  dateStart!: Date;
  saveText!: string;
  getComers(): any {
    this.price.comer = this.comers.data![this.price.comer.id];
    this.management.electricPricesComersGet().subscribe((response) => {
      this.comers = response;
      this.price.comer = this.comers.data![this.price.comer.id];
    });
  }
  getPrices(): void {
    this.api.electricHomeHomeIdPricesGet(this.data.id).subscribe(
      (response) => {
        let x: any = response;
        this.price = x.data[0];
        this.dateStart = new Date(this.price.billStartTime);
        this.getComers();

        this.loading = false;
      },

      (error) => {
        this.error = error.status;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api.electricHomeHomeIdPricesGet(this.data.id).subscribe(
            (response) => {
              let x: any = response;
              this.price = x.data[0];
              this.dateStart = new Date(this.price.billStartTime);
              this.getComers();

              this.loading = false;
            },

            (error) => (this.error = error.status)
          );
        }, 15000);
      }
    );
  }
  updateUser(): void {}
  ngOnInit(): void {
    this.loading = true;
    this.saveText = 'Guardar';
    this.ahora = new Date();
    this.getPrices();
  }

  cambioFecha(event: MatDatepickerInputEvent<Date>): void {
    let fecha = new Date(event.value!);
    this.price.billStartTime = fecha.toISOString().replace('Z', '');
    this.price.priceStartTime = fecha.toISOString().replace('Z', '');
  }

  updateBill(): void {
    if (this.price.comer.lowRegulatedConsumer) {
      this.price.nonFixedPrice = true;
    }
    this.saveText = 'Guardando...';
    this.api.electricHomeHomeIdPricePost(this.data.id, this.price).subscribe(
      (response) => this.closeDialog(),
      (error) => {
        this.saveText = 'No se ha podido guardar';
        setTimeout(() => {
          this.saveText = 'Guardar';
        }, 3000);
      }
    );
  }
}
