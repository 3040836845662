<div class="widget" *ngIf="!error">
    <div class="list" *ngIf="!loading">
        <div (click)="openDialog(challenge)" class="element" *ngFor="let challenge of challengesResponse?.data">
            <div class="content">
                <div class="logo"><img src="{{ challenge.icon }}" alt="" /></div>
                <div class="text nav-up">{{ challenge.title }}</div>
            </div>
        </div>
    </div>

    <div class="loading" *ngIf="loading">
        <div class="loading-spinner"></div>
    </div>
</div>

<div class="widget error" *ngIf="error">
    <app-error [error]="error"></app-error>
</div>