<div class="home">
  <a class="home-header" [routerLink]="['/home', home.id, 'summary']">
    <div class="text">
      <h5 class="title">{{ home.homeName }}</h5>
      <div class="subtitle body-1">{{ home.address }}</div>
    </div>
    <div class="arrow"><img src="/assets/img/new-arrow.svg" alt="" /></div>
  </a>
  <app-error
    [error]="error"
    *ngIf="error"
    style="padding-top: 16px"
  ></app-error>
  <div class="home-body" *ngIf="!loading && !error">
    <div class="icons">
      <div class="electricity">
        <div class="icon">
          <img src="/assets/img/icons/widget/electricity.svg" alt="" />
        </div>
        <div class="amount body-1">
          <ng-container *ngIf="this.response?.electric?.count! > 0; else else1">
            {{
              (this.response?.electric?.data)![1].cost || 0
                | number : "1.2-2" : "es"
            }}
            €</ng-container
          >
          <ng-template #else1>0,00 €</ng-template>
        </div>
      </div>
      <div class="water" *ngIf="!(environment.flavour == 'total')">
        <div class="icon">
          <img src="/assets/img/icons/widget/water.svg" alt="" />
        </div>

        <div class="amount body-1">
          <!--   {{ this.response?.air.data[1].cost || 0 | number: "1.2-2":"es" }} € -->--
          €
        </div>
      </div>

      <div class="temperature">
        <div class="icon">
          <img src="/assets/img/icons/widget/temperature.svg" alt="" />
        </div>

        <div class="amount body-1">
          <ng-container *ngIf="(this.response?.heating?.data)![1]; else else2"
            >{{
              (this.response?.heating?.data)![1].cost || 0
                | number : "1.2-2" : "es"
            }}
            €</ng-container
          >
          <ng-template #else2>0,00 €</ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="home-body" style="justify-content: flex-start">
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
  <hr *ngIf="!error" />
  <div class="comparative body-1" *ngIf="!loading && !error">
    <div class="last-month">
      <div class="title body-1">Mes anterior</div>
      <div class="amount bold">
        <ng-container *ngIf="(this.response?.total?.data)![0]; else else3"
          >{{
            (this.response?.total?.data)![0].cost || 0 | number : "1.2-2" : "es"
          }}
          €</ng-container
        >
        <ng-template #else3>0,00 €</ng-template>
      </div>
    </div>
    <div
      class="symbol"
      *ngIf="
        (this.response?.total?.data)![0]?.cost! >
        (this.response?.total?.data)![1]?.cost!
      "
    >
      <img *ngIf="!empty" src="/assets/img/icons/vs/less.svg" alt="" />
    </div>
    <div
      class="symbol"
      *ngIf="
        (this.response?.total?.data)![0]?.cost! <=
        (this.response?.total?.data)![1]?.cost!
      "
    >
      <img *ngIf="!empty" src="/assets/img/icons/vs/more.svg" alt="" />
    </div>
    <div class="this-month">
      <div class="title">Este mes</div>
      <div class="amount bold">
        <ng-container *ngIf="(this.response?.total?.data)![1]; else else4">
          {{
            (this.response?.total?.data)![1].cost || 0 | number : "1.2-2" : "es"
          }}
          €</ng-container
        >
        <ng-template #else4>0,00 €</ng-template>
      </div>
    </div>
  </div>
  <div
    class="label comparative"
    style="text-align: center; margin: auto; padding: 0px"
    *ngIf="!loading && !error"
  >
    (exc. Imp.)
  </div>
  <div class="home-footer" *ngIf="!loading && !error">
    <div
      class="text label success bold"
      *ngIf="
        (this.response?.total?.data)![0]?.cost! >
          (this.response?.total?.data)![1]?.cost! && !empty
      "
    >
      Llevas un ahorro de
      {{
        (this.response?.total?.data)![0].cost! -
          (this.response?.total?.data)![1].cost! || 0 | number : "1.2-2" : "es"
      }}
      € este mes
    </div>
    <div
      class="text label error-color bold"
      *ngIf="
        (this.response?.total?.data)![0]?.cost! <=
          (this.response?.total?.data)![1]?.cost! && !empty
      "
    >
      Llevas gastado
      {{
        (this.response?.total?.data)![1].cost! -
          (this.response?.total?.data)![0].cost! || 0 | number : "1.2-2" : "es"
      }}
      € más este mes
    </div>

    <div
      class="text label error-color bold"
      *ngIf="!loading && !error && empty"
    >
      No hay datos disponibles
    </div>
  </div>
  <div class="comparative" *ngIf="loading">
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>
