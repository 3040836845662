import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { HeatingControlService } from 'src/app/api';
import { NetatmoScheduleViewComponent } from '../netatmo-schedule-view/netatmo-schedule-view.component';

@Component({
  selector: 'app-netatmo-programmer',
  templateUrl: './netatmo-programmer.component.html',
  styleUrls: ['./netatmo-programmer.component.scss'],
})
export class NetatmoProgrammerComponent implements OnInit, AfterViewInit {
  loading!: boolean;
  today;
  homeId!: string;
  now;
  seconds;
  dias = [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ];
  @Input() tempInfo: any;
  constructor(
    public dialog: MatDialog,
    private heatingApi: HeatingControlService,
    private route: ActivatedRoute
  ) {
    this.loading = true;
    this.now = new Date();

    const defaultDayIndex = this.now.getDay();

    this.today = defaultDayIndex === 0 ? 6 : defaultDayIndex - 1;
    this.seconds =
      this.now.getSeconds() +
      60 * (this.now.getMinutes() + 60 * this.now.getHours());
  }

  ngOnInit(): void {
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
  }

  ngAfterViewInit(): void {
    this.heatingApi
      .climaHomeHomeIdHeatingSetcontrolmodePost(this.homeId, 'schedule')
      .subscribe();
  }

  openDialog(): void {
    this.dialog.open(NetatmoScheduleViewComponent, {
      data: { tempInfo: this.tempInfo, homeId: this.homeId },
    });
  }
  findPreset(time: any) {
    let value;
    try {
      value = this.tempInfo.appliedSchedule.presets.find(
        (x: any) => x.id === time.presetId
      );
      if (value == undefined) {
        return 'black';
      }
      return value.color;
    } catch {
      return 'black';
    }

    return value;
  }
}
