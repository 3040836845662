<div class="widget" *ngIf="!error">
    <div class="chart" echarts [options]="options" [loading]="loading" [merge]="options" style="flex: 1; min-height: auto" *ngIf="!loading"></div>
    <div class="chart" *ngIf="loading">
        <div class="loading-spinner"></div>
    </div>
    <div class="toggle">
        <mat-button-toggle-group class="left" (change)="adaptData(this.response)" [(value)]="unit">
            <mat-button-toggle value="cost">€</mat-button-toggle>
            <mat-button-toggle value="energy">kWh</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="vr">&nbsp;</div>
        <mat-button-toggle-group class="right" (change)="reloadData()" [(value)]="ratio">
            <mat-button-toggle value="hour">Hora</mat-button-toggle>
            <mat-button-toggle value="day">Día</mat-button-toggle>
            <mat-button-toggle value="week">Semana</mat-button-toggle>
            <mat-button-toggle value="month">Mes</mat-button-toggle>
            <mat-button-toggle value="year">Año</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
<div class="widget error" *ngIf="error">
    <app-error [error]="error"></app-error>
</div>