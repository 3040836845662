<div class="widget">
  <div class="text subtitle-1">
    <div>
      Desde
      {{ flavour === "hobeen" ? "Hobeen" : "Total Energies" }} tratamos de
      ofrecerte la información más exacta sobre los costes asociados a los
      consumos que medimos. Para poder indicar correctamente los costes
      asociados a tu consumo eléctrico, necesitamos que mantengas actualizada la
      información de facturación de tu vivienda.
    </div>
    <div style="font-weight: 500">
      Por defecto, cada vivienda que añadas mostrará los costes asociados al
      precio de mercado. Si tu instalación tiene un precio fijo u otra
      configuración diferente, asegúrate de incluirlo introduciendo la
      información de una de tus facturas eléctricas actuales en el

      <b>"Configurador de precios de electricidad"</b> de tu vivienda en este
      panel.
    </div>
  </div>
  <div class="icon"><img src="/assets/img/settings/home.svg" alt="" /></div>
</div>
