<div class="widget extend column" *ngIf="!error">
  <h5 class="header">Este mes <b class=""></b></h5>
  <div class="widget-content" *ngIf="!loading">
    <div
      echarts
      [options]="options"
      [loading]="loading"
      [merge]="options"
      class="chart"
    ></div>
  </div>

  <div class="widget-content" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>
</div>
<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
