<div class="header">
  <div class="left">
    <h1 class="title">{{ obtenerPagina() }}</h1>
  </div>
  <div class="right">
    <div class="welcome-container">
      <div class="avatar">
        <img
          src="{{ environment.apiUrl.replace('backend', 'api') }}/{{
            loggedUser.photo
          }}"
          alt=""
        />
      </div>
      <div class="welcome body-1 bold">
        Bienvenido <b class="user-name secondary">{{ loggedUser.fullName }}</b>
      </div>
    </div>
    <div class="selector">
      <mat-form-field [floatLabel]="'always'" appearance="outline">
        <mat-label>Vivienda</mat-label>
        <mat-select
          [(value)]="selectedHome"
          placeholder="No seleccionada"
          (selectionChange)="navigateNewHome()"
        >
          <mat-option *ngFor="let home of homesArray" [value]="home"
            >{{ home.homeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
