<div class="widget extend netatmo" *ngIf="tempInfo">
  <h5 class="header">Modos</h5>
  <div class="toggle" [ngClass]="flavour == 'total' ? 'total' : ''">
    <mat-button-toggle-group class="right" [(value)]="selected">
      <mat-button-toggle value="manual" class="body-1 bold"
        >MANUAL</mat-button-toggle
      >
      <mat-button-toggle
        value="schedule"
        class="body-1 bold"
        [disabled]="!tempInfo"
        >PROGRAMADOR</mat-button-toggle
      >
      <mat-button-toggle
        value="hg"
        class="body-1 bold"
        [disabled]="!tempInfo"
        (click)="frostWard()"
        >ANTIHIELO</mat-button-toggle
      >
      <mat-button-toggle value="away" class="body-1 bold" [disabled]="!tempInfo"
        >FUERA DE CASA</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <div class="content extend">
    <app-netatmo-manual-controller
      class="extend"
      *ngIf="selected == 'manual'"
      [tempInfo]="tempInfo"
    >
    </app-netatmo-manual-controller>
    <app-netatmo-programmer
      class="extend"
      *ngIf="selected == 'schedule'"
      [tempInfo]="tempInfo"
    ></app-netatmo-programmer>
    <app-netatmo-outside-controller
      class="extend"
      *ngIf="selected == 'away'"
      [tempInfo]="tempInfo"
    >
    </app-netatmo-outside-controller>

    <h5 *ngIf="selected == 'hg'" class="extend antifreeze">
      La temperatura se mantendrá a {{ tempInfo.appliedSchedule?.hgTemp }}ºC,
      esto evitará que se congelen las cañerías.
    </h5>
  </div>
</div>
<div class="widget extend netatmo" *ngIf="!tempInfo">
  <div class="widget-content">
    <div class="loading-spinner"></div>
  </div>
</div>
