import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AirControlService } from 'src/app/api';
import { ProgrammingWizardComponent } from '../programming-wizard/programming-wizard.component';

@Component({
  selector: 'app-new-programming-wizard',
  templateUrl: './new-programming-wizard.component.html',
  styleUrls: ['./new-programming-wizard.component.scss'],
})
export class NewProgrammingWizardComponent implements OnInit {
  loading!: boolean;
  saveText: string = 'Guardar';
  schedule: any;

  state!: string;
  temperaturesArray: any;
  startHour: string = '09:00';
  endHour: string = '10:00';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ProgrammingWizardComponent>,
    private api: AirControlService
  ) {
    this.temperaturesArray =
      this.data.deviceInfo.result.remoteCapabilities.modes.cool.temperatures.c.values;

    this.schedule = {
      targetTimeLocal: '00:00',
      timezone: 'Europe/Madrid',
      acState: {
        on: true,
        mode: 'dry',
        fanLevel: 'auto',
        targetTemperature: this.temperaturesArray[0],
        temperatureUnit: 'C',
        swing: 'stopped',
      },
      recurOnDaysOfWeek: [],
    };
    this.saveText = 'Guardar';
    this.loading = false;
  }

  ngOnInit(): void {
    this.schedule = {
      targetTimeLocal: '00:00',
      timezone: 'Europe/Madrid',
      acState: {
        on: true,
        mode: 'dry',
        fanLevel: 'auto',
        targetTemperature: this.temperaturesArray[0],
        temperatureUnit: 'C',
        swing: 'stopped',
      },
      recurOnDaysOfWeek: ['Monday'],
    };
    if (this.schedule.acState.on) {
      this.state = 'on';
    } else {
      this.state = 'off';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  setSchedule(): void {
    console.log(this.schedule);
    if (this.state === 'on/off') {
      this.schedule.acState.on = true;
      this.schedule.targetTimeLocal = this.startHour;
      this.api
        .climaDeviceDeviceIdAirSchedulePost(this.data.deviceId, this.schedule)
        .subscribe((response) => {
          this.data.schedules.unshift(response);
          this.dialogRef.close();
        });
      this.schedule.acState.on = false;
      this.schedule.targetTimeLocal = this.endHour;
      this.api
        .climaDeviceDeviceIdAirSchedulePost(this.data.deviceId, this.schedule)
        .subscribe((response) => {
          this.data.schedules.unshift(response);
          this.dialogRef.close();
        });
    } else {
      if (this.state === 'on') {
        this.schedule.acState.on = true;
      } else {
        this.schedule.acState.on = false;
      }

      this.api
        .climaDeviceDeviceIdAirSchedulePost(this.data.deviceId, this.schedule)
        .subscribe((response) => {
          this.data.schedules.unshift(response);
          this.dialogRef.close();
        });
    }
  }
}
