import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AuthService, ManagmentService, UserFromAuth } from 'src/app/api';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  loading!: boolean;
  text!: string;
  saveText!: string;

  //@Input() user;
  editUser!: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authApi: AuthService,
    private api: ManagmentService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<UserSettingsComponent>
  ) {}

  closeDialog(bool?: boolean) {
    this.dialogRef.close(bool);
  }
  uneditedUser!: any;
  ngOnInit(): void {
    this.saveText = 'Guardar';
    this.editUser = JSON.stringify(this.data);
    this.editUser = JSON.parse(this.editUser);
    this.loading = false;
  }

  sendPasswordReset(): void {
    this.loading = true;
    this.authApi.authPasswordSendCodePost(this.data.email).subscribe(() => {
      this.loading = false;
    });
  }

  updateUser(): void {
    this.saveText = 'Guardando...';

    this.api.userPut({ user: this.editUser }).subscribe(
      (response) => {
        this.data.email = this.editUser.email;
        this.data.full_name = this.editUser.full_name;
        this.data.phone = this.editUser.phone;
        this.data.city = this.editUser.city;
        this.data.postal_code = this.editUser.postal_code;
        this.closeDialog(true);
      },
      (error) => {
        this.saveText = 'No se ha podido guardar';
        setTimeout(() => {
          this.saveText = 'Guardar';
        }, 3000);
      }
    );
  }
}
