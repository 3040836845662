/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserFromAuth { 
    id?: string;
    fullName?: string;
    email?: string;
    phone?: string;
    birthdate?: Date;
    audience?: Array<string>;
    status?: string;
    points?: number;
    outOfHome?: boolean;
    address?: string;
    city?: string;
    postalCode?: string;
    deliveryNote?: string;
    customerId?: string;
    createdAt?: Date;
    updatedAt?: Date;
    levelUp?: Date;
    lastConnection?: Date;
    photo?: string;
    nickname?: string;
}