<div class="widget extend" *ngIf="!error">
    <div class="widget-content" *ngIf="!loading">
        <h5 class="header">Este mes (según análisis elaborado por IDAE)</h5>
        <div echarts [options]="options" [loading]="loading" [merge]="options" class="chart"></div>
    </div>

    <div class="widget-content" *ngIf="loading">
        <div class="loading-spinner"></div>
    </div>
</div>
<div class="widget error" *ngIf="error">
    <app-error [error]="error"></app-error>
</div>