<div class="body">
  <div class="header">
    <div class="datepicker">
      <app-widget-datepicker
        class="extend"
        (dateRangeChange)="changeDate($event)"
      ></app-widget-datepicker>
    </div>
    <a class="return" [routerLink]="['../..']"
      ><img src="/assets/img/arrow-back.svg" style="padding: 0px 8px" />
      <h1 class="return-text">Volver a resumen</h1>
    </a>
  </div>
  <div class="main">
    <div class="up">
      <div class="consumption-chart extend column">
        <h2 class="title">Evolución del consumo</h2>
        <div
          *ngIf="lastData != undefined"
          class="body-1"
          style="margin-top: -20px; margin-bottom: 8px"
        >
          Fecha del último dato recibido: {{ lastData | date : "shortDate" }}
        </div>
        <div class="content extend">
          <app-electricity-consumption-chart
            class="extend"
            [type]="'device'"
            [deviceId]="deviceId"
            [dateRange]="dateRange"
          ></app-electricity-consumption-chart>
        </div>
      </div>
      <div class="heatmap extend column">
        <h2 class="title">
          Mapa de calor
          <div class="doubts" (click)="openHeatmapHelp()">
            <div class="label light">¿Tienes dudas?</div>

            <div class="icon">
              <img src="/assets/img/help-icon.svg" alt="" />
            </div>
          </div>
        </h2>
        <div
          *ngIf="lastData != undefined"
          class="body-1"
          style="margin-top: -20px; margin-bottom: 8px"
        >
          Fecha del último dato recibido: {{ lastData | date : "shortDate" }}
        </div>
        <div class="content extend">
          <app-electricity-heatmap
            class="extend"
            [type]="'device'"
            [deviceId]="deviceId"
            [dateRange]="dateRange"
          ></app-electricity-heatmap>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="realtime-chart extend column">
        <h2 class="title chart">
          Potencia activa vs potencia contratada

          <div class="selector">
            <div class="matlabel">Tramo de potencia</div>
            <mat-form-field appearance="outline">
              <mat-select [(value)]="period">
                <mat-option [value]="1" id="p1">
                  <span style="color: #73c0de">P1</span>
                </mat-option>
                <mat-option [value]="2"
                  ><span style="color: #f17e7e">P2</span>
                </mat-option>
                <mat-option [value]="3"
                  ><span style="color: #199479">Ambas</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </h2>
        <div class="content extend">
          <app-electricity-counter-realtime-chart
            class="extend"
            [type]="'device'"
            [deviceId]="deviceId"
            [period]="period"
          ></app-electricity-counter-realtime-chart>
        </div>
      </div>
      <div class="period-consumption extend column">
        <h2 class="title">Consumo por periodos horarios</h2>
        <div class="content extend">
          <app-electricity-consumption-by-period
            class="extend"
            [type]="'device'"
            [deviceId]="deviceId"
            [dateRange]="dateRange"
            [response]="response"
            [loading]="loading"
            [error]="error"
          ></app-electricity-consumption-by-period>
        </div>
      </div>
    </div>
  </div>
</div>
