import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-water-home-comparison',
  templateUrl: './water-home-comparison.component.html',
  styleUrls: ['./water-home-comparison.component.scss'],
})
export class WaterHomeComparisonComponent implements OnInit {
  @Input() valueCold: any;
  @Input() valueHot: any;
  @Input() loading!: boolean;
  constructor() {}

  ngOnInit(): void {}
}
