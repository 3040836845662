<div class="widget" *ngIf="!error">
    <div class="chart" echarts [options]="options" [loading]="loading" [merge]="options" *ngIf="!loading"></div>
    <div class="chart" *ngIf="loading">
        <div class="loading-spinner"></div>
    </div>

    <div class="toggle">
        <mat-button-toggle-group (change)="loadData($event)" [(value)]="unit">
            <mat-button-toggle value="cost">€</mat-button-toggle>
            <mat-button-toggle value="energy">kWh</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
<div class="widget error" *ngIf="error">
    <app-error [error]="error"></app-error>
</div>