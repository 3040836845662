<div class="widget extend" *ngIf="!error">
    <div class="widget-content" *ngIf="!loading">
        <h5 class="header" *ngIf="header">Aire acondicionado <b class=""></b></h5>
        <div class="body extend">
            <app-cold-today [homeId]="homeId" [deviceId]="deviceId" [type]="type" class="extend"></app-cold-today>
            <app-cold-accumulated [homeId]="homeId" [deviceId]="deviceId" [type]="type" class="extend"></app-cold-accumulated>
            <app-cold-average [homeId]="homeId" [deviceId]="deviceId" [type]="type" [dateRange]="dateRange" class="extend"></app-cold-average>
        </div>
    </div>
    <div class="widget-content" *ngIf="loading">
        <h5 class="header" *ngIf="header">Aire acondicionado</h5>
        <div class="loading-spinner" *ngIf="loading"></div>
    </div>
</div>

<div class="widget error" *ngIf="error">
    <app-error [error]="error"></app-error>
</div>