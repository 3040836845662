<div class="widget">
  <div class="header">
    <h2 class="primary">Programaciones</h2>
  </div>
  <div class="body">
    <div class="text caption">
      Puedes configurar las franjas horarias de cada dia de la semana
    </div>

    <div class="days">
      <div class="buttons">
        <button
          mat-button
          mat-dialog-close
          class="button-secondary caption"
          (click)="openDialogAssistant()"
        >
          ASISTENTE
        </button>
      </div>
      <div
        class="day-container"
        *ngFor="
          let day of data.tempInfo.appliedSchedule.groupedTimeTable;
          let i = index
        "
      >
        <div class="left">
          <h5 class="dayweek">{{ dayWeek[i] }}</h5>
          <div class="day">
            <div
              class="time"
              *ngFor="let time of day"
              [style]="
                ' width: ' +
                (((time.endHour - time.startHour) * 60 +
                  (time.endMinute - time.startMinute)) /
                  1439) *
                  100 +
                '%; background-color: ' +
                findPreset(time) +
                ';'
              "
            ></div>
          </div>
        </div>
        <div class="right" (click)="openDialog(i)">
          <img
            src="/assets/img/navbar/config.svg"
            style="
              filter: invert(46%) sepia(0%) saturate(1439%) hue-rotate(176deg)
                brightness(93%) contrast(80%);
            "
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="buttons">
      <button class="button-secondary caption" (click)="closeDialog()">
        Salir
      </button>
    </div>
  </div>
</div>
