import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  ClimaHomeService,
  ComposedConsumptionList,
  Consumption,
  ConsumptionList,
  ElectricConsumption,
  ElectricHomeService,
  HomeConsumptionService,
  HourAndConsumption,
  HourAndConsumptionList,
} from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-expenses-info',
  templateUrl: './expenses-info.component.html',
  styleUrls: ['./expenses-info.component.scss'],
})
export class ExpensesInfoComponent implements OnChanges, OnInit {
  @Input() homeId!: string;
  @Input() responseLast12Months!: ComposedConsumptionList | undefined;
  @Input() loading!: boolean;
  @Input() error: any;
  responseAvgDayElectric!: Consumption | undefined;
  responseAvgDayHeat!: Consumption | undefined;
  responseAvgDayTotal!: Consumption | undefined;
  responseTodayElectric!: Consumption | undefined;
  responseTodayHeat!: Consumption | undefined;
  responseTodayTotal!: Consumption | undefined;
  environment = environment;

  constructor(private homeApi: HomeConsumptionService) {
    this.error = 0;
  }

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.homeApi
      .homeHomeIdConsumptionTodayGet(this.homeId)
      .subscribe((response) => {
        if (!!response) {
          this.responseTodayHeat = response.heating?.data![0];
          this.responseTodayElectric = response.electric?.data![0];
          this.responseTodayTotal = response.total?.data![0];
        }
      });
    this.homeApi
      .homeHomeIdConsumptionThismonthAvgDayGet(this.homeId)
      .subscribe((response) => {
        if (!!response) {
          this.responseAvgDayHeat = response.heating?.data![0];
          this.responseAvgDayElectric = response.electric?.data![0];
          this.responseAvgDayTotal = response.total?.data![0];
        }
      });

    /* this.homeApi.electricHomeHomeIdEnergyTodayGet(this.homeId).subscribe(
      (response) => (this.responseToday = response),
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.homeApi.electricHomeHomeIdEnergyTodayGet(this.homeId).subscribe(
            (response) => (this.responseToday = response),
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );

    this.climaApi
      .climaHomeHomeIdConsumptionAvgDayGet(
        this.homeId,
        new Date(environment.startDate.toISOString().replace('Z', '')),
        new Date(environment.endDate.toISOString().replace('Z', ''))
      )
      .subscribe((response) => (this.responseAverageClima = response));
    this.homeApi
      .electricHomeHomeIdEnergyAvgDayGet(
        this.homeId,
        new Date(environment.startDate.toISOString().replace('Z', '')),
        new Date(environment.endDate.toISOString().replace('Z', ''))
      )
      .subscribe(
        (response) => (this.responseAverage = response),
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .electricHomeHomeIdEnergyAvgDayGet(
                this.homeId,
                new Date(environment.startDate.toISOString().replace('Z', '')),
                new Date(environment.endDate.toISOString().replace('Z', ''))
              )
              .subscribe(
                (response) => (this.responseAverage = response),
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );*/
  }
}
