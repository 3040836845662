/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ComparedWaterConsumption } from '../model/comparedWaterConsumption';
import { HourWeekCalc } from '../model/hourWeekCalc';
import { WaterConsumption } from '../model/waterConsumption';
import { WaterConsumptionList } from '../model/waterConsumptionList';
import { WaterConsumptionToday } from '../model/waterConsumptionToday';
import { WaterPercentageConsumption } from '../model/waterPercentageConsumption';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WaterDataService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdConsumptionThismonthGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<WaterPercentageConsumption>;
    public waterHomeHomeIdConsumptionThismonthGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterPercentageConsumption>>;
    public waterHomeHomeIdConsumptionThismonthGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterPercentageConsumption>>;
    public waterHomeHomeIdConsumptionThismonthGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdConsumptionThismonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterPercentageConsumption>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/consumption/thismonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionAbsoluteGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumption>;
    public waterHomeHomeIdTypeConsumptionAbsoluteGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumption>>;
    public waterHomeHomeIdTypeConsumptionAbsoluteGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumption>>;
    public waterHomeHomeIdTypeConsumptionAbsoluteGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionAbsoluteGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionAbsoluteGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumption>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/absolute`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionAvgDayGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumption>;
    public waterHomeHomeIdTypeConsumptionAvgDayGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumption>>;
    public waterHomeHomeIdTypeConsumptionAvgDayGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumption>>;
    public waterHomeHomeIdTypeConsumptionAvgDayGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionAvgDayGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionAvgDayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumption>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/avg/day`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionAvgHourweekGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourWeekCalc>;
    public waterHomeHomeIdTypeConsumptionAvgHourweekGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourWeekCalc>>;
    public waterHomeHomeIdTypeConsumptionAvgHourweekGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourWeekCalc>>;
    public waterHomeHomeIdTypeConsumptionAvgHourweekGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionAvgHourweekGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionAvgHourweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourWeekCalc>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/avg/hourweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionBydayGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionList>;
    public waterHomeHomeIdTypeConsumptionBydayGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionBydayGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionBydayGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionBydayGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionBydayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionList>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/byday`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionByhourGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionList>;
    public waterHomeHomeIdTypeConsumptionByhourGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionByhourGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionByhourGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionByhourGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionByhourGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionList>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/byhour`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionBymonthGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionList>;
    public waterHomeHomeIdTypeConsumptionBymonthGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionBymonthGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionBymonthGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionBymonthGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionBymonthGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionList>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/bymonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionByweekGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionList>;
    public waterHomeHomeIdTypeConsumptionByweekGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionByweekGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionByweekGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionByweekGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionByweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionList>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/byweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionByyearGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionList>;
    public waterHomeHomeIdTypeConsumptionByyearGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionByyearGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionByyearGet(homeId: string, type: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionByyearGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionByyearGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionList>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/byyear`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionLast2monthGet(homeId: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionList>;
    public waterHomeHomeIdTypeConsumptionLast2monthGet(homeId: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionLast2monthGet(homeId: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionLast2monthGet(homeId: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionLast2monthGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionLast2monthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionList>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/last2month`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionLast6monthGet(homeId: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionList>;
    public waterHomeHomeIdTypeConsumptionLast6monthGet(homeId: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionLast6monthGet(homeId: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionLast6monthGet(homeId: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionLast6monthGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionLast6monthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionList>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/last6month`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionLastMonthGet(homeId: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionList>;
    public waterHomeHomeIdTypeConsumptionLastMonthGet(homeId: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionLastMonthGet(homeId: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionList>>;
    public waterHomeHomeIdTypeConsumptionLastMonthGet(homeId: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionLastMonthGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionLastMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionList>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/lastMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionThisMonthGet(homeId: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumption>;
    public waterHomeHomeIdTypeConsumptionThisMonthGet(homeId: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumption>>;
    public waterHomeHomeIdTypeConsumptionThisMonthGet(homeId: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumption>>;
    public waterHomeHomeIdTypeConsumptionThisMonthGet(homeId: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionThisMonthGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionThisMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumption>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/thisMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(homeId: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<ComparedWaterConsumption>;
    public waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(homeId: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComparedWaterConsumption>>;
    public waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(homeId: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComparedWaterConsumption>>;
    public waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(homeId: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionThismonthLastmonthGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionThismonthLastmonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ComparedWaterConsumption>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/thismonth/lastmonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterHomeHomeIdTypeConsumptionTodayGet(homeId: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<WaterConsumptionToday>;
    public waterHomeHomeIdTypeConsumptionTodayGet(homeId: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterConsumptionToday>>;
    public waterHomeHomeIdTypeConsumptionTodayGet(homeId: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterConsumptionToday>>;
    public waterHomeHomeIdTypeConsumptionTodayGet(homeId: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling waterHomeHomeIdTypeConsumptionTodayGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling waterHomeHomeIdTypeConsumptionTodayGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WaterConsumptionToday>('get',`${this.basePath}/water/home/${encodeURIComponent(String(homeId))}/${encodeURIComponent(String(type))}/consumption/today`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
