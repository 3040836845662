export * from './airControl.service';
import { AirControlService } from './airControl.service';
export * from './airData.service';
import { AirDataService } from './airData.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './client.service';
import { ClientService } from './client.service';
export * from './climaHome.service';
import { ClimaHomeService } from './climaHome.service';
export * from './electricDevice.service';
import { ElectricDeviceService } from './electricDevice.service';
export * from './electricHome.service';
import { ElectricHomeService } from './electricHome.service';
export * from './heatingControl.service';
import { HeatingControlService } from './heatingControl.service';
export * from './heatingData.service';
import { HeatingDataService } from './heatingData.service';
export * from './home.service';
import { HomeService } from './home.service';
export * from './homeConsumption.service';
import { HomeConsumptionService } from './homeConsumption.service';
export * from './managment.service';
import { ManagmentService } from './managment.service';
export * from './userConsumption.service';
import { UserConsumptionService } from './userConsumption.service';
export * from './version.service';
import { VersionService } from './version.service';
export * from './waterData.service';
import { WaterDataService } from './waterData.service';
export * from './weather.service';
import { WeatherService } from './weather.service';
export const APIS = [AirControlService, AirDataService, AuthService, ClientService, ClimaHomeService, ElectricDeviceService, ElectricHomeService, HeatingControlService, HeatingDataService, HomeService, HomeConsumptionService, ManagmentService, UserConsumptionService, VersionService, WaterDataService, WeatherService];
