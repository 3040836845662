import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Colors } from 'src/assets/colors';
import { PaletteGeneratorService } from './palette-generator.service';

import { MaterialCssVarsService } from 'angular-material-css-vars';

@Injectable({
  providedIn: 'root',
})
export class UpdateCssService {
  private renderer: Renderer2;
  private styleTag: HTMLStyleElement;
  constructor(
    private rendererFactory: RendererFactory2,
    private generator: PaletteGeneratorService,
    public materialCssVarsService: MaterialCssVarsService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    // Create a style tag and append it to the head of the document
    this.styleTag = this.renderer.createElement('style');
    this.renderer.appendChild(document.head, this.styleTag);
  }

  setNewPalette(isPrimary: boolean, hex: string) {
    if (isPrimary) {
      Colors.primary = hex;
      this.materialCssVarsService.setPrimaryColor(hex);
      this.generator.toCssVarsAux(
        this.generator.generatePaletteFromHex(hex),
        'primary-'
      );
    } else {
      Colors.secondary = hex;
      this.materialCssVarsService.setAccentColor(hex);
      this.generator.toCssVarsAux(
        this.generator.generatePaletteFromHex(hex),
        'secondary-'
      );
    }
  }

  setCssVariable(variableName: string, value: string): void {
    // Update the CSS variable in the style tag's text content
    this.styleTag.textContent = `:host { --${variableName}: ${value}; }`;
  }

  changeCssVariable(variableName: string, value: string): void {
    document.documentElement.style.setProperty(variableName, value);
  }
}
