import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.scss'],
})
export class SavingsComponent implements OnInit {
  constructor() {}

  @Input() category!: string;

  ngOnInit(): void {}
}
