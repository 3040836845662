<div class="widget" *ngIf="!error">
  <div class="content" style="width: 100%" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>

  <div class="content extend column" *ngIf="!loading">
    <div class="main">
      <h1 class="temperature" *ngIf="true">
        {{
          deviceInfo?.result.measurements.temperature || 0
            | number: "1.0-0":"es"
        }}ºC
      </h1>
      <h2 class="humidity">
        {{
          deviceInfo?.result.measurements.humidity || 0 | number: "1.0-0":"es"
        }}%
      </h2>
    </div>
    <div
      class="text label bold"
      style="text-align: center"
      *ngIf="deviceInfo?.result.acState.on"
    >
      DISPOSITIVO CONECTADO
    </div>

    <div
      class="text label bold"
      style="text-align: center"
      *ngIf="!deviceInfo?.result.acState.on"
    >
      DISPOSITIVO DESCONECTADO
    </div>
  </div>
</div>
<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
