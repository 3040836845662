/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateTime } from './createTime';

export interface Location { 
    id?: string;
    name?: string;
    latLon?: Array<number>;
    address?: Array<string>;
    country?: any;
    createTime?: CreateTime;
    updateTime?: any;
    features?: Array<any>;
    geofenceTriggerRadius?: number;
    subscription?: any;
    technician?: any;
    shareAnalytics?: boolean;
}