<div class="widget extend">
  <h5 class="header">Contador {{ header }}</h5>
  <div class="devices {{ type }}" *ngIf="!loading">
    <div class="content">
      <div class="label">Consumido hoy</div>
      <h4 class="value">
        {{ response?.today?.m3 || 0 | number : "1.2-2" : "es" }} m3
      </h4>
      <div class="label">
        Actualizado:
        <div class="date">
          {{ response?.today?.homeEndTime | date : "d/M/yy 'a las' HH:mm" }}h
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div
      class="button-compact-primary body bold"
      [routerLink]="['/home', homeId, 'water', 'device', type]"
      *ngIf="!loading"
    >
      Ir
    </div>
  </div>
  <div class="content extend load">
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>
