import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-room-climate',
  templateUrl: './room-climate.component.html',
  styleUrls: ['./room-climate.component.scss'],
})
export class RoomClimateComponent implements OnInit {
  @Input() deviceInfo: any;
  @Input() loading!: boolean;
  constructor() {
    this.error = false;
  }
  error: any;

  ngOnInit(): void {}
}
