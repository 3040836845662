<div
  class="main-frame"
  [ngStyle]="{ 'flex-direction': noPlugs ? '' : 'column' }"
>
  <div class="main-device widget extend">
    <!--
      Contador fiscal
    -->
    <div
      class="content"
      *ngIf="!loading && mainDevice && mainDevice.api == 'datadis'"
      [ngStyle]="{ 'flex-direction': !!secondDevice && !noPlugs ? 'row' : '' }"
    >
      <div class="main">
        <div class="icon" [ngStyle]="{ flex: noPlugs ? '1' : '' }">
          <img src="/assets/img/devices/datadis-white.svg" />
        </div>
        <div
          class="info"
          [ngStyle]="{ 'justify-content': noPlugs ? 'flex-start' : '' }"
        >
          <h4 class="name" [ngStyle]="{ flex: noPlugs ? '0' : '' }">
            Contador fiscal
          </h4>
          <div class="label" *ngIf="!!!secondDevice || noPlugs">
            Tienes un dispositivo instalado:
            <b>Contador fiscal</b>
          </div>
        </div>
      </div>
      <div class="footer" [ngStyle]="{ flex: noPlugs ? '0' : '' }">
        <a
          class="button-primary label white"
          *ngIf="mainDevice"
          [routerLink]="[
            '/home',
            homeId,
            'electricity',
            'counter',
            mainDevice.id
          ]"
          >Ir al panel general</a
        >
        <div
          class="button-primary label white disabled"
          *ngIf="!mainDevice"
          disabled
        >
          No disponible
        </div>
      </div>
    </div>
    <!--
      Monitor
    -->
    <div
      class="content"
      *ngIf="
        !loading &&
        mainDevice &&
        (mainDevice.api == 'shelly' || mainDevice.api == 'wibeee')
      "
    >
      <div class="main">
        <div class="icon" [ngStyle]="{ flex: noPlugs ? '1' : '' }">
          <img src="/assets/img/devices/monitor-white.svg" />
        </div>
        <div
          class="info"
          [ngStyle]="{ 'justify-content': noPlugs ? 'flex-start' : '' }"
        >
          <h4 class="name" [ngStyle]="{ flex: noPlugs ? '0' : '' }">
            <ng-container *ngIf="mainDevice.productModel == 'mirubox_v2'"
              >Wibeee Box</ng-container
            >
            <ng-container *ngIf="mainDevice.productModel == 'shellyem'"
              >Shelly</ng-container
            >
          </h4>
          <div class="label">
            Tienes un dispositivo instalado:
            <b>Monitor</b>
          </div>
        </div>
      </div>
      <div class="footer" [ngStyle]="{ flex: noPlugs ? '0' : '' }">
        <a
          class="button-primary label white"
          *ngIf="mainDevice"
          [routerLink]="[
            '/home',
            homeId,
            'electricity',
            'device',
            'monitor',
            mainDevice.id
          ]"
          >Ir al panel general</a
        >
        <div
          class="button-primary label white disabled"
          *ngIf="!mainDevice"
          disabled
        >
          No disponible
        </div>
      </div>
    </div>
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>

  <div class="main-device widget extend" *ngIf="!!secondDevice">
    <!--
      Monitor
    -->
    <div
      class="content"
      *ngIf="
        !loading &&
        secondDevice &&
        (secondDevice.api == 'shelly' || secondDevice.api == 'wibeee')
      "
    >
      <div class="main">
        <div class="icon" [ngStyle]="{ flex: noPlugs ? '1' : '' }">
          <img src="/assets/img/devices/monitor-white.svg" />
        </div>
        <div
          class="info"
          [ngStyle]="{ 'justify-content': noPlugs ? 'flex-start' : '' }"
        >
          <h4 class="name" [ngStyle]="{ flex: noPlugs ? '0' : '' }">
            <ng-container *ngIf="secondDevice.productModel == 'mirubox_v2'"
              >Wibeee Box</ng-container
            >
            <ng-container *ngIf="secondDevice.productModel == 'shellyem'"
              >Shelly</ng-container
            >
          </h4>
          <div class="label">
            Tienes un dispositivo instalado:
            <b>Monitor</b>
          </div>
        </div>
      </div>
      <div class="footer" [ngStyle]="{ flex: noPlugs ? '0' : '' }">
        <a
          class="button-primary label white"
          *ngIf="secondDevice"
          [routerLink]="[
            '/home',
            homeId,
            'electricity',
            'device',
            'monitor',
            secondDevice.id
          ]"
          >Ir al panel general</a
        >
        <div
          class="button-primary label white disabled"
          *ngIf="!secondDevice"
          disabled
        >
          No disponible
        </div>
      </div>
    </div>
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>
