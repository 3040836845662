import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CookieTextComponent } from '../cookie-text/cookie-text.component';

@Component({
  selector: 'app-cookie-accept',
  templateUrl: './cookie-accept.component.html',
  styleUrls: ['./cookie-accept.component.scss'],
})
export class CookieAcceptComponent implements OnInit {
  flavour: any;
  constructor(public dialog: MatDialog) {
    this.flavour = environment.flavour;
  }

  ngOnInit(): void {}

  acceptCookies(): void {
    localStorage.setItem('cookies', 'true');
  }

  openCookiesText(): void {
    const dialogRef = this.dialog.open(CookieTextComponent, {
      maxWidth: '900px',
    });
  }
}
