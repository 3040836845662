<div class="widget">
  <div class="header">
    <h2 class="primary">Modificar un tramo horario</h2>
  </div>
  <div class="body">
    <div class="text caption">
      Modifica los horarios y las temperaturas del tramo seleccionado
    </div>

    <div class="days">
      <div class="left"></div>
      <div class="schedules">
        <div class="schedule label">
          <div class="icon-schedule" style="flex: 0; padding-right: 12px">
            <img
              src="/assets/img/icons/schedule.svg"
              style="
                max-height: 25px;
                filter: invert(46%) sepia(0%) saturate(1439%) hue-rotate(176deg)
                  brightness(93%) contrast(80%);
                padding-left: 4px;
              "
              alt=""
            />
          </div>
          <div class="startTime">Hora inicio</div>
          <div class="endTime">Hora final</div>
          <div class="preset">Temperatura</div>
        </div>
        <div class="schedule label">
          <div class="icon-schedule" style="flex: 0; padding-right: 12px">
            <img
              src="/assets/img/icons/schedule.svg"
              style="
                max-height: 25px;
                filter: invert(46%) sepia(0%) saturate(1439%) hue-rotate(176deg)
                  brightness(93%) contrast(80%);
                padding-left: 4px;
                opacity: 0;
              "
              alt=""
            />
          </div>
          <div class="startTime">
            <mat-form-field
              appearance="outline"
              class="select"
              style="max-width: 72px"
            >
              <mat-select
                [(value)]="data.time.startHour"
                (selectionChange)="changeHours()"
                [disabled]="this.data.index == 0"
              >
                <ng-container *ngFor="let hour of hours">
                  <mat-option
                    [value]="hour"
                    *ngIf="
                      ((this.data.index == 0 ||
                        hour >=
                          this.data.tempInfo.appliedSchedule.groupedTimeTable[
                            this.data.day
                          ][this.data.index - 1].startHour) &&
                        hour <= 23) ||
                      this.data.tempInfo.appliedSchedule.groupedTimeTable[
                        this.data.day
                      ].length == this.data.index
                    "
                  >
                    {{ hour | number : "2.0-0" }}</mat-option
                  >
                </ng-container>
              </mat-select>
            </mat-form-field>
            :
            <mat-form-field
              appearance="outline"
              class="select"
              style="max-width: 72px"
            >
              <mat-select
                [(value)]="data.time.startMinute"
                (selectionChange)="changeHours()"
                [disabled]="this.data.index == 0"
              >
                <mat-option *ngFor="let minute of minutes" [value]="minute">
                  {{ minute | number : "2.0-0" }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="endTime">
            <mat-form-field
              appearance="outline"
              class="select"
              style="max-width: 72px"
            >
              <mat-select
                [disabled]="
                  this.data.index ==
                  this.data.tempInfo.appliedSchedule.groupedTimeTable[
                    this.data.day
                  ].length -
                    1
                "
                [(value)]="data.time.endHour"
                (selectionChange)="changeHours()"
              >
                <ng-container *ngFor="let hour of hours">
                  <mat-option *ngIf="endHourConditions(hour)" [value]="hour">
                    {{ hour | number : "2.0-0" }}</mat-option
                  >
                </ng-container>
              </mat-select>
            </mat-form-field>
            :
            <mat-form-field
              appearance="outline"
              class="select"
              style="max-width: 72px"
            >
              <mat-select
                [disabled]="
                  this.data.index ==
                  this.data.tempInfo.appliedSchedule.groupedTimeTable[
                    this.data.day
                  ].length -
                    1
                "
                [(value)]="data.time.endMinute"
                (selectionChange)="changeHours()"
              >
                <ng-container *ngFor="let minute of minutes">
                  <mat-option [value]="minute" *ngIf="endMinuteValid(minute)">
                    {{ minute | number : "2.0-0" }}</mat-option
                  >
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="preset">
            <mat-form-field
              appearance="outline"
              class="select"
              style="max-width: 120px; text-transform: capitalize"
            >
              <mat-label>Temperatura</mat-label>
              <mat-select [(value)]="data.time.presetId">
                <mat-option
                  *ngFor="
                    let preset of this.data.tempInfo.appliedSchedule.presets
                  "
                  [value]="preset.id"
                  style="text-transform: capitalize"
                >
                  {{ preset.name }} ({{ preset.setpointTemp }}ºC)</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <button class="button-secondary caption" (click)="closeDialog()">
        Salir sin cambiar
      </button>
      <button class="button-primary caption" (click)="saveDialog()">
        Cambiar horario
      </button>
    </div>
  </div>
</div>
