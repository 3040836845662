import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NetatmoAssistantComponent } from '../netatmo-assistant/netatmo-assistant.component';
import { NetatmoDaySchedulerComponent } from '../netatmo-day-scheduler/netatmo-day-scheduler.component';

@Component({
  selector: 'app-netatmo-schedule-view',
  templateUrl: './netatmo-schedule-view.component.html',
  styleUrls: ['./netatmo-schedule-view.component.scss'],
})
export class NetatmoScheduleViewComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<NetatmoScheduleViewComponent>
  ) {}

  dayWeek = [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ];
  presets = [];

  ngOnInit(): void {
    this.presets = this.data.tempInfo.appliedSchedule.presets;
    this.presets.find((element) => element > 10);
  }

  findPreset(time: any) {
    let value;
    try {
      value = this.data.tempInfo.appliedSchedule.presets.find(
        (x: any) => x.id === time.presetId
      );

      return value.color;
    } catch {
      return 'none';
    }

    return value;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openDialog(day: number): void {
    this.dialog.open(NetatmoDaySchedulerComponent, {
      data: {
        tempInfo: this.data.tempInfo,
        day: day,
        homeId: this.data.homeId,
      },
    });
  }

  openDialogAssistant(): void {
    this.dialog.open(NetatmoAssistantComponent, {
      data: { homeId: this.data.homeId },
    });
  }

  saveChanges() {
    this.closeDialog();
  }
}
