<div class="widget">
  <div class="header">
    <h2 class="primary">Crear nueva programación</h2>
  </div>
  <div class="body">
    <div class="text caption">
      Modifica los parámetros de esta programación de tu dispositivo de aire
      acondicionado.
    </div>
    <div class="form">
      <div class="element">
        <div class="name body-1 neutral-40">Estado</div>
        <div class="toggle" *ngIf="state">
          <mat-button-toggle-group class="right" [(value)]="state">
            <mat-button-toggle class="toggle-text" value="on"
              >Encender</mat-button-toggle
            >
            <mat-button-toggle class="toggle-text" value="off"
              >Apagar</mat-button-toggle
            >
            <mat-button-toggle class="toggle-text" value="on/off"
              >Encender/Apagar</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="element">
        <ng-container *ngIf="state != 'on/off'; else onAndOff"
          ><div class="name body-1 neutral-40">Hora programada</div>
          <ngx-mat-timepicker-field
            class="wizard-timepicker"
            color="primary"
            [format]="24"
            [defaultTime]="'23:11'"
            [(ngModel)]="schedule.targetTimeLocal"
            required
          ></ngx-mat-timepicker-field
        ></ng-container>
        <ng-template #onAndOff>
          <div class="name body-1 neutral-40">Desde</div>
          <ngx-mat-timepicker-field
            class="wizard-timepicker"
            color="primary"
            [format]="24"
            [defaultTime]="'23:11'"
            [(ngModel)]="startHour"
            required
          ></ngx-mat-timepicker-field>
          <div class="name body-1 neutral-40">Hasta</div>
          <ngx-mat-timepicker-field
            class="wizard-timepicker"
            color="primary"
            [format]="24"
            [defaultTime]="'23:11'"
            [(ngModel)]="endHour"
            required
          ></ngx-mat-timepicker-field>
        </ng-template>
      </div>

      <div class="element" *ngIf="state != 'off'">
        <div class="name body-1 neutral-40">Modo</div>

        <mat-button-toggle-group
          class="right"
          [(value)]="schedule.acState.mode"
        >
          <mat-button-toggle
            [disabled]="!data.deviceInfo.result.remoteCapabilities.modes.cool"
            value="cool"
            ><img src="/assets/img/icons/temperature/cold.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle
            [disabled]="!data.deviceInfo.result.remoteCapabilities.modes.heat"
            value="heat"
            ><img src="/assets/img/icons/temperature/heat.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle
            [disabled]="!data.deviceInfo.result.remoteCapabilities.modes.dry"
            value="dry"
            ><img src="/assets/img/icons/temperature/water.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle
            [disabled]="!data.deviceInfo.result.remoteCapabilities.modes.fan"
            value="fan"
            ><img src="/assets/img/icons/temperature/fan.svg" alt=""
          /></mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="element" *ngIf="state != 'off'">
        <div class="name body-1 neutral-40">Ventilador</div>

        <mat-button-toggle-group
          class="right"
          [(value)]="schedule.acState.fanLevel"
        >
          <mat-button-toggle value="auto">
            <img src="/assets/img/icons/temperature/fan-1.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle value="low">
            <img src="/assets/img/icons/temperature/fan-2.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle value="medium">
            <img src="/assets/img/icons/temperature/fan-3.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle value="high">
            <img src="/assets/img/icons/temperature/fan-4.svg" alt=""
          /></mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="element" *ngIf="state != 'off'">
        <div class="name body-1 neutral-40">Temperatura</div>
        <div class="celsius" style="flex: 1">
          <mat-slider
            class="example-margin"
            [max]="this.temperaturesArray.slice(-1)"
            [min]="this.temperaturesArray[0]"
            [step]="1"
            [(ngModel)]="schedule.acState.targetTemperature"
          >
            <input
              matSliderThumb
              [(ngModel)]="schedule.acState.targetTemperature"
            />
          </mat-slider>
        </div>
        <p class="celsius-unit neutral-40">
          {{ schedule.acState.targetTemperature }} ºC
        </p>
      </div>

      <div class="element">
        <div class="name body-1 neutral-40">Repetición</div>

        <mat-button-toggle-group
          class="right"
          [(value)]="schedule.recurOnDaysOfWeek"
          multiple
        >
          <mat-button-toggle value="Monday">L</mat-button-toggle>
          <mat-button-toggle value="Tuesday">M</mat-button-toggle>
          <mat-button-toggle value="Wednesday">X</mat-button-toggle>
          <mat-button-toggle value="Thursday">J</mat-button-toggle>
          <mat-button-toggle value="Friday">V</mat-button-toggle>
          <mat-button-toggle value="Saturday">S</mat-button-toggle>
          <mat-button-toggle value="Sunday">D</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="buttons">
        <button class="button-secondary caption" (click)="closeDialog()">
          Salir sin guardar
        </button>
        <button class="button-primary caption" (click)="setSchedule()">
          {{ saveText }}
        </button>
      </div>
    </div>
  </div>
</div>
