<div class="widget extend column">
  <h2 class="switch" style="height: 200px; width: 100%; text-align: center">
    <div class="text">FUERA DE CASA</div>
  </h2>
  <div class="content remote extend">
    <div class="button button-compact-primary" (click)="temperatureDown()">
      -
    </div>
    <div class="chart-container">
      <div
        class="chart"
        echarts
        [options]="options"
        [loading]="loading"
        [merge]="options"
        *ngIf="!loading"
      ></div>
    </div>
    <div class="button button-compact-primary" (click)="temperatureUp()">+</div>
  </div>
</div>
