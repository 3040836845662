// Automatically generated - do not modify!

import { createParamString } from '../internals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IHomeConsumptionApiService {
  /**
   * @method
   * @name  getHomeHomeIdConsumptionLast6month
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionLast6month(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionLast12month
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionLast12month(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionThisMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionLastMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionLastMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionThisandlastmonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionThisandlastmonth(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionToday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionToday(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionAvgDay(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionByhour
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionByhour(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionByday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionByday(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionByweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionByweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionBymonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionBymonth(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getHomeHomeIdConsumptionByyear
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getHomeHomeIdConsumptionByyear(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class HomeConsumptionApiService implements IHomeConsumptionApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'HomeConsumptionApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionLast6month
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionLast6month(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/last6month`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionLast12month
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionLast12month(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/last12month`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionThisMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/thisMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionLastMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionLastMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/lastMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionThisandlastmonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionThisandlastmonth(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/thisandlastmonth`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionToday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionToday(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/today`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionAvgDay(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/avg/day`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionByhour
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionByhour(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/byhour`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionByday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionByday(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/byday`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionByweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionByweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/byweek`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionBymonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionBymonth(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/bymonth`,
      options
    );
  }

  /**
   * @method
   * @name  getHomeHomeIdConsumptionByyear
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getHomeHomeIdConsumptionByyear(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/home/${homeId}/consumption/byyear`,
      options
    );
  }
}
