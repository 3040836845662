<div class="datepicker">
  <mat-date-range-picker touchUi #rangePicker color="primary">
    <mat-date-range-picker-actions>
      <button
        matDateRangePickerCance
        class="button-primary caption"
        matDateRangePickerCancel
      >
        Cancelar
      </button>
      <button class="button-primary caption" matDateRangePickerApply>
        Establecer nuevo periodo
      </button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
  <div class="from">
    <div class="text caption">Desde:</div>
    <div class="picker nav-up primary">
      <input
        matInput
        [matDatepicker]="startDatePicker"
        [value]="this.dateRange.value.start"
        readonly
      />
      <mat-datepicker-toggle matSuffix [for]="rangePicker">
        <mat-icon
          matDatepickerToggleIcon
          fontIcon="keyboard_arrow_down"
        ></mat-icon>
      </mat-datepicker-toggle>

      <mat-datepicker #startDatePicker></mat-datepicker>
    </div>
  </div>
  <mat-date-range-input
    [rangePicker]="rangePicker"
    style="width: 0px; opacity: 0"
    [formGroup]="dateRange"
    [max]="now"
  >
    <input
      matStartDate
      formControlName="start"
      placeholder="Start date"
      (dateChange)="newDate()"
    />
    <input
      matEndDate
      formControlName="end"
      placeholder="End date"
      (dateChange)="newDate()"
    />
  </mat-date-range-input>
  <div class="until">
    <div class="text caption">Hasta:</div>
    <div class="picker nav-up primary">
      <input
        matInput
        [matDatepicker]="endDatePicker"
        [value]="this.dateRange.value.end"
        readonly
      />
      <mat-datepicker-toggle matSuffix [for]="rangePicker">
        <mat-icon
          matDatepickerToggleIcon
          fontIcon="keyboard_arrow_down"
        ></mat-icon>
      </mat-datepicker-toggle>

      <mat-datepicker #endDatePicker></mat-datepicker>
    </div>
  </div>
</div>
