import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WaterConsumptionToday, WaterDataService } from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-water-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.scss'],
})
export class TodayWaterComponent implements OnChanges {
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup | undefined;
  @Input() response!: WaterConsumptionToday;
  @Input() response2!: WaterConsumptionToday;
  @Input() loading!: boolean;
  @Input() error: any;
  data: any = [];
  names: any = [];
  options: any;

  constructor(private homeApi: WaterDataService) {}

  ngOnInit(): void {
    this.loading = true;
    this.getData();
  }
  ngOnChanges(): void {
    if (!this.loading) this.adaptData();
  }

  getData(): void {
    this.homeApi
      .waterHomeHomeIdTypeConsumptionTodayGet(this.homeId, 'cold')
      .subscribe(
        (response) => {
          this.response = response;
          this.adaptData();
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .waterHomeHomeIdTypeConsumptionTodayGet(this.homeId, 'cold')
              .subscribe(
                (response) => {
                  this.response = response;
                  this.adaptData();
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );

    this.homeApi
      .waterHomeHomeIdTypeConsumptionTodayGet(this.homeId, 'hot')
      .subscribe(
        (response) => {
          this.response2 = response;
          this.adaptData();
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .waterHomeHomeIdTypeConsumptionTodayGet(this.homeId, 'hot')
              .subscribe(
                (response) => {
                  this.response2 = response;
                  this.adaptData();
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
  adaptData(): void {
    if (this.response && this.response2) {
      this.names = [];
      let index = 0;

      this.data.push({
        value: this.response.today?.m3?.toFixed(2),
        name: 'Agua fría sanitaria',
        itemStyle: {
          color: '#73C0DE',
        },
      });
      this.names.push('Caliente');

      index++;

      this.data.push({
        value: this.response2.today?.m3?.toFixed(2),
        name: 'Agua caliente sanitaria',
        itemStyle: {
          color: '#F17E7E',
        },
      });
      this.names.push('Fría');

      this.setOptions();
    }
  }
  setOptions(): void {
    let max = 0;
    for (const valor of this.data) {
      if (valor.value > max) {
        max = valor.value;
      }
    }
    const options = {
      tooltip: {
        position: 'top',
        formatter: function (params: any) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params.color +
            '"></span>  <a style="font-size: 1rem"> ' +
            params.name +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params.value.replace('.', ',') +
            ' m3' +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        type: 'category',
        name: 'Periodo',
        data: ['Fría', 'Caliente'],
        axisLabel: {
          fontFamily: environment.fontFamily,
        },

        splitLine: {
          show: false,
        },
      },

      yAxis: {
        type: 'value',
        show: false,
        name: '€',
      },
      series: [
        {
          name: 'Consumo por periodo',
          type: 'bar',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          data: this.data,
          label: {
            fontFamily: environment.fontFamily,
            show: true,
            formatter(value: any): any {
              if (value.value < max / 8) {
                return '';
              }
              return ''; //value.value + '€';
            },
            position: 'insideTop',
          },

          barGap: '4px',
          barWidth: '99%',
        },
      ],

      grid: {
        top: '4px',
        left: 0,
        right: 0,
        bottom: '20px',
      },
    };

    this.options = options;
    this.loading = false;
  }
}
