<div class="body">
  <div class="main">
    <div class="left extend column">
      <div class="devices extend column">
        <h2 class="title">Dispositivos instalados</h2>
        <div class="content extend">
          <app-cold-devices class="extend"></app-cold-devices>
          <app-heat-devices class="extend"></app-heat-devices>
        </div>
      </div>
      <div class="chart extend column">
        <div class="title">
          <h2 class="text">Evolución del consumo</h2>
          <div class="datepicker">
            <app-widget-datepicker
              class="extend"
              (dateRangeChange)="changeDate($event)"
            ></app-widget-datepicker>
          </div>
        </div>
        <div class="content extend">
          <app-temperature-consumption-chart
            class="extend"
            [type]="'home'"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-temperature-consumption-chart>
        </div>
      </div>
    </div>
    <div class="right extend">
      <div class="left extend column nogap">
        <h2 class="title">Consumo climatización</h2>
        <div class="content extend column">
          <div class="extend total">
            <app-temperature-today
              class="extend"
              [homeId]="homeId"
            ></app-temperature-today>
            <app-temperature-accumulated
              class="extend"
              [response]="response"
              [loading]="loading"
              [error]="error"
            ></app-temperature-accumulated>
            <app-temperature-average
              class="extend"
              [homeId]="homeId"
              [dateRange]="dateRange"
            ></app-temperature-average>
          </div>
          <div class="cold" class="extend">
            <app-cold-consumption
              class="extend"
              [type]="'home'"
              [homeId]="homeId"
              [dateRange]="dateRange"
            ></app-cold-consumption>
          </div>
          <div class="heat" class="extend">
            <app-heat-consumption
              class="extend"
              [homeId]="homeId"
              [dateRange]="dateRange"
            ></app-heat-consumption>
          </div>
        </div>
      </div>
      <div class="left extend column">
        <div class="climate extend column">
          <h2 class="title">Tiempo en tu zona</h2>
          <div class="content extend">
            <app-weather class="extend" [homeId]="homeId"></app-weather>
          </div>
        </div>
        <div class="footprint extend column">
          <h2 class="title">Huella de carbono</h2>
          <div class="content extend column">
            <app-home-carbon-footprint-summary
              class="extend"
              [response]="response"
              [loading]="loading"
              [error]="error"
            ></app-home-carbon-footprint-summary>
            <app-home-carbon-footprint-chart
              class="extend"
              style="flex: 2"
              [response]="response"
              [loading]="loading"
              [error]="error"
            ></app-home-carbon-footprint-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
