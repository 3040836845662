/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HeatingSchedule } from '../model/heatingSchedule';
import { HeatingScheduleAssistant } from '../model/heatingScheduleAssistant';
import { HeatingScheduleAssistantV2 } from '../model/heatingScheduleAssistantV2';
import { HomeInfo } from '../model/homeInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HeatingControlService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHeatingTempPresetsGet(observe?: 'body', reportProgress?: boolean): Observable<HomeInfo>;
    public climaHeatingTempPresetsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HomeInfo>>;
    public climaHeatingTempPresetsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HomeInfo>>;
    public climaHeatingTempPresetsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HomeInfo>('get',`${this.basePath}/clima/heating/tempPresets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingInfoAppGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HomeInfo>;
    public climaHomeHomeIdHeatingInfoAppGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HomeInfo>>;
    public climaHomeHomeIdHeatingInfoAppGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HomeInfo>>;
    public climaHomeHomeIdHeatingInfoAppGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingInfoAppGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HomeInfo>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/info/app`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingInfoGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HomeInfo>;
    public climaHomeHomeIdHeatingInfoGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HomeInfo>>;
    public climaHomeHomeIdHeatingInfoGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HomeInfo>>;
    public climaHomeHomeIdHeatingInfoGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingInfoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HomeInfo>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingScheduleAssistantAppPost(homeId: string, body?: HeatingScheduleAssistantV2, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaHomeHomeIdHeatingScheduleAssistantAppPost(homeId: string, body?: HeatingScheduleAssistantV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaHomeHomeIdHeatingScheduleAssistantAppPost(homeId: string, body?: HeatingScheduleAssistantV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaHomeHomeIdHeatingScheduleAssistantAppPost(homeId: string, body?: HeatingScheduleAssistantV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingScheduleAssistantAppPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/schedule/assistant/app`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingScheduleAssistantPost(homeId: string, body?: HeatingScheduleAssistant, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaHomeHomeIdHeatingScheduleAssistantPost(homeId: string, body?: HeatingScheduleAssistant, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaHomeHomeIdHeatingScheduleAssistantPost(homeId: string, body?: HeatingScheduleAssistant, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaHomeHomeIdHeatingScheduleAssistantPost(homeId: string, body?: HeatingScheduleAssistant, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingScheduleAssistantPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/schedule/assistant`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingSchedulePost(homeId: string, body?: HeatingSchedule, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaHomeHomeIdHeatingSchedulePost(homeId: string, body?: HeatingSchedule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaHomeHomeIdHeatingSchedulePost(homeId: string, body?: HeatingSchedule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaHomeHomeIdHeatingSchedulePost(homeId: string, body?: HeatingSchedule, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingSchedulePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/schedule`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param scheduleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingScheduleScheduleIdActivatePost(homeId: string, scheduleId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaHomeHomeIdHeatingScheduleScheduleIdActivatePost(homeId: string, scheduleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaHomeHomeIdHeatingScheduleScheduleIdActivatePost(homeId: string, scheduleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaHomeHomeIdHeatingScheduleScheduleIdActivatePost(homeId: string, scheduleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingScheduleScheduleIdActivatePost.');
        }

        if (scheduleId === null || scheduleId === undefined) {
            throw new Error('Required parameter scheduleId was null or undefined when calling climaHomeHomeIdHeatingScheduleScheduleIdActivatePost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/schedule/${encodeURIComponent(String(scheduleId))}/activate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param controlMode 
     * @param setpointTemp 
     * @param offsetInSeconds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingSetcontrolmodePost(homeId: string, controlMode?: string, setpointTemp?: number, offsetInSeconds?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaHomeHomeIdHeatingSetcontrolmodePost(homeId: string, controlMode?: string, setpointTemp?: number, offsetInSeconds?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaHomeHomeIdHeatingSetcontrolmodePost(homeId: string, controlMode?: string, setpointTemp?: number, offsetInSeconds?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaHomeHomeIdHeatingSetcontrolmodePost(homeId: string, controlMode?: string, setpointTemp?: number, offsetInSeconds?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingSetcontrolmodePost.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (controlMode !== undefined && controlMode !== null) {
            queryParameters = queryParameters.set('controlMode', <any>controlMode);
        }
        if (setpointTemp !== undefined && setpointTemp !== null) {
            queryParameters = queryParameters.set('setpointTemp', <any>setpointTemp);
        }
        if (offsetInSeconds !== undefined && offsetInSeconds !== null) {
            queryParameters = queryParameters.set('offsetInSeconds', <any>offsetInSeconds);
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/setcontrolmode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
