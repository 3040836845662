<div class="widget" *ngIf="!error">
  <div
    class="chart"
    echarts
    [options]="options"
    [loading]="loading"
    [merge]="options"
    *ngIf="!loading && !noContent"
  ></div>
  <div class="chart" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>
  <div class="last" *ngIf="!loading">
    <ng-container *ngIf="noContent; else contentOk">
      <div class="no-data">No se han encontrado datos disponibles.</div>
    </ng-container>
    <ng-template #contentOk
      ><div class="header">
        <h3 class="title">
          Estado
          <div *ngIf="online" class="status-icon online"></div>
          <div *ngIf="!online" class="status-icon offline"></div>
        </h3>
        <div class="status body-1 bold" *ngIf="this.online">Monitorizando</div>
        <div class="status body-1 bold" *ngIf="!this.online">
          Última lectura
          <br />{{ lastData.utcTime | date : "medium" }}
        </div>
      </div>
      <hr />
      <div class="bottom">
        <div class="element power">
          <h5 class="name">Potencia Activa</h5>
          <div class="value">
            <h3 class="number">{{ lastData.p | number : "1.2-2" : "es" }}</h3>
            <h3 class="units">kW</h3>
          </div>
        </div>
        <div class="element" *ngIf="lastData.v">
          <h5 class="name">Tensión</h5>
          <div class="value">
            <h5 class="number">{{ lastData.v | number : "1.2-2" : "es" }}</h5>
            <h5 class="units">V</h5>
          </div>
        </div>
        <div class="element" *ngIf="lastData.i">
          <h5 class="name">Corriente</h5>
          <div class="value">
            <h5 class="number">{{ lastData.i | number : "1.2-2" : "es" }}</h5>
            <h5 class="units">A</h5>
          </div>
        </div>
      </div></ng-template
    >
  </div>
</div>
<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
