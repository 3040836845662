<div class="widget" *ngIf="!error">
  <div class="header">
    <h2 class="primary">Tu contrato eléctrico</h2>
  </div>
  <div class="body" *ngIf="loading" style="display: flex; flex: 1; width: 100%">
    <div class="loading" style="min-height: 300px; flex: 1">
      <div class="loading-spinner"></div>
    </div>
  </div>
  <div class="body" *ngIf="!loading">
    <div class="text caption">
      Aquí puedes visualizar y configurar tus datos eléctricos para conseguir
      mejores estadísticas. Si no conoces los datos, ten una factura a mano.
    </div>
    <div class="form">
      <div class="element input">
        <div class="name body-1 bold primary">Fecha de inicio de factura</div>
        <mat-form-field appearance="outline">
          <mat-label>Fecha de Inicio de Factura</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            (dateChange)="cambioFecha($event)"
            [max]="ahora"
            [value]="dateStart"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="element select">
        <div class="name body-1 bold primary">Comercializadora</div>
        <mat-form-field appearance="outline">
          <mat-select [(value)]="price.comer" placeholder="Comercializadora">
            <mat-option *ngFor="let comer of comers.data" [value]="comer">
              {{ comer.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="element input">
        <div class="name body-1 bold primary bigger">
          <div class="title">¿Tienes precio fijo?</div>
        </div>
        <div class="select" *ngIf="!price?.comer?.regulatedPrice">
          <mat-checkbox [(ngModel)]="price.nonFixedPrice" class="body-1"
            >Marca esta casilla si el precio de tus kWh varía según el mercado.
          </mat-checkbox>
        </div>

        <div class="select" *ngIf="price?.comer?.regulatedPrice">
          <mat-checkbox
            [(ngModel)]="price.comer.regulatedPrice"
            class="body-1"
            disabled
            >Esta comercializadora es de último recurso y cuenta con precios
            regulados por el mercado.
          </mat-checkbox>
        </div>
      </div>
      <div
        class="element input column"
        *ngIf="!price.nonFixedPrice && !price?.comer?.regulatedPrice"
      >
        <div class="caption">
          Introduce los precios de los periodos que indica tu factura
          correspondientes al término de <b>energía</b>.
        </div>
        <div class="options">
          <div class="option">
            <div class="name body-1 bold primary">Periodo 1 (Punta)</div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price?.energyPrices[0]"
                placeholder=""
                type="number"
                min="0"
                max="1"
                required
              /> </mat-form-field
            ><b class="body-1">€</b>
          </div>
          <div class="option">
            <div class="name body-1 bold primary">Periodo 2 (Llano)</div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price?.energyPrices[1]"
                placeholder=""
                type="number"
                min="0"
                max="1"
                required
              /> </mat-form-field
            ><b class="body-1">€</b>
          </div>
          <div class="option">
            <div class="name body-1 bold primary">Periodo 3 (Valle)</div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price?.energyPrices[2]"
                placeholder=""
                type="number"
                min="0"
                max="1"
                required
              /> </mat-form-field
            ><b class="body-1">€</b>
          </div>
        </div>
      </div>
      <div class="element input">
        <div class="name body-1 bold primary bigger">
          <div class="title">Potencia contratada</div>
        </div>
        <div class="options">
          <div class="option">
            <div class="name body-1 bold primary">
              Periodo 1 (Punta) + Periodo 2 (Llano)
            </div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price.hiredPower[0]"
                placeholder=""
                type="number"
                min="0"
                max="15"
                required
              /> </mat-form-field
            ><b class="body-1">kW</b>
          </div>
          <div class="option">
            <div class="name body-1 bold primary">Periodo 3 (Valle)</div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price.hiredPower[1]"
                placeholder=""
                type="number"
                min="0"
                max="15"
                required
              /> </mat-form-field
            ><b class="body-1">kW</b>
          </div>
        </div>
      </div>

      <div class="element input">
        <div class="name body-1 bold primary bigger">
          <div class="title">
            Precios asociados al <b>término de potencia</b>
          </div>
        </div>
        <div class="options">
          <div class="option">
            <div class="name body-1 bold primary">
              Periodo 1 (Punta) + Periodo 2 (Llano)
            </div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price?.powerPrices[0]"
                placeholder=""
                type="number"
                min="0"
                max="1"
                required
              /> </mat-form-field
            ><b class="body-1">€</b>
          </div>
          <div class="option">
            <div class="name body-1 bold primary">Periodo 3 (Valle)</div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price?.powerPrices[2]"
                placeholder=""
                type="number"
                min="0"
                max="1"
                required
              /> </mat-form-field
            ><b class="body-1">€</b>
          </div>
        </div>
      </div>

      <div class="element input column">
        <div class="caption">
          Introduce los <b>descuentos</b> que tengas aplicados por tu
          comercializadora.
        </div>
        <div class="options">
          <div class="option">
            <div class="name body-1 bold primary">Término de energía</div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price.energyDtoPercentage"
                placeholder=""
                type="number"
                min="0"
                max="100"
                required
              /> </mat-form-field
            ><b class="body-1">%</b>
          </div>
          <div class="option">
            <div class="name body-1 bold primary">Término de potencia</div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price.powerDtoPercentage"
                placeholder=""
                type="number"
                min="0"
                max="100"
                required
              /> </mat-form-field
            ><b class="body-1">%</b>
          </div>
        </div>
      </div>

      <div class="element input column">
        <div class="caption">
          Introduce los <b>costes adicionales</b> que figuren en tu factura
        </div>
        <div class="options">
          <div class="option">
            <div class="name body-1 bold primary">
              Coste de alquiler de contador (por día)
            </div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price.rentByDay"
                placeholder=""
                type="number"
                min="0"
                required
              /> </mat-form-field
            ><b class="body-1">€</b>
          </div>
          <div class="option">
            <div class="name body-1 bold primary">
              Resto de costes extra (por día)
            </div>
            <mat-form-field appearance="outline" class="input body-1">
              <input
                numeric
                matInput
                [(ngModel)]="price.otherByDay"
                placeholder=""
                type="number"
                min="0"
                required
              /> </mat-form-field
            ><b class="body-1">€</b>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="info body-2">
          Recuerda: Los costes que podrás visualizar a lo largo de la plataforma
          corresponden al <b>término de energía</b> de tu factura. Los impuestos
          eléctricos y adicionales <b>no se verán reflejados.</b>
        </div>
        <div class="buttons">
          <button
            mat-dialog-close
            class="button-secondary caption"
            (click)="closeDialog()"
          >
            Salir sin guardar
          </button>
          <button class="button-primary caption" (click)="updateBill()">
            {{ saveText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
