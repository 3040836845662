import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeSummaryComponent } from './pages/summary/summary.component';
import { ElectricityComponent } from './pages/electricity/electricity.component';
import { WaterComponent } from './pages/water/water.component';
import { TemperatureComponent } from './pages/temperature/temperature.component';
import { SavingsComponent } from './components/savings/savings.component';
import { HomeInfoComponent } from './components/home-info/home-info.component';
import { DeviceInfoComponent } from './components/device-info/device-info.component';
import { ExpensesChartComponent } from './components/expenses-chart/expenses-chart.component';
import { ExpensesInfoComponent } from './components/expenses-info/expenses-info.component';
import { MainDeviceComponent } from './components/electricity/main-device/main-device.component';
import { DevicesComponent } from './components/electricity/devices/devices.component';
import { TodayComponent } from './components/electricity/today/today.component';
import { AccumulatedComponent } from './components/electricity/accumulated/accumulated.component';
import { AverageComponent } from './components/electricity/average/average.component';
import { ExpensesByDeviceComponent } from './components/electricity/expenses-by-device/expenses-by-device.component';
import { ElectricityRankingComponent } from './components/electricity/electricity-ranking/electricity-ranking.component';
import { ElectricitySavingsComponent } from './components/electricity/electricity-savings/electricity-savings.component';
import { ElectricityImprovementComponent } from './components/electricity/electricity-improvement/electricity-improvement.component';
import { RouterModule } from '@angular/router';

import { NgxEchartsModule } from 'ngx-echarts';
import { APIModule } from 'src/app/legacy-api';

import { DashboardModule } from '../dashboard/dashboard.module';
import { ConsumptionChartComponent } from './components/consumption-chart/consumption-chart.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ColdDevicesComponent } from './components/temperature/cold-devices/cold-devices.component';
import { HeatDevicesComponent } from './components/temperature/heat-devices/heat-devices.component';
import { TemperatureConsumptionChartComponent } from './components/temperature/temperature-consumption-chart/temperature-consumption-chart.component';
import { WeatherComponent } from './components/weather/weather.component';
import { AccumulatedTemperatureComponent } from './components/temperature/accumulated/accumulated.component';
import { ColdConsumptionComponent } from './components/temperature/cold-consumption/cold-consumption.component';
import { HeatConsumptionComponent } from './components/temperature/heat-consumption/heat-consumption.component';
import { TodayTemperatureComponent } from './components/temperature/today/today.component';
import { AverageTemperatureComponent } from './components/temperature/average/average.component';
import { HeatAccumulatedComponent } from './components/temperature/heat-accumulated/heat-accumulated.component';
import { HeatTodayComponent } from './components/temperature/heat-today/heat-today.component';
import { HeatAverageComponent } from './components/temperature/heat-average/heat-average.component';
import { ColdTodayComponent } from './components/temperature/cold-today/cold-today.component';
import { ColdAverageComponent } from './components/temperature/cold-average/cold-average.component';
import { ColdAccumulatedComponent } from './components/temperature/cold-accumulated/cold-accumulated.component';
import { WaterStatusComponent } from './components/water/water-status/water-status.component';
import { WaterComparisonComponent } from './components/water/water-comparison/water-comparison.component';
import { WaterPercentageComponent } from './components/water/water-percentage/water-percentage.component';
import { WaterHomeComparisonComponent } from './components/water/water-home-comparison/water-home-comparison.component';
import { TodayWaterComponent } from './components/water/today/today.component';
import { AverageWaterComponent } from './components/water/average/average.component';
import { AccumulatedWaterComponent } from './components/water/accumulated/accumulated.component';
import { WaterConsumptionChartComponent } from './components/water/water-consumption-chart/water-consumption-chart.component';
import { WaterTemperatureComponent } from './pages/water-temperature/water-temperature.component';
import { WaterStatusDetailComponent } from './components/water/water-status-detail/water-status-detail.component';

@NgModule({
  declarations: [
    HomeSummaryComponent,
    ElectricityComponent,
    WaterComponent,
    TemperatureComponent,
    SavingsComponent,
    HomeInfoComponent,
    DeviceInfoComponent,
    ExpensesChartComponent,
    ExpensesInfoComponent,
    MainDeviceComponent,
    DevicesComponent,
    TodayComponent,
    AccumulatedComponent,
    AverageComponent,
    ExpensesByDeviceComponent,
    ElectricityRankingComponent,
    ElectricitySavingsComponent,
    ElectricityImprovementComponent,
    ConsumptionChartComponent,
    ColdDevicesComponent,
    HeatDevicesComponent,
    TemperatureConsumptionChartComponent,
    WeatherComponent,
    AccumulatedTemperatureComponent,
    ColdConsumptionComponent,
    HeatConsumptionComponent,
    TodayTemperatureComponent,
    AverageTemperatureComponent,
    HeatAccumulatedComponent,
    HeatTodayComponent,
    HeatAverageComponent,
    ColdTodayComponent,
    ColdAverageComponent,
    ColdAccumulatedComponent,
    WaterStatusComponent,
    WaterComparisonComponent,
    WaterPercentageComponent,
    WaterHomeComparisonComponent,
    AccumulatedWaterComponent,
    TodayWaterComponent,
    AverageWaterComponent,
    WaterConsumptionChartComponent,
    WaterTemperatureComponent,
    WaterStatusDetailComponent,
  ],
  imports: [
    APIModule,
    CommonModule,
    RouterModule,
    DashboardModule,

    MatButtonToggleModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [
    ConsumptionChartComponent,
    TemperatureConsumptionChartComponent,
    ColdConsumptionComponent,
    HeatConsumptionComponent,
  ],
})
export class HomeModule {}
