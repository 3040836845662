import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class SubscribableTitleService {
  public title: BehaviorSubject<string> = new BehaviorSubject<string>('');

  setTitle(value: string) {
    this.title.next(value);
  }
}
