// Automatically generated - do not modify!

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IUserConsumptionApiService {
  /**
   * @method
   * @name  getUserConsumptionLast12month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserConsumptionLast12month(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getUserConsumptionElectricLast12month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserConsumptionElectricLast12month(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getUserConsumptionClimaLast12month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserConsumptionClimaLast12month(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getUserConsumptionWaterLast12month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserConsumptionWaterLast12month(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getUserConsumptionLast6month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserConsumptionLast6month(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getUserConsumptionThisMonth
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getUserConsumptionThisMonth(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class UserConsumptionApiService implements IUserConsumptionApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'UserConsumptionApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getUserConsumptionLast12month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserConsumptionLast12month(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/consumption/last12month`,
      options
    );
  }

  /**
   * @method
   * @name  getUserConsumptionElectricLast12month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserConsumptionElectricLast12month(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/consumption/electric/last12month`,
      options
    );
  }

  /**
   * @method
   * @name  getUserConsumptionClimaLast12month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserConsumptionClimaLast12month(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/consumption/clima/last12month`,
      options
    );
  }

  /**
   * @method
   * @name  getUserConsumptionWaterLast12month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserConsumptionWaterLast12month(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/consumption/water/last12month`,
      options
    );
  }

  /**
   * @method
   * @name  getUserConsumptionLast6month
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserConsumptionLast6month(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/consumption/last6month`,
      options
    );
  }

  /**
   * @method
   * @name  getUserConsumptionThisMonth
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getUserConsumptionThisMonth(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/user/consumption/thisMonth`,
      options
    );
  }
}
