<head>
  <meta charset="UTF-8" />

  <title>
    {{ flavour === "hobeen" ? "Hobeen" : "Gestiona tu Energía" }} - Inicio de
    sesión
  </title>
</head>

<body>
  <div
    class="login-page"
    [ngStyle]="{
      'background-image': common.getClientData()
        ? ''
        : 'url(/assets/img/login-background.svg)'
    }"
  >
    <div class="content">
      <div class="login-left">
        {{ clientData | json }}
        <img
          [src]="
            common.getClientData() ? clientLogo : '/assets/img/login-logo.svg'
          "
          alt="{{ clientDataString }}"
          class=""
          [ngStyle]="{ 'max-width': clientData ? '250px' : '' }"
        />
      </div>

      <div class="login-right">
        <form class="form-signin" (ngSubmit)="login()" *ngIf="cookies">
          <div class="form-label-group">
            <h2 class="login-label body-2">Correo Electrónico</h2>
            <input
              type="text"
              id="userName"
              name="username"
              [(ngModel)]="username"
              class="form-control label"
              placeholder="Correo Electrónico"
              required
              autofocus
            />
          </div>
          <div class="form-label-group password-box">
            <h2 class="login-label body-2">Contraseña</h2>
            <input
              type="password"
              id="inputPassword"
              name="password"
              [(ngModel)]="password"
              class="form-control label"
              placeholder="**********"
              autocomplete="on"
              required
            />
          </div>
          <div class="login-error body-1 bold" *ngIf="loginError">
            Tus datos de acceso no son correctos. <br />
            Por favor, inténtalo de nuevo
          </div>
          <div (click)="openRecovery()" class="forget caption bold">
            Recupera tu clave
          </div>

          <div class="login-button">
            <button
              type="submit"
              class="button-primary caption"
              *ngIf="!loading"
            >
              Entrar
            </button>
            <button class="button-primary caption" *ngIf="loading">
              <mat-spinner
                diameter="20"
                style="padding-right: 12px; margin-left: 11px"
              ></mat-spinner>
            </button>
          </div>
        </form>

        <form class="form-signin" (ngSubmit)="openCookies()" *ngIf="!cookies">
          <div class="login-button">
            <button type="submit" class="button-primary caption">
              Acepta las cookies
            </button>
          </div>
        </form>
      </div>
    </div>
    <h5 class="footer">
      <div (click)="openLegalText()" class="element">Aviso Legal</div>
      |
      <div (click)="openCookiesText()" class="element">Política de Cookies</div>
      |
      <div (click)="openPrivacyText()" class="element">
        Política de Privacidad
      </div>
    </h5>
  </div>
</body>
