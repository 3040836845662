import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-widget-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  constructor() {}

  @Output() dateRangeChange = new EventEmitter<UntypedFormGroup>();
  now = new Date();

  dateRange!: UntypedFormGroup;
  ngOnInit(): void {
    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });
  }

  newDate(): void {
    environment.startDate = this.dateRange.value.start;

    if (this.dateRange.value.end) {
      environment.endDate = this.dateRange.value.end;
    } else {
      this.dateRange.value.end = new Date(this.dateRange.value.start);
      environment.endDate = this.dateRange.value.end;
    }
    this.dateRangeChange.emit(this.dateRange);
  }
}
