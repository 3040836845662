import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AirControlService, PodInfoRoot, Schedule } from 'src/app/api';
import { ProgrammingWizardComponent } from '../programming-wizard/programming-wizard.component';

@Component({
  selector: 'app-sensibo-schedule',
  templateUrl: './sensibo-schedule.component.html',
  styleUrls: ['./sensibo-schedule.component.scss'],
})
export class SensiboScheduleComponent implements OnInit {
  @Input() schedules!: Array<Schedule> | undefined;
  @Input() deviceId!: string;
  @Input() deviceInfo!: PodInfoRoot | undefined;
  loading!: boolean;
  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private api: AirControlService
  ) {
    this.schedules = [];
  }

  scheduleDeletedSnackbar() {
    this.snackBar.open('La programación ha sido borrada', 'Ocultar');
  }

  openDialog(schedule: any): void {
    this.dialog.open(ProgrammingWizardComponent, {
      data: {
        schedule: schedule,
        deviceId: this.deviceId,
        deviceInfo: this.deviceInfo,
      },
    });
  }

  enableSchedule(schedule: any): void {
    this.api
      .climaDeviceDeviceIdAirScheduleScheduleIdEnablePost(
        schedule.id,
        this.deviceId
      )
      .subscribe((response) => console.log(response));
  }

  deleteSchedule(schedule: any): void {
    this.api
      .climaDeviceDeviceIdAirScheduleScheduleIdDelete(
        this.deviceId,
        schedule.id
      )
      .subscribe((response) => {
        if (this.schedules) {
          this.schedules.splice(this.schedules.indexOf(schedule), 1);
          this.scheduleDeletedSnackbar();
        }
      });
  }

  changeSchedule(schedule: any): void {
    if (schedule.isEnabled) {
      this.enableSchedule(schedule);
    } else {
      this.disableSchedule(schedule);
    }
  }
  disableSchedule(schedule: any): void {
    this.api
      .climaDeviceDeviceIdAirScheduleScheduleIdDisablePost(
        schedule.id,
        this.deviceId
      )
      .subscribe((response) => console.log(response));
  }

  ngOnChanges(): void {
    if (this.schedules != undefined) {
      this.loading = false;
    }
  }
  ngOnInit(): void {
    this.loading = true;
  }
}
