import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HeatingDataService, HourAndConsumption } from 'src/app/api';

@Component({
  selector: 'app-heat-accumulated',
  templateUrl: './heat-accumulated.component.html',
  styleUrls: ['./heat-accumulated.component.scss'],
})
export class HeatAccumulatedComponent implements OnInit {
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup;
  @Input() response!: HourAndConsumption | undefined;
  @Input() loading!: boolean;
  @Input() error: any;

  data: any = [];
  names: any = [];
  options: any;

  constructor(private homeApi: HeatingDataService) {
    this.error = 0;
  }
  ngOnInit(): void {
    this.loading = true;
    this.getData();
  }

  getData(): void {
    this.homeApi
      .climaHomeHomeIdHeatingConsumptionThisMonthGet(this.homeId)
      .subscribe(
        (response) => {
          this.response = response;
          this.loading = false;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .climaHomeHomeIdHeatingConsumptionThisMonthGet(this.homeId)
              .subscribe(
                (response) => {
                  this.response = response;
                  this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
}
