<div class="widget">
  <div class="header">
    <div class="icon"><img src="/assets/img/settings/home.svg" alt="" /></div>
  </div>
  <div class="body body-1">
    <div class="user" *ngFor="let home of homes">
      <div class="header">
        <div class="user-icon">
          <img src="/assets/img/settings/home.svg" alt="" />
        </div>
        <div class="name bold">{{ home.homeName }}</div>
        <div class="space"></div>
        <div class="edit">
          <a class="icon" (click)="openLocationDialog(home)">
            <img src="/assets/img/settings/edit.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="content">
        <div class="list">
          <div class="element">
            <div class="element-name bold">Titular de la vivienda</div>
            <div class="value">
              {{ home.user?.name }} - {{ home.user?.email }}
            </div>
          </div>
          <div class="element">
            <div class="element-name bold">Dirección</div>
            <div class="value">{{ home.address }} - {{ home.city }}</div>
          </div>

          <div class="element">
            <div class="element-name bold">Superficie</div>
            <div class="value">{{ home.surface }} <b class="body-1">m2</b></div>
          </div>
          <div class="element">
            <div class="element-name bold">Año de construcción</div>
            <div class="value">{{ home.year }}</div>
          </div>
          <div class="element">
            <div class="element-name bold">Orientación</div>
            <div class="value">{{ home.orientation }}</div>
          </div>
          <div class="element">
            <div class="element-name bold">Número de habitantes</div>
            <div class="value">{{ home.people }}</div>
          </div>
          <div class="element">
            <div class="element-name bold">Fecha de alta</div>
            <div class="value">
              {{ home.createdAt | date : "short" }}
            </div>
          </div>
        </div>
        <div class="footer">
          <div
            class="button-compact-primary caption"
            (click)="openBillDialog(home)"
          >
            Configurar electricidad
          </div>
          <div
            class="button-compact-primary caption"
            (click)="openClimaDialog(home)"
          >
            Configurar climatización
          </div>
          <div
            class="button-compact-primary caption"
            (click)="navigateNewHome(home)"
          >
            Ir a la vivienda
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
