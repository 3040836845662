import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-electricity-ranking',
  templateUrl: './electricity-ranking.component.html',
  styleUrls: ['./electricity-ranking.component.scss']
})
export class ElectricityRankingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
