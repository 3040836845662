import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

import { DashboardComponent } from './dashboard.component';

import { HomesComponent } from './pages/homes/homes.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

import { SavingsComponent } from './components/savings/savings.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { APIModule } from 'src/app/legacy-api';
import { ErrorComponent } from './components/error/error.component';
import { CarbonFootprintChartComponent } from './components/carbon-footprint-chart/carbon-footprint-chart.component';
import { CarbonFootprintSummaryComponent } from './components/carbon-footprint-summary/carbon-footprint-summary.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ChallengeListComponent } from './components/challenges/challenge-list/challenge-list.component';
import { ChallengePopupComponent } from './components/challenges/challenge-popup/challenge-popup.component';
import { MatIconModule } from '@angular/material/icon';
import { SettingsComponent } from './pages/settings/settings.component';
import { LocationsListComponent } from './components/settings/locations-list/locations-list.component';
import { UserSettingsComponent } from './components/settings/user-settings/user-settings.component';
import { UserInfoComponent } from './components/settings/user-info/user-info.component';
import { DisclaimerComponent } from './components/settings/disclaimer/disclaimer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { HomeSettingsComponent } from './components/settings/home-settings/home-settings.component';
import { BillSettingsComponent } from './components/settings/bill-settings/bill-settings.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from 'src/app/core/adapters/custom-date-adapter';
import { SpecialCharacterDirective } from './components/settings/home-settings/specialCharacter.directive';
import { NumericDirective } from './components/settings/home-settings/number.directive';
import { ClimaSettingsComponent } from './components/settings/clima-settings/clima-settings.component';
@NgModule({
  declarations: [
    DashboardComponent,
    DatepickerComponent,
    HomesComponent,
    HomeComponent,
    SavingsComponent,
    ErrorComponent,
    CarbonFootprintChartComponent,
    CarbonFootprintSummaryComponent,
    ChallengeListComponent,
    ChallengePopupComponent,
    SettingsComponent,
    LocationsListComponent,
    UserSettingsComponent,
    UserInfoComponent,
    DisclaimerComponent,
    HomeSettingsComponent,
    BillSettingsComponent,
    SpecialCharacterDirective,
    NumericDirective,
    ClimaSettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    APIModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressSpinnerModule,

    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [
    ErrorComponent,
    CarbonFootprintChartComponent,
    CarbonFootprintSummaryComponent,
    DatepickerComponent,
  ],
})
export class DashboardModule {}
