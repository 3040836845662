<div class="expenses-info">
  <div class="element">
    <h5 class="text">Hoy</h5>
    <div class="icons">
      <div class="electricity">
        <div class="icon">
          <img src="/assets/img/icons/widget/electricity.svg" alt="" />
        </div>
        <div class="amount body-1">
          {{ responseTodayElectric?.cost || 0 | number : "1.2-2" : "es" }} €
        </div>
      </div>
      <div class="water" *ngIf="!(environment.flavour == 'total')">
        <div class="icon">
          <img src="/assets/img/icons/widget/water.svg" alt="" />
        </div>
        <div class="amount body-1">
          {{ undefined || 0 | number : "1.2-2" : "es" }} €
        </div>
      </div>
      <div class="temperature">
        <div class="icon">
          <img src="/assets/img/icons/widget/temperature.svg" alt="" />
        </div>
        <div class="amount body-1">
          {{ responseTodayHeat?.cost || 0 | number : "1.2-2" : "es" }} €
        </div>
      </div>
    </div>
    <h1 class="total">
      {{ responseTodayTotal?.cost || 0 | number : "1.2-2" : "es" }} €
    </h1>
  </div>
  <div class="element">
    <h5 class="text">Este mes</h5>
    <div class="icons">
      <div class="electricity">
        <div class="icon">
          <img src="/assets/img/icons/widget/electricity.svg" alt="" />
        </div>
        <div
          class="amount body-1"
          *ngIf="
            responseLast12Months &&
              responseLast12Months.electric &&
              responseLast12Months.electric.data &&
              responseLast12Months.electric.data.length &&
              responseLast12Months.electric.data.length >= 0;
            else noData
          "
        >
          {{
            responseLast12Months.electric.data![
              responseLast12Months.electric!.data!.length - 1
            ].cost || 0 | number : "1.2-2" : "es"
          }}
          €
        </div>
        <ng-template #noData
          ><div class="amount body-1">0,00 €</div></ng-template
        >
      </div>
      <div class="water" *ngIf="!(environment.flavour == 'total')">
        <div class="icon">
          <img src="/assets/img/icons/widget/water.svg" alt="" />
        </div>
        <div class="amount body-1">
          {{ undefined || 0 | number : "1.2-2" : "es" }} €
        </div>
      </div>
      <div class="temperature">
        <div class="icon">
          <img src="/assets/img/icons/widget/temperature.svg" alt="" />
        </div>
        <div
          class="amount body-1"
          *ngIf="
            responseLast12Months &&
              responseLast12Months.heating &&
              responseLast12Months.heating.data &&
              responseLast12Months.heating.data.length &&
              responseLast12Months.heating.data.length >= 0;
            else noData2
          "
        >
          {{
            responseLast12Months.heating.data![
              responseLast12Months.heating!.data!.length - 1
            ].cost || 0 | number : "1.2-2" : "es"
          }}
          €
        </div>
        <ng-template #noData2
          ><div class="amount body-1">0,00 €</div></ng-template
        >
      </div>
    </div>
    <h1 class="total">
      <ng-container
        *ngIf="
          responseLast12Months &&
            responseLast12Months.total &&
            responseLast12Months.total.data &&
            responseLast12Months.total.data.length &&
            (responseLast12Months?.total?.data)![
              responseLast12Months.total.data.length - 1
            ];
          else noData3
        "
        >{{
          responseLast12Months.total.data[
            responseLast12Months.total.data.length - 1
          ].cost || 0 | number : "1.2-2" : "es"
        }}
        €</ng-container
      >
      <ng-template #noData3>0,00 €</ng-template>
    </h1>
  </div>
  <div class="element">
    <h5 class="text">Medio</h5>
    <div class="icons">
      <div class="electricity">
        <div class="icon">
          <img src="/assets/img/icons/widget/electricity.svg" alt="" />
        </div>
        <div class="amount body-1">
          {{ responseAvgDayElectric?.cost || 0 | number : "1.2-2" : "es" }} €
        </div>
      </div>
      <div class="water" *ngIf="!(environment.flavour == 'total')">
        <div class="icon">
          <img src="/assets/img/icons/widget/water.svg" alt="" />
        </div>
        <div class="amount body-1">
          {{ undefined || 0 | number : "1.2-2" : "es" }} €
        </div>
      </div>
      <div class="temperature">
        <div class="icon">
          <img src="/assets/img/icons/widget/temperature.svg" alt="" />
        </div>
        <div class="amount body-1">
          {{ responseAvgDayHeat?.cost || 0 | number : "1.2-2" : "es" }} €
        </div>
      </div>
    </div>
    <h1 class="total">
      {{ responseAvgDayTotal?.cost! || 0 | number : "1.2-2" : "es" }} €
    </h1>
  </div>
</div>
