<div class="body">
    <div class="main">
        <div class="up">
            <div class="consumption-chart extend column">
                <h2 class="title">Mis viviendas</h2>
                <div class="content extend">
                    <app-locations-list class="extend"></app-locations-list>
                </div>
            </div>

            <div class="right extend column">
                <div class="up-right extend column">
                    <h2 class="title">Mi usuario</h2>
                    <div class="content extend">
                        <app-user-info class="extend"></app-user-info>
                    </div>
                </div>

                <div class="down-right extend column">
                    <h2 class="title">Información importante</h2>
                    <div class="content extend">
                        <app-disclaimer class="extend"></app-disclaimer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>