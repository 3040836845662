import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationUser } from '../models/application-user';

interface LoginResult {
  message: string;
  user: ApplicationUser;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly apiUrl = `${environment.apiUrl}/auth`;
  private aux!: ApplicationUser;
  private appUser = new BehaviorSubject<ApplicationUser>(this.aux);
  user$: Observable<ApplicationUser> = this.appUser.asObservable();

  private storageEventListener(event: StorageEvent): void {
    if (event.storageArea === localStorage) {
      if (event.key === 'logout-event') {
        this.appUser.next(this.aux);
      }
      if (event.key === 'login-event') {
        this.http.get<LoginResult>(`${this.apiUrl}/me`).subscribe((x) => {
          this.appUser.next(x.user);
        });
      }
    }
  }

  constructor(private router: Router, private http: HttpClient) {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  login(email: string, password: string): any {
    return this.http
      .post<LoginResult>(
        `${this.apiUrl}/login`,
        { email, password },
        {
          withCredentials: true,
        }
      )
      .pipe(
        map((x) => {
          this.appUser.next(x.user);
          this.setLocalStorage(x);
          return x;
        })
      );
  }

  logout(): void {
    this.clearLocalStorage();
    localStorage.setItem('userHomes', '');
    this.http
      .post(
        `${this.apiUrl}/logout`,
        {},
        {
          withCredentials: true,
        }
      )
      .toPromise()
      .then((x) => this.router.navigate(['login']));
  }

  refreshToken(): any {
    const refreshToken = localStorage.getItem('loggedUser');
    if (!refreshToken) {
      this.clearLocalStorage();
      return of(null);
    }

    return this.http
      .get<LoginResult>(`${this.apiUrl}/me`, {
        withCredentials: true,
      })
      .pipe(
        map((x) => {
          this.appUser.next(x.user);
          this.setLocalStorage(x);
          return x;
        })
      );
  }

  setLocalStorage(x: LoginResult): void {
    localStorage.setItem('loggedUser', JSON.stringify(x.user));
    localStorage.setItem('login-event', 'login' + Math.random());
  }

  clearLocalStorage(): void {
    localStorage.removeItem('loggedUser');
    localStorage.setItem('logout-event', 'logout' + Math.random());
  }
}
