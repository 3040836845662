<div class="widget">
  <div class="header">
    <h2 class="primary">Editar programación</h2>
  </div>
  <div class="body">
    <div class="text caption">
      Modifica los parámetros de esta programación de tu dispositivo de aire
      acondicionado.
    </div>
    <div class="form">
      <div class="element">
        <div class="name body-1 neutral-40">Estado</div>
        <div class="toggle" *ngIf="state">
          <mat-button-toggle-group class="right" [(value)]="state">
            <mat-button-toggle value="on">Encendido</mat-button-toggle>
            <mat-button-toggle value="off">Apagado</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="element">
        <div class="name body-1 neutral-40">Hora programada</div>

        <input
          matInput
          specialIsAlphaNumeric
          [(ngModel)]="schedule.targetTimeLocal"
          placeholder="00"
          required
        />
      </div>

      <div class="element" *ngIf="state == 'on'">
        <div class="name body-1 neutral-40">Modo</div>

        <mat-button-toggle-group
          class="right"
          [(value)]="schedule.acState.mode"
        >
          <mat-button-toggle
            [disabled]="!data.deviceInfo.result.remoteCapabilities.modes.cool"
            value="cool"
            ><img src="/assets/img/icons/temperature/cold.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle
            [disabled]="!data.deviceInfo.result.remoteCapabilities.modes.heat"
            value="heat"
            ><img src="/assets/img/icons/temperature/heat.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle
            [disabled]="!data.deviceInfo.result.remoteCapabilities.modes.dry"
            value="dry"
            ><img src="/assets/img/icons/temperature/water.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle
            [disabled]="!data.deviceInfo.result.remoteCapabilities.modes.fan"
            value="fan"
            ><img src="/assets/img/icons/temperature/fan.svg" alt=""
          /></mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="element" *ngIf="state == 'on'">
        <div class="name body-1 neutral-40">Ventilador</div>

        <mat-button-toggle-group
          class="right"
          [(value)]="schedule.acState.fanLevel"
        >
          <mat-button-toggle value="auto">
            <img src="/assets/img/icons/temperature/fan-1.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle value="low">
            <img src="/assets/img/icons/temperature/fan-2.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle value="medium">
            <img src="/assets/img/icons/temperature/fan-3.svg" alt=""
          /></mat-button-toggle>
          <mat-button-toggle value="high">
            <img src="/assets/img/icons/temperature/fan-4.svg" alt=""
          /></mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="element" *ngIf="state == 'on'">
        <div class="name body-1 neutral-40">Temperatura</div>
        <div class="celsius" style="display: flex">
          <mat-form-field appearance="outline" class="input">
            <input
              matInput
              type="number"
              [(ngModel)]="schedule.acState.targetTemperature"
              placeholder="20"
              required
            />
          </mat-form-field>
          <p class="celsius-unit">ºC</p>
        </div>
      </div>

      <div class="element">
        <div class="name body-1 neutral-40">Repetición</div>

        <mat-button-toggle-group
          class="right"
          [(value)]="schedule.recurringDays"
          multiple
        >
          <mat-button-toggle value="Monday">L</mat-button-toggle>
          <mat-button-toggle value="Tuesday">M</mat-button-toggle>
          <mat-button-toggle value="Wednesday">X</mat-button-toggle>
          <mat-button-toggle value="Thursday">J</mat-button-toggle>
          <mat-button-toggle value="Friday">V</mat-button-toggle>
          <mat-button-toggle value="Saturday">S</mat-button-toggle>
          <mat-button-toggle value="Sunday">D</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="buttons">
        <button class="button-secondary caption" (click)="closeDialog()">
          Salir sin guardar
        </button>
        <button class="button-primary caption" (click)="setSchedule()">
          {{ saveText }}
        </button>
      </div>
    </div>
  </div>
</div>
