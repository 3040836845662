import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ElectricDeviceService,
  ElectricHomeService,
  HomeConsumptionService,
} from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity-consumption-chart',
  templateUrl: './consumption-chart.component.html',
  styleUrls: ['./consumption-chart.component.scss'],
})
export class ConsumptionChartComponent implements OnInit, OnChanges {
  @Input() homeId!: string;
  @Input() deviceId!: string;
  @Input() type!: string;
  @Input() dateRange!: FormGroup;
  @Input() response: any;
  @Input() loading!: boolean;
  @Input() error: any;

  unit!: string;
  ratio!: string;
  options: any;
  subscription: any;

  data: any = [];
  constructor(
    private homeApi: ElectricHomeService,
    private deviceApi: ElectricDeviceService,
    private totalApi: HomeConsumptionService
  ) {
    this.error = false;
    this.loading = true;
    this.unit = 'energy';
    this.ratio = 'day';
  }

  ngOnInit(): void {
    this.reloadData();
  }

  ngOnChanges(): void {
    this.loading = true;
    this.dateRange.valueChanges.subscribe((x) => this.reloadData());
    this.reloadData();
  }

  onValueChange(event: any) {
    if (!!event) this.unit = event;
  }
  adaptData(data: any): any {
    this.response = data;
    this.data = [];
    setTimeout(() => {
      switch (this.unit) {
        case 'energy':
          for (let dato of data.data) {
            let energy = 0;
            if (dato.energy > 0.1) {
              energy = dato.energy.toFixed(2);
            } else if (dato.energy > 0.01) {
              energy = dato.energy.toFixed(3);
            } else if (dato.energy > 0) {
              energy = dato.energy.toFixed(4);
            } else {
              energy = dato.energy.toFixed(0);
            }
            const elemento = {
              name: dato.homeStartTime,
              value: [new Date(dato.homeStartTime), energy],
            };
            this.data.push(elemento);
          }
          break;
        case 'cost':
          for (const dato of data.data) {
            const elemento = {
              name: dato.homeStartTime,
              value: [new Date(dato.homeStartTime), dato.cost.toFixed(2)],
            };
            this.data.push(elemento);
          }
          break;
      }
      this.setOptions();
    }, 1);
  }

  reloadData(): any {
    setTimeout(() => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.loading = true;
      }
      switch (this.type) {
        case 'device':
          switch (this.ratio) {
            case 'hour':
              this.subscription = this.deviceApi
                .electricDeviceDeviceIdEnergyByhourGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'day':
              this.subscription = this.deviceApi
                .electricDeviceDeviceIdEnergyBydayGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.deviceApi
                        .electricDeviceDeviceIdEnergyBydayGet(
                          this.deviceId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'week':
              this.subscription = this.deviceApi
                .electricDeviceDeviceIdEnergyByweekGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.deviceApi
                        .electricDeviceDeviceIdEnergyByweekGet(
                          this.deviceId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'month':
              this.subscription = this.deviceApi
                .electricDeviceDeviceIdEnergyBymonthGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.deviceApi
                        .electricDeviceDeviceIdEnergyBymonthGet(
                          this.deviceId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'year':
              this.subscription = this.deviceApi
                .electricDeviceDeviceIdEnergyByyearGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.deviceApi
                        .electricDeviceDeviceIdEnergyByyearGet(
                          this.deviceId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
          }
          break;

        case 'total':
          switch (this.ratio) {
            case 'hour':
              this.subscription = this.totalApi
                .homeHomeIdConsumptionByhourGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),

                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.totalApi
                        .homeHomeIdConsumptionByhourGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'day':
              this.subscription = this.totalApi
                .homeHomeIdConsumptionBydayGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),

                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.totalApi
                        .homeHomeIdConsumptionBydayGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'week':
              this.subscription = this.totalApi
                .homeHomeIdConsumptionByweekGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.loading = true;
                      this.error = false;
                      this.subscription = this.totalApi
                        .homeHomeIdConsumptionByweekGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'month':
              this.subscription = this.totalApi
                .homeHomeIdConsumptionBymonthGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.loading = true;
                      this.error = null;
                      this.subscription = this.totalApi
                        .homeHomeIdConsumptionBymonthGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'year':
              this.subscription = this.totalApi
                .homeHomeIdConsumptionByyearGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;

                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.totalApi
                        .homeHomeIdConsumptionByyearGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
          }
          break;
        case 'home':
          switch (this.ratio) {
            case 'hour':
              this.subscription = this.homeApi
                .electricHomeHomeIdEnergyByhourGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.homeApi
                        .electricHomeHomeIdEnergyByhourGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'day':
              this.subscription = this.homeApi
                .electricHomeHomeIdEnergyBydayGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.homeApi
                        .electricHomeHomeIdEnergyBydayGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'week':
              this.subscription = this.homeApi
                .electricHomeHomeIdEnergyByweekGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.loading = true;
                      this.error = false;
                      this.subscription = this.homeApi
                        .electricHomeHomeIdEnergyByweekGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'month':
              this.subscription = this.homeApi
                .electricHomeHomeIdEnergyBymonthGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.loading = true;
                      this.error = null;
                      this.subscription = this.homeApi
                        .electricHomeHomeIdEnergyBymonthGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'year':
              this.subscription = this.homeApi
                .electricHomeHomeIdEnergyByyearGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;

                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.homeApi
                        .electricHomeHomeIdEnergyByyearGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(
                            this.dateRange.value.end.getTime() +
                              24 * 60 * 60 * 1000
                          )
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
          }
          break;
      }
    }, 1);
  }

  setOptions(): void {
    let interval;
    let axisName: any;
    let units: any;
    let series: any;
    switch (this.ratio) {
      case 'hour':
        axisName = 'Hora';
        interval = 60 * 60 * 1000;
        break;
      case 'day':
        axisName = 'Día';
        interval = 60 * 60 * 1000 * 24;
        break;
      case 'week':
        axisName = 'Semana';
        interval = 60 * 60 * 1000 * 24 * 7;
        break;
      case 'month':
        axisName = 'Mes';
        interval = 60 * 60 * 1000 * 24 * 28;
        break;
      case 'year':
        axisName = 'Año';
        interval = 60 * 60 * 1000 * 24 * 365;
        break;
    }

    if (this.unit === 'energy') {
      units = ' kWh';
      series = 'Consumo';
    } else {
      units = ' € (exc. Imp)';
      series = 'Coste';
    }

    const options = {
      tooltip: {
        position: 'right',
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        confine: true,

        formatter: function (params: any) {
          let text = '';
          switch (axisName) {
            case 'Hora':
              text =
                series +
                ' a las ' +
                new Date(params[0].data.name).getHours() +
                ':00h del ' +
                new Date(params[0].data.name).toLocaleDateString();
              break;
            case 'Día':
              text =
                series +
                ' el ' +
                new Date(params[0].data.name).toLocaleDateString();

              break;
            case 'Semana':
              let date = new Date(params[0].data.name);
              text =
                series +
                ' la semana (' +
                date.toLocaleDateString() +
                ' - ' +
                new Date(
                  new Date().setDate(date.getDate() + 7)
                ).toLocaleDateString() +
                ')';
              break;
            case 'Mes':
              const monthNames = [
                'enero',
                'febrero',
                'marzo',
                'abril',
                'mayo',
                'junio',
                'julio',
                'agosto',
                'septiembre',
                'octubre',
                'noviembre',
                'diciembre',
              ];
              text =
                series +
                ' en ' +
                monthNames[new Date(params[0].data.name).getMonth()] +
                ' de ' +
                new Date(params[0].data.name).getFullYear();

              break;
            case 'Año':
              text =
                series +
                ' en el año ' +
                new Date(params[0].data.name).getFullYear();

              break;
          }
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params[0].color +
            '"></span>  <a style="font-size: 1rem"> ' +
            text +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params[0].data.value[1].replace('.', ',') +
            units +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },

      xAxis: {
        type: 'time',
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        minInterval: interval,
        name: axisName,
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },

        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: units,
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        splitLine: {
          show: true,
        },
      },
      series: [
        {
          name: series,
          type: 'bar',
          data: this.data,
          color: environment.textColor,
          itemStyle: {
            borderRadius: [11, 11, 0, 0],
          },
        },
      ],
      grid: {
        top: 32,
        left: 32,
        right: 48,
        bottom: 32,
      },
    };

    this.options = options;
    this.loading = false;
  }
}
