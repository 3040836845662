<div class="widget extend" *ngIf="!error">
  <div class="content extend" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>

  <div class="data" *ngIf="!loading">
    <h4 class="day">{{ today | date : "EEEE" | titlecase }}</h4>
    <div class="date caption">{{ today | date : "dd LLLL" }}</div>
    <div class="city nav-up">{{ response?.name }}</div>
  </div>
  <div class="info" *ngIf="!loading">
    <div class="icon">
      <img
        src="/assets/img/weather/sunny.svg"
        alt=""
        *ngIf="(response?.weather)![0].description == 'clear sky'"
      />
      <img
        src="/assets/img/weather/sunny-cloud.svg"
        alt=""
        *ngIf="(response?.weather)![0].description == 'few clouds'"
      />
      <img
        src="/assets/img/weather/sun-storm.svg"
        alt=""
        *ngIf="
          (response?.weather)![0].description == 'thunderstorm' ||
          (response?.weather)![0].description == 'light thunderstorm' ||
          (response?.weather)![0].description == 'heavy thunderstorm' ||
          (response?.weather)![0].description == 'ragged thunderstorm'
        "
      />
      <img
        src="/assets/img/weather/storm-rain.svg"
        alt=""
        *ngIf="
          (response?.weather)![0].description ==
            'thunderstorm with light rain' ||
          (response?.weather)![0].description == 'thunderstorm with rain' ||
          (response?.weather)![0].description ==
            'thunderstorm with heavy rain' ||
          (response?.weather)![0].description == 'thunderstorm with drizzle' ||
          (response?.weather)![0].description ==
            'thunderstorm with heavy drizzle' ||
          (response?.weather)![0].description ==
            'thunderstorm with light drizzle'
        "
      />
      <img
        src="/assets/img/weather/clouds.svg"
        alt=""
        *ngIf="
          (response?.weather)![0].description == 'scattered clouds' ||
          (response?.weather)![0].description == 'broken clouds' ||
          (response?.weather)![0].description == 'overcast clouds' ||
          (response?.weather)![0].description == 'tornado'
        "
      />

      <img
        src="/assets/img/weather/rain.svg"
        alt=""
        *ngIf="
          (response?.weather)![0].description == 'shower rain' ||
          (response?.weather)![0].description == 'light intensity drizzle' ||
          (response?.weather)![0].description == 'drizzle' ||
          (response?.weather)![0].description == 'rain' ||
          (response?.weather)![0].description == 'heavy intensity drizzle' ||
          (response?.weather)![0].description ==
            'light intensity drizzle rain' ||
          (response?.weather)![0].description == 'drizzle rain' ||
          (response?.weather)![0].description ==
            'heavy intensity drizzle rain' ||
          (response?.weather)![0].description == 'shower rain and drizzle' ||
          (response?.weather)![0].description == 'shower drizzle' ||
          (response?.weather)![0].description == 'light rain' ||
          (response?.weather)![0].description == 'heavy intensity rain' ||
          (response?.weather)![0].description == 'very heavy rain' ||
          (response?.weather)![0].description == 'extreme rain' ||
          (response?.weather)![0].description == 'freezing rain' ||
          (response?.weather)![0].description ==
            'light intensity shower rain' ||
          (response?.weather)![0].description == 'shower rain' ||
          (response?.weather)![0].description ==
            'heavy intensity shower rain' ||
          (response?.weather)![0].description == 'moderate rain' ||
          (response?.weather)![0].description == 'ragged shower rain' ||
          (response?.weather)![0].description == 'squalls'
        "
      />

      <img
        src="/assets/img/weather/snow.svg"
        alt=""
        *ngIf="
          (response?.weather)![0].description == 'light snow' ||
          (response?.weather)![0].description == 'Snow' ||
          (response?.weather)![0].description == 'Heavy snow' ||
          (response?.weather)![0].description == 'Sleet' ||
          (response?.weather)![0].description == 'Light shower sleet' ||
          (response?.weather)![0].description == 'Shower sleet' ||
          (response?.weather)![0].description == 'Light rain and snow' ||
          (response?.weather)![0].description == 'Rain and snow' ||
          (response?.weather)![0].description == 'Light shower snow' ||
          (response?.weather)![0].description == 'Shower snow' ||
          (response?.weather)![0].description == 'Heavy shower snow'
        "
      />

      <img
        src="/assets/img/weather/fog.svg"
        alt=""
        *ngIf="
          (response?.weather)![0].description == 'Smoke' ||
          (response?.weather)![0].description == 'mist' ||
          (response?.weather)![0].description == 'Haze' ||
          (response?.weather)![0].description == 'sand/ dust whirls' ||
          (response?.weather)![0].description == 'fog' ||
          (response?.weather)![0].description == 'sand' ||
          (response?.weather)![0].description == 'dust' ||
          (response?.weather)![0].description == 'volcanic ash'
        "
      />
    </div>

    <div class="values">
      <div class="h0 temperature cold" *ngIf="response?.main?.temp! < 288">
        {{ response?.main?.temp! - 273 || 0 | number : "1.0-0" : "es" }}ºC
      </div>
      <div
        class="h0 temperature warm"
        *ngIf="response?.main?.temp! >= 288 && response?.main?.temp! < 298"
      >
        {{ response?.main?.temp! - 273 || 0 | number : "1.0-0" : "es" }}ºC
      </div>
      <div class="h0 temperature hot" *ngIf="response?.main?.temp! >= 298">
        {{ response?.main?.temp! - 273 || 0 | number : "1.0-0" : "es" }}ºC
      </div>
      <div class="h0 hunidity">
        {{ response?.main?.humidity! || 0 | number : "1.0-0" : "es" }}%
      </div>
    </div>
  </div>
</div>

<div class="widget error extend column" *ngIf="error && error != 500">
  <div class="nav-up">
    Para ver la información del tiempo en tu zona introduce tu localización en
    el panel de configuración
  </div>
  <div
    class="button-compact-primary label"
    style="flex: 0; width: fit-content; margin: 0px auto"
    [routerLink]="'/user/settings'"
  >
    Ir a configuración
  </div>
</div>

<div class="widget error extend" *ngIf="error && error == 500">
  <app-error [error]="error"></app-error>
</div>
