<div class="widget {{ data.type }}">
    <div class="header">
        <div class="logo"><img src="{{ data.image }}" alt="" /></div>
        <h2 class="title primary">{{ data.title }}</h2>
    </div>

    <div class="body nav-up">
        {{ data.text }}
    </div>

    <div class="footer">
        <h2 class="text secondary">¡Únete a los retos en nuestra APP!</h2>
        <a href="https://www.hobeen.com/get-app/ " target="_blank" class="button-primary caption">
      Accede a la app
    </a>
    </div>
</div>