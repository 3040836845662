import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ElectricConsumption, ElectricConsumptionList } from 'src/app/api';
import { echartOptions } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity-accumulated',
  templateUrl: './accumulated.component.html',
  styleUrls: ['./accumulated.component.scss'],
})
export class AccumulatedComponent implements OnChanges {
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup;
  @Input() response!: ElectricConsumptionList;
  @Input() loading!: boolean;
  @Input() error: any;
  responseData!: ElectricConsumption | undefined;
  data: any = [];
  names: any = [];
  options: any;

  constructor() {
    this.error = 0;
  }

  ngOnChanges(): void {
    if (!this.loading) this.adaptData(this.response);
  }

  adaptData(response: any): void {
    const colors = echartOptions.colorShort;
    this.response = response;
    this.responseData = response?.data[response.data.length - 1];
    this.names = [];
    let index = 0;
    if (
      response &&
      response.data &&
      response.data.length &&
      response.data.length - 1 >= 0 &&
      response?.data[response.data.length - 1].periodEnergy
    ) {
      for (const periodo of response?.data[response.data.length - 1]
        .periodEnergy) {
        if (periodo !== 0) {
          this.data.push({
            value: parseFloat('' + periodo).toFixed(2),
            name: 'P' + (index + 1),
            itemStyle: {
              color: colors[index],
            },
          });
          this.names.push('P' + (index + 1));
        }
        index++;
      }

      this.setOptions();
    }
  }
  setOptions(): void {
    let max = 0;
    for (const valor of this.data) {
      if (valor.value > max) {
        max = valor.value;
      }
    }
    const options = {
      tooltip: {
        position: 'top',
        formatter: function (params: any) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params.color +
            '"></span>  <a style="font-size: 1rem"> Consumo en ' +
            params.name +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params.value.replace('.', ',') +
            'kWh' +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        type: 'category',
        name: 'Periodo',
        data: this.names,
        axisLabel: {
          fontFamily: environment.fontFamily,
        },

        splitLine: {
          show: false,
        },
      },

      yAxis: {
        type: 'value',
        show: false,
        name: '€',
      },
      series: [
        {
          name: 'Consumo por periodo',
          type: 'bar',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          data: this.data,
          label: {
            fontFamily: environment.fontFamily,
            show: true,
            formatter(value: any): any {
              if (value.value < max / 8) {
                return '';
              }
              return ''; //value.value + '€';
            },
            position: 'insideTop',
          },

          barGap: '4px',
          barWidth: '99%',
        },
      ],

      grid: {
        top: '4px',
        left: 0,
        right: 0,
        bottom: '20px',
      },
    };

    this.options = options;
    this.loading = false;
  }
}
