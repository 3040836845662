<div class="widget" *ngIf="loading">
  <div class="body" *ngIf="true" style="display: flex; flex: 1; width: 100%">
    <div class="loading" style="min-height: 300px; flex: 1">
      <div class="loading-spinner"></div>
    </div>
  </div>
</div>
<!-- Página de selección de configuración. Aire acondicionado o calefacción-->
<div class="widget extend" *ngIf="!loading">
  <div class="page-content extend" *ngIf="page == 0">
    <div *ngIf="true" class="primary header h0">
      ¿Qué dispositivos quieres configurar?
    </div>
    <div class="content">
      <div class="nav-up" style="padding: 16px">
        Accede al asistente de cada dispositivo
      </div>
      <div class="buttons">
        <div class="buttons-row">
          <div
            class="button-compact-primary caption"
            (click)="switchMode('heating')"
          >
            Calefacción
          </div>
          <div
            class="button-compact-primary caption"
            (click)="switchMode('air')"
          >
            Aire Acondicionado
          </div>
        </div>

        <div class="exit">
          <div
            class="button-compact-secondary caption"
            style="margin: auto; width: 50%; text-align: center"
            (click)="closeDialog()"
          >
            Salir
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Aquí las páginas de calefacción-->
  <div class="page-content extend" *ngIf="page == 1 && type == 'heating'">
    <div *ngIf="true" class="primary h0">Configura tu calefacción</div>
    <div class="content">
      <div class="nav-up" style="max-width: 400px; text-align: center">
        Vamos a preguntarte algunos datos sobre tu instalación de calefacción.
        Te indicaremos dónde encontrar la información que necesitamos.
      </div>

      <div class="buttons">
        <div class="buttons-row">
          <div class="button-compact-secondary caption" (click)="pageBack()">
            Volver
          </div>
          <div class="button-compact-primary caption" (click)="getFuelTypes()">
            Empezar
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content extend" *ngIf="page == 2 && type == 'heating'">
    <div *ngIf="true" class="primary h0">Selecciona tu tipo de combustible</div>
    <div class="content">
      <div class="nav-up" style="max-width: 400px; text-align: center">
        ¿Qué tipo de combustible usa tu caldera? Conocer esta información nos
        permite obtener el valor calorífico de tu consumo.
      </div>
      <div
        class="selector"
        style="
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
        "
      >
        <div
          class="button-compact-secondary caption"
          style="text-transform: capitalize"
          *ngFor="let combustible of fuelList.data"
          (click)="selectFuel(combustible)"
        >
          {{ combustible.name }}
        </div>
      </div>

      <div class="buttons">
        <div class="buttons-row">
          <div class="button-compact-secondary caption" (click)="pageBack()">
            Volver
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content extend" *ngIf="page == 3 && type == 'heating'">
    <div *ngIf="true" class="primary h0">Precio del combustible</div>
    <div
      class="nav-up"
      style="text-align: center; text-align: center; padding: 32px 64px"
    >
      Introduce el precio de tu combustible. <br />
      Para el tipo de combustible que has seleccionado, debes introducir el
      precio de tu {{ this.heatingPrices.data![0].boilerFuelType!.text }}:
    </div>
    <div class="first-input" style="justify-content: center">
      <mat-form-field appearance="outline" class="input body-1">
        <input
          numeric
          matInput
          [(ngModel)]="heatingPrices.data![0].fuelPrice"
          placeholder=""
          type="number"
          min="0"
          max="3000"
          required
        />
      </mat-form-field>
      <div class="units">
        {{ heatingPrices.data![0].boilerFuelType!.fuelPriceUni }}
      </div>
    </div>
    <div class="buttons">
      <div class="buttons-row" style="justify-content: space-between">
        <div class="button-compact-secondary caption" (click)="pageBack()">
          Volver
        </div>
        <div class="button-compact-primary caption" (click)="pageUp()">
          Continuar
        </div>
      </div>
    </div>
  </div>

  <div class="page-content extend" *ngIf="page == 4 && type == 'heating'">
    <div *ngIf="true" class="primary h0">Eficiencia de tu caldera</div>
    <div class="nav-up" style="text-align: center">
      Busca en tu dispositivo o en los documentos del mismo la etiqueta:
    </div>
    <div class="columns">
      <img
        src="/assets/img/temperature/heat-efficiency-tag.png"
        alt=""
        class="efficiency"
      />
      <div class="side">
        <div class="nav-up">
          Necesitamos que nos indiques la clasificación de la eficiencia
          energética de tu caldera. Selecciona el valor indicado con el número
          1.
        </div>
        <mat-button-toggle-group
          class="icons"
          style="flex-wrap: wrap; justify-content: center"
          [(value)]="heatingPrices.data![0].boilerEfficiency"
        >
          <mat-button-toggle [value]="150">A+++</mat-button-toggle>
          <mat-button-toggle [value]="137">A++</mat-button-toggle>
          <mat-button-toggle [value]="112">A+</mat-button-toggle>
          <mat-button-toggle [value]="95">A</mat-button-toggle>
          <mat-button-toggle [value]="86">B</mat-button-toggle>
          <mat-button-toggle [value]="77">C</mat-button-toggle>
          <mat-button-toggle [value]="55">D</mat-button-toggle>
          <mat-button-toggle [value]="35">E</mat-button-toggle>
          <mat-button-toggle [value]="32">F</mat-button-toggle>
          <mat-button-toggle [value]="20">G</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="nav-up">
          Introduce el valor de la potencia calorífica indicada con el número 2
          en la etiqueta.
        </div>
        <div class="first-input">
          <mat-form-field appearance="outline" class="input body-1">
            <input
              numeric
              matInput
              [(ngModel)]="this.heatingPrices.data![0].boilerPower"
              placeholder=""
              type="number"
              min="0"
              max="3000"
              required
            />
          </mat-form-field>
          <div class="units">kW</div>
        </div>
      </div>
    </div>
    <div class="buttons extend">
      <div class="buttons-row spaced">
        <div class="button-compact-secondary caption" (click)="pageBack()">
          Volver
        </div>
        <div class="button-compact-primary caption" (click)="saveHeatPrices()">
          Guardar
        </div>
      </div>
    </div>
  </div>
  <!-- Aquí las páginas de aire acondicionado-->
  <div class="page-content extend" *ngIf="page == 1 && type == 'air'">
    <div *ngIf="true" class="primary h0">Configura tu aire acondicionado</div>

    <div class="content">
      <div class="nav-up" style="text-align: center; padding: 32px">
        Vamos a preguntarte algunos datos sobre tu dispositivo de aire
        acondicionado. Te indicaremos dónde encontrar la información que
        necesitamos.
      </div>

      <div class="buttons">
        <div class="buttons-row" style="justify-content: space-between">
          <div class="button-compact-secondary caption" (click)="pageBack()">
            Volver
          </div>
          <div class="button-compact-primary caption" (click)="pageUp()">
            Empezar
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content extend" *ngIf="page == 2 && type == 'air'">
    <div *ngIf="true" class="primary h0">Selecciona tu aire acondicionado</div>
    <div class="content">
      <div class="info nav-up">
        Selecciona el aire acondicionado sobre el que quieras aplicar la
        configuración
      </div>
      <div class="selector">
        <div class="devices" *ngIf="!loading">
          <a class="device" *ngFor="let device of devicesResponse?.data">
            <div class="icon" (click)="loadPrices(device)">
              <img
                src="/assets/img/temperature/airconditioner-on.svg"
                *ngIf="device.active"
                alt=""
              />
              <img
                src="/assets/img/temperature/airconditioner-off.svg"
                *ngIf="!device.active"
                alt=""
              />
            </div>
            <div class="name caption bold" (click)="loadPrices(device)">
              {{ device.device!.name || "Aire acondicionado" }}
            </div>
            <div class="data caption" (click)="loadPrices(device)">
              <div class="temperature">{{ device.temp || "--" }} ºC</div>
              <div class="humidity">{{ device.humidity || "--" }}%</div>
            </div>
            <div class="arrow" (click)="loadPrices(device)">
              <img src="/assets/img/new-arrow.svg" alt="" />
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="buttons-row" style="justify-content: center">
      <div class="button-compact-secondary caption" (click)="pageBack()">
        Volver
      </div>
    </div>
  </div>

  <div class="page-content extend" *ngIf="page == 3 && type == 'air'">
    <div *ngIf="true" class="primary h0">
      Eficiencia de tu aire acondicionado
    </div>
    <div class="nav-up" style="text-align: center">
      Busca en tu dispositivo o en los documentos del mismo la etiqueta:
    </div>
    <div class="columns">
      <img
        src="/assets/img/temperature/air-efficiency-tag.png"
        alt=""
        class="efficiency"
      />
      <div class="side">
        <div class="nav-up">
          Introduce el valor de la potencia frigorífica (el valor en kW) que
          encontrarás en el apartado SEER de tu etiqueta.
        </div>
        <div class="first-input">
          <mat-form-field appearance="outline" class="input body-1">
            <input
              numeric
              matInput
              [(ngModel)]="airPrices.coolPower"
              placeholder=""
              type="number"
              min="0"
              max="3000"
              required
            />
          </mat-form-field>
          <div class="units">kW</div>
        </div>
        <div class="nav-up">
          Introduce el valor de la potencia calorífica (el valor en kW) que
          encontrarás en el apartado SCOP de tu etiqueta.
        </div>
        <div class="first-input">
          <mat-form-field appearance="outline" class="input body-1">
            <input
              numeric
              matInput
              [(ngModel)]="airPrices.heatPower"
              placeholder=""
              type="number"
              min="0"
              max="3000"
              required
            />
          </mat-form-field>
          <div class="units">kW</div>
        </div>
      </div>
    </div>
    <div class="buttons extend">
      <div class="buttons-row spaced">
        <div class="button-compact-secondary caption" (click)="pageBack()">
          Volver
        </div>
        <div class="button-compact-primary caption" (click)="savePrices()">
          Guardar
        </div>
      </div>
    </div>
  </div>
</div>
