// Automatically generated - do not modify!

import { createParamString } from '../internals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IElectricDeviceApiService {
  /**
   * @method
   * @name  getElectricDeviceDeviceIdPrices
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdPrices(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdPricesCurrentperiod
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdPricesCurrentperiod(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdSensorLast
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdSensorLast(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdSensors
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdSensors(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdSensorsLast15m
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdSensorsLast15m(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyAbsolute
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyAbsolute(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyToday
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyToday(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyByhour
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyByhour(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyByday
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyByday(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyByweek
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyByweek(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyBymonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyBymonth(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyLast6month
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyLast6month(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyThisMonth(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyByyear
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyByyear(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyAvgHourweek
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyAvgHourweek(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricDeviceDeviceIdEnergyAvgDay(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class ElectricDeviceApiService implements IElectricDeviceApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'ElectricDeviceApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdPrices
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdPrices(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/prices`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdPricesCurrentperiod
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdPricesCurrentperiod(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/prices/currentperiod`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdSensorLast
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdSensorLast(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/sensor/last`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdSensors
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdSensors(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/sensors`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdSensorsLast15m
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdSensorsLast15m(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/sensors/last15m`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyAbsolute
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyAbsolute(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/absolute`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyToday
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyToday(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/today`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyByhour
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyByhour(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/byhour`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyByday
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyByday(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/byday`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyByweek
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyByweek(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/byweek`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyBymonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyBymonth(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/bymonth`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyLast6month
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyLast6month(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/last6month`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyThisMonth(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/thisMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyByyear
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyByyear(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/byyear`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyAvgHourweek
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyAvgHourweek(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/avg/hourweek`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricDeviceDeviceIdEnergyAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricDeviceDeviceIdEnergyAvgDay(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/device/${deviceId}/energy/avg/day`,
      options
    );
  }
}
