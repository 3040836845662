import { Component, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HeatingControlService, HomeInfo } from 'src/app/api';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-netatmo',
  templateUrl: './netatmo.component.html',
  styleUrls: ['./netatmo.component.scss'],
})
export class NetatmoComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private heatingApi: HeatingControlService
  ) {
    this.error = 0;
  }
  response!: HomeInfo;
  loading!: boolean;
  error: any;
  homeId!: string;
  dateRange!: UntypedFormGroup;
  deviceId!: string;
  ngOnInit(): void {
    this.loading = true;
    environment.current = 'Climatización';
    environment.currentRoute = this.route;
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    environment.homeId = this.homeId;
    this.deviceId = this.route.snapshot.paramMap.get('deviceId')!;

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });

    this.heatingApi.climaHomeHomeIdHeatingInfoGet(this.homeId).subscribe(
      (response) => (this.response = response),
      (error) => {
        this.error = error.status;
        this.loading = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
  }
  changeDate(event: any): void {
    this.dateRange = event;
  }
}
