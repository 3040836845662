export class Colors {
  static primary = "#199479";
  static primary50 = "#e3f2ef";
  static primary100 = "#badfd7";
  static primary200 = "#8ccabc";
  static primary300 = "#5eb4a1";
  static primary400 = "#3ca48d";
  static primary500 = Colors.primary;
  static primary600 = "#168c71";
  static primary700 = "#128166";
  static primary800 = "#0e775c";
  static primary900 = "#086549";
  static primaryA100 = "#96ffdc";
  static primaryA200 = "#63ffcb";
  static primaryA400 = "#30ffb9";
  static primaryA700 = "#17ffb1";

  static contrast50 = "#000000";
  static contrast100 = "#000000";
  static contrast200 = "#000000";
  static contrast300 = "#000000";
  static contrast400 = "#000000";
  static contrast500 = "#ffffff";
  static contrast600 = "#ffffff";
  static contrast700 = "#ffffff";
  static contrast800 = "#ffffff";
  static contrast900 = "#ffffff";
  static contrastA100 = "#000000";
  static contrastA200 = "#000000";
  static contrastA400 = "#000000";
  static contrastA700 = "#000000";

  static secondary = "#edc628";
  static secondary50 = "#fdf8e5";
  static secondary100 = "#faeebf";
  static secondary200 = "#f6e394";
  static secondary300 = "#f2d769";
  static secondary400 = "#f0cf48";
  static secondary500 = Colors.secondary;
  static secondary600 = "#ebc024";
  static secondary700 = "#e8b91e";
  static secondary800 = "#e5b118";
  static secondary900 = "#24221c";
  static secondaryA100 = "#ffffff";
  static secondaryA200 = "#fff3da";
  static secondaryA400 = "#ffe3a7";
  static secondaryA700 = "#ffdb8d";

  static secondaryContrast50 = "#000000";
  static secondaryContrast100 = "#000000";
  static secondaryContrast200 = "#000000";
  static secondaryContrast300 = "#000000";
  static secondaryContrast400 = "#000000";
  static secondaryContrast500 = "#000000";
  static secondaryContrast600 = "#000000";
  static secondaryContrast700 = "#000000";
  static secondaryContrast800 = "#000000";
  static secondaryContrast900 = "#000000";
  static secondaryContrastA100 = "#000000";
  static secondaryContrastA200 = "#000000";
  static secondaryContrastA400 = "#000000";
  static secondaryContrastA700 = "#000000";

  static neutral = "#ffffff";
  static neutral00 = "#f8f7f2";
  static neutral05 = "#d7d7d7";
  static neutral10 = "#a5a5a5";
  static neutral20 = "#707070";
  static neutral40 = "#393939";
  static neutral60 = "#272727";
  static neutral80 = "#03110e";

  static background = "#fff";
  static background01 = "#f8f7f2";
  static background02 = Colors.primary;

  static success = "#30af43";
  static successMedium = "#59bf69";
  static successLight = "#c1e7c7";

  static warning = "#fa7921";
  static warningMedium = "#ffa965";
  static warningLight = "#ffe0ca";

  static error = "#eb4747";
  static errorMedium = "#f17e7e";
  static errorLight = "#ffcaca";

  static water20 = "#acdaec";
  static water = "#73c0de";

  static transparent05 = "rgba(255, 255, 255, 0.05)";
  static transparent10 = "rgba(255, 255, 255, 0.1)";
  static transparent20 = "rgba(255, 255, 255, 0.2)";
  static transparent40 = "rgba(255, 255, 255, 0.4)";
  static transparent60 = "rgba(255, 255, 255, 0.6)";
  static transparent80 = "rgba(255, 255, 255, 0.8)";

  static transparentDark05 = "rgba(0, 0, 0, 0.05)";
  static transparentDark10 = "rgba(0, 0, 0, 0.1)";
  static transparentDark20 = "rgba(0, 0, 0, 0.2)";
  static transparentDark40 = "rgba(0, 0, 0, 0.4)";
  static transparentDark60 = "rgba(0, 0, 0, 0.6)";
  static transparentDark80 = "rgba(0, 0, 0, 0.8)";

  static gradientPrimary = "linear-gradient(315deg, #00446f 0%, #0070b5 100%)";
  static gradientSecondary =
    "linear-gradient(315deg, #56e39f 0%, #0070b7 100%)";
  static gradientNeutral =
    "linear-gradient(180deg, #5a5a5a 0%, rgba(255, 255, 255, 0) 100%)";
  static gradient03 = `linear-gradient(170.56deg, ${Colors.primary} 62.44%, #f8f7f2 154.48%)`;
  static gradientLogin =
    "linear-gradient(180deg, #f8f7f2 0%, #8dc7b8 238.36%, " +
    Colors.primary +
    " 402.23%)";

  static shadowSoft = "0px 2px 2px rgba(0, 0, 0, 0.15)";
  static shadowMedium = "0px 4px 8px rgba(0, 0, 0, 0.15)";
  static shadowHard = "0px 8px 16px rgba(0, 0, 0, 0.2)";

  static textLight = Colors.neutral40;
  static textMedium = Colors.neutral60;
  static textDark = Colors.neutral80;
  static heading = Colors.primary500;
  static link = Colors.primary600;
}

class Classes {
  static white = {
    color: Colors.neutral + " !important",
  };

  static primary = {
    color: Colors.primary,
  };

  static secondary = {
    color: Colors.secondary,
  };

  static success = {
    color: Colors.success,
  };

  static warning = {
    color: Colors.warning,
  };

  static error = {
    color: Colors.error,
  };

  static errorColor = {
    color: Colors.error,
  };
}

export const echartOptions: {
  colorLong: string[];
  colorShort: string[];
  realtimeColor: string;
} = {
  colorLong: [
    "#004B3A",
    "#006851",
    "#118B70",
    "#199479",
    "#2AA489",
    "#3DB399",
    "#65CDB6",
    "#98E3D1",
    "#CAF3E9",
  ],

  colorShort: [Colors.error, Colors.warning, Colors.success],

  realtimeColor: Colors.primary,
};
