// Automatically generated - do not modify!

import { createParamString } from '../internals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IHeatingDataApiService {
  /**
   * @method
   * @name  getClimaHeatingFuellist
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHeatingFuellist(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingPrices
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingPrices(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingTemp
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingTemp(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingPrice
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaHomeHomeIdHeatingPrice(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionAbsolute
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionAbsolute(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionToday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionToday(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionByhour
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionByhour(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionByday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionByday(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionByweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionByweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionBymonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionBymonth(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionLast6month
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionLast6month(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionThisMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionLastMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionLastMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionByyear
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionByyear(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionAvgHourweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionAvgHourweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingConsumptionAvgDay(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class HeatingDataApiService implements IHeatingDataApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'HeatingDataApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getClimaHeatingFuellist
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHeatingFuellist(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/heating/fuellist`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingPrices
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingPrices(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/prices`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingTemp
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingTemp(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/temp`,
      options
    );
  }

  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingPrice
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaHomeHomeIdHeatingPrice(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/price`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionAbsolute
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionAbsolute(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/absolute`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionToday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionToday(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/today`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionByhour
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionByhour(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/byhour`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionByday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionByday(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/byday`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionByweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionByweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/byweek`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionBymonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionBymonth(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/bymonth`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionLast6month
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionLast6month(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/last6month`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionThisMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/thisMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionLastMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionLastMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/lastMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionByyear
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionByyear(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/byyear`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionAvgHourweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionAvgHourweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/avg/hourweek`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingConsumptionAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingConsumptionAvgDay(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/consumption/avg/day`,
      options
    );
  }
}
