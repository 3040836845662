import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ElectricDeviceService, ElectricHomeService } from 'src/app/api';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
})
export class HeatmapComponent implements OnInit, OnChanges {
  @Input() homeId!: string;
  @Input() deviceId!: string;
  @Input() type!: string;
  @Input() dateRange!: FormGroup;
  //@Input() endDate
  //@Input() changes;
  error: any;
  precision!: number;

  options: any;
  minHora!: number;
  maxHora!: number;
  data: any = [];
  loading: boolean = true;
  constructor(
    private http: HttpClient,
    private electricHome: ElectricHomeService,
    private electricDevice: ElectricDeviceService
  ) {
    this.error = 0;
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(): void {
    this.dateRange.valueChanges.subscribe((x) => this.getData());
    this.getData();
  }

  getData(): void {
    this.loading = true;
    this.data = [];
    if (this.type === 'home') {
      this.electricHome
        .electricHomeHomeIdEnergyAvgHourweekGet(
          this.homeId,
          new Date(this.dateRange.value.start),
          new Date(this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000)
        )
        .subscribe(
          (response) => this.adaptData(response),
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.electricHome
                .electricHomeHomeIdEnergyAvgHourweekGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    }
    if (this.type === 'device') {
      this.electricDevice
        .electricDeviceDeviceIdEnergyAvgHourweekGet(
          this.deviceId,
          new Date(this.dateRange.value.start),
          new Date(this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000)
        )
        .subscribe(
          (response) => this.adaptData(response),
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.electricDevice
                .electricDeviceDeviceIdEnergyAvgHourweekGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    }
  }

  adaptData(data: any): void {
    this.maxHora = 0;
    this.minHora = 9999999;
    this.data = [];
    let diaIndex = 0;
    for (const dato of data.days) {
      let i = 0;
      let dia;

      dia = 6 - diaIndex;

      for (let hora of dato) {
        if (hora) {
          if (hora > 0.1) {
            hora = hora.toFixed(2);
          } else if (hora > 0.01) {
            hora = hora.toFixed(3);
            this.precision = 3;
          } else if (hora > 0.0001) {
            hora = hora.toFixed(4);
            this.precision = 4;
          } else {
            hora = hora.toFixed(0);
          }
        }
        if (hora != null) {
          this.data.push([i, dia, hora]);
          if (hora > this.maxHora) {
            this.maxHora = hora;
          }

          if (hora < this.minHora) {
            this.minHora = hora;
          }
        } else {
          this.data.push([i, dia, hora]);
        }
        i++;
      }

      diaIndex++;
    }
    // console.log(this.minHora);
    if (this.minHora > 99999) {
      this.minHora = 0;
    }

    this.setOptions();
  }
  setOptions(): void {
    const hours = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ];
    const days = ['D', 'S', 'V', 'J', 'M', 'M', 'L'];
    const longDays = [
      'Domingos',
      'Sábados',
      'Viernes',
      'Jueves',
      'Miércoles',
      'Martes',
      'Lunes',
    ];

    this.options = {
      gradientColor: {
        1: '#30B043',
        2: '#75B343',
        3: '#A9B742',
        4: '#DCBA42',
        6: '#FB7921',
        8: '#F25F34',
        10: '#EB4746',
      },
      grid: {
        bottom: '25%',
        top: '8px',
        left: '32px',
        right: '16px',
      },
      tooltip: { position: 'top' },
      xAxis: {
        type: 'category',
        data: hours,
        name: 'Hora',
        splitArea: {
          show: true,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
      },
      yAxis: {
        type: 'category',
        data: days,
        name: 'Día',
        splitArea: {
          show: true,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
        },
      },

      series: [
        {
          name: 'Consumo Medio',
          type: 'heatmap',
          data: this.data,

          itemStyle: {
            borderWidth: '1',
            borderColor: '#fff',
          },
          tooltip: {
            position: 'top',
            formatter: function (params: any) {
              let value;
              if (params.data[2] > 0) {
                value = params.data[2].replace('.', ',');
              } else {
                value = params.data[2];
              }
              return (
                '<a style="font-size: 1rem"> Consumo medio: <br><b>' +
                longDays[params.value[params.encode.y[0]]] +
                ' a las ' +
                params.value[params.encode.x[0]] +
                ':00h   </b> <br> <a/> <b style="font-size: 1.25rem; text-align: right"> ' +
                value +
                'kWh' +
                ' <span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-left:5px; border-radius: 25px; background-color:' +
                params.color +
                '"></span></b>'
              );
            },

            textStyle: {
              fontFamily: environment.fontFamily,
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.1)',
            },
          },
        },
      ],
      visualMap: {
        calculable: true,
        orient: 'horizontal',
        max: this.maxHora,
        min: this.minHora - this.minHora * 0.1,
        itemHeight: '250px',
        itemWidth: '10px',

        align: 'top',
        bottom: 0,
        left: 'center',
        textStyle: {
          fontFamily: environment.fontFamily,
          fontSize: 12,
          fontWeight: 400,
        },
        text: [
          this.maxHora + ' kWh',
          (this.minHora - this.minHora * 0.1).toFixed(3).replace('.', ',') +
            'kWh',
        ],
        precision: this.precision,
      },
    };
    if (this.data.length) {
      this.loading = false;
    }
  }
}
