<div class="body">
  <div class="main">
    <div class="up">
      <div class="devices extend">
        <h2 class="title">Dispositivos instalados</h2>
        <div class="content">
          <div
            class="main-devices"
            *ngIf="
              devicesResponse &&
              devicesResponse.counters &&
              devicesResponse.monitors &&
              (devicesResponse.counters.length > 0 ||
                devicesResponse.monitors.length > 0)
            "
            [ngClass]="{
              noplugs:
                devicesResponse &&
                devicesResponse.plugs &&
                devicesResponse.plugs.length == 0
            }"
          >
            <app-electricity-main-device
              class="extend"
              [homeId]="homeId"
              [mainDevices]="devicesResponse"
              [loading]="devicesLoading"
              [error]="devicesError"
            ></app-electricity-main-device>
          </div>
          <ng-container
            *ngIf="
              devicesResponse &&
              devicesResponse.plugs &&
              devicesResponse.plugs.length > 0
            "
          >
            <app-electricity-devices
              class="extend"
              [homeId]="homeId"
              [devicesResponse]="devicesResponse"
              [loading]="devicesLoading"
              [error]="devicesError"
            ></app-electricity-devices
          ></ng-container>
        </div>
      </div>
      <div class="total extend">
        <h2 class="title">Consumo eléctrico</h2>
        <div class="content total">
          <app-electricity-today
            class="extend"
            [homeId]="homeId"
          ></app-electricity-today>
          <app-electricity-accumulated
            class="extend"
            [homeId]="homeId"
            [dateRange]="dateRange"
            [response]="response"
            [loading]="loading"
            [error]="error"
          ></app-electricity-accumulated>
          <app-electricity-average
            class="extend"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-electricity-average>
        </div>
      </div>
      <div class="service-expenses">
        <h2 class="title">Huella de carbono</h2>
        <div class="content">
          <app-home-carbon-footprint-summary
            [response]="response"
            [loading]="loading"
            [error]="error"
            class="extend"
          ></app-home-carbon-footprint-summary>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="expenses extend">
        <h2 class="title">
          <div class="text">Evolución del consumo</div>
          <div class="datepicker">
            <app-widget-datepicker
              class="extend"
              (dateRangeChange)="changeDate($event)"
            ></app-widget-datepicker>
          </div>
        </h2>
        <div
          *ngIf="lastData != undefined"
          class="body-1"
          style="margin-top: -20px; margin-bottom: 8px"
        >
          Fecha del último dato recibido: {{ lastData | date : "shortDate" }}
        </div>
        <div class="content extend">
          <app-electricity-consumption-chart
            class="extend"
            [type]="'home'"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-electricity-consumption-chart>
        </div>
      </div>
      <div class="footprint">
        <h2 class="title">
          <div class="text">Consumo por servicios</div>
        </h2>
        <div class="content extend">
          <app-expenses-by-device
            class="extend"
            [homeId]="homeId"
            [dateRange]="dateRange"
            [response]="response"
            [loading]="loading"
            [error]="error"
          ></app-expenses-by-device>
        </div>
      </div>
    </div>
  </div>
</div>
