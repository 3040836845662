/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AcState } from './acState';
import { CausedByUser } from './causedByUser';
import { ResolveTime } from './resolveTime';
import { ResultingAcState } from './resultingAcState';
import { Time } from './time';

export interface LastACStateChange { 
    id?: string;
    time?: Time;
    status?: string;
    acState?: AcState;
    resultingAcState?: ResultingAcState;
    changedProperties?: Array<string>;
    reason?: string;
    failureReason?: any;
    resolveTime?: ResolveTime;
    causedByUser?: CausedByUser;
    causedByScheduleId?: any;
    causedByScheduleType?: any;
}