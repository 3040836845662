import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global.service';
import { BillSettingsComponent } from '../bill-settings/bill-settings.component';
import { ClimaSettingsComponent } from '../clima-settings/clima-settings.component';
import { HomeSettingsComponent } from '../home-settings/home-settings.component';
import { Home, ManagmentService } from 'src/app/api';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss'],
})
export class LocationsListComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private api: ManagmentService,
    private global: GlobalService,
    private router: Router
  ) {}
  homes!: Home[];
  error: any;
  loading!: boolean;
  ngOnInit(): void {
    this.getHomes();
  }
  openClimaDialog(home?: any): void {
    this.dialog.open(ClimaSettingsComponent, {
      data: home,
    });
  }
  openLocationDialog(home?: any): void {
    this.dialog.open(HomeSettingsComponent, {
      data: home,
    });
  }

  openBillDialog(home?: any): void {
    this.dialog.open(BillSettingsComponent, {
      data: home,
    });
  }

  navigateNewHome(home: any): void {
    this.router.navigate(['home', home.id, 'summary']);
  }

  getHomes(): void {
    let homes;
    if (this.global.userHomes) {
      this.homes = this.global.userHomes;

      this.loading = false;
    }
    this.api.userHomesGet().subscribe(
      (response) => {
        homes = response;
        this.homes = homes.data!;
        this.global.userHomes = homes.data;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api.userHomesGet().subscribe(
            (response) => {
              homes = response;
              this.homes = homes.data!;

              this.global.userHomes = homes.data;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }
}
