<div class="devices">
  <div class="device-info extend widget">
    <h5 class="header">Dispositivos instalados</h5>
    <div class="main">
      <div class="content">
        <div class="element">
          <img src="/assets/img/devices/datadis.svg" class="icon" />
          <h5 class="amount">{{ response?.datadis || "--" }}</h5>
        </div>
        <div class="element">
          <img src="/assets/img/devices/plug.svg" class="icon" />
          <h5 class="amount">
            {{
              response
                ? response.wibeeePlug! + response.shellyPlug!
                : "--" || "--"
            }}
          </h5>
        </div>
        <div class="element">
          <img src="/assets/img/devices/monitor.svg" class="icon" />
          <h5 class="amount">
            {{
              response
                ? response.wibeeeMirubox! + response.shellyEm!
                : "--" || "--"
            }}
          </h5>
        </div>
        <div class="element">
          <img src="/assets/img/devices/sensibo.svg" class="icon" />
          <h5 class="amount">{{ response?.sensibo || "--" }}</h5>
        </div>
        <div class="element" *ngIf="!(environment.flavour == 'total')">
          <img src="/assets/img/devices/hidroconta.svg" class="icon" />
          <h5 class="amount">{{ response?.hidroconta || "--" }}</h5>
        </div>
        <div class="element">
          <img src="/assets/img/devices/netatmo.svg" class="icon" />
          <h5 class="amount">{{ response?.netatmo || "--" }}</h5>
        </div>
        <div class="element" *ngIf="!(environment.flavour == 'total')">
          <img src="/assets/img/devices/pani.svg" class="icon" />
          <h5 class="amount">{{ response?.hydrao || "--" }}</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <h5 class="text">Añade nuevos dispositivos a tu vivienda desde la APP</h5>
  </div>
</div>
