import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  constructor(private api: AuthService) {}
  email!: string;
  loading!: boolean;
  text!: boolean;
  error: any;

  ngOnInit(): void {
    this.text = false;
    this.loading = false;
  }

  sendPasswordReset(): void {
    this.loading = true;
    this.error = false;

    this.api.authPasswordSendCodePost(this.email).subscribe(
      (x) => {
        this.loading = false;
        this.text = true;
      },
      (error) => {
        this.loading = false;
        this.error = true;
      }
    );
  }
}
