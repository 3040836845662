<div class="widget" *ngIf="!error">
    <div class="content" *ngIf="!loading">
        <h5 class="header">Últimos meses</h5>
        <div class="body-1" *ngIf="saving && exists">
            Tu consumo equivale a menos kg de CO2 que el mes pasado.
        </div>
        <div class="body-1" *ngIf="!saving && exists">
            Tu consumo equivale a más kg de CO2 que el mes pasado.
        </div>
        <div class="body-1" *ngIf="!exists">
            No se ha registrado ningún consumo en los últimos meses.
        </div>

        <div class="body-1 bold" *ngIf="saving">¡Sigue así!</div>

        <div class="chart" *ngIf="exists" echarts [options]="options" [loading]="loading" [merge]="options" style="flex: 1; min-height: 150px"></div>
    </div>

    <div class="content" *ngIf="loading">
        <div class="loading-spinner"></div>
    </div>
</div>
<div class="widget error" *ngIf="error">
    <app-error [error]="error"></app-error>
</div>