<div class="body">
  <div class="main">
    <div class="up">
      <div class="devices extend">
        <h2 class="title">Dispositivos instalados</h2>
        <div class="content">
          <app-water-status
            [type]="'cold'"
            [homeId]="homeId"
            [header]="'agua fría sanitaria'"
          ></app-water-status>
          <app-water-status
            [type]="'hot'"
            [homeId]="homeId"
            [header]="'agua caliente sanitaria'"
          ></app-water-status>
        </div>
      </div>
      <div class="total extend">
        <h2 class="title" style="opacity: 0">.</h2>
        <div class="content total">
          <app-water-today class="extend" [homeId]="homeId"></app-water-today>

          <app-water-accumulated
            class="extend"
            [homeId]="homeId"
            [dateRange]="dateRange"
            [response]="response"
            [loading]="loading"
            [response2]="response2"
            [error]="error"
          ></app-water-accumulated>
          <app-water-average
            class="extend"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-water-average>
        </div>
      </div>
      <div class="service-expenses">
        <h2 class="title">Comparativo de consumo</h2>
        <div class="content">
          <app-water-comparison
            [response]="response"
            [loading]="loading"
            [error]="error"
            [header]="'Agua fría sanitaria'"
            class="extend"
          ></app-water-comparison>
          <app-water-comparison
            [response]="response2"
            [loading]="loading"
            [error]="error"
            [header]="'Agua caliente sanitaria'"
            class="extend"
          ></app-water-comparison>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="expenses extend">
        <h2 class="title">
          <div class="text">Evolución del consumo</div>
          <div class="datepicker">
            <app-widget-datepicker
              class="extend"
              (dateRangeChange)="changeDate($event)"
            ></app-widget-datepicker>
          </div>
        </h2>
        <div class="content extend">
          <app-water-consumption-chart
            class="extend"
            [type]="'home'"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-water-consumption-chart>
        </div>
      </div>
      <div class="footprint">
        <h2 class="title">
          <div class="text">Porcentaje de consumo</div>
        </h2>
        <div class="content extend">
          <app-water-percentage
            [valueCold]="response"
            [loading]="loading"
            [valueHot]="response2"
            class="extend"
          ></app-water-percentage>
        </div>
      </div>
      <div class="footprint">
        <h2 class="title">
          <div class="text">Consumo medio hogares</div>
        </h2>
        <div class="content extend">
          <app-water-home-comparison
            [valueCold]="response"
            [loading]="loading"
            [valueHot]="response2"
            class="extend"
          ></app-water-home-comparison>
        </div>
      </div>
    </div>
  </div>
</div>
