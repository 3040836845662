import { Component, Input, OnInit } from '@angular/core';
import { ClimaHomeService, HourAndConsumption } from 'src/app/api';

@Component({
  selector: 'app-temperature-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.scss'],
})
export class TodayTemperatureComponent implements OnInit {
  @Input() homeId!: string;
  //@Input() dateRange;
  @Input() response!: HourAndConsumption | undefined;
  @Input() loading!: boolean;
  @Input() error: any;

  constructor(private homeApi: ClimaHomeService) {}

  ngOnInit(): void {
    this.loading = true;
    this.getData();
  }

  getData(): void {
    this.homeApi.climaHomeHomeIdConsumptionTodayGet(this.homeId).subscribe(
      (response) => {
        this.response = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.homeApi
            .climaHomeHomeIdConsumptionTodayGet(this.homeId)
            .subscribe(
              (response) => {
                this.response = response;
                this.loading = false;
              },
              (error) => {
                this.error = error.status;
                this.loading = false;
              }
            );
        }, 15000);
      }
    );
  }
}
