<div class="widget extend column">
  <h5 class="header">{{ header }} <b class=""></b></h5>
  <div class="content" *ngIf="!loading">
    <div class="title body-1">Consumo mensual</div>
    <h3 class="value primary" *ngIf="!loading">
      {{ response?.thisMonth?.m3! || 0 | number : "1.2-2" : "es" }}
      m3
    </h3>
    <div class="comparison body-1">
      <div class="arrow" *ngIf="response?.thisMonthVsLastMonth! < 0">
        <img src="/assets/img/down_good.svg" alt="" />
      </div>
      <div class="arrow" *ngIf="response?.thisMonthVsLastMonth! > 0">
        <img src="/assets/img/up_bad.svg" alt="" />
      </div>

      {{
        response?.thisMonthVsLastMonth! < 0
          ? (response?.thisMonthVsLastMonth | number : "1.2-2" : "es") +
            "% inferior"
          : (response?.thisMonthVsLastMonth | number : "1.2-2" : "es") +
            "% superior"
      }}
      al mes anterior
    </div>
  </div>
  <div class="load" *ngIf="loading">
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>
