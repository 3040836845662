import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Home } from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-info',
  templateUrl: './home-info.component.html',
  styleUrls: ['./home-info.component.scss'],
})
export class HomeInfoComponent implements OnInit, OnChanges {
  beta!: boolean;
  @Input() home!: Home;
  loading!: boolean;
  constructor() {}

  ngOnInit(): void {
    this.loading = true;
    this.beta = environment.beta;
  }

  ngOnChanges(): void {
    if (this.home) this.loading = false;
  }
}
