<div class="widget" *ngIf="!error">
  <div class="chart" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>
  <div class="side" [ngStyle]="{ flex: noData ? '1' : '' }" *ngIf="!loading">
    <div class="chart" *ngIf="noData; else hasData">
      No se han encontrado datos disponibles.
    </div>
    <ng-template #hasData>
      <div class="icon">
        <img src="assets/img/icons/widget/{{ category }}.svg" alt="" />
      </div>
      <div class="element body-1">
        <div class="text">Este mes</div>
        <div class="amount bold">
          {{ current?.cost | number : "1.2-2" : "es" }} €
        </div>
      </div>
      <div class="element body-1" *ngIf="savings > 0">
        <div class="text">Ahorro</div>
        <div class="amount bold success">
          {{ savings | number : "1.2-2" : "es" }} €
        </div>
      </div>
      <div class="element body-1" *ngIf="savings <= 0">
        <div class="text">Exceso</div>
        <div class="amount bold error-color">
          {{ -savings | number : "1.2-2" : "es" }} €
        </div>
      </div>

      <div class="element body-1">
        <div class="text" style="white-space: nowrap">(exc. Imp)</div>
      </div>
    </ng-template>
  </div>
  <div class="chart-container" *ngIf="!loading && !noData">
    <div
      class="chart"
      echarts
      [options]="options"
      [loading]="loading"
      [merge]="options"
      style="flex: 1; min-height: 100px"
      *ngIf="!loading"
    ></div>

    <div class="text label bold">
      Este mes has gastado
      <b class="success" *ngIf="savings > 0">menos</b>
      <b class="error-color" *ngIf="savings <= 0">más</b> que el mes anterior
    </div>
  </div>
</div>

<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
