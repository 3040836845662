// Automatically generated - do not modify!

import { createParamString } from '../internals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IAirDataApiService {
  /**
   * @method
   * @name  getClimaHomeHomeIdAirConsumptionThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdAirConsumptionThisMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdAirConsumptionToday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdAirConsumptionToday(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdAirConsumptionAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdAirConsumptionAvgDay(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirPrice
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirPrice(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirPrice
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaDeviceDeviceIdAirPrice(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirTemp
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirTemp(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionAbsolute
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionAbsolute(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionToday
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionToday(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionByhour
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionByhour(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionByday
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionByday(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionByweek
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionByweek(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionBymonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionBymonth(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionLast6month
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionLast6month(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionThisMonth(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionLastMonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionLastMonth(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionByyear
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionByyear(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionAvgHourweek
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionAvgHourweek(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirConsumptionAvgDay(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class AirDataApiService implements IAirDataApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'AirDataApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdAirConsumptionThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdAirConsumptionThisMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/air/consumption/thisMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdAirConsumptionToday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdAirConsumptionToday(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/air/consumption/today`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdAirConsumptionAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdAirConsumptionAvgDay(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/air/consumption/avg/day`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirPrice
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirPrice(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/price`,
      options
    );
  }

  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirPrice
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaDeviceDeviceIdAirPrice(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/price`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirTemp
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirTemp(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/temp`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionAbsolute
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionAbsolute(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/absolute`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionToday
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionToday(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/today`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionByhour
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionByhour(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/byhour`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionByday
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionByday(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/byday`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionByweek
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionByweek(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/byweek`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionBymonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionBymonth(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/bymonth`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionLast6month
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionLast6month(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/last6month`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionThisMonth(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/thisMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionLastMonth
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionLastMonth(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/lastMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionByyear
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionByyear(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/byyear`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionAvgHourweek
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionAvgHourweek(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/avg/hourweek`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirConsumptionAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirConsumptionAvgDay(
    deviceId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/consumption/avg/day`,
      options
    );
  }
}
