/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AcState } from './acState';
import { CausedBy } from './causedBy';

export interface Schedule { 
    id?: string;
    isEnabled?: boolean;
    acState?: AcState;
    causedBy?: CausedBy;
    createTime?: Date;
    createTimeSecondsAgo?: number;
    recurringDays?: Array<string>;
    targetTimeLocal?: string;
    timezone?: string;
    podUid?: string;
    nextTime?: Date;
    nextTimeSecondsFromNow?: number;
}