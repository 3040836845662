import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private router: Router, private authService: AuthService) {}
  //jwtToken;
  //expires;
  tiempo: any;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.user$.pipe(
      map((user) => {
        if (localStorage.getItem('loggedUser')) {
          this.tiempo = Date.now() + 999999999 - Date.now();
          this.authService.refreshToken().subscribe(
            (x: any) => x,
            (error: any) => {
              this.router.navigate(['login'], {
                queryParams: { returnUrl: state.url },
              });
              localStorage.removeItem('loggedUser');
              return false;
            }
          );
          if (this.tiempo < 0) {
            this.router.navigate(['login'], {
              queryParams: { returnUrl: state.url },
            });
            localStorage.removeItem('loggedUser');
            return false;
          }

          return true;
        } else {
          this.router.navigate(['login'], {
            queryParams: { returnUrl: state.url },
          });
          return false;
        }
      })
    );
  }
}
