import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieAcceptComponent } from './components/cookie-accept/cookie-accept.component';
import { CookieTextComponent } from './components/cookie-text/cookie-text.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LegalComponent } from './components/legal/legal.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { APIModule } from 'src/app/legacy-api';

@NgModule({
  declarations: [
    LoginComponent,
    CookieAcceptComponent,
    CookieTextComponent,
    LoginFormComponent,
    LegalComponent,
    PrivacyComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    APIModule,
  ],
})
export class LoginModule {}
