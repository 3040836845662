import { Component, Input, OnInit } from '@angular/core';
import { ElectricConsumption, ElectricHomeService } from 'src/app/api';
import { echartOptions } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.scss'],
})
export class TodayComponent implements OnInit {
  @Input() homeId!: string;
  data: any = [];
  names: any = [];
  error: any;
  options: any;
  loading!: boolean;
  response!: ElectricConsumption | undefined;
  noContent: boolean = false;

  constructor(private homeApi: ElectricHomeService) {}

  ngOnInit(): void {
    this.loading = true;
    this.getData();
  }

  getData(): void {
    this.homeApi.electricHomeHomeIdEnergyTodayGet(this.homeId).subscribe(
      (response) => {
        if (response != null) this.adaptData(response);
        else this.noContent = true;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.homeApi.electricHomeHomeIdEnergyTodayGet(this.homeId).subscribe(
            (response) => this.adaptData(response),
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }

  adaptData(response: any): void {
    const colors = echartOptions.colorShort;
    this.response = response;
    this.names = [];
    let index = 0;
    for (const periodo of response.periodEnergy) {
      if (periodo !== 0) {
        this.data.push({
          value: parseFloat('' + periodo).toFixed(2),
          name: 'P' + (index + 1),
          itemStyle: {
            color: colors[index],
          },
        });
        this.names.push('P' + (index + 1));
      }
      index++;
    }

    this.setOptions();
  }
  setOptions(): void {
    let max = 0;
    for (const valor of this.data) {
      if (valor.value > max) {
        max = valor.value;
      }
    }
    const options = {
      tooltip: {
        position: 'top',
        formatter: function (params: any) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params.color +
            '"></span>  <a style="font-size: 1rem"> Consumo en ' +
            params.name +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params.value.replace('.', ',') +
            'kWh' +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        type: 'category',
        name: 'Periodo',
        data: this.names,
        axisLabel: {
          fontFamily: environment.fontFamily,
        },

        splitLine: {
          show: false,
        },
      },

      yAxis: {
        type: 'value',
        show: false,
        name: 'kWh',
      },
      series: [
        {
          name: 'Consumo por periodo',
          type: 'bar',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          data: this.data,
          label: {
            fontFamily: environment.fontFamily,
            show: true,
            formatter(value: any): any {
              if (value.value < max / 8) {
                return '';
              }
              return ''; //value.value + '€';
            },
            position: 'insideTop',
          },

          barGap: '4px',
          barWidth: '99%',
        },
      ],

      grid: {
        top: '4px',
        left: 0,
        right: 0,
        bottom: '20px',
      },
    };

    this.options = options;
    this.loading = false;
  }
}
