<div class="widget">
  <div class="vs">
    <div class="current">
      <h1 class="cost">
        {{ homeCostResponse.cost | number : "1.2-2" : "es" }} €
      </h1>
      <div class="consumption label">
        <div class="title">Este mes</div>
        <div class="value">
          {{ homeConsumptionResponse.energy | number : "1.2-2" : "es" }}kWh
        </div>
      </div>
    </div>
    <div class="symbol">
      <ng-container
        *ngIf="homeCostResponse!.cost! > previousHomeCostResponse!.cost!"
      >
        <img src="/assets/img/icons/vs/more.svg" alt="" />
      </ng-container>
      <ng-container
        *ngIf="homeCostResponse!.cost! < previousHomeCostResponse!.cost!"
      >
        <img src="/assets/img/icons/vs/less.svg" alt="" />
      </ng-container>
      <ng-container
        *ngIf="homeCostResponse!.cost! === previousHomeCostResponse!.cost!"
      >
        <img src="/assets/img/icons/vs/same.svg" alt="" />
      </ng-container>
    </div>
    <div class="previous">
      <h1 class="cost">
        {{ previousHomeCostResponse.cost | number : "1.2-2" : "es" }} €
      </h1>
      <div class="consumption label">
        <div class="title">Mes anterior</div>
        <div class="value">
          {{
            previousHomeConsumptionResponse.energy | number : "1.2-2" : "es"
          }}kWh
        </div>
      </div>
    </div>
  </div>

  <div class="heading">
    <h2 class="title-h1">¡Mejora tu eficiencia energética!</h2>
    <div class="body-1 bold">
      Aquí tienes unos consejos con los que podrás realizar actuaciones de
      mejora.
    </div>
  </div>

  <div class="recomendations caption">
    <div class="element">
      Usa la lavadora en el periodo Valle de tu factura eléctrica.
    </div>
    <div class="element">Si tu frigorífico tiene modo ECO, ¡Úsalo!</div>
    <div class="element">
      No dejes tus dispositivos multimedia encendidos si no los estas usando
    </div>
  </div>
</div>
