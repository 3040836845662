import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ElectricConsumptionList } from 'src/app/api';
import { echartOptions } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-expenses-by-device',
  templateUrl: './expenses-by-device.component.html',
  styleUrls: ['./expenses-by-device.component.scss'],
})
export class ExpensesByDeviceComponent implements OnInit, OnChanges {
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup;
  @Input() response!: ElectricConsumptionList;
  @Input() loading!: boolean;
  @Input() error: any;
  options: any;
  data: any = [];

  constructor() {}

  ngOnInit(): void {
    this.getData();
    this.setOptions();
  }

  ngOnChanges(): void {
    if (!this.loading)
      this.adaptData(
        this.response.data![this.response.data!.length - 1].dataPerType
      );
  }

  getData(): void {
    /*  this.homeApi
      .getElectricHomeHomeIdEnergyAbsolute(
        this.homeId,
        this.dateRange.value.start.toISOString().replace('Z', ''),
       new Date(this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000)
      .subscribe((response) => this.adaptData(response)); 
    const demoExpensesResponse = {
      count: 7,
      types: [
        {
          type: 'Calefacción',
          data: {
            utcStartTime: '2021-08-03T02:00:00Z',
            utcEndTime: '2021-08-30T15:00:00Z',
            homeStartTime: '2021-08-03T04:00:00',
            homeEndTime: '2021-08-30T17:00:00',
            value: 0.0008003635875000013,
          },
        },
        {
          type: 'ACS',
          data: {
            utcStartTime: '2021-08-03T02:00:00Z',
            utcEndTime: '2021-08-30T15:00:00Z',
            homeStartTime: '2021-08-03T04:00:00',
            homeEndTime: '2021-08-30T17:00:00',
            value: 0.00032184833625000057,
          },
        },
        {
          type: 'Cocina',
          data: {
            utcStartTime: '2021-08-03T02:00:00Z',
            utcEndTime: '2021-08-30T15:00:00Z',
            homeStartTime: '2021-08-03T04:00:00',
            homeEndTime: '2021-08-30T17:00:00',
            value: 0.00012601469250000022,
          },
        },
        {
          type: 'Refrigeración',
          data: {
            utcStartTime: '2021-08-03T02:00:00Z',
            utcEndTime: '2021-08-30T15:00:00Z',
            homeStartTime: '2021-08-03T04:00:00',
            homeEndTime: '2021-08-30T17:00:00',
            value: 0.00013623210000000024,
          },
        },
        {
          type: 'Iluminación',
          data: {
            utcStartTime: '2021-08-03T02:00:00Z',
            utcEndTime: '2021-08-30T15:00:00Z',
            homeStartTime: '2021-08-03T04:00:00',
            homeEndTime: '2021-08-30T17:00:00',
            value: 0.00006981895125000013,
          },
        },
        {
          type: 'Electrodomésticos',
          data: {
            utcStartTime: '2021-08-03T02:00:00Z',
            utcEndTime: '2021-08-30T15:00:00Z',
            homeStartTime: '2021-08-03T04:00:00',
            homeEndTime: '2021-08-30T17:00:00',
            value: 0.0003303628425000006,
          },
        },
        {
          type: 'Standby',
          data: {
            utcStartTime: '2021-08-03T02:00:00Z',
            utcEndTime: '2021-08-30T15:00:00Z',
            homeStartTime: '2021-08-03T04:00:00',
            homeEndTime: '2021-08-30T17:00:00',
            value: 0.00003916672875000007,
          },
        },
      ],
    };
    this.adaptData(demoExpensesResponse);
    */
  }
  adaptData(data: any): void {
    for (const dato of data.types) {
      const push = {
        name: dato.type,
        value: dato.data.value.toFixed(2),
      };
      this.data.push(push);
    }
    this.setOptions();
  }

  setOptions(): void {
    this.options = {
      tooltip: {
        position: 'top',
        formatter: function (params: any) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params.color +
            '"></span>  <a style="font-size: 1rem"> Consumo en ' +
            params.name +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params.value.replace('.', ',') +
            'kWh' +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      legend: {
        align: 'auto',
        textStyle: {
          fontFamily: environment.fontFamily,
          fontSize: 12,
        },
        orient: 'horizontal',
        top: 'bottom',
        padding: 5,
      },
      series: [
        {
          name: 'Consumo',
          type: 'pie',
          top: 0,
          color: echartOptions.colorLong,
          center: ['50%', '42%'],
          radius: ['40%', '70%'],
          avoidLabelOverlap: true,

          label: {
            show: false,
          },

          labelLine: {
            show: true,
          },
          data: this.data,
        },
      ],
    };
  }
}
