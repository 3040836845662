/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Client { 
    id?: string;
    icon?: string;
    companyName?: string;
    cif?: string;
    address?: string;
    postalCode?: number;
    municipality?: string;
    telephone?: string;
    web?: string;
    sector?: number;
    logo?: string;
    textsColor?: string;
    graphicsColor?: string;
    createdAt?: Date;
    updatedAt?: Date;
    isLanguageEnable?: boolean;
    defaultLanguage?: string;
    urlMarket?: string;
}