import { Component, Input, OnInit, Inject } from '@angular/core';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-challenge-popup',
  templateUrl: './challenge-popup.component.html',
  styleUrls: ['./challenge-popup.component.scss'],
})
export class ChallengePopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}
}
