<div class="widget extend column">
  <h2
    class="switch"
    style="height: 200px; width: 100%; text-align: center; opacity: 0"
    *ngIf="!loading"
  >
    <div class="text" *ngIf="!power" (click)="switchOnOff()">ENCENDER</div>
    <div class="text" *ngIf="power" (click)="switchOnOff()">APAGAR</div>
  </h2>
  <div class="content remote extend" *ngIf="!loading">
    <div class="button button-compact-primary" (click)="temperatureDown()">
      -
    </div>
    <div class="chart-container">
      <div
        class="chart"
        echarts
        [options]="options"
        [loading]="loading"
        [merge]="options"
        *ngIf="!loading"
      ></div>
    </div>
    <div class="button button-compact-primary" (click)="temperatureUp()">+</div>
  </div>
  <div class="content remote extend" *ngIf="loading">
    <div class="nav-up">No hay conexión con el dispositivo</div>
  </div>
</div>
