import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-cold-consumption',
  templateUrl: './cold-consumption.component.html',
  styleUrls: ['./cold-consumption.component.scss'],
})
export class ColdConsumptionComponent implements OnInit {
  @Input() deviceId!: string;
  @Input() header: boolean;
  @Input() type: any;
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup;
  @Input() response: any;
  @Input() loading!: boolean;
  @Input() error: any;

  constructor() {
    this.header = true;
  }

  ngOnInit(): void {}
}
