import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AirDataService,
  ClimaHomeService,
  HeatingDataService,
  HourAndConsumptionList,
} from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-temperature-consumption-chart',
  templateUrl: './temperature-consumption-chart.component.html',
  styleUrls: ['./temperature-consumption-chart.component.scss'],
})
export class TemperatureConsumptionChartComponent implements OnInit {
  @Input() homeId!: string;
  @Input() deviceId!: string;
  @Input() type!: string;
  @Input() dateRange!: UntypedFormGroup;
  @Input() response!: HourAndConsumptionList;
  @Input() loading!: boolean;
  @Input() error: any;

  unit: string;
  ratio: string;
  options: any;
  subscription: any;

  data: any[] = [];
  constructor(
    private homeApi: ClimaHomeService,
    private heatApi: HeatingDataService,
    private coldApi: AirDataService
  ) {
    this.error = false;
    this.loading = true;
    this.unit = 'energy';
    this.ratio = 'day';
  }

  ngOnInit(): void {
    this.reloadData();
  }

  ngOnChanges(): void {
    this.loading = true;
    this.dateRange.valueChanges.subscribe((x) => this.reloadData());
    this.reloadData();
  }

  adaptData(data: any): any {
    this.response = data;
    this.data = [];
    setTimeout(() => {
      switch (this.unit) {
        case 'energy':
          for (let dato of data.data) {
            let energy = 0;
            if (dato.energy > 0.1) {
              energy = dato.energy.toFixed(2);
            } else if (dato.energy > 0.01) {
              energy = dato.energy.toFixed(3);
            } else if (dato.energy > 0) {
              energy = dato.energy.toFixed(4);
            } else {
              energy = dato.energy.toFixed(0);
            }
            const elemento = {
              name: dato.homeStartTime,
              value: [new Date(dato.homeStartTime), energy],
            };
            this.data.push(elemento);
          }
          break;
        case 'cost':
          for (const dato of data.data) {
            const elemento = {
              name: dato.homeStartTime,
              value: [new Date(dato.homeStartTime), dato.cost.toFixed(2)],
            };
            this.data.push(elemento);
          }
          break;
      }
      this.setOptions();
    }, 1);
  }

  reloadData(): any {
    setTimeout(() => {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.loading = true;
      }
      switch (this.type) {
        case 'heat':
          switch (this.ratio) {
            case 'hour':
              this.subscription = this.heatApi
                .climaHomeHomeIdHeatingConsumptionByhourGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'day':
              this.subscription = this.heatApi
                .climaHomeHomeIdHeatingConsumptionBydayGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.heatApi
                        .climaHomeHomeIdHeatingConsumptionBydayGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'week':
              this.subscription = this.heatApi
                .climaHomeHomeIdHeatingConsumptionByweekGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.heatApi
                        .climaHomeHomeIdHeatingConsumptionByweekGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'month':
              this.subscription = this.heatApi
                .climaHomeHomeIdHeatingConsumptionBymonthGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.heatApi
                        .climaHomeHomeIdHeatingConsumptionBymonthGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'year':
              this.subscription = this.heatApi
                .climaHomeHomeIdHeatingConsumptionByyearGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.heatApi
                        .climaHomeHomeIdHeatingConsumptionByyearGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
          }
          break;

        case 'home':
          switch (this.ratio) {
            case 'hour':
              this.subscription = this.homeApi
                .climaHomeHomeIdConsumptionByhourGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.homeApi
                        .climaHomeHomeIdConsumptionByhourGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'day':
              this.subscription = this.homeApi
                .climaHomeHomeIdConsumptionBydayGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.homeApi
                        .climaHomeHomeIdConsumptionBydayGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'week':
              this.subscription = this.homeApi
                .climaHomeHomeIdConsumptionByweekGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.loading = true;
                      this.error = false;
                      this.subscription = this.homeApi
                        .climaHomeHomeIdConsumptionByweekGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'month':
              this.subscription = this.homeApi
                .climaHomeHomeIdConsumptionBymonthGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.loading = true;
                      this.error = null;
                      this.subscription = this.homeApi
                        .climaHomeHomeIdConsumptionBymonthGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'year':
              this.subscription = this.homeApi
                .climaHomeHomeIdConsumptionByyearGet(
                  this.homeId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;

                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.homeApi
                        .climaHomeHomeIdConsumptionByyearGet(
                          this.homeId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
          }
          break;

        case 'cold':
          switch (this.ratio) {
            case 'hour':
              this.subscription = this.coldApi
                .climaDeviceDeviceIdAirConsumptionByhourGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'day':
              this.subscription = this.coldApi
                .climaDeviceDeviceIdAirConsumptionBydayGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.coldApi
                        .climaDeviceDeviceIdAirConsumptionBydayGet(
                          this.deviceId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'week':
              this.subscription = this.coldApi
                .climaDeviceDeviceIdAirConsumptionByweekGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.coldApi
                        .climaDeviceDeviceIdAirConsumptionByweekGet(
                          this.deviceId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
            case 'month':
              this.subscription = this.coldApi
                .climaDeviceDeviceIdAirConsumptionBymonthGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.coldApi
                        .climaDeviceDeviceIdAirConsumptionBymonthGet(
                          this.deviceId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                  }
                );
              break;
            case 'year':
              this.subscription = this.coldApi
                .climaDeviceDeviceIdAirConsumptionByyearGet(
                  this.deviceId,
                  new Date(this.dateRange.value.start),
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => this.adaptData(response),
                  (error) => {
                    setTimeout(() => {
                      this.error = null;
                      this.loading = true;
                      this.subscription = this.coldApi
                        .climaDeviceDeviceIdAirConsumptionByyearGet(
                          this.deviceId,
                          new Date(this.dateRange.value.start),
                          new Date(this.dateRange.value.end)
                        )
                        .subscribe(
                          (response) => this.adaptData(response),
                          (error) => {
                            this.error = error.status;
                            this.loading = false;
                          }
                        );
                    }, 15000);
                    this.error = error.status;
                    this.loading = false;
                  }
                );
              break;
          }
          break;
      }
    }, 1);
  }

  setOptions(): void {
    let interval;
    let axisName: string = '';
    let units: string;
    let series: string;
    switch (this.ratio) {
      case 'hour':
        axisName = 'Hora';
        interval = 60 * 60 * 1000;
        break;
      case 'day':
        axisName = 'Día';
        interval = 60 * 60 * 1000 * 24;
        break;
      case 'week':
        axisName = 'Semana';
        interval = 60 * 60 * 1000 * 24 * 7;
        break;
      case 'month':
        axisName = 'Mes';
        interval = 60 * 60 * 1000 * 24 * 28;
        break;
      case 'year':
        axisName = 'Año';
        interval = 60 * 60 * 1000 * 24 * 365;
        break;
    }

    if (this.unit === 'energy') {
      units = ' kWh';
      series = 'Consumo';
    } else {
      units = ' € (exc. Imp)';
      series = 'Coste';
    }

    const options = {
      tooltip: {
        position: 'top',
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        confine: true,

        formatter: function (params: any[]) {
          let text = '';
          switch (axisName) {
            case 'Hora':
              text =
                series +
                ' a las ' +
                new Date(params[0].data.name).getHours() +
                ':00h del ' +
                new Date(params[0].data.name).toLocaleDateString();
              break;
            case 'Día':
              text =
                series +
                ' el ' +
                new Date(params[0].data.name).toLocaleDateString();

              break;
            case 'Semana':
              let date = new Date(params[0].data.name);
              text =
                series +
                ' la semana del ' +
                date.toLocaleDateString() +
                ' al ' +
                new Date(
                  new Date().setDate(date.getDate() + 7)
                ).toLocaleDateString();
              break;
            case 'Mes':
              const monthNames = [
                'enero',
                'febrero',
                'marzo',
                'abril',
                'mayo',
                'junio',
                'julio',
                'agosto',
                'septiembre',
                'octubre',
                'noviembre',
                'diciembre',
              ];
              text =
                series +
                ' en ' +
                monthNames[new Date(params[0].data.name).getMonth()] +
                ' de ' +
                new Date(params[0].data.name).getFullYear();

              break;
            case 'Año':
              text =
                series +
                ' en el año ' +
                new Date(params[0].data.name).getFullYear();

              break;
          }
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params[0].color +
            '"></span>  <a style="font-size: 1rem"> ' +
            text +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params[0].data.value[1].replace('.', ',') +
            units +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },

      xAxis: {
        type: 'time',
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        minInterval: interval,
        name: axisName,
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },

        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: units,
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        splitLine: {
          show: true,
        },
      },
      series: [
        {
          name: series,
          type: 'bar',
          data: this.data,
          color: environment.textColor,
          itemStyle: {
            borderRadius: [11, 11, 0, 0],
          },
        },
      ],
      grid: {
        top: 32,
        left: 32,
        right: 48,
        bottom: 32,
      },
    };

    this.options = options;
    if (this.response.data && this.response.data.length > 0)
      this.loading = false;
  }
}
