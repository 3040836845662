import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ComparedWaterConsumption, WaterDataService } from 'src/app/api';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-water-temperature',
  templateUrl: './water-temperature.component.html',
  styleUrls: ['./water-temperature.component.scss'],
})
export class WaterTemperatureComponent implements OnInit, OnChanges {
  constructor(private route: ActivatedRoute, private api: WaterDataService) {
    this.error = 0;
  }
  response!: ComparedWaterConsumption;
  loading!: boolean;
  error: any;
  deviceType!: string;
  homeId!: string;
  dateRange!: FormGroup;
  ngOnInit(): void {
    this.loading = true;
    this.deviceType = this.route.snapshot.paramMap.get('type')!;
    if (this.route.snapshot.paramMap.get('type') == 'hot') {
      environment.current = 'Agua caliente sanitaria';
    } else {
      environment.current = 'Agua fría sanitaria';
    }
    environment.currentRoute = this.route;
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    environment.homeId = this.homeId;

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });

    //this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
  }
  changeDate(event: any): void {
    this.dateRange = event;
  }

  getData(): void {
    this.api
      .waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(
        this.homeId,
        this.deviceType
      )
      .subscribe(
        (response) => {
          this.response = response;
          if (this.response) this.loading = false;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.api
              .waterHomeHomeIdTypeConsumptionThismonthLastmonthGet(
                this.homeId,
                this.deviceType
              )
              .subscribe(
                (response) => {
                  this.response = response;
                  if (this.response) this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
}
