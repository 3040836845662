import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeatingControlService, HomeInfo } from 'src/app/api';
import { Colors } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-netatmo-manual-controller',
  templateUrl: './netatmo-manual-controller.component.html',
  styleUrls: ['./netatmo-manual-controller.component.scss'],
})
export class NetatmoManualControllerComponent
  implements OnChanges, AfterViewInit
{
  @Input() tempInfo!: HomeInfo;
  options: any;
  temperature;
  loading!: boolean;
  homeId!: string;
  power!: boolean;
  constructor(
    private heatingApi: HeatingControlService,
    private route: ActivatedRoute
  ) {
    this.temperature = 20;
  }

  ngAfterViewInit(): void {
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    console.log(this.tempInfo);
    this.heatingApi
      .climaHomeHomeIdHeatingSetcontrolmodePost(
        this.homeId,
        'manual',
        this.tempInfo.setpointTemp
      )
      .subscribe();
    this.temperature = this.tempInfo.setpointTemp!;
    if (!this.temperature) {
      this.temperature = 17;
    }
    this.getOptions();
  }

  ngOnChanges(): void {
    this.loading = true;
    if (this.tempInfo) {
      this.temperature = this.tempInfo.setpointTemp!;
      this.power = this.tempInfo.power!;
      this.getOptions();
    }
  }

  switchOnOff() {
    if (this.power) {
      this.power = false;
    } else {
      this.power = true;
    }
  }

  temperatureUp() {
    this.temperature += 0.5;
    this.tempInfo.setpointTemp! += 0.5;
    this.heatingApi
      .climaHomeHomeIdHeatingSetcontrolmodePost(
        this.homeId,
        'manual',
        this.tempInfo.setpointTemp
      )
      .subscribe();
    this.getOptions();
  }
  temperatureDown() {
    this.temperature -= 0.5;
    this.tempInfo.setpointTemp! += -0.5;
    this.heatingApi
      .climaHomeHomeIdHeatingSetcontrolmodePost(
        this.homeId,
        'manual',
        this.tempInfo.setpointTemp
      )
      .subscribe();
    this.getOptions();
  }
  getOptions(): void {
    this.options = {
      grid: {
        bottom: '0%',
        top: '0%',
        left: '0%',
        right: '1%',
      },

      series: [
        {
          type: 'gauge',
          center: ['50%', '38%'],
          startAngle: 240,
          endAngle: -60,
          min: 0,
          max: 40,
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 40,
            borderRadius: 10,
            offsetCenter: [0, '-15%'],
            fontSize: 38,
            fontWeight: 'bolder',
            formatter: '{value}°C',
            color: environment.textColor,
          },
          itemStyle: {
            color: Colors.primary,
            backgroundColor: '#eeeeee',
          },
          progress: {
            show: true,
            width: 16,
            clip: true,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: true,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },

          data: [
            {
              value: this.temperature,
            },
          ],
        },
      ],
    };
    this.loading = false;
  }
}
