import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-heatmap-help',
  templateUrl: './heatmap-help.component.html',
  styleUrls: ['./heatmap-help.component.scss'],
})
export class HeatmapHelpComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<HeatmapHelpComponent>
  ) {}

  ngOnInit(): void {}
  closeDialog() {
    this.dialogRef.close();
  }
}
