<div
  class="sidebar"
  [ngStyle]="
    this.common.getClientData()
      ? {
          'backgroud-color':
            'linear-gradient(170.56deg, white 62.44%, #f8f7f2 154.48%)',
          background:
            'linear-gradient(170.56deg, white 62.44%, #f8f7f2 154.48%)'
        }
      : {}
  "
>
  <div class="heading">
    <div class="logo">
      <img [src]="common.getClientLogo()" />
    </div>
  </div>
  <div
    class="menu"
    [ngClass]="common.getFlavour() !== 'hobeen' || (common.getFlavour() === 'hobeen' && common.getClientData()) ? 'grey' : 'white'"
  >
    <mat-nav-list>
      <a
        mat-list-item
        [routerLink]="'/user/homes'"
        *ngIf="obtenerRuta() != '/user/homes'"
      >
        <div class="menu-element">
          <div matLine class="icon">
            <div
              class="home-icon-up"
              [ngClass]="common.getFlavour() !== 'hobeen' || (common.getFlavour() === 'hobeen' && common.getClientData()) ? 'grey' : 'white'"
            ></div>
          </div>
          <div
            matLine
            class="nav"
            [ngClass]="common.getFlavour() !== 'hobeen' || (common.getFlavour() === 'hobeen' && common.getClientData()) ? 'grey' : 'white'"
          >
            Mis viviendas
          </div>
        </div>
      </a>
      <a
        mat-list-item
        [routerLink]="'/user/homes'"
        *ngIf="obtenerRuta() == '/user/homes'"
      >
        <div class="menu-element">
          <div matLine class="icon">
            <div class="home-icon-enabled"></div>
          </div>
          <div matLine class="nav secondary">Mis viviendas</div>
        </div>
      </a>
      <ng-container *ngIf="isHome()">
        <a
          mat-list-item
          [routerLink]="'/home/' + homeId + '/summary'"
          *ngIf="obtenerRuta() != '/home/' + homeId + '/summary'"
        >
          <div class="menu-element">
            <div matLine class="icon">
              <div
                class="summary-icon-up"
                [ngClass]="common.getFlavour() !== 'hobeen' || (common.getFlavour() === 'hobeen' && common.getClientData()) ? 'grey' : 'white'"
              ></div>
            </div>
            <div
              matLine
              class="nav"
              [ngClass]="common.getFlavour() !== 'hobeen' || (common.getFlavour() === 'hobeen' && common.getClientData()) ? 'grey' : 'white'"
            >
              Resumen
            </div>
          </div>
        </a>
        <a
          mat-list-item
          [routerLink]="'/home/' + homeId + '/summary'"
          *ngIf="obtenerRuta() == '/home/' + homeId + '/summary'"
        >
          <div class="menu-element">
            <div matLine class="icon">
              <div
                class="summary-icon-enabled"
                [ngClass]="common.getFlavour() !== 'hobeen' || (common.getFlavour() === 'hobeen' && common.getClientData()) ? 'grey' : 'white'"
              ></div>
            </div>
            <div matLine class="nav secondary">Resumen</div>
          </div>
        </a>
      </ng-container>

      <div class="menu-elements">
        <a mat-list-item style="margin: 8px 0px" *ngIf="!isHome() || !electric">
          <div class="icon-menu">
            <img
              src="/assets/img/icons/disabled/electricity.svg"
              [ngStyle]="{
                opacity: common.getClientData() !== undefined ? '50%' : '100%',
                filter: common.getClientData()
                  ? 'contrast(10%) brightness(110%)'
                  : ''
              }"
            />
          </div>
        </a>
        <a
          mat-list-item
          [routerLink]="'/home/' + homeId + '/electricity'"
          style="margin: 8px 0px"
          *ngIf="electric"
        >
          <div class="icon-menu">
            <img
              *ngIf="!(obtenerRuta() == '/home/' + homeId + '/electricity')"
              src="/assets/img/icons/enabled/electricity.svg"
            />
            <img
              *ngIf="obtenerRuta() == '/home/' + homeId + '/electricity'"
              src="/assets/img/icons/enabled/electricity-full.svg"
            />
          </div>
        </a>
        <ng-container *ngIf="!(environment.flavour == 'total')">
          <a mat-list-item style="margin: 8px 0px" *ngIf="!isHome() || !water">
            <div class="icon-menu">
              <img
                src="/assets/img/icons/disabled/water.svg"
                [ngStyle]="{
                  opacity:
                    common.getClientData() !== undefined ? '50%' : '100%',
                  filter: common.getClientData()
                    ? 'contrast(10%) brightness(110%)'
                    : ''
                }"
              />
            </div>
          </a>
          <a
            mat-list-item
            [routerLink]="'/home/' + homeId + '/water'"
            style="margin: 8px 0px"
            *ngIf="water"
          >
            <div class="icon-menu">
              <img
                *ngIf="!(obtenerRuta() == '/home/' + homeId + '/water')"
                src="/assets/img/icons/enabled/water.svg"
              />
              <img
                *ngIf="obtenerRuta() == '/home/' + homeId + '/water'"
                src="/assets/img/icons/enabled/water-full.svg"
              />
            </div> </a
        ></ng-container>
        <a
          mat-list-item
          style="margin: 8px 0px"
          *ngIf="!isHome() || !temperature"
        >
          <div class="icon-menu">
            <img
              src="/assets/img/icons/disabled/temperature.svg"
              [ngStyle]="{
                opacity: common.getClientData() !== undefined ? '50%' : '100%',
                filter: common.getClientData()
                  ? 'contrast(10%) brightness(110%)'
                  : ''
              }"
            />
          </div>
        </a>
        <a
          mat-list-item
          [routerLink]="'/home/' + homeId + '/temperature'"
          style="margin: 8px 0px"
          *ngIf="temperature"
        >
          <div class="icon-menu">
            <img
              *ngIf="!(obtenerRuta() == '/home/' + homeId + '/temperature')"
              src="/assets/img/icons/enabled/temperature.svg"
            />
            <img
              *ngIf="obtenerRuta() == '/home/' + homeId + '/temperature'"
              src="/assets/img/icons/enabled/temperature-full.svg"
            />
          </div>
        </a>
      </div>
    </mat-nav-list>
  </div>

  <div class="sidebar-footer">
    <div class="sidebar-footer-menu">
      <a class="sidebar-footer-icon" [routerLink]="'/user/settings'"
      [ngStyle]="{'filter': common.getClientData() ? 'brightness(50%)' : 'none'}"
        ><img src="assets/img/navbar/config.svg" alt="Configuración" />
      </a>
      <a
        class="sidebar-footer-icon"
        [ngStyle]="{'filter': common.getClientData() ? 'brightness(50%)' : 'none'}"
        [routerLink]=""
        (click)="authService.logout()"
        ><img src="assets/img/navbar/logout.svg" alt="Cerrar Sesión" />
      </a>
    </div>
  </div>
</div>
