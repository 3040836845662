import { getLocaleDateFormat } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ElectricConsumption, ElectricHomeService } from 'src/app/api';

@Component({
  selector: 'app-electricity-improvement',
  templateUrl: './electricity-improvement.component.html',
  styleUrls: ['./electricity-improvement.component.scss'],
})
export class ElectricityImprovementComponent implements OnInit {
  @Input() homeId!: string;
  constructor(private homeApi: ElectricHomeService) {}

  homeCostResponse!: ElectricConsumption;
  homeConsumptionResponse!: ElectricConsumption;
  previousHomeCostResponse!: ElectricConsumption;
  previousHomeConsumptionResponse!: ElectricConsumption;

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const endDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );
    const previousStartDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    );
    const previousEndDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      0
    );
    this.homeApi
      .electricHomeHomeIdEnergyAbsoluteGet(
        this.homeId,
        new Date(startDate.toISOString().replace('Z', '')),
        new Date(endDate.toISOString().replace('Z', ''))
      )
      .subscribe((response) => (this.homeCostResponse = response));
    this.homeApi
      .electricHomeHomeIdEnergyAbsoluteGet(
        this.homeId,
        new Date(startDate.toISOString().replace('Z', '')),
        new Date(endDate.toISOString().replace('Z', ''))
      )
      .subscribe((response) => (this.homeConsumptionResponse = response));

    this.homeApi
      .electricHomeHomeIdEnergyAbsoluteGet(
        this.homeId,
        new Date(previousStartDate.toISOString().replace('Z', '')),
        new Date(previousEndDate.toISOString().replace('Z', ''))
      )
      .subscribe((response) => (this.previousHomeCostResponse = response));
    this.homeApi
      .electricHomeHomeIdEnergyAbsoluteGet(
        this.homeId,
        new Date(previousStartDate.toISOString().replace('Z', '')),
        new Date(previousEndDate.toISOString().replace('Z', ''))
      )
      .subscribe(
        (response) => (this.previousHomeConsumptionResponse = response)
      );
  }
}
