<div class="widget">
  <div class="header">
    <h2 class="primary">Editar Usuario</h2>
  </div>
  <div class="body">
    <div class="text caption">
      Edita la información asociada a tu usuario en el panel de administración
    </div>
    <div class="form">
      <div class="element">
        <div class="name body-1 bold primary">Nombre</div>
        <mat-form-field appearance="outline" class="input">
          <input
            specialIsAlphaNumeric
            matInput
            [(ngModel)]="editUser.fullName"
            placeholder="Debes introducir un nombre"
            required
          />
        </mat-form-field>
      </div>
      <div class="element">
        <div class="name body-1 bold primary">Correo electrónico</div>
        <mat-form-field appearance="outline" class="input">
          <input
            matInput
            specialIsAlphaNumeric
            [(ngModel)]="editUser.email"
            placeholder="Debes introducir un correo electrónico"
            required
          />
        </mat-form-field>
      </div>

      <div class="element">
        <div class="name body-1 bold primary">Teléfono de Contacto</div>
        <mat-form-field appearance="outline" class="input">
          <input
            matInput
            numeric
            [(ngModel)]="editUser.phone"
            placeholder="Debes introducir un teléfono de contacto"
            required
          />
        </mat-form-field>
      </div>
      <div class="element">
        <div class="name body-1 bold primary">Población</div>
        <mat-form-field appearance="outline" class="input">
          <input
            matInput
            specialIsAlphaNumeric
            [(ngModel)]="editUser.city"
            placeholder="Debes introducir una población"
            required
          />
        </mat-form-field>
      </div>
      <div class="element">
        <div class="name body-1 bold primary">Código Postal</div>
        <mat-form-field appearance="outline" class="input">
          <input
            matInput
            numeric
            [(ngModel)]="editUser.postalCode"
            placeholder="Debes introducir un código postal"
            required
          />
        </mat-form-field>
      </div>

      <div class="element">
        <div class="name body-1 bold primary">Reestablecer contraseña</div>
        <button class="button-compact-primary caption" *ngIf="loading">
          <mat-spinner
            diameter="20"
            style="padding-right: 12px; margin-left: 11px"
          ></mat-spinner>
        </button>
        <div
          class="button-compact-primary caption"
          (click)="sendPasswordReset()"
          *ngIf="!loading"
        >
          Enviar código al correo electrónico
        </div>
      </div>
      <div class="buttons">
        <button class="button-secondary caption" (click)="closeDialog()">
          Salir sin guardar
        </button>
        <button class="button-primary caption" (click)="updateUser()">
          {{ saveText }}
        </button>
      </div>
    </div>
  </div>
</div>
