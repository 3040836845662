// Automatically generated - do not modify!

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IAirControlApiService {
  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirInfo
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaDeviceDeviceIdAirInfo(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirAcstate
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaDeviceDeviceIdAirAcstate(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirSchedule
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaDeviceDeviceIdAirSchedule(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  updateClimaDeviceDeviceIdAirSchedule
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  updateClimaDeviceDeviceIdAirSchedule(
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  deleteClimaDeviceDeviceIdAirScheduleScheduleId
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} scheduleId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  deleteClimaDeviceDeviceIdAirScheduleScheduleId(
    deviceId: Record<string, unknown> | unknown,
    scheduleId: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirScheduleScheduleIdEnable
   * @description
   * @param {string} scheduleId
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaDeviceDeviceIdAirScheduleScheduleIdEnable(
    scheduleId: string,
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirScheduleScheduleIdDisable
   * @description
   * @param {string} scheduleId
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaDeviceDeviceIdAirScheduleScheduleIdDisable(
    scheduleId: string,
    deviceId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class AirControlApiService implements IAirControlApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'AirControlApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getClimaDeviceDeviceIdAirInfo
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaDeviceDeviceIdAirInfo(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/info`,
      options
    );
  }

  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirAcstate
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaDeviceDeviceIdAirAcstate(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/acstate`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirSchedule
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaDeviceDeviceIdAirSchedule(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/schedule`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  updateClimaDeviceDeviceIdAirSchedule
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public updateClimaDeviceDeviceIdAirSchedule(
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.put<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/schedule`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  deleteClimaDeviceDeviceIdAirScheduleScheduleId
   * @description
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {string} scheduleId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public deleteClimaDeviceDeviceIdAirScheduleScheduleId(
    deviceId: Record<string, unknown> | unknown,
    scheduleId: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.delete<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/schedule/${scheduleId}`,
      options
    );
  }

  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirScheduleScheduleIdEnable
   * @description
   * @param {string} scheduleId
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaDeviceDeviceIdAirScheduleScheduleIdEnable(
    scheduleId: string,
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/schedule/${scheduleId}/enable`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  sendClimaDeviceDeviceIdAirScheduleScheduleIdDisable
   * @description
   * @param {string} scheduleId
   * @param {Record<string, unknown> | unknown} deviceId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaDeviceDeviceIdAirScheduleScheduleIdDisable(
    scheduleId: string,
    deviceId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/device/${deviceId}/air/schedule/${scheduleId}/disable`,
      JSON.stringify(payload),
      options
    );
  }
}
