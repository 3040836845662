import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatList, MatListModule } from '@angular/material/list';

import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { MobileFooterComponent } from './components/mobile-footer/mobile-footer.component';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    MobileHeaderComponent,
    MobileFooterComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    RouterModule,
    MatSelectModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    MobileHeaderComponent,
    MobileFooterComponent,
  ],
})
export class SharedModule {}
