<div class="widget extend">
  <ng-container *ngIf="!loading"
    ><h5 class="header">
      <img src="/assets/img/devices/plug.svg" class="icon" /> Enchufes
    </h5>
    <div class="devices">
      <div class="device label" *ngFor="let device of devicesResponse?.plugs">
        <img src="/assets/img/devices/plug.svg" alt="" />
        <div class="caption bold" style="flex: 1">
          {{ device.name || "Enchufe" }}
        </div>
        <ng-container *ngIf="device.api == 'wibeee'">Wibeee</ng-container>
        <ng-container *ngIf="device.api == 'shelly'">Shelly</ng-container>
        <a
          [routerLink]="[
            '/home',
            homeId,
            'electricity',
            'device',
            'plug',
            device.id,
            device.name
          ]"
          ><img src="/assets/img/new-arrow.svg" alt=""
        /></a>
      </div></div
  ></ng-container>
  <div class="loading-spinner" *ngIf="loading"></div>
</div>
