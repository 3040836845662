import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ElectricDeviceService,
  ElectricHomeService,
  ElectricSensorPower,
} from 'src/app/api';
import { echartOptions } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity-realtime-chart',
  templateUrl: './realtime-chart.component.html',
  styleUrls: ['./realtime-chart.component.scss'],
})
export class RealtimeChartComponent implements OnInit, OnDestroy {
  @Input() homeId!: string;
  @Input() deviceId!: string;
  @Input() type!: string;
  @Input() monitor!: boolean;
  options: any;
  loading!: boolean;
  reload: any;
  lastData!: ElectricSensorPower;
  online!: boolean;
  offline!: boolean;
  error: any;
  activePower: any = [];
  hiredPower: any = [];

  noContent: boolean = false;

  constructor(
    private electricHome: ElectricHomeService,
    private electricDevice: ElectricDeviceService
  ) {
    this.error = 0;
    this.loading = true;
  }

  ngOnInit(): void {
    this.online = true;
    this.offline = false;
    this.getData();
    this.reload = setInterval(() => this.getNewData(), environment.interval);
  }

  getData(): void {
    this.noContent = false;
    if (this.type === 'home') {
      this.electricHome
        .electricHomeHomeIdSensorsLast15mGet(this.homeId)
        .subscribe(
          (responseData) => this.adaptData(responseData),
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.electricHome
                .electricHomeHomeIdSensorsLast15mGet(this.homeId)
                .subscribe(
                  (responseData) => this.adaptData(responseData),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    }
    if (this.type === 'device') {
      this.electricDevice
        .electricDeviceDeviceIdSensorsLast15mGet(this.deviceId)
        .subscribe(
          (responseData) => this.adaptData(responseData),
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.electricDevice
                .electricDeviceDeviceIdSensorsLast15mGet(this.deviceId)
                .subscribe(
                  (responseData) => this.adaptData(responseData),
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    }
  }

  getNewData(): void {
    if (this.type === 'home') {
      this.electricHome.electricHomeHomeIdSensorLastGet(this.homeId).subscribe(
        (responseData) => {
          let instance: ElectricSensorPower = responseData;
          this.lastData = instance;

          if (instance.p! > 0.1) {
            instance.p = Number(instance.p!.toFixed(2));
          } else {
            instance.p = Number(instance.p!.toFixed(3));
          }
          let element = {
            name: instance.utcTime,
            value: [new Date(instance.utcTime!), instance.p],
          };

          if (
            this.activePower[this.activePower.length - 1].name !== element.name
          ) {
            this.activePower.push(element);
            this.activePower.shift();
          }

          element = {
            name: instance.utcTime,
            value: [new Date(instance.utcTime!), instance.hiredPower!],
          };
          if (
            this.hiredPower[this.hiredPower.length - 1].name !== element.name
          ) {
            this.hiredPower.push(element);
            this.hiredPower.shift();
          }

          if (
            new Date(instance.utcTime!).getTime() >
            new Date().getTime() - 600000
          ) {
            this.online = true;
            this.offline = false;
          } else {
            this.offline = true;
            this.online = false;
          }
          this.generateOptions();
        },
        (error) => {
          this.loading = false;
        }
      );
    }
    if (this.type === 'device') {
      this.electricDevice
        .electricDeviceDeviceIdSensorLastGet(this.deviceId)
        .subscribe(
          (responseData) => {
            const instance: any = responseData;
            if (instance.p > 0.1) {
              instance.p = instance.p.toFixed(2);
            } else {
              instance.p = instance.p.toFixed(3);
            }
            this.lastData = instance;
            let element = {
              name: instance.utcTime,
              value: [new Date(instance.utcTime), instance.p],
            };

            if (
              this.activePower[this.activePower.length - 1].name !==
              element.name
            ) {
              this.activePower.push(element);
              this.activePower.shift();
            }

            element = {
              name: instance.utcTime,
              value: [new Date(instance.utcTime), instance.hiredPower],
            };
            element = {
              name: instance.utcTime,
              value: [new Date(instance.utcTime!), instance.hiredPower!],
            };
            if (
              this.hiredPower[this.hiredPower.length - 1].name !== element.name
            ) {
              this.hiredPower.push(element);
              this.hiredPower.shift();
            }

            if (
              new Date(instance.utcTime).getTime() >
              new Date().getTime() - 600000
            ) {
              this.online = true;
              this.offline = false;
            } else {
              this.offline = true;
              this.online = false;
            }
            this.generateOptions();
          },
          (error) => {
            this.loading = false;
          }
        );
    }
  }

  adaptData(data: any): void {
    if (!!!data) {
      this.loading = false;
      this.noContent = true;
    }
    let decimales = 4;
    if (this.monitor) {
      decimales = 2;
    }
    for (const instance of data.data) {
      this.lastData = instance;
      if (instance.p > 0.1) {
        instance.p = instance.p.toFixed(2);
      } else if (instance.p > 0.01) {
        instance.p = instance.p.toFixed(3);
      } else if (instance.p > 0) {
        instance.p = instance.p.toFixed(4);
      } else {
        instance.p = instance.p.toFixed(0);
      }
      let element = {
        name: instance.utcTime,
        value: [new Date(instance.utcTime), instance.p],
      };
      this.activePower.push(element);

      element = {
        name: instance.utcTime,
        value: [new Date(instance.utcTime), instance.hiredPower],
      };

      this.hiredPower.push(element);
      if (
        new Date(instance.utcTime).getTime() >
        new Date().getTime() - 600000
      ) {
        this.online = true;
        this.offline = false;
      } else {
        this.offline = true;
        this.online = false;
      }
    }

    this.generateOptions();
  }

  generateOptions(): void {
    const options = {
      tooltip: {
        position: 'top',
        trigger: 'axis',
        formatter: function (params: any) {
          let contratada = '';
          try {
            if (params[1].value[1]) {
              contratada =
                ' <br> <span style="height: 0px; width: 5px; padding: 0px 2px; margin-right:3px; border-radius: 25px; background-color:' +
                params[1].color +
                '"></span>  <a style="font-size: 0.9rem"> Potencia contratada: ' +
                '  <a/> <b style="font-size: 0.9rem">' +
                params[1].value[1]?.replace('.', ',') +
                'kW';
            }
          } catch (Exception) {
            console.log('Problemas al formatear la potencia contratada');
          }
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params[0].color +
            '"></span>  <a style="font-size: 1.25rem"> Potencia activa: ' +
            ' <br> <a/>  <b style="font-size: 1.25rem">' +
            params[0].value[1].replace('.', ',') +
            'kW  </b>  (a las ' +
            new Date(params[0].name).toLocaleTimeString() +
            ') ' +
            contratada
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        name: 'Hora',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        type: 'time',
        minInterval: 60 * 1000 * 10,
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
      },
      yAxis: {
        name: 'kW',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        type: 'value',
        splitLine: {
          show: true,
        },
      },

      series: [
        {
          name: 'Potencia Activa',
          type: 'line',

          showSymbol: false,
          hoverAnimation: true,
          smooth: false,
          data: this.activePower,
          legend: {
            textStyle: {
              fontFamily: environment.fontFamily,
            },
            orient: 'horizontal',
            top: 'bottom',
          },
          itemStyle: {
            color: echartOptions.realtimeColor,
            borderWidth: 50,
          },
          lineStyle: {
            color: echartOptions.realtimeColor,
            width: 3,
          },
          symbolSize: 2,
        },
        ,
        {
          name: 'Potencia Contratada',
          type: 'line',

          showSymbol: false,
          hoverAnimation: false,
          smooth: false,
          data: this.hiredPower,

          itemStyle: {
            color: '#EB4747',
            borderWidth: 50,
          },
          lineStyle: {
            color: '#EB4747',
            width: 3,
          },
          symbolSize: 2,
        },
      ],
      grid: {
        top: '32px',
        left: '32px',
        bottom: '24px',
        right: '48px',
        containLabel: false,
      },
    };
    this.options = options;

    this.loading = false;
  }

  ngOnDestroy(): void {
    clearInterval(this.reload);
  }
}
