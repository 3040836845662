/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DatadisMaxPower } from './datadisMaxPower';

export interface DatadisMaxPowerList { 
    maxPowerList?: Array<DatadisMaxPower>;
    absoluteMaxPower?: DatadisMaxPower;
}