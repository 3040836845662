import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-water-percentage',
  templateUrl: './water-percentage.component.html',
  styleUrls: ['./water-percentage.component.scss'],
})
export class WaterPercentageComponent implements OnInit {
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup;
  //@Input() response;
  @Input() loading!: boolean;
  @Input() valueCold: any;
  @Input() valueHot: any;
  // @todo ver qué debe ser
  @Input() error: any;
  options: any;
  data: any = [];

  constructor() {}

  ngOnInit(): void {
    if (!this.loading) this.adaptData();
  }

  ngOnChanges(): void {
    if (!this.loading) this.adaptData();
  }

  adaptData(): void {
    const push = {
      name: 'Agua fría sanitaria',
      value: this.valueCold.thisMonth.m3,
    };

    const push2 = {
      name: 'Agua caliente sanitaria',
      value: this.valueHot.thisMonth.m3,
    };
    this.data.push(push);
    this.data.push(push2);

    this.setOptions();
  }

  setOptions(): void {
    this.options = {
      tooltip: {
        position: 'top',
        formatter: function (params: any) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params.color +
            '"></span>  <a style="font-size: 1rem"> ' +
            params.name +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params.value.toFixed(2).replace('.', ',') +
            ' m3' +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      legend: {
        align: 'auto',
        textStyle: {
          fontFamily: environment.fontFamily,
          fontSize: 14,
        },
        orient: 'vertical',
        top: 'bottom',
        padding: 5,
      },
      series: [
        {
          name: 'Consumo',
          type: 'pie',
          top: 0,
          color: ['#73C0DE', '#F17E7E'],
          center: ['50%', '42%'],
          radius: ['40%', '70%'],
          avoidLabelOverlap: true,

          label: {
            formatter: '{d}%',
            position: 'top',
            textStyle: {
              fontFamily: environment.fontFamily,
              fontSize: 14,
            },
          },

          labelLine: {
            show: true,
          },
          data: this.data,
        },
      ],
    };
  }
}
