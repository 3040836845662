/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateSchedule } from '../model/createSchedule';
import { PodInfoRoot } from '../model/podInfoRoot';
import { Schedule } from '../model/schedule';
import { SetAC } from '../model/setAC';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AirControlService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param deviceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirAcstatePost(deviceId: string, body?: SetAC, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaDeviceDeviceIdAirAcstatePost(deviceId: string, body?: SetAC, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaDeviceDeviceIdAirAcstatePost(deviceId: string, body?: SetAC, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaDeviceDeviceIdAirAcstatePost(deviceId: string, body?: SetAC, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirAcstatePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/acstate`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirInfoGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<PodInfoRoot>;
    public climaDeviceDeviceIdAirInfoGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PodInfoRoot>>;
    public climaDeviceDeviceIdAirInfoGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PodInfoRoot>>;
    public climaDeviceDeviceIdAirInfoGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirInfoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PodInfoRoot>('get',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirSchedulePost(deviceId: string, body?: CreateSchedule, observe?: 'body', reportProgress?: boolean): Observable<Schedule>;
    public climaDeviceDeviceIdAirSchedulePost(deviceId: string, body?: CreateSchedule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Schedule>>;
    public climaDeviceDeviceIdAirSchedulePost(deviceId: string, body?: CreateSchedule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Schedule>>;
    public climaDeviceDeviceIdAirSchedulePost(deviceId: string, body?: CreateSchedule, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirSchedulePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Schedule>('post',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/schedule`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirSchedulePut(deviceId: string, body?: Schedule, observe?: 'body', reportProgress?: boolean): Observable<Schedule>;
    public climaDeviceDeviceIdAirSchedulePut(deviceId: string, body?: Schedule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Schedule>>;
    public climaDeviceDeviceIdAirSchedulePut(deviceId: string, body?: Schedule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Schedule>>;
    public climaDeviceDeviceIdAirSchedulePut(deviceId: string, body?: Schedule, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirSchedulePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Schedule>('put',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/schedule`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param scheduleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirScheduleScheduleIdDelete(deviceId: string, scheduleId: string, observe?: 'body', reportProgress?: boolean): Observable<Schedule>;
    public climaDeviceDeviceIdAirScheduleScheduleIdDelete(deviceId: string, scheduleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Schedule>>;
    public climaDeviceDeviceIdAirScheduleScheduleIdDelete(deviceId: string, scheduleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Schedule>>;
    public climaDeviceDeviceIdAirScheduleScheduleIdDelete(deviceId: string, scheduleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirScheduleScheduleIdDelete.');
        }

        if (scheduleId === null || scheduleId === undefined) {
            throw new Error('Required parameter scheduleId was null or undefined when calling climaDeviceDeviceIdAirScheduleScheduleIdDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Schedule>('delete',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/schedule/${encodeURIComponent(String(scheduleId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheduleId 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirScheduleScheduleIdDisablePost(scheduleId: string, deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaDeviceDeviceIdAirScheduleScheduleIdDisablePost(scheduleId: string, deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaDeviceDeviceIdAirScheduleScheduleIdDisablePost(scheduleId: string, deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaDeviceDeviceIdAirScheduleScheduleIdDisablePost(scheduleId: string, deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheduleId === null || scheduleId === undefined) {
            throw new Error('Required parameter scheduleId was null or undefined when calling climaDeviceDeviceIdAirScheduleScheduleIdDisablePost.');
        }

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirScheduleScheduleIdDisablePost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/schedule/${encodeURIComponent(String(scheduleId))}/disable`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheduleId 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaDeviceDeviceIdAirScheduleScheduleIdEnablePost(scheduleId: string, deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaDeviceDeviceIdAirScheduleScheduleIdEnablePost(scheduleId: string, deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaDeviceDeviceIdAirScheduleScheduleIdEnablePost(scheduleId: string, deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaDeviceDeviceIdAirScheduleScheduleIdEnablePost(scheduleId: string, deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheduleId === null || scheduleId === undefined) {
            throw new Error('Required parameter scheduleId was null or undefined when calling climaDeviceDeviceIdAirScheduleScheduleIdEnablePost.');
        }

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling climaDeviceDeviceIdAirScheduleScheduleIdEnablePost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/clima/device/${encodeURIComponent(String(deviceId))}/air/schedule/${encodeURIComponent(String(scheduleId))}/enable`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
