<div class="body">
  <div class="header">
    <a class="return" [routerLink]="['../..']"
      ><img src="/assets/img/arrow-back.svg" style="padding: 0px 8px" />
      <h1 class="return-text">Volver a resumen</h1>
    </a>
  </div>
  <div class="main">
    <div class="left extend column">
      <div class="extend gap">
        <div class="climate column extend" style="flex: 2">
          <h2 class="title">Estado y control</h2>
          <div class="content extend adaptable">
            <app-netatmo-temperature
              [homeId]="homeId"
              class="extend"
              [response]="response"
            ></app-netatmo-temperature>
            <app-netatmo-controller
              class="extend"
              [tempInfo]="response"
            ></app-netatmo-controller>
          </div>
        </div>
      </div>

      <div class="consumption column">
        <h2 class="title">Consumo del dispositivo</h2>
        <div class="content extend">
          <app-heat-consumption
            class="extend"
            [header]="false"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-heat-consumption>
        </div>
      </div>
    </div>
    <div class="right extend column">
      <div class="chart extend column">
        <div class="title">
          <h2 class="text">Histórico de consumo</h2>
          <div class="datepicker">
            <app-widget-datepicker
              class="extend"
              (dateRangeChange)="changeDate($event)"
            ></app-widget-datepicker>
          </div>
        </div>
        <div class="content extend">
          <app-temperature-consumption-chart
            class="extend"
            [type]="'heat'"
            [deviceId]="deviceId"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-temperature-consumption-chart>
        </div>
      </div>
      <div class="schedule extend column">
        <h2 class="title">Temperatura en tiempo real</h2>
        <div class="content extend">
          <app-temperature-chart
            class="extend"
            [type]="'home'"
            [homeId]="homeId"
          ></app-temperature-chart>
        </div>
      </div>
    </div>
  </div>
</div>
