/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BoilerFuelTypeList } from '../model/boilerFuelTypeList';
import { HeatingPrice } from '../model/heatingPrice';
import { HeatingPriceList } from '../model/heatingPriceList';
import { HourAndConsumption } from '../model/hourAndConsumption';
import { HourAndConsumptionList } from '../model/hourAndConsumptionList';
import { HourWeekCalc } from '../model/hourWeekCalc';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HeatingDataService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHeatingFuellistGet(observe?: 'body', reportProgress?: boolean): Observable<BoilerFuelTypeList>;
    public climaHeatingFuellistGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BoilerFuelTypeList>>;
    public climaHeatingFuellistGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BoilerFuelTypeList>>;
    public climaHeatingFuellistGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BoilerFuelTypeList>('get',`${this.basePath}/clima/heating/fuellist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionAbsoluteGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaHomeHomeIdHeatingConsumptionAbsoluteGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaHomeHomeIdHeatingConsumptionAbsoluteGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaHomeHomeIdHeatingConsumptionAbsoluteGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionAbsoluteGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/absolute`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaHomeHomeIdHeatingConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaHomeHomeIdHeatingConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaHomeHomeIdHeatingConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionAvgDayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/avg/day`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionAvgHourweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourWeekCalc>;
    public climaHomeHomeIdHeatingConsumptionAvgHourweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourWeekCalc>>;
    public climaHomeHomeIdHeatingConsumptionAvgHourweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourWeekCalc>>;
    public climaHomeHomeIdHeatingConsumptionAvgHourweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionAvgHourweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourWeekCalc>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/avg/hourweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionBydayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaHomeHomeIdHeatingConsumptionBydayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionBydayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionBydayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionBydayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/byday`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionByhourGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaHomeHomeIdHeatingConsumptionByhourGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionByhourGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionByhourGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionByhourGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/byhour`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionBymonthGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaHomeHomeIdHeatingConsumptionBymonthGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionBymonthGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionBymonthGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionBymonthGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/bymonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionByweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaHomeHomeIdHeatingConsumptionByweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionByweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionByweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionByweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/byweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionByyearGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaHomeHomeIdHeatingConsumptionByyearGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionByyearGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionByyearGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionByyearGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/byyear`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionLast6monthGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaHomeHomeIdHeatingConsumptionLast6monthGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionLast6monthGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionLast6monthGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionLast6monthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/last6month`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionLastMonthGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumptionList>;
    public climaHomeHomeIdHeatingConsumptionLastMonthGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionLastMonthGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumptionList>>;
    public climaHomeHomeIdHeatingConsumptionLastMonthGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionLastMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumptionList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/lastMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionThisMonthGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaHomeHomeIdHeatingConsumptionThisMonthGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaHomeHomeIdHeatingConsumptionThisMonthGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaHomeHomeIdHeatingConsumptionThisMonthGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionThisMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/thisMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingConsumptionTodayGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HourAndConsumption>;
    public climaHomeHomeIdHeatingConsumptionTodayGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourAndConsumption>>;
    public climaHomeHomeIdHeatingConsumptionTodayGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourAndConsumption>>;
    public climaHomeHomeIdHeatingConsumptionTodayGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingConsumptionTodayGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourAndConsumption>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/consumption/today`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingPricePost(homeId: string, body?: HeatingPrice, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public climaHomeHomeIdHeatingPricePost(homeId: string, body?: HeatingPrice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public climaHomeHomeIdHeatingPricePost(homeId: string, body?: HeatingPrice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public climaHomeHomeIdHeatingPricePost(homeId: string, body?: HeatingPrice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingPricePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/price`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingPricesGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<HeatingPriceList>;
    public climaHomeHomeIdHeatingPricesGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatingPriceList>>;
    public climaHomeHomeIdHeatingPricesGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatingPriceList>>;
    public climaHomeHomeIdHeatingPricesGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingPricesGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HeatingPriceList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/prices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climaHomeHomeIdHeatingTempGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HeatingPriceList>;
    public climaHomeHomeIdHeatingTempGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HeatingPriceList>>;
    public climaHomeHomeIdHeatingTempGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HeatingPriceList>>;
    public climaHomeHomeIdHeatingTempGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling climaHomeHomeIdHeatingTempGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HeatingPriceList>('get',`${this.basePath}/clima/home/${encodeURIComponent(String(homeId))}/heating/temp`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
