import { Component, Input, OnInit } from '@angular/core';
import { WaterConsumptionToday, WaterDataService } from 'src/app/api';

@Component({
  selector: 'app-water-status',
  templateUrl: './water-status.component.html',
  styleUrls: ['./water-status.component.scss'],
})
export class WaterStatusComponent implements OnInit {
  loading: boolean = true;
  @Input() header!: string;
  @Input() type!: string;
  @Input() homeId!: string;
  @Input() error: any;

  response: WaterConsumptionToday | undefined;
  constructor(private homeApi: WaterDataService) {}

  getData(): void {
    let hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    this.homeApi
      .waterHomeHomeIdTypeConsumptionTodayGet(this.homeId, this.type)
      .subscribe(
        (response) => {
          this.response = response;
          if (!!this.response) {
            if (
              new Date(this.response.today!.utcEndTime!).getTime() <
              hoy.getTime()
            ) {
              this.response.today!.utcEndTime = hoy;
            }
            this.loading = false;
          }
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .waterHomeHomeIdTypeConsumptionTodayGet(this.homeId, this.type)
              .subscribe(
                (response) => {
                  this.response = response;
                  this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
  ngOnInit(): void {
    this.getData();
  }
}
