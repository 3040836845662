import { NgModule } from '@angular/core';
import { AuthGuard } from './core/guards/auth.guard';
import { Routes, RouterModule } from '@angular/router';

import { DefaultComponent } from './layouts/default/default.component';
import { LoginComponent } from './layouts/login/login.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { HomesComponent } from './modules/dashboard/pages/homes/homes.component';
import { HomeSummaryComponent } from './modules/home/pages/summary/summary.component';
import { ElectricityComponent } from './modules/home/pages/electricity/electricity.component';
import { WaterComponent } from './modules/home/pages/water/water.component';
import { TemperatureComponent } from './modules/home/pages/temperature/temperature.component';
import { MiruboxComponent } from './modules/device/pages/mirubox/mirubox.component';
import { WibeeeComponent } from './modules/device/pages/wibeee/wibeee.component';
import { SettingsComponent } from './modules/dashboard/pages/settings/settings.component';
import { NetatmoComponent } from './modules/device/pages/netatmo/netatmo.component';
import { SensiboComponent } from './modules/device/pages/sensibo/sensibo.component';
import { WaterTemperatureComponent } from './modules/home/pages/water-temperature/water-temperature.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DefaultComponent,
    children: [
      {
        path: '',
        redirectTo: 'user',
        pathMatch: 'full',
      },
      {
        path: 'user',
        component: DashboardComponent,
        children: [
          {
            path: '',
            redirectTo: 'homes',
            pathMatch: 'full',
          },
          {
            path: 'homes',

            component: HomesComponent,
          },
          {
            path: 'settings',

            component: SettingsComponent,
          },
        ],
      },
      {
        path: 'home/:homeId',
        children: [
          {
            path: 'summary',
            component: HomeSummaryComponent,
          },
          {
            path: 'electricity',
            children: [
              {
                path: '',
                component: ElectricityComponent,
              },
              {
                path: 'counter',
                children: [
                  {
                    path: '',
                    redirectTo: '..',
                    pathMatch: 'full',
                  },
                  {
                    path: ':deviceId',
                    component: MiruboxComponent,
                  },
                ],
              },
              {
                path: 'device',
                children: [
                  {
                    path: '',
                    redirectTo: '..',
                    pathMatch: 'full',
                  },
                  {
                    path: 'monitor',
                    children: [
                      {
                        path: '',
                        redirectTo: '..',
                        pathMatch: 'full',
                      },
                      {
                        path: ':deviceId',
                        component: WibeeeComponent,
                      },
                    ],
                  },

                  {
                    path: 'netatmo',
                    component: NetatmoComponent,
                  },
                  {
                    path: 'plug',
                    children: [
                      {
                        path: '',
                        redirectTo: '..',
                        pathMatch: 'full',
                      },
                      {
                        path: ':deviceId',
                        children: [
                          {
                            path: '',
                            component: WibeeeComponent,
                          },
                          {
                            path: ':deviceName',
                            component: WibeeeComponent,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'sensibo',
                    children: [
                      {
                        path: '',
                        redirectTo: '..',
                        pathMatch: 'full',
                      },
                      {
                        path: ':deviceId',
                        children: [
                          {
                            path: '',
                            component: SensiboComponent,
                          },
                          {
                            path: ':deviceName',
                            component: SensiboComponent,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'water',

            children: [
              {
                path: '',
                component: WaterComponent,
              },
              {
                path: 'device',
                children: [
                  {
                    path: '',
                    redirectTo: '..',
                    pathMatch: 'full',
                  },
                  {
                    path: ':type',
                    component: WaterTemperatureComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'temperature',
            children: [
              {
                path: '',
                component: TemperatureComponent,
              },
              {
                path: 'device',
                children: [
                  {
                    path: '',
                    redirectTo: '..',
                    pathMatch: 'full',
                  },

                  {
                    path: 'netatmo',
                    component: NetatmoComponent,
                  },

                  {
                    path: 'sensibo',
                    children: [
                      {
                        path: '',
                        redirectTo: '..',
                        pathMatch: 'full',
                      },
                      {
                        path: ':deviceId',
                        children: [
                          {
                            path: '',
                            component: SensiboComponent,
                          },
                          {
                            path: ':deviceName',
                            component: SensiboComponent,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
