import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Colors } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-carbon-footprint-chart',
  templateUrl: './carbon-footprint-chart.component.html',
  styleUrls: ['./carbon-footprint-chart.component.scss'],
})
export class CarbonFootprintChartComponent implements OnInit, OnChanges {
  @Input() response!: any;
  @Input() loading!: boolean;
  @Input() error: any;

  data: any;
  options: any;
  saving!: boolean;
  exists!: boolean;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (!this.loading) {
      this.adaptData(this.response.data);
    }
  }

  adaptData(data: any): any {
    if (data.length > 0) {
      this.exists = true;
      if (data[data.length - 1].kgCO2 > data[data.length - 2].kgCO2) {
        this.saving = false;
      } else {
        this.saving = true;
      }
    } else {
      this.exists = false;
    }
    this.data = [];

    for (const dato of data) {
      const elemento = {
        name: dato.utcStartTime,
        value: [new Date(dato.utcStartTime), dato.kgCO2.toFixed(2)],
      };
      this.data.push(elemento);
    }
    this.setOptions();
  }

  setOptions(): void {
    let interval;
    let axisName;
    let units;

    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    const options = {
      tooltip: {
        position: 'top',
        trigger: 'axis',
        formatter: function (params: any) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background:' +
            params[0].color +
            '"></span>  <a style="font-size: 1rem">' +
            monthNames[new Date(params[0].name).getMonth()] +
            ' ' +
            new Date(params[0].name).getFullYear() +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params[0].value[1].replace('.', ',') +
            ' kg de CO2 </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      title: {
        left: 'Left',
        text: 'kg de CO2',

        textStyle: {
          fontSize: 12,
          fontFamily: environment.fontFamily,
        },
      },

      xAxis: {
        type: 'time',
        interval: 60 * 60 * 1000 * 24 * 90,
        minInterval: 60 * 60 * 1000 * 24 * 90,
        boundaryGap: false,
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 600,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 600,
        },
      },
      series: [
        {
          smooth: true,
          name: 'Emisiones de CO2',

          type: 'line',
          symbol: 'circle',
          symbolSize: 10,
          lineStyle: {
            width: 3,
          },

          itemStyle: {
            color:
              environment.flavour === 'hobeen' ? Colors.primary : '#285aff',
          },

          data: this.data,
        },
      ],
      grid: {
        top: 32,
        left: 0,
        right: 20,
        bottom: 10,
        containLabel: true,
      },
    };
    this.options = options;
    this.loading = false;
  }
}
