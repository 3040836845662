import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-netatmo-timetable-editor',
  templateUrl: './netatmo-timetable-editor.component.html',
  styleUrls: ['./netatmo-timetable-editor.component.scss'],
})
export class NetatmoTimetableEditorComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NetatmoTimetableEditorComponent>
  ) {
    dialogRef.disableClose = true;
  }
  aux: any;
  hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
  minutes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59,
  ];

  ngOnInit(): void {
    console.log(this.data.tempInfo.appliedSchedule.presets);
    this.aux = JSON.stringify(this.data);
  }

  findPreset(time: any) {
    let value;
    try {
      value = this.data.tempInfo.appliedSchedule.presets.find(
        (x: any) => x.id === time.presetId
      );

      return value.color;
    } catch {
      return 'none';
    }

    return value;
  }

  endHourConditions(hour: number): any {
    if (
      this.data.index + 1 ==
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day].length
    ) {
      if (hour == 23) return true;
      return false;
    } else if (
      hour >= this.data.time.startHour &&
      hour <=
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          this.data.index + 1
        ].endHour
    ) {
      return true;
    }
  }
  saveDialog() {
    if (this.data.index > 0) {
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
        this.data.index - 1
      ].endHour = this.data.time.startHour;
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
        this.data.index - 1
      ].endMinute = this.data.time.startMinute;
    }
    if (
      this.data.index <
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day]
        .length -
        1
    ) {
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
        this.data.index + 1
      ].startHour = this.data.time.endHour;
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
        this.data.index + 1
      ].startMinute = this.data.time.endMinute;
    }
    if (
      this.data.index <
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day]
        .length -
        1
    ) {
      if (
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          this.data.index + 1
        ].endHour == this.data.time.endHour &&
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          this.data.index + 1
        ].endMinute == this.data.time.endMinute
      ) {
        this.data.tempInfo.appliedSchedule.groupedTimeTable[
          this.data.day
        ].splice(this.data.index + 1, 1);
      }
    }

    if (this.data.index > 0) {
      if (
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          this.data.index - 1
        ].endHour ==
          this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
            this.data.index - 1
          ].startHour &&
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          this.data.index - 1
        ].endMinute ==
          this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
            this.data.index - 1
          ].startMinute
      ) {
        this.data.tempInfo.appliedSchedule.groupedTimeTable[
          this.data.day
        ].splice(this.data.index - 1, 1);
      }
    }

    if (
      this.data.time.startHour == this.data.time.endHour &&
      this.data.time.startMinute == this.data.time.endMinute
    ) {
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day].splice(
        this.data.index,
        1
      );
    }
    this.dialogRef.close();
  }

  closeDialog() {
    this.aux = JSON.parse(this.aux);
    this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
      this.data.index
    ] =
      this.aux.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
        this.data.index
      ];

    this.dialogRef.close();
  }

  endMinuteValid(minute: number): any {
    //falso si hora final es igual a hora final del siguiente y minuto  es superior a minuto final del siguiente
    if (
      this.data.index <
      this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day]
        .length -
        1
    ) {
      if (
        this.data.time.endHour ==
        this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
          this.data.index + 1
        ].endHour
      ) {
        if (
          minute >
          this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
            this.data.index + 1
          ].endMinute
        ) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    } else if (minute == 59) {
      return true;
    }
  }
  changeHours(): void {
    if (this.data.time.startHour >= this.data.time.endHour) {
      if (this.data.time.startHour == this.data.time.endHour) {
        if (this.data.time.startMinute > this.data.time.endMinute) {
          this.data.time.endMinute = this.data.time.startMinute + 1;
        }
      } else {
        if (this.data.time.startHour < 23) {
          this.data.time.endHour = this.data.time.startHour;
          this.data.time.endMinute = 59;
        } else {
          this.data.time.endHour = 23;
          if (
            this.data.tempInfo.appliedSchedule.groupedTimeTable[this.data.day][
              this.data.index + 1
            ].endHour == this.data.time.endHour
          )
            this.data.time.endMinute =
              this.data.tempInfo.appliedSchedule.groupedTimeTable[
                this.data.day
              ][this.data.index + 1].endMinute;
        }
      }
    }
  }
  findPresetTemp(presetId: any) {
    let value;

    try {
      value = this.data.tempInfo.appliedSchedule.presets.find(
        (x: any) => x.id === presetId
      );

      return value.setpointTemp;
    } catch {
      return 'none';
    }

    return value;
  }

  findPresetName(presetId: any) {
    let value;

    try {
      value = this.data.tempInfo.appliedSchedule.presets.find(
        (x: any) => x.id === presetId
      );

      return value.name;
    } catch {
      return 'none';
    }

    return value;
  }
}
