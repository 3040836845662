<div class="body">
  <div class="main" style="width: 100%">
    <div class="up">
      <div class="homes">
        <h2 class="title">Mis viviendas</h2>
        <div class="content">
          <ng-container *ngFor="let home of homesArray">
            <app-summary-home class="homeUnit" [home]="home"></app-summary-home>
          </ng-container>
        </div>
      </div>
      <div class="ranking">
        <h2 class="title">Huella de carbono</h2>
        <div class="content">
          <app-home-carbon-footprint-summary
            class="extend"
            [response]="response?.total"
            [loading]="loading"
            [error]="error"
          ></app-home-carbon-footprint-summary>
          <app-home-carbon-footprint-chart
            class="extend"
            style="flex: 2; min-width: 150px"
            [response]="response && response.total"
            [loading]="loading"
            [error]="error"
          ></app-home-carbon-footprint-chart>
        </div>
      </div>
      <div class="expenses">
        <h2 class="title">Evolución del gasto total</h2>
        <div class="content">
          <app-summary-savings
            [category]="'total'"
            class="extend"
            style="margin: 0px"
            [response]="response?.total"
            [loading]="loading"
            [error]="error"
          ></app-summary-savings>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="expenses">
        <h2 class="title">Evolución del gasto en A/A</h2>
        <div class="content">
          <app-summary-savings
            [category]="'cold'"
            class="extend"
            [response]="response?.air"
            [loading]="loading"
            [error]="error"
          ></app-summary-savings>
        </div>
      </div>
      <div class="expenses">
        <h2 class="title">Evolución del gasto eléctrico</h2>
        <div class="content">
          <app-summary-savings
            [category]="'electricity'"
            class="extend"
            [response]="response?.electric"
            [loading]="loading"
            [error]="error"
          ></app-summary-savings>
        </div>
      </div>
      <div class="expenses">
        <h2 class="title">Evolución del gasto en calefacción</h2>
        <div class="content">
          <app-summary-savings
            [category]="'heat'"
            class="extend"
            [response]="response?.heating"
            [loading]="loading"
            [error]="error"
          ></app-summary-savings>
        </div>
      </div>
    </div>
  </div>
  <div class="side" *ngIf="false">
    <div class="side-content">
      <h2 class="title">Retos para ahorrar</h2>
      <div class="content">
        <app-challenge-list style="display: flex; flex: 1"></app-challenge-list>
      </div>
    </div>
  </div>
</div>
