import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClimaHomeService, HourAndConsumption } from 'src/app/api';

@Component({
  selector: 'app-temperature-average',
  templateUrl: './average.component.html',
  styleUrls: ['./average.component.scss'],
})
export class AverageTemperatureComponent implements OnInit {
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup;
  @Input() response!: HourAndConsumption;
  @Input() loading!: boolean;
  @Input() error: any;
  constructor(private homeApi: ClimaHomeService) {}

  ngOnInit(): void {
    this.loading = true;
    this.getData();
  }

  getData(): void {
    this.homeApi
      .climaHomeHomeIdConsumptionAvgDayGet(
        this.homeId,
        this.dateRange.value.start,
        new Date(this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000)
      )
      .subscribe(
        (response) => {
          this.response = response;
          this.loading = false;
        },
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .climaHomeHomeIdConsumptionAvgDayGet(
                this.homeId,
                new Date(this.dateRange.value.start),
                new Date(
                  this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                )
              )
              .subscribe(
                (response) => {
                  this.response = response;
                  this.loading = false;
                },
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }
}
