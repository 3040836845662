import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChallengeList, ManagmentService } from 'src/app/api';
import { ChallengePopupComponent } from '../challenge-popup/challenge-popup.component';

@Component({
  selector: 'app-challenge-list',
  templateUrl: './challenge-list.component.html',
  styleUrls: ['./challenge-list.component.scss'],
})
export class ChallengeListComponent implements OnInit {
  challengesResponse!: ChallengeList;
  error: any;
  loading!: boolean;
  demoResponse = {
    count: 46,
    data: [
      {
        id: '69428044-5d89-486e-932c-f6aed690d7fc',
        active_users: 8,
        title: 'Compra termostato programable',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: '98c98ef6-c1b9-4109-a1b2-845e786b61d5',
        active_users: 6,
        title: 'Ventila el tiempo justo',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: '3046f14c-ce91-442d-a93a-8f5ca25527d8',
        active_users: 4,
        title: 'Compra en comercios locales',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: '3bd7236c-73da-4fbe-b53a-b8532db916be',
        active_users: 21,
        title: 'Apaga la luz',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/luz.png',
      },
      {
        id: '1419a3f3-ddb5-4809-b899-144070704228',
        active_users: 665,
        title: 'Controla tus gastos',
        category: 'Free',
        points: 100,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: 'e26104f6-5fdb-4bb9-a818-51fa37b4b68d',
        active_users: 3,
        title: 'Ir en bicicleta al trabajo',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: '37e87651-93d8-4f36-9da8-16650de52496',
        active_users: 9,
        title: 'Compensa tu huella de carbono con Fundeen',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/fundeen.svg',
      },
      {
        id: '6bddff7c-d99e-427b-8965-596fee95d15e',
        active_users: 2,
        title: 'Cerrar las ventanas',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: '7c37f5fd-4def-4ed8-9d4b-fdd0935b43b7',
        active_users: 3,
        title: 'Reduce la temperatura del agua',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: '564ccfb5-777f-4bdf-99f3-ef04fc9514f0',
        active_users: 27,
        title: 'Cepillo de dientes',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/agua.png',
      },
      {
        id: '700e3fd0-d92d-4d42-8975-822b35f73b24',
        active_users: 12,
        title: 'Adopta una tarifa de electricidad renovable',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/luz.png',
      },
      {
        id: '132ae7b3-8d7d-4ab3-8e42-2feaa9fd6225',
        active_users: 0,
        title: 'Te ayudamos',
        category: 'Premium',
        points: 100,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: 'd57111cb-996b-4bf5-b01c-4490bcddf0ed',
        active_users: 2,
        title: 'Configura la calefacción',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: '1256f06e-0e4b-4136-8776-86d203129814',
        active_users: 4,
        title: 'Compra moda sostenible',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: 'ee4dd1cd-da13-47ab-8789-10cf83bed23b',
        active_users: 3,
        title: 'Nuevas tarifas',
        category: 'Free',
        points: 25,
        image:
          'http://api.staging.hobeen.com/public/uploads/images/Captura de pantalla 2021-06-06 a las 17.55.40-dc2d17f2c4.png',
      },
      {
        id: 'a3d36a3c-3f44-47c8-9bf3-13119c4eadc4',
        active_users: 35,
        title: 'Reto lavavajillas',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/agua.png',
      },
      {
        id: '9258dac9-b603-4531-a4a0-f82e08359dad',
        active_users: 5,
        title: 'Actualiza tu factura',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: 'f7635d1b-a914-4611-8c46-5730f87a410d',
        active_users: 2,
        title: 'Conducción suave',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: '4d8ee987-47e3-420c-a588-5f3f7489290e',
        active_users: 23,
        title: 'Ahorrar tiempo en ducha',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/agua.png',
      },
      {
        id: 'fac34a22-1663-45b5-9059-72e392f198f1',
        active_users: 17,
        title: 'Usa más el microondas',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/luz.png',
      },
      {
        id: '2a051f92-1274-42e1-aba2-5e0461136be5',
        active_users: 3,
        title: 'Sube la temperatura de tu AC',
        category: 'Free',
        points: 30,
        image: 'undefined',
      },
      {
        id: '97af41a9-4b62-42b0-aabc-9ccc557e6025',
        active_users: 1,
        title: 'Programa tus enchufes',
        category: 'Premium',
        points: 100,
        image: 'http://api.staging.hobeen.com/public/images/challenge/luz.png',
      },
      {
        id: '110e3948-e3aa-48a4-95ab-5b9e9f9c5784',
        active_users: 2,
        title: 'Apaga el dispositivo',
        category: 'Free',
        points: 30,
        image: 'undefined',
      },
      {
        id: '448b4920-8f0e-4cfc-8260-ced326d891d4',
        active_users: 7,
        title: 'Utiliza programas eco',
        category: 'Free',
        points: 30,
        image: 'undefined',
      },
      {
        id: '7ee3f7f6-b858-47cc-b2a8-0f6382f10215',
        active_users: 1,
        title: 'Consumo energético',
        category: 'Premium',
        points: 100,
        image: 'http://api.staging.hobeen.com/public/images/challenge/luz.png',
      },
      {
        id: 'cd49c9b2-ab20-41d2-bd35-99086628e9ef',
        active_users: 10,
        title: 'Reduce la temperatura de tu vivienda',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: 'a6c80002-468e-41e4-89bd-e99e855cfc5f',
        active_users: 4,
        title: 'Compartir vehículo',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: 'd1608782-625e-4321-8bd6-7b2fac7b53cd',
        active_users: 13,
        title: 'Cierra el grifo ',
        category: 'Free',
        points: 30,
        image: 'undefined',
      },
      {
        id: '493e4ff2-479a-4fac-8091-ee8243ecdb53',
        active_users: 7,
        title: 'Aprovecha la producción renovable',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/luz.png',
      },
      {
        id: '782f6033-8afa-4744-89f8-3e16612f972d',
        active_users: 1,
        title: 'Programa el modo ahorro',
        category: 'Premium',
        points: 100,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: '7a5dfb0a-3068-4408-9590-ad09e44b3ecb',
        active_users: 9,
        title: 'Pásate a la sostenibilidad y cambia el mundo',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/liight.png',
      },
      {
        id: '3690bd0a-edd1-426b-846e-c94e07c2a382',
        active_users: 16,
        title: 'Actualiza tu tarifa',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/watiofy.png',
      },
      {
        id: '3a2e8e2c-a366-4017-8f07-83d335a70c2b',
        active_users: 15,
        title: 'Reto lavadora',
        category: 'Free',
        points: 30,
        image: 'undefined',
      },
      {
        id: '619042dc-628b-47d2-a027-b5a8ca212a04',
        active_users: 3,
        title: 'Dieta baja en carne',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: '5fb2514a-1662-41d5-a8de-179d491368f1',
        active_users: 2,
        title: 'Apaga la calefacción en tu ausencia',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: 'e53df3b6-4b51-4f08-99d4-0305cd12e8c7',
        active_users: 2,
        title: 'Dejar de fumar',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: 'ac9f8d61-5256-42ff-84d8-296ce1de888e',
        active_users: 3,
        title: 'Recicla',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/mejora.png',
      },
      {
        id: '1dfe0543-6984-47df-82d6-0f67f254ee35',
        active_users: 1,
        title: 'Programa tu A/A',
        category: 'Premium',
        points: 100,
        image: 'http://api.staging.hobeen.com/public/images/challenge/luz.png',
      },
      {
        id: 'ade11f5f-490d-4f56-803f-5862c54ed3b1',
        active_users: 1,
        title: 'Reducción consumo enchufes',
        category: 'Premium',
        points: 100,
        image: 'http://api.staging.hobeen.com/public/images/challenge/luz.png',
      },
      {
        id: '389e23d9-30a7-4047-ad8c-b6bd33b1fdcb',
        active_users: 5,
        title: 'Únete a la comunidad de #HobeenHeroes',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/images/challenge/hobeen.png',
      },
      {
        id: '5f3dcbf1-c152-4fba-9158-4ab151ccc2a8',
        active_users: 1,
        title: 'Aprende a usar el AC',
        category: 'Free',
        points: 30,
        image: 'undefined',
      },
      {
        id: '0c882e47-cde3-476b-ad5e-71a13e0b8264',
        active_users: 5,
        title: 'Fregar con agua tibia',
        category: 'Free',
        points: 30,
        image: 'http://api.staging.hobeen.com/public/images/challenge/gas.png',
      },
      {
        id: '0f4fc9da-4dd0-4608-85e4-bf0a440cf5e0',
        active_users: 3,
        title: 'De la bombilla al led',
        category: 'Free',
        points: 30,
        image: 'undefined',
      },
      {
        id: 'e2e8bd9d-ff6f-4946-8c44-7f7504b65d9c',
        active_users: 11,
        title: 'Consume contenido responsable',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/uploads/images/logos theobjective alta-hobeen-c2575ba4ed.png',
      },
      {
        id: '89d1d966-3911-4d7d-846b-3a0967b96b41',
        active_users: 8,
        title: 'Compañía telefónica sostenible',
        category: 'Free',
        points: 30,
        image:
          'http://api.staging.hobeen.com/public/uploads/images/lowi-350x183_0-eeaf5ed755.png',
      },
      {
        id: '92b3350b-29ef-42aa-841c-8de79edb54a7',
        active_users: 16,
        title: 'Aprovecha las horas más baratas',
        category: 'Free',
        points: 30,
        image: 'undefined',
      },
    ],
  };
  constructor(private api: ManagmentService, public dialog: MatDialog) {
    this.error = 0;
  }

  ngOnInit(): void {
    this.loading = true;
    this.api.challengesGet().subscribe(
      (x) => {
        this.challengesResponse = x;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api.challengesGet().subscribe(
            (x) => {
              this.challengesResponse = x;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }

  openDialog(challenge: any): void {
    this.dialog.open(ChallengePopupComponent, {
      data: challenge,
    });
  }
}
