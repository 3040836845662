import { Injectable } from '@angular/core';
import { ClientService } from 'src/app/api';
import { environment } from 'src/environments/environment';
import { UpdateCssService } from './update-css-service.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private client: ClientService,
    private cssService: UpdateCssService
  ) {}

  getFlavour(): string {
    return environment.flavour;
  }

  getClientData(): any {
    if (environment.flavour == 'total') return undefined;
    if (environment.clientData != undefined) {
      return environment.clientData;
    } else {
      let client = localStorage.getItem('client');

      if (client != null) {
        console.log(client);
        environment.clientData = JSON.parse(client);
        console.log('Client detected', environment.clientData);
        this.cssService.setNewPalette(true, environment.clientData.textsColor);
        this.cssService.setNewPalette(
          false,
          environment.clientData.graphicsColor
        );
        return JSON.parse(client);
      } else {
        console.log('client is null');
        console.log(environment.clientData);
        if (environment.clientData != false) {
          console.log('getting new client');
          environment.clientData = false;
          this.client.clientGet('response').subscribe(
            (response) => {
              if (response.status != 204) {
                console.log('client retrieved');

                environment.clientData = response.body;
                this.cssService.setNewPalette(
                  true,
                  environment.clientData.textsColor
                );
                this.cssService.setNewPalette(
                  false,
                  environment.clientData.graphicsColor
                );
                localStorage.setItem('client', JSON.stringify(response.body));
              } else {
                this.cssService.setNewPalette(true, '#199479');
                this.cssService.setNewPalette(false, '#edc628');
              }
            },
            (error) => {}
          );
        }
        return environment.clientData;
      }
    }
  }

  getClientLogo(): string {
    if (this.getClientData() == undefined || this.getClientData() == false) {
      return 'assets/img/navbar/logo-navbar.svg';
    } else {
      if (environment.clientData.logo != null) {
        return environment.clientData.logo;
      } else {
        return environment.clientData.icon;
      }
    }
  }
}
