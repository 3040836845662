import { Component, Input, OnInit } from '@angular/core';
import {
  ComposedConsumptionList,
  ConsumptionList,
  Home,
  HomeConsumptionService,
} from 'src/app/api';

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-summary-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  beta!: boolean;
  lastMonth: any = null;
  currentMonth: any = null;
  loading!: boolean;
  response!: ComposedConsumptionList | undefined;
  empty!: boolean;
  error: any;
  environment = environment;
  constructor(private api: HomeConsumptionService) {}
  @Input() home!: Home;

  ngOnInit(): void {
    this.loading = true;
    this.empty = false;
    this.api.homeHomeIdConsumptionThisandlastmonthGet(this.home.id!).subscribe(
      (response) => {
        this.response = response;
        if (response.total?.count! < 1) this.empty = true;
        if (!this.response) {
          this.error = 204;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.error = error.status;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api
            .homeHomeIdConsumptionThisandlastmonthGet(this.home.id!)
            .subscribe(
              (response) => {
                this.response = response;

                if (this.response.total?.count! < 1) {
                  this.empty = true;
                }
                if (!this.response) {
                  this.error = 204;
                  this.loading = false;
                } else {
                  this.lastMonth =
                    this.response.total?.data![
                      this.response.total?.data!.length - 2
                    ];
                  this.currentMonth =
                    this.response.total?.data![
                      this.response.total?.data!.length - 1
                    ];
                  this.loading = false;
                }
              },
              (error) => {
                this.loading = false;
                this.error = error.status;
              }
            );
        }, 15000);
      }
    );
    this.beta = environment.beta;
  }
}
