/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AcState } from './acState';
import { AccessPoint } from './accessPoint';
import { ConnectionStatus } from './connectionStatus';
import { FiltersCleaning } from './filtersCleaning';
import { LastACStateChange } from './lastACStateChange';
import { LastStateChange } from './lastStateChange';
import { LastStateChangeToOff } from './lastStateChangeToOff';
import { LastStateChangeToOn } from './lastStateChangeToOn';
import { Location } from './location';
import { Measurements } from './measurements';
import { PodRoom } from './podRoom';
import { Remote } from './remote';
import { RemoteCapabilities } from './remoteCapabilities';
import { Schedule } from './schedule';
import { SensorsCalibration } from './sensorsCalibration';
import { WarrantyEligibleUntil } from './warrantyEligibleUntil';

export interface Result { 
    id?: string;
    qrId?: string;
    temperatureUnit?: string;
    room?: PodRoom;
    acState?: AcState;
    lastStateChange?: LastStateChange;
    lastStateChangeToOn?: LastStateChangeToOn;
    lastStateChangeToOff?: LastStateChangeToOff;
    location?: Location;
    connectionStatus?: ConnectionStatus;
    firmwareVersion?: string;
    firmwareType?: string;
    productModel?: string;
    configGroup?: string;
    currentlyAvailableFirmwareVersion?: string;
    cleanFiltersNotificationEnabled?: boolean;
    shouldShowFilterCleaningNotification?: boolean;
    isGeofenceOnExitEnabled?: boolean;
    isClimateReactGeofenceOnExitEnabled?: boolean;
    isMotionGeofenceOnExitEnabled?: boolean;
    serial?: string;
    sensorsCalibration?: SensorsCalibration;
    motionSensors?: Array<any>;
    tags?: Array<any>;
    schedules?: Array<Schedule>;
    motionConfig?: any;
    filtersCleaning?: FiltersCleaning;
    serviceSubscriptions?: Array<any>;
    warrantyEligible?: string;
    warrantyEligibleUntil?: WarrantyEligibleUntil;
    features?: Array<string>;
    lastACStateChange?: LastACStateChange;
    homekitSupported?: boolean;
    remoteCapabilities?: RemoteCapabilities;
    remote?: Remote;
    remoteFlavor?: string;
    remoteAlternatives?: Array<string>;
    measurements?: Measurements;
    accessPoint?: AccessPoint;
    macAddress?: string;
}