import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AirControlService } from 'src/app/api';

@Component({
  selector: 'app-programming-wizard',
  templateUrl: './programming-wizard.component.html',
  styleUrls: ['./programming-wizard.component.scss'],
})
export class ProgrammingWizardComponent implements OnInit {
  loading!: boolean;
  saveText: string = 'Guardar';
  schedule: any = { status: 'on' };
  state!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,

    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ProgrammingWizardComponent>,
    private api: AirControlService
  ) {
    this.saveText = 'Guardar';
    this.loading = false;
    this.schedule.status = 'off';
  }

  ngOnInit(): void {
    this.schedule = this.data.schedule;

    if (this.schedule.acState.on) {
      this.state = 'on';
    } else {
      this.state = 'off';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  setSchedule(): void {
    console.log(this.schedule);
    if (this.state === 'on') {
      this.schedule.acState.on = true;
    } else {
      this.schedule.acState.on = false;
    }

    this.api
      .climaDeviceDeviceIdAirSchedulePut(this.data.deviceId, this.schedule)
      .subscribe((response) => {
        let aux: any = response;
        this.data.schedule.id = aux.id;
        this.dialogRef.close();
      });
  }
}
