import { Component, OnInit, SimpleChanges } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClimaHomeService, HourAndConsumptionList } from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss'],
})
export class TemperatureComponent implements OnInit {
  constructor(private route: ActivatedRoute, private api: ClimaHomeService) {
    this.error = 0;
  }
  response!: HourAndConsumptionList;
  loading!: boolean;
  error: any;
  homeId!: string;
  dateRange!: FormGroup;
  ngOnInit(): void {
    this.loading = true;
    environment.current = 'Climatización';
    environment.currentRoute = this.route;
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    environment.homeId = this.homeId;

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });

    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
  }
  changeDate(event: any): void {
    this.dateRange = event;
  }

  getData(): void {
    this.api.climaHomeHomeIdConsumptionLast6monthGet(this.homeId).subscribe(
      (response) => {
        this.response = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api
            .climaHomeHomeIdConsumptionLast6monthGet(this.homeId)
            .subscribe(
              (response) => {
                this.response = response;
                this.loading = false;
              },
              (error) => {
                this.error = error.status;
                this.loading = false;
              }
            );
        }, 15000);
      }
    );
  }
}
