import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceList, HeatDeviceList, ManagmentService } from 'src/app/api';

@Component({
  selector: 'app-heat-devices',
  templateUrl: './heat-devices.component.html',
  styleUrls: ['./heat-devices.component.scss'],
})
export class HeatDevicesComponent implements OnInit {
  constructor(private route: ActivatedRoute, private api: ManagmentService) {}
  homeId!: string;
  loading!: boolean;
  error: any;
  devicesResponse!: HeatDeviceList;
  ngOnInit(): void {
    this.loading = true;

    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    this.getDevices();
  }

  getDevices(): void {
    this.api.homeHomeIdNetatmoGet(this.homeId).subscribe(
      (response) => {
        this.devicesResponse = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api.homeHomeIdNetatmoGet(this.homeId).subscribe(
            (response) => {
              this.devicesResponse = response;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }
}
