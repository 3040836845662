/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DatadisClassifiedMaxPowerList } from '../model/datadisClassifiedMaxPowerList';
import { ElectricConsumption } from '../model/electricConsumption';
import { ElectricConsumptionList } from '../model/electricConsumptionList';
import { ElectricContract } from '../model/electricContract';
import { ElectricSensorPower } from '../model/electricSensorPower';
import { ElectricSensorPowerList } from '../model/electricSensorPowerList';
import { HourWeekCalc } from '../model/hourWeekCalc';
import { Period } from '../model/period';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ElectricDeviceService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdDatadisMaxpowerLast12monthsGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<DatadisClassifiedMaxPowerList>;
    public electricDeviceDeviceIdDatadisMaxpowerLast12monthsGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DatadisClassifiedMaxPowerList>>;
    public electricDeviceDeviceIdDatadisMaxpowerLast12monthsGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DatadisClassifiedMaxPowerList>>;
    public electricDeviceDeviceIdDatadisMaxpowerLast12monthsGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdDatadisMaxpowerLast12monthsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DatadisClassifiedMaxPowerList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/datadis/maxpower/last12months`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyAbsoluteGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumption>;
    public electricDeviceDeviceIdEnergyAbsoluteGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumption>>;
    public electricDeviceDeviceIdEnergyAbsoluteGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumption>>;
    public electricDeviceDeviceIdEnergyAbsoluteGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyAbsoluteGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumption>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/absolute`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyAvgDayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumption>;
    public electricDeviceDeviceIdEnergyAvgDayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumption>>;
    public electricDeviceDeviceIdEnergyAvgDayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumption>>;
    public electricDeviceDeviceIdEnergyAvgDayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyAvgDayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumption>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/avg/day`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyAvgHourweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<HourWeekCalc>;
    public electricDeviceDeviceIdEnergyAvgHourweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HourWeekCalc>>;
    public electricDeviceDeviceIdEnergyAvgHourweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HourWeekCalc>>;
    public electricDeviceDeviceIdEnergyAvgHourweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyAvgHourweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HourWeekCalc>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/avg/hourweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyBydayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumptionList>;
    public electricDeviceDeviceIdEnergyBydayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyBydayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyBydayGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyBydayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumptionList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/byday`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyByhourGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumptionList>;
    public electricDeviceDeviceIdEnergyByhourGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyByhourGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyByhourGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyByhourGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumptionList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/byhour`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyBymonthGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumptionList>;
    public electricDeviceDeviceIdEnergyBymonthGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyBymonthGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyBymonthGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyBymonthGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumptionList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/bymonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyByweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumptionList>;
    public electricDeviceDeviceIdEnergyByweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyByweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyByweekGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyByweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumptionList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/byweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyByyearGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumptionList>;
    public electricDeviceDeviceIdEnergyByyearGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyByyearGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyByyearGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyByyearGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumptionList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/byyear`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyLast6monthGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumptionList>;
    public electricDeviceDeviceIdEnergyLast6monthGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyLast6monthGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyLast6monthGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyLast6monthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumptionList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/last6month`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyLastGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumption>;
    public electricDeviceDeviceIdEnergyLastGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumption>>;
    public electricDeviceDeviceIdEnergyLastGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumption>>;
    public electricDeviceDeviceIdEnergyLastGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyLastGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumption>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/last`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyThisMonthGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumptionList>;
    public electricDeviceDeviceIdEnergyThisMonthGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyThisMonthGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumptionList>>;
    public electricDeviceDeviceIdEnergyThisMonthGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyThisMonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumptionList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/thisMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdEnergyTodayGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<ElectricConsumption>;
    public electricDeviceDeviceIdEnergyTodayGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricConsumption>>;
    public electricDeviceDeviceIdEnergyTodayGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricConsumption>>;
    public electricDeviceDeviceIdEnergyTodayGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdEnergyTodayGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricConsumption>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/energy/today`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdPricesCurrentperiodGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<Period>;
    public electricDeviceDeviceIdPricesCurrentperiodGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Period>>;
    public electricDeviceDeviceIdPricesCurrentperiodGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Period>>;
    public electricDeviceDeviceIdPricesCurrentperiodGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdPricesCurrentperiodGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Period>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/prices/currentperiod`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdPricesGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ElectricContract>;
    public electricDeviceDeviceIdPricesGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricContract>>;
    public electricDeviceDeviceIdPricesGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricContract>>;
    public electricDeviceDeviceIdPricesGet(deviceId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdPricesGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricContract>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/prices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdSensorLastGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<ElectricSensorPower>;
    public electricDeviceDeviceIdSensorLastGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricSensorPower>>;
    public electricDeviceDeviceIdSensorLastGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricSensorPower>>;
    public electricDeviceDeviceIdSensorLastGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdSensorLastGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricSensorPower>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/sensor/last`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deviceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public electricDeviceDeviceIdSensorsLast15mGet(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<ElectricSensorPowerList>;
    public electricDeviceDeviceIdSensorsLast15mGet(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElectricSensorPowerList>>;
    public electricDeviceDeviceIdSensorsLast15mGet(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElectricSensorPowerList>>;
    public electricDeviceDeviceIdSensorsLast15mGet(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling electricDeviceDeviceIdSensorsLast15mGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElectricSensorPowerList>('get',`${this.basePath}/electric/device/${encodeURIComponent(String(deviceId))}/sensors/last15m`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
