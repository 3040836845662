<div class="body">
  <div class="main">
    <div class="up">
      <div class="home-devices extend column">
        <h2 class="title">Datos de mi vivienda</h2>
        <div class="content extend">
          <app-home-info [home]="home" class="extend"></app-home-info>
          <app-home-device-info
            class="extend"
            style="flex: 2"
          ></app-home-device-info>
        </div>
      </div>
      <div class="footprint extend column">
        <h2 class="title">Huella de carbono</h2>
        <div class="content extend">
          <app-home-carbon-footprint-summary
            class="extend"
            *ngIf="response && response.total"
            [response]="response.total"
            [loading]="loading"
            [error]="error"
          ></app-home-carbon-footprint-summary>
          <app-home-carbon-footprint-chart
            class="extend"
            style="flex: 2"
            *ngIf="response && response.total"
            [response]="response.total"
            [loading]="loading"
            [error]="error"
          ></app-home-carbon-footprint-chart>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="expenses extend column">
        <h2 class="title datepicker-widget">
          <div class="text">Histórico de gasto</div>
          <div class="datepicker">
            <app-widget-datepicker
              class="extend"
              (dateRangeChange)="changeDate($event)"
            ></app-widget-datepicker>
          </div>
        </h2>
        <div class="content extend">
          <app-electricity-consumption-chart
            class="extend"
            [type]="'total'"
            [homeId]="homeId"
            [dateRange]="dateRange"
          ></app-electricity-consumption-chart>
        </div>
      </div>
      <div class="home-devices extend column">
        <h2 class="title">Gasto total <b class="label">(exc. Imp)</b></h2>
        <div class="content extend">
          <app-home-expenses-info
            class="extend"
            *ngIf="response"
            [homeId]="homeId"
            [responseLast12Months]="response"
            [loading]="loading"
            [error]="error"
          ></app-home-expenses-info>
        </div>
      </div>
    </div>
  </div>
</div>
