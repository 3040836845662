/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HeatingScheduleAssistant { 
    dayWakeUpTimeHour?: number;
    dayWakeUpTimeMinute?: number;
    dayBedTimeHour?: number;
    dayBedTimeMinute?: number;
    dayAtWork?: boolean;
    dayWorkArrivalHour?: number;
    dayWorkArrivalMinute?: number;
    dayWorkDepartureHour?: number;
    dayWorkDepartureMinute?: number;
    dayWorkLunch?: boolean;
    dayWorkLunchArrivalHour?: number;
    dayWorkLunchArrivalMinute?: number;
    dayWorkLunchDepartureHour?: number;
    dayWorkLunchDepartureMinute?: number;
    weekendWakeupHour?: number;
    weekendWakeupMinute?: number;
    weekendBedHour?: number;
    weekendBedMinute?: number;
    saturdayDeparture?: boolean;
    saturdayDepartureHour?: number;
    saturdayDepartureMinute?: number;
    saturdayArrivalHour?: number;
    saturdayArrivalMinute?: number;
    sundayDeparture?: boolean;
    sundayDepartureHour?: number;
    sundayDepartureMinute?: number;
    sundayArrivalHour?: number;
    sundayArrivalMinute?: number;
    idealTemperature?: number;
}