import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { HourAndConsumption, HourAndConsumptionList } from 'src/app/api';

@Component({
  selector: 'app-home-carbon-footprint-summary',
  templateUrl: './carbon-footprint-summary.component.html',
  styleUrls: ['./carbon-footprint-summary.component.scss'],
})
export class CarbonFootprintSummaryComponent implements OnInit, OnChanges {
  @Input() type: any;
  data!: HourAndConsumption | undefined;
  @Input() response!: any;
  @Input() loading!: boolean;
  @Input() error: any;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (!this.loading) {
      this.data = this.response?.data![this.response.data!.length - 1];
    }
  }
}
