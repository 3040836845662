<div class="widget extend" *ngIf="!error">
  <div class="widget-content" *ngIf="!loading">
    <h5 class="header">Este mes <b class=""></b></h5>
    <div class="body">
      <h1 class="consumption">
        {{ responseData?.energy || 0 | number : "1.0-2" }} kWh
      </h1>
      <h5 class="cost">
        {{ responseData?.cost || 0 | number : "1.2-2" }} €
        <b class="label">(exc. Imp)</b>
      </h5>
    </div>
    <div class="chart" *ngIf="!loading">
      <div
        class="chart"
        echarts
        [options]="options"
        [loading]="loading"
        [merge]="options"
      ></div>
    </div>
  </div>
  <div class="widget-content" *ngIf="loading">
    <h5 class="header">Este mes</h5>
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>

<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
