<div class="popup">
  <h1 class="primary">Aviso de Cookies</h1>

  <h5 class="text">
    En {{ flavour === "hobeen" ? "Hobeen" : "Gestiona tu Energía" }} utilizamos
    cookies propias y de terceros para mejorar nuestros servicios y poder darle
    una experiencia más satisfactoria. Si continúa con la navegación, estará
    aceptando nuestra Politica de Cookies.
  </h5>

  <h4 class="primary link" (click)="openCookiesText()">Leer más</h4>
  <button
    class="button-secondary caption"
    (click)="acceptCookies()"
    [mat-dialog-close]="true"
  >
    Aceptar
  </button>
</div>
