import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Client, ClientService } from 'src/app/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PaletteGeneratorService } from 'src/app/core/services/palette-generator.service';
import { environment } from 'src/environments/environment';
import { CookieAcceptComponent } from './components/cookie-accept/cookie-accept.component';
import { CookieTextComponent } from './components/cookie-text/cookie-text.component';
import { LegalComponent } from './components/legal/legal.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  busy = false;
  username = '';
  password = '';
  loginError = false;
  mobile: any;
  buildType: any;
  isDev: any;
  cookies: any;
  versionInfo: any;
  private subscription!: Subscription;
  loading!: boolean;
  flavour: string = '';
  clientLogo: string = this.common.getClientLogo();
  clientData: Client | undefined;
  clientDataString: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    public dialog: MatDialog,
    public common: CommonService,
    private paletteGenerator: PaletteGeneratorService,
    private client: ClientService
  ) {
    this.flavour = environment.flavour;

    this.clientDataString = !!this.clientData
      ? JSON.stringify(this.clientData)
      : 'login';
  }

  ngOnInit(): void {
    this.common.getClientData();

    this.cookies = true;
    if (!localStorage.getItem('cookies')) {
      this.cookies = false;
      this.openCookies();
    }
    this.loading = false;
    if (localStorage.getItem('loggedUser')) {
      this.router.navigate(['/user/homes']);
    } else {
      if (window.screen.width < 600) {
        // 768px portrait
        this.mobile = true;
      }
      this.buildType = environment.buildType;
      this.isDev = environment.isDev;
      // Comprobar si tengo un usuario iniciado

      this.http
        .get(`${environment.apiUrl}/auth/me`, {
          withCredentials: true,
        })
        .subscribe((responseData) => {
          localStorage.setItem('loggedUser', JSON.stringify(responseData));
          this.router.navigate(['/user/homes']);
        });
      this.subscription = this.authService.user$.subscribe((x) => {
        if (this.route.snapshot.url[0].path === 'login') {
          const loggedUser = localStorage.getItem('loggedUser');

          if (x && loggedUser) {
            const returnUrl = this.route.snapshot.queryParams.returnUrl || '';
            this.router.navigate([returnUrl]);
          }
        } // optional touch-up: if a tab shows login page, then refresh the page to reduce duplicate login
      });
    }
  }

  openCookies(): void {
    const dialogRef = this.dialog.open(CookieAcceptComponent, {
      maxWidth: '900px',
      data: { cookies: this.cookies },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (localStorage.getItem('cookies')) {
        this.cookies = true;
      }
    });
  }

  openCookiesText(): void {
    const dialogRef = this.dialog.open(CookieTextComponent, {
      maxWidth: '900px',
    });
  }

  openLegalText(): void {
    const dialogRef = this.dialog.open(LegalComponent, {
      maxWidth: '900px',
    });
  }

  openPrivacyText(): void {
    const dialogRef = this.dialog.open(PrivacyComponent, {
      maxWidth: '900px',
    });
  }
  openRecovery(): void {
    const dialogRef = this.dialog.open(LoginFormComponent, {
      maxWidth: '900px',
    });
  }
  login(): void {
    this.loading = true;
    this.loginError = false;
    if (!this.username || !this.password) {
      this.loading = false;
      this.loginError = true;
      return;
    }
    this.busy = true;
    const returnUrl = this.route.snapshot.queryParams.returnUrl || '';
    this.authService
      .login(this.username, this.password)
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        (x: any) => {
          environment.clientData = undefined;
          localStorage.removeItem('client');

          this.common.getClientData();

          localStorage.setItem('loggedUser', JSON.stringify(x.user));
          localStorage.setItem('login-event', 'login' + Math.random());
          this.router.navigate([returnUrl]);
        },
        () => {
          this.loading = false;
          this.loginError = true;
        }
      );
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
