<div class="popup">
    <h1 class="primary">Reestablece tu Contraseña</h1>

    <h5 class="text" *ngIf="!text">
        Introduce tu email y te mandaremos un correo electrónico para restablecer tu contraseña
    </h5>
    <h5 class="text" *ngIf="text">
        Te hemos enviado un correo. ¿No te ha llegado? vuelve a intentarlo.
    </h5>
    <h5 class="text" *ngIf="error">El correo es incorrecto.</h5>
    <form class="form-signin" (ngSubmit)="sendPasswordReset()">
        <h2 class="login-label">Correo Electrónico</h2>
        <input type="text" [(ngModel)]="email" name="email" class="form-control label" placeholder="usuario@hobeen.es" required autofocus />
        <h5 class="text" *ngIf="error">El correo es incorrecto.</h5>
        <button type="submit" class="button-primary caption" *ngIf="!loading">
      Enviar
    </button>
        <button class="button-primary caption" *ngIf="loading">
      <mat-spinner
        diameter="20"
        style="padding-right: 24px; margin-left: 11px"
      ></mat-spinner>
    </button>
    </form>
</div>