import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HomeInfo } from 'src/app/api';

@Component({
  selector: 'app-heat-consumption',
  templateUrl: './heat-consumption.component.html',
  styleUrls: ['./heat-consumption.component.scss'],
})
export class HeatConsumptionComponent implements OnInit {
  @Input() homeId!: string;
  @Input() dateRange!: UntypedFormGroup;
  @Input() header: boolean;
  @Input() response!: HomeInfo;
  @Input() loading!: boolean;
  @Input() error!: number;
  constructor() {
    this.header = true;
  }

  ngOnInit(): void {}
}
