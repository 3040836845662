import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WeatherRoot, WeatherService } from 'src/app/api';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private api: WeatherService) {}

  @Input() homeId!: string;
  response!: WeatherRoot | undefined;
  loading!: boolean;
  error: any;
  today!: Date;
  subscription: any;

  ngOnInit(): void {
    this.today = new Date();
    this.loading = true;
    this.getData();
  }

  getData() {
    this.subscription = this.api.weatherHomeIdGet(this.homeId).subscribe(
      (response) => {
        this.response = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.subscription = this.api.weatherHomeIdGet(this.homeId).subscribe(
            (response) => {
              this.response = response;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.loading = true;
    }
  }
}
