<div class="menu">
    <a [routerLink]="'/'">
        <div class="menu-element">
            <div class="icon"><img src="assets/img/icons/enabled/home.svg" alt="Resumen"></div>

        </div>
    </a>
    <a [routerLink]="'/panel/consumption/'">
        <div class="menu-element">
            <div class="icon"><img src="assets/img/icons/enabled/consumption.svg" alt="Consumo"></div>

        </div>
    </a>
    <a [routerLink]="'/panel/realtime/'">
        <div class="menu-element">
            <div class="icon"><img src="assets/img/icons/enabled/realtime.svg" alt="Tiempo Real"></div>

        </div>
    </a>
    <a [routerLink]="'/panel/analysis/'">
        <div class="menu-element">
            <div class="icon"><img src="assets/img/icons/enabled/analysis.svg" alt="Análisis"></div>

        </div>
    </a>

    <a [routerLink]="'/panel/alerts/'">
        <div class="menu-element">
            <div class="icon"><img src="assets/img/icons/enabled/alert.svg" alt="Alarmas"></div>

        </div>
    </a>
</div>