import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AirControlService } from 'src/app/api';
import { Colors } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sensibo-control',
  templateUrl: './sensibo-control.component.html',
  styleUrls: ['./sensibo-control.component.scss'],
})
export class SensiboControlComponent implements OnInit, OnChanges {
  @Input() deviceInfo!: any;
  // @clara @kike PodInfoRoot.result.remoteCapabilities.modes.x no tiene low/medium/high, ver html. En el parent component
  // tb es PodInfoRoot
  options: any;
  @Input() deviceId!: string;
  temperature!: number;
  loading!: boolean;
  power!: boolean;
  constructor(public dialog: MatDialog, private api: AirControlService) {
    this.temperature = 20;
    this.loading = true;
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.deviceInfo) {
      this.temperature = this.deviceInfo.result!.acState!.targetTemperature!;
      if (this.deviceInfo.result!.acState!.on) {
        this.power = true;
      } else {
        this.power = false;
      }
      this.getOptions();
    }
  }
  temperatureUp() {
    if (
      this.temperature <
      this.deviceInfo.result.remoteCapabilities.modes.cool.temperatures.c.values.slice(
        -1
      )
    ) {
      this.temperature += 1;
      this.deviceInfo.result!.acState!.targetTemperature! += 1;
      this.updateAcState();
      this.getOptions();
    }
  }

  updateAcState() {
    setTimeout(() => {
      this.api
        .climaDeviceDeviceIdAirAcstatePost(this.deviceId, {
          acState: this.deviceInfo.result!.acState,
        })
        .subscribe((response) => console.log(response));
    }, 100);
  }
  temperatureDown() {
    if (
      this.temperature >
      this.deviceInfo.result.remoteCapabilities.modes.cool.temperatures.c
        .values[0]
    ) {
      this.temperature -= 1;
      this.deviceInfo.result!.acState!.targetTemperature! -= 1;
      this.updateAcState();
      this.getOptions();
    }
  }

  switchOnOff() {
    if (this.power) {
      this.power = false;
      this.deviceInfo.result!.acState!.on = false;
      this.updateAcState();
    } else {
      this.power = true;
      this.deviceInfo.result!.acState!.on = true;
      this.updateAcState();
    }
  }
  getOptions(): void {
    this.options = {
      grid: {
        bottom: '-15%',
        top: '0%',
        left: '-25%',
        right: '-25%',
      },

      series: [
        {
          type: 'gauge',
          center: ['50%', '50%'],
          startAngle: 240,
          endAngle: -60,
          min: 0,
          max: 40,
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 40,
            borderRadius: 10,
            offsetCenter: [0, '-15%'],
            fontSize: 38,
            fontWeight: 'bolder',
            formatter: '{value}°C',
            color: environment.textColor,
          },
          itemStyle: {
            color: Colors.primary,
            backgroundColor: '#eeeeee',
          },
          radius: '100%',
          progress: {
            show: true,
            width: 16,
            clip: true,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: true,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },

          data: [
            {
              value: this.temperature,
            },
          ],
        },
      ],
    };
    this.loading = false;
  }
}
