<div class="widget extend column content">
  <div class="temperature extend column">
    <h5 class="header">Temperatura actual</h5>
    <h1 class="value" *ngIf="true">
      {{ response?.meassuredTemp | number : "1.1-1" : "es" || "--" }}ºC
    </h1>
    <h2 class="time">{{ now | date : "HH:mm" | titlecase }}</h2>
  </div>
  <hr />
  <div class="device-temperature extend column">
    <h5 class="header">Temperatura programada</h5>

    <h2 class="value" *ngIf="response">
      {{ response.setpointTemp || 0 | number : "1.1-1" : "es" }}ºC
    </h2>

    <h2 class="value" *ngIf="!response">--ºC</h2>
  </div>
</div>
