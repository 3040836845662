<div class="widget extend" *ngIf="!error">
    <div class="widget-content" *ngIf="!loading">
        <h5 class="header">Hoy <b class=""></b></h5>
        <div class="body">
            <h2 class="cost primary">
                {{ response?.cost || 0 | number: "1.2-2" }} €
            </h2>
            <b class="label">(exc. Imp)</b>
            <div class="label bold time">
                {{ response?.hours || 0 | number: "1.0-2" }} horas
            </div>
        </div>
    </div>
    <div class="widget-content" *ngIf="loading">
        <h5 class="header">Hoy</h5>
        <div class="loading-spinner" *ngIf="loading"></div>
    </div>
</div>

<div class="widget error" *ngIf="error">
    <app-error [error]="error"></app-error>
</div>