import { HttpClient } from '@angular/common/http';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

import {
  ComposedConsumptionList,
  Home,
  HomeConsumptionService,
  ManagmentService,
} from 'src/app/api';

@Component({
  selector: 'app-home-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class HomeSummaryComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private api: HomeConsumptionService,
    private management: ManagmentService
  ) {
    this.error = 0;
  }

  homeId!: string;
  home!: Home;
  errorHome!: Home;
  response!: ComposedConsumptionList;
  error: any;
  loading!: boolean;
  dateRange!: FormGroup;
  ngOnInit(): void {
    this.loading = true;
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    environment.homeId = this.homeId;
    environment.currentRoute = this.route;
    this.getData();

    this.getHome();
    environment.current = 'Resumen de la vivienda';

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });
  }

  getData(): void {
    this.api.homeHomeIdConsumptionLast12monthGet(this.homeId).subscribe(
      (response) => {
        this.response = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api.homeHomeIdConsumptionLast12monthGet(this.homeId).subscribe(
            (response) => {
              this.response = response;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
  }
  changeDate(event: any): void {
    this.dateRange = event;
  }

  getHome(): void {
    let home;
    this.management.userHomeHomeIdGet(this.homeId).subscribe(
      (response) => {
        home = response;
        this.home = home;
      },
      (error) => {
        this.errorHome = error.status;
      }
    );

    this.http
      .get(environment.apiUrl + '/home/' + this.homeId, {
        withCredentials: true,
      })
      .subscribe((response) => {
        home = response;
        this.home = home;
      });
  }
}
