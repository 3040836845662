import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardModule } from 'src/app/modules/dashboard/dashboard.module';
import { HomeModule } from 'src/app/modules/home/home.module';
import { DeviceModule } from 'src/app/modules/device/device.module';

@NgModule({
  declarations: [
    DefaultComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    MatSidenavModule,
    MatGridListModule,
    SharedModule,
    DashboardModule,
    HomeModule,
    DeviceModule
  ]
})
export class DefaultModule { }
