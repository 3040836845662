import { Component, Input, OnInit } from '@angular/core';
import {
  AirDataService,
  ElectricHomeService,
  HeatingDataService,
} from 'src/app/api';
import { echartOptions } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-temperature-chart',
  templateUrl: './temperature-chart.component.html',
  styleUrls: ['./temperature-chart.component.scss'],
})
export class TemperatureChartComponent implements OnInit {
  @Input() homeId!: string;
  @Input() deviceId!: string;
  @Input() type!: string;

  options: any;
  loading!: boolean;
  reload: string | number | NodeJS.Timeout | undefined;
  lastData: any;
  online!: boolean;
  offline!: boolean;
  error: any;
  activePower: any = [];
  hiredPower: any = [];

  constructor(
    private electricHome: ElectricHomeService,
    private heatingApi: HeatingDataService,
    private coldApi: AirDataService
  ) {
    this.error = 0;
    this.loading = true;
  }

  ngOnInit(): void {
    this.online = true;
    this.offline = false;
    this.getData();
    this.reload = setInterval(() => this.getNewData(), environment.interval);
  }

  getData(): void {
    if (this.type === 'home') {
      this.heatingApi.climaHomeHomeIdHeatingTempGet(this.homeId).subscribe(
        (responseData) => this.adaptDataHeat(responseData),
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.electricHome
              .electricHomeHomeIdSensorsLast15mGet(this.homeId)
              .subscribe(
                (responseData) => this.adaptDataHeat(responseData),
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
    }
    if (this.type === 'device') {
      this.coldApi.climaDeviceDeviceIdAirTempGet(this.deviceId).subscribe(
        (responseData) => this.adaptData(responseData),
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.coldApi.climaDeviceDeviceIdAirTempGet(this.deviceId).subscribe(
              (responseData) => this.adaptData(responseData),
              (error) => {
                this.error = error.status;
                this.loading = false;
              }
            );
          }, 15000);
        }
      );
    }
  }

  getNewData(): void {
    if (this.type === 'home') {
    }
    if (this.type === 'device') {
    }
  }

  adaptDataHeat(data: any): void {
    console.log('dentro');
    for (const instance of data.data) {
      this.lastData = instance;
      instance.value = instance.value.toFixed(1);
      let element = {
        name: instance.utcTime,
        value: [new Date(instance.utcTime), instance.value],
      };

      this.activePower.push(element);
      if (
        new Date(instance.utcTime).getTime() >
        new Date().getTime() - 600000
      ) {
        this.online = true;
        this.offline = false;
      } else {
        this.offline = true;
        this.online = false;
      }
    }
    this.generateOptions();
  }
  adaptData(data: any): void {
    let decimales = 4;
    if (this.type === 'home') {
      decimales = 2;
    }
    for (const instance of data.data) {
      this.lastData = instance;

      instance.temperature = instance.temperature.toFixed(1);

      let element = {
        name: instance.utcTime,
        value: [new Date(instance.utcTime), instance.temperature],
      };
      this.activePower.push(element);

      if (
        new Date(instance.utcTime).getTime() >
        new Date().getTime() - 600000
      ) {
        this.online = true;
        this.offline = false;
      } else {
        this.offline = true;
        this.online = false;
      }
    }

    this.generateOptions();
  }

  generateOptions(): void {
    const options = {
      tooltip: {
        position: 'top',
        trigger: 'axis',
        formatter: function (params: any[]) {
          let contratada = '';

          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params[0].color +
            '"></span>  <a style="font-size: 1.25rem"> Temperatura: ' +
            ' <br> <a/>  <b style="font-size: 1.25rem">' +
            params[0].value[1].replace('.', ',') +
            'ºC  </b>  (a las ' +
            new Date(params[0].name).toLocaleTimeString() +
            ') ' +
            contratada
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        name: 'Hora',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        type: 'time',
        minInterval: 60 * 1000 * 10,
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
      },
      yAxis: {
        name: 'ºC',
        nameTextStyle: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        axisLabel: {
          fontFamily: environment.fontFamily,
          fontWeight: 400,
        },
        type: 'value',
        splitLine: {
          show: true,
        },
      },

      series: [
        {
          name: 'Temperatura',
          type: 'line',

          showSymbol: false,
          hoverAnimation: true,
          smooth: false,
          data: this.activePower,
          legend: {
            textStyle: {
              fontFamily: environment.fontFamily,
            },
            orient: 'horizontal',
            top: 'bottom',
          },
          itemStyle: {
            color: echartOptions.realtimeColor,
            borderWidth: 50,
          },
          lineStyle: {
            color: echartOptions.realtimeColor,
            width: 3,
          },
          symbolSize: 2,
        },
        ,
        {
          name: 'Potencia Contratada',
          type: 'line',

          showSymbol: false,
          hoverAnimation: false,
          smooth: false,
          data: this.hiredPower,

          itemStyle: {
            color: '#EB4747',
            borderWidth: 50,
          },
          lineStyle: {
            color: '#EB4747',
            width: 3,
          },
          symbolSize: 2,
        },
      ],
      grid: {
        top: '32px',
        left: '32px',
        bottom: '24px',
        right: '48px',
        containLabel: false,
      },
    };
    if (!this.options) {
      this.options = options;
    } else {
      this.options = options;
    }

    if (!!this.lastData) this.loading = false;
  }

  ngOnDestroy(): void {
    clearInterval(this.reload);
  }
}
