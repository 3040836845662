<div class="widget extend column" *ngIf="loading">
  <div class="content extend" style="width: 100%" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>
</div>

<div class="widget extend" *ngIf="!loading">
  <h4 class="text" *ngIf="schedules == undefined || schedules.length == 0">
    Actualmente no cuentas con ninguna programación establecida.
  </h4>
  <div class="content">
    <div class="schedule" *ngFor="let schedule of schedules">
      <div class="checkbox">
        <mat-checkbox
          class="checkbox-main"
          [(ngModel)]="schedule.isEnabled"
          (change)="changeSchedule(schedule)"
        >
        </mat-checkbox>
      </div>
      <div class="status">
        <img
          src="/assets/img/icons/temperature/schedule-on.svg"
          *ngIf="schedule.acState?.on"
          alt=""
        />
        <img
          src="/assets/img/icons/temperature/schedule-off.svg"
          *ngIf="!schedule.acState?.on"
          alt=""
        />
      </div>
      <div class="time">{{ schedule.targetTimeLocal }}</div>
      <ng-container *ngIf="!schedule.acState?.on">
        <div class="mode body-1 bold">-</div>
      </ng-container>
      <ng-container *ngIf="schedule.acState?.on">
        <div class="mode" *ngIf="schedule.acState?.mode == 'dry'">
          <img src="/assets/img/icons/temperature/water.svg" alt="" />
        </div>
        <div class="mode" *ngIf="schedule.acState?.mode == 'cool'">
          <img src="/assets/img/icons/temperature/cold.svg" alt="" />
        </div>
        <div class="mode" *ngIf="schedule.acState?.mode == 'heat'">
          <img src="/assets/img/icons/temperature/heat.svg" alt="" />
        </div>
        <div class="mode" *ngIf="schedule.acState?.mode == 'fan'">
          <img src="/assets/img/icons/temperature/fan.svg" alt="" />
        </div>
        <div class="mode" *ngIf="schedule.acState?.mode == 'auto'">
          <img src="/assets/img/icons/temperature/fan.svg" alt="" /></div
      ></ng-container>
      <ng-container *ngIf="!schedule.acState?.on">
        <div class="temp body-1 bold">-</div>
      </ng-container>
      <ng-container *ngIf="schedule.acState?.on">
        <div class="temp">
          {{ schedule.acState?.targetTemperature }}º
          {{ schedule.acState?.temperatureUnit }}
        </div></ng-container
      >

      <div class="days">
        <div
          class="body-1 bold"
          *ngIf="schedule.recurringDays?.includes('Monday')"
        >
          L
        </div>
        <div
          class="body-1 bold"
          *ngIf="schedule.recurringDays?.includes('Tuesday')"
        >
          M
        </div>
        <div
          class="body-1 bold"
          *ngIf="schedule.recurringDays?.includes('Wednesday')"
        >
          X
        </div>
        <div
          class="body-1 bold"
          *ngIf="schedule.recurringDays?.includes('Thursday')"
        >
          J
        </div>
        <div
          class="body-1 bold"
          *ngIf="schedule.recurringDays?.includes('Friday')"
        >
          V
        </div>
        <div
          class="body-1 bold"
          *ngIf="schedule.recurringDays?.includes('Saturday')"
        >
          S
        </div>
        <div
          class="body-1 bold"
          *ngIf="schedule.recurringDays?.includes('Sunday')"
        >
          D
        </div>
      </div>
      <div class="current body-1 bold">-</div>
      <div class="space" style="flex: 2; border-right: white"></div>
      <div class="edit" style="border-right: white">
        <img
          src="/assets/img/icons/temperature/schedule-edit.svg"
          (click)="openDialog(schedule)"
          alt=""
        />
      </div>
      <div class="delete" (click)="deleteSchedule(schedule)">
        <img src="/assets/img/icons/temperature/schedule-delete.svg" alt="" />
      </div>
    </div>
  </div>
</div>
