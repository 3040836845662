import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Client,
  DeviceTypesCount,
  ElectricHomeService,
  ManagmentService,
} from 'src/app/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private router: Router,
    private api: ManagmentService,
    private electricHomeApi: ElectricHomeService,
    public common: CommonService
  ) {}
  //tokenData;
  clientData: Client | undefined;
  backgroundColor!: string;
  homeId!: string;
  devices!: DeviceTypesCount | boolean;
  devicesFlag!: boolean;
  electric!: boolean;
  water!: boolean;
  temperature!: boolean;
  environment = environment;

  ngOnInit(): void {
    this.electric = false;
    this.water = false;
    this.temperature = false;
    this.backgroundColor = environment.actualView;
    this.clientData = this.common.getClientData();
    this.isHome();
  }

  getData(): void {
    this.electricHomeApi
      .electricHomeHomeIdGet(this.homeId)
      .subscribe((response) => (this.electric = response));
    this.api.homeHomeIdDevicetypesGet(this.homeId).subscribe((response) => {
      this.devices = response;
      if (this.devices.sensibo && this.devices.sensibo > 0) {
        this.temperature = true;
      } else if (this.devices.netatmo && this.devices.netatmo > 0) {
        this.temperature = true;
      } else {
        this.temperature = false;
      }
      if (this.devices.hidroconta && this.devices.hidroconta > 0) {
        this.water = true;
      } else {
        this.water = false;
      }
    });
  }

  obtenerRuta(): string {
    return this.router.url;
  }
  isHome(): boolean {
    const url = this.obtenerRuta().split('/');
    if (url[1] === 'home') {
      if (!this.devices) {
        this.devices = true;
        this.homeId = url[2];
        this.getData();
      }
      if (this.homeId !== url[2]) {
        this.homeId = url[2];
        this.devices = false;
      }

      return true;
    }
    this.devices = false;
    this.electric = false;
    this.water = false;
    this.temperature = false;
    return false;
  }
}
