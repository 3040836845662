import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { HomeInfo } from 'src/app/api';

@Component({
  selector: 'app-netatmo-temperature',
  templateUrl: './netatmo-temperature.component.html',
  styleUrls: ['./netatmo-temperature.component.scss'],
})
export class NetatmoTemperatureComponent implements OnChanges {
  @Input() homeId!: string;
  @Input() loading!: boolean;
  @Input() error!: number;
  @Input() response!: HomeInfo | undefined;
  public now: Date = new Date();

  constructor() {
    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  ngOnChanges(): void {
    if (this.response != undefined) {
      this.loading = false;
    }
  }
}
