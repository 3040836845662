import { Component, OnInit } from '@angular/core';
import { LegalText, ManagmentService } from 'src/app/api';

@Component({
  selector: 'app-cookie-text',
  templateUrl: './cookie-text.component.html',
  styleUrls: ['./cookie-text.component.scss'],
})
export class CookieTextComponent implements OnInit {
  constructor(private api: ManagmentService) {}
  text!: LegalText | string;
  loading!: boolean;
  ngOnInit(): void {
    this.loading = true;
    this.text = 'Cargando...';
    this.api.legaltextNameGet('politica_de_cookies').subscribe((response) => {
      let text: any = response;
      this.loading = false;
      this.text = text.text;
    });
  }
}
