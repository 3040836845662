/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HeatingScheduleV2Away } from './heatingScheduleV2Away';

export interface HeatingScheduleAssistantV2 { 
    dayWakeUpTimeHour?: number;
    dayWakeUpTimeMinute?: number;
    dayBedTimeHour?: number;
    dayBedTimeMinute?: number;
    dayAway?: boolean;
    aways?: Array<HeatingScheduleV2Away>;
    weekendDifferentBehaviour?: boolean;
    dayWakeUpTimeHourSaturday?: number;
    dayWakeUpTimeMinuteSaturday?: number;
    dayBedTimeHourSaturday?: number;
    dayBedTimeMinuteSaturday?: number;
    dayWakeUpTimeHourSunday?: number;
    dayWakeUpTimeMinuteSunday?: number;
    dayBedTimeHourSunday?: number;
    dayBedTimeMinuteSunday?: number;
    dayTemperature?: number;
    nightTemperature?: number;
}