<div class="widget">
  <div class="header">
    <h2 class="primary">
      <div class="logo">
        <img src="/assets/img/entendiendo-logo.svg" alt="" />
      </div>
      <div class="title">
        <div class="text">El mapa de calor</div>
      </div>
    </h2>
  </div>
  <div class="body">
    <div class="text caption content">
      <div class="section">
        <div class="text half">
          El mapa de calor nos permite saber cuál ha sido el consumo medio por
          horas durante los días de la semana. En cada cuadro donde te
          posiciones, podrás conocer los datos específicos de cada día a la hora
          elegida.
          <div class="important">
            <div class="bold">
              <div class="box green"></div>
              Los colores verdes indican que tu consumo es bajo
            </div>
            <b class="bold">
              <div class="box red"></div>
              Los colores rojos indican que tu consumo es alto</b
            >
          </div>
        </div>
        <div class="image half">
          <img src="/assets/img/heatmap-2.svg" alt="" />
        </div>
      </div>
      <div class="section">
        Con esta barra podrás limitar el consumo medio en un rango
        personalizado. Esto sirve para que puedas ver, únicamente el consumo
        dentro de los límites elegidos.
      </div>
      <div class="section"><img src="/assets/img/heatmap-1.svg" alt="" /></div>
      <div class="section">
        Por ejemplo: Si quieres ver a qué horas gastas más, puedes mover el
        límite inferior para que sólo se vean las horas de mayor consumo. Si
        pones un límite por abajo y otro por arriba sólo se mostrarán los
        consumos que estén DENTRO de ese rango.
      </div>
    </div>
  </div>
  <div class="buttons">
    <button
      mat-button
      mat-dialog-close
      class="button-secondary caption"
      (click)="closeDialog()"
    >
      Volver
    </button>
  </div>
</div>
