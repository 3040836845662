/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AvgHomeConsume } from './avgHomeConsume';

export interface WaterConsumption { 
    id?: string;
    utcStartTime?: Date;
    utcEndTime?: Date;
    homeStartTime?: Date;
    homeEndTime?: Date;
    liters?: number;
    m3?: number;
    cost?: number;
    avgHomeConsume?: AvgHomeConsume;
}