import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiruboxComponent } from './pages/mirubox/mirubox.component';
import { RealtimeChartComponent } from './components/electricity/realtime-chart/realtime-chart.component';
import { ConsumptionByPeriodComponent } from './components/electricity/consumption-by-period/consumption-by-period.component';
import { HeatmapComponent } from './components/electricity/heatmap/heatmap.component';
import { RouterModule } from '@angular/router';
import { WibeeeComponent } from './pages/wibeee/wibeee.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { APIModule } from 'src/app/legacy-api';
import { DashboardModule } from '../dashboard/dashboard.module';
import { HomeModule } from '../home/home.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { HeatmapHelpComponent } from './components/electricity/heatmap-help/heatmap-help.component';
import { SensiboComponent } from './pages/sensibo/sensibo.component';
import { NetatmoComponent } from './pages/netatmo/netatmo.component';
import { RoomClimateComponent } from './components/temperature/room-climate/room-climate.component';
import { TemperatureChartComponent } from './components/temperature/temperature-chart/temperature-chart.component';
import { SensiboControlComponent } from './components/temperature/sensibo-control/sensibo-control.component';
import { SensiboScheduleComponent } from './components/temperature/sensibo-schedule/sensibo-schedule.component';
import { MatSliderModule } from '@angular/material/slider';
import { NetatmoControllerComponent } from './components/netatmo/netatmo-controller/netatmo-controller.component';
import { NetatmoTemperatureComponent } from './components/netatmo/netatmo-temperature/netatmo-temperature.component';
import { NetatmoManualControllerComponent } from './components/netatmo/netatmo-manual-controller/netatmo-manual-controller.component';
import { NetatmoOutsideControllerComponent } from './components/netatmo/netatmo-outside-controller/netatmo-outside-controller.component';
import { ProgrammingWizardComponent } from './components/temperature/programming-wizard/programming-wizard.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NewProgrammingWizardComponent } from './components/temperature/new-programming-wizard/new-programming-wizard.component';
import { NetatmoProgrammerComponent } from './components/netatmo/netatmo-programmer/netatmo-programmer.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NetatmoScheduleViewComponent } from './components/netatmo/netatmo-schedule-view/netatmo-schedule-view.component';
import { NetatmoDaySchedulerComponent } from './components/netatmo/netatmo-day-scheduler/netatmo-day-scheduler.component';
import { NetatmoAssistantComponent } from './components/netatmo/netatmo-assistant/netatmo-assistant.component';
import { NetatmoTimetableEditorComponent } from './components/netatmo/netatmo-timetable-editor/netatmo-timetable-editor.component';
import { MatSelectModule } from '@angular/material/select';
import { CounterRealtimeChartComponent } from './components/electricity/counter-realtime-chart/counter-realtime-chart.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

@NgModule({
  declarations: [
    MiruboxComponent,
    RealtimeChartComponent,
    ConsumptionByPeriodComponent,
    HeatmapComponent,
    WibeeeComponent,
    HeatmapHelpComponent,
    SensiboComponent,
    NetatmoComponent,
    RoomClimateComponent,
    TemperatureChartComponent,
    SensiboControlComponent,
    SensiboScheduleComponent,

    NetatmoControllerComponent,
    NetatmoTemperatureComponent,
    NetatmoManualControllerComponent,
    NetatmoOutsideControllerComponent,
    ProgrammingWizardComponent,
    NewProgrammingWizardComponent,
    NetatmoProgrammerComponent,
    NetatmoScheduleViewComponent,
    NetatmoDaySchedulerComponent,
    NetatmoAssistantComponent,
    NetatmoTimetableEditorComponent,
    CounterRealtimeChartComponent,
  ],
  imports: [
    HomeModule,
    APIModule,
    CommonModule,
    RouterModule,
    DashboardModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSelectModule,
    MatSliderModule,
    NgxMatTimepickerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [],
})
export class DeviceModule {}
