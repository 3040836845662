<div class="widget extend" style="min-width: 50%">
  <h5 class="header">Contador {{ header }}</h5>
  <div
    class="content"
    style="
      display: flex;
      align-items: center;

      justify-content: left;
    "
    *ngIf="!loading"
  >
    <div class="devices {{ type }}" style="width: 60%" *ngIf="!loading">
      <div class="content">
        <div class="label">Consumido hoy</div>
        <h4
          class="value"
          [title]="
            ((response?.today)!.m3! * 1000).toFixed(2).replace('.', ',') +
            ' litros'
          "
        >
          {{ (response?.today)!.m3 || 0 | number : "1.2-2" : "es" }} m3
        </h4>
        <div class="label">
          Actualizado:
          <div class="date">
            {{
              (response?.today)!.homeEndTime | date : "d/M/yy 'a las' HH:mm"
            }}h
          </div>
        </div>
      </div>
    </div>
    <div class="comparison body-1" *ngIf="!loading">
      <div class="arrow" *ngIf="response?.differentialPercentage! <= 0">
        <img src="/assets/img/down_good.svg" alt="" />
      </div>
      <div class="arrow" *ngIf="response?.differentialPercentage! > 0">
        <img src="/assets/img/up_bad.svg" alt="" />
      </div>

      {{
        response?.differentialPercentage! <= 0
          ? (-response?.differentialPercentage! | number : "1.2-2" : "es") +
            "% menos"
          : (response?.differentialPercentage | number : "1.2-2" : "es") +
            "% más"
      }}
      que ayer a la misma hora
    </div>
  </div>
  <div class="content extend load">
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>
<div class="widget extend" style="margin: 0px 16px">
  <h5 class="header" *ngIf="!loading">Consumo medio de agua al día</h5>
  <h3
    class="value value-not-today"
    *ngIf="!loading"
    [title]="(response3?.today.m3).toFixed(2).replace('.', ',') + ' litros'"
  >
    {{
      response2?.thisMonth.m3 / ahora.getDay() || 0 | number : "1.2-2" : "es"
    }}
    m3
  </h3>
  <h5 class="header" *ngIf="!loading">Coste medio de agua al día</h5>
  <h3 class="value value-not-today" *ngIf="!loading">-- €</h3>
  <div class="comparison body-1" *ngIf="!loading">
    <div class="arrow" *ngIf="response2.thisMonthVsLastMonth < 0">
      <img src="/assets/img/down_good.svg" alt="" />
    </div>
    <div class="arrow" *ngIf="response2.thisMonthVsLastMonth > 0">
      <img src="/assets/img/up_bad.svg" alt="" />
    </div>

    {{
      response2.thisMonthVsLastMonth < 0
        ? (-response2.thisMonthVsLastMonth | number : "1.2-2" : "es") + "% más"
        : (response2.thisMonthVsLastMonth | number : "1.2-2" : "es") + "% menos"
    }}
    de media al día
  </div>
  <div class="content extend load">
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>
<div class="widget extend">
  <h5 class="header" *ngIf="!loading">Consumo acumulado este mes</h5>
  <h3
    class="value value-not-today"
    [title]="
      (response2?.thisMonth.m3 * 1000).toFixed(2).replace('.', ',') + ' litros'
    "
    *ngIf="!loading"
  >
    {{ response2?.thisMonth.m3 || 0 | number : "1.2-2" : "es" }} m3
  </h3>
  <h5 class="header" *ngIf="!loading">Coste acumulado este mes</h5>
  <h3 class="value value-not-today" *ngIf="!loading">-- €</h3>
  <div class="comparison body-1" *ngIf="!loading">
    <div class="arrow" *ngIf="response2.thisMonthVsLastMonth < 0">
      <img src="/assets/img/down_good.svg" alt="" />
    </div>
    <div class="arrow" *ngIf="response2.thisMonthVsLastMonth > 0">
      <img src="/assets/img/up_bad.svg" alt="" />
    </div>

    {{
      response2.thisMonthVsLastMonth < 0
        ? (-response2.thisMonthVsLastMonth | number : "1.2-2" : "es") +
          "% menos"
        : (response2.thisMonthVsLastMonth | number : "1.2-2" : "es") + "% más"
    }}
    este mes que el mes pasado
  </div>
  <div class="content extend load">
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</div>
