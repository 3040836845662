import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import { SubscribableTitleService } from 'src/app/core/services/suscribable-title.service';
import { Colors } from 'src/assets/colors';
import { environment } from 'src/environments/environment';
import { ApiModule, BASE_PATH, Configuration } from './api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomDateAdapter } from './core/adapters/custom-date-adapter';
import { DefaultModule } from './layouts/default/default.module';
import { LoginModule } from './layouts/login/login.module';

function configurationFactory() {
  const configuration = new Configuration({
    basePath: environment.apiUrl,
    withCredentials: true,
  });
  return configuration;
}
registerLocaleData(localeEs, 'es');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,

    MaterialCssVarsModule.forRoot({
      // all optional
      isAutoContrast: true,
      primary: Colors.primary,
      accent: Colors.secondary,
      // ...
    }),
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    DefaultModule,
    HttpClientModule,
    MatNativeDateModule,
    LoginModule,
    ApiModule.forRoot(configurationFactory),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: BASE_PATH, useValue: environment.apiUrl },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    Title,
    SubscribableTitleService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
