import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ElectricConsumption, ElectricHomeService } from 'src/app/api';
import { echartOptions } from 'src/assets/colors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-electricity-average',
  templateUrl: './average.component.html',
  styleUrls: ['./average.component.scss'],
})
export class AverageComponent implements OnInit {
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup;
  data: any = [];
  names: any = [];
  options: any;
  loading!: boolean;
  response!: ElectricConsumption | undefined;
  error: any;

  constructor(private homeApi: ElectricHomeService) {
    this.error = false;
  }

  ngOnInit(): void {
    this.loading = true;
    this.getData();
  }

  getData(): void {
    this.homeApi
      .electricHomeHomeIdEnergyThismonthAvgDayGet(this.homeId)
      .subscribe(
        (response) => this.adaptData(response),
        (error) => {
          this.error = error.status;
          this.loading = false;
          setTimeout(() => {
            this.error = null;
            this.loading = true;
            this.homeApi
              .electricHomeHomeIdEnergyAvgDayGet(
                this.homeId,
                this.dateRange.value.start,
                new Date(
                  this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                )
              )
              .subscribe(
                (response) => this.adaptData(response),
                (error) => {
                  this.error = error.status;
                  this.loading = false;
                }
              );
          }, 15000);
        }
      );
  }

  adaptData(response: any): void {
    const colors = echartOptions.colorShort;
    this.response = response;
    this.names = [];
    let index = 0;

    for (const periodo of response.periodEnergy) {
      if (periodo !== 0) {
        this.data.push({
          value: parseFloat('' + periodo).toFixed(2),
          name: 'P' + (index + 1),
          itemStyle: {
            color: colors[index],
          },
        });
        this.names.push('P' + (index + 1));
      }
      index++;
    }

    this.setOptions();
  }
  setOptions(): void {
    let max = 0;
    for (const valor of this.data) {
      if (valor.value > max) {
        max = valor.value;
      }
    }
    const options = {
      tooltip: {
        position: 'top',
        formatter: function (params: any) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params.color +
            '"></span>  <a style="font-size: 1rem"> Consumo en ' +
            params.name +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params.value.replace('.', ',') +
            'kWh' +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        type: 'category',
        name: 'Periodo',
        data: this.names,
        axisLabel: {
          fontFamily: environment.fontFamily,
        },

        splitLine: {
          show: false,
        },
      },

      yAxis: {
        type: 'value',
        show: false,
        name: '€',
      },
      series: [
        {
          name: 'Consumo por periodo',
          type: 'bar',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          data: this.data,
          label: {
            fontFamily: environment.fontFamily,
            show: true,
            formatter(value: any): any {
              if (value.value < max / 8) {
                return '';
              }
              return ''; //value.value + '€';
            },
            position: 'insideTop',
          },

          barGap: '4px',
          barWidth: '99%',
        },
      ],

      grid: {
        top: '4px',
        left: 0,
        right: 0,
        bottom: '20px',
      },
    };

    this.options = options;
    this.loading = false;
  }
}
