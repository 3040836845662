import { HttpClient } from '@angular/common/http';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global.service';
import { Home } from 'src/app/api';
//import { Home } from '../../models/home.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  loggedUser: any;

  homeId!: string;
  selectedHome!: Home | null;
  environment: any;
  observer: any;
  requested: boolean = false;

  homesArray: Array<Home>;
  obtenerPagina(): any {
    return environment.current;
  }

  obtenerUsuario(): any {
    this.loggedUser = JSON.parse(localStorage.getItem('loggedUser')!);
    console.log(this.loggedUser);
    return JSON.parse(localStorage.getItem('loggedUser')!);
  }

  constructor(
    private http: HttpClient,
    private global: GlobalService,
    private router: Router
  ) {
    this.homesArray = new Array<Home>();
    this.observer = router.events.subscribe(() => {
      setTimeout(() => {
        this.getHomes();
      }, 50);
    });
  }

  ngOnInit(): void {
    this.homesArray = new Array<Home>();
    this.environment = environment;
    this.obtenerUsuario();
    setTimeout(() => {
      this.getHomes();
    }, 1);
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  getCurrentHome(): void {
    this.selectedHome = null;
    for (const home of this.homesArray) {
      if (home.id === environment.homeId) {
        this.selectedHome = home;
      }
    }
  }

  navigateNewHome(): void {
    if (environment.homeId === '') {
      this.router.navigate(['home', this.selectedHome!.id, 'summary']);
    } else {
      let route = new ActivatedRoute();
      route = environment.currentRoute!;
      const newUrl = this.router.url.replace(
        route.snapshot.paramMap.get('homeId')!,
        this.selectedHome!.id!
      );
      this.router.navigateByUrl(newUrl).then(() => {
        window.location.reload();
      });
    }
  }
  getHomes(): void {
    let homes: any;
    if (this.homesArray) {
      if (!this.homesArray.length) {
        try {
          this.homesArray = JSON.parse(localStorage.getItem('userHomes')!);
          this.getCurrentHome();
        } catch {}
      }

      if (!this.homesArray.length) {
        if (!this.requested) {
          this.requested = true;
          this.http
            .get(environment.apiUrl + '/user/homes', {
              withCredentials: true,
            })
            .subscribe((response) => {
              homes = response;
              this.homesArray = homes.data;
              this.global.userHomes = homes.data;
              this.getCurrentHome();
            });
        }
      }
      if (this.homesArray.length) {
        this.getCurrentHome();
      }
    }
  }
}
