import { Component, Input, OnInit } from '@angular/core';
import { ComparedWaterConsumption, WaterConsumptionList } from 'src/app/api';

@Component({
  selector: 'app-water-comparison',
  templateUrl: './water-comparison.component.html',
  styleUrls: ['./water-comparison.component.scss'],
})
export class WaterComparisonComponent implements OnInit {
  @Input() response!: ComparedWaterConsumption | undefined;

  @Input() loading!: boolean;

  @Input() header!: string;
  @Input() error: any;

  constructor() {}

  ngOnInit(): void {}
}
