/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ComposedConsumptionList } from '../model/composedConsumptionList';
import { ConsumptionList } from '../model/consumptionList';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HomeConsumptionService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ComposedConsumptionList>;
    public homeHomeIdConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComposedConsumptionList>>;
    public homeHomeIdConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComposedConsumptionList>>;
    public homeHomeIdConsumptionAvgDayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionAvgDayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ComposedConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/avg/day`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionBydayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ConsumptionList>;
    public homeHomeIdConsumptionBydayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConsumptionList>>;
    public homeHomeIdConsumptionBydayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConsumptionList>>;
    public homeHomeIdConsumptionBydayGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionBydayGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/byday`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionByhourGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ConsumptionList>;
    public homeHomeIdConsumptionByhourGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConsumptionList>>;
    public homeHomeIdConsumptionByhourGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConsumptionList>>;
    public homeHomeIdConsumptionByhourGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionByhourGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/byhour`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionBymonthGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ConsumptionList>;
    public homeHomeIdConsumptionBymonthGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConsumptionList>>;
    public homeHomeIdConsumptionBymonthGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConsumptionList>>;
    public homeHomeIdConsumptionBymonthGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionBymonthGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/bymonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionByweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ConsumptionList>;
    public homeHomeIdConsumptionByweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConsumptionList>>;
    public homeHomeIdConsumptionByweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConsumptionList>>;
    public homeHomeIdConsumptionByweekGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionByweekGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/byweek`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param utcStartTime 
     * @param utcEndTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionByyearGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<ConsumptionList>;
    public homeHomeIdConsumptionByyearGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConsumptionList>>;
    public homeHomeIdConsumptionByyearGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConsumptionList>>;
    public homeHomeIdConsumptionByyearGet(homeId: string, utcStartTime?: Date, utcEndTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionByyearGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (utcStartTime !== undefined && utcStartTime !== null) {
            queryParameters = queryParameters.set('utcStartTime', <any>utcStartTime.toISOString());
        }
        if (utcEndTime !== undefined && utcEndTime !== null) {
            queryParameters = queryParameters.set('utcEndTime', <any>utcEndTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/byyear`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionLast12monthGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<ComposedConsumptionList>;
    public homeHomeIdConsumptionLast12monthGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComposedConsumptionList>>;
    public homeHomeIdConsumptionLast12monthGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComposedConsumptionList>>;
    public homeHomeIdConsumptionLast12monthGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionLast12monthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ComposedConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/last12month`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionThisandlastmonthGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<ComposedConsumptionList>;
    public homeHomeIdConsumptionThisandlastmonthGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComposedConsumptionList>>;
    public homeHomeIdConsumptionThisandlastmonthGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComposedConsumptionList>>;
    public homeHomeIdConsumptionThisandlastmonthGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionThisandlastmonthGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ComposedConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/thisandlastmonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionThismonthAvgDayGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<ComposedConsumptionList>;
    public homeHomeIdConsumptionThismonthAvgDayGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComposedConsumptionList>>;
    public homeHomeIdConsumptionThismonthAvgDayGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComposedConsumptionList>>;
    public homeHomeIdConsumptionThismonthAvgDayGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionThismonthAvgDayGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ComposedConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/thismonth/avg/day`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param homeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeHomeIdConsumptionTodayGet(homeId: string, observe?: 'body', reportProgress?: boolean): Observable<ComposedConsumptionList>;
    public homeHomeIdConsumptionTodayGet(homeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComposedConsumptionList>>;
    public homeHomeIdConsumptionTodayGet(homeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComposedConsumptionList>>;
    public homeHomeIdConsumptionTodayGet(homeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (homeId === null || homeId === undefined) {
            throw new Error('Required parameter homeId was null or undefined when calling homeHomeIdConsumptionTodayGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (cookieAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["connect.sid"]) {
            queryParameters = queryParameters.set('connect.sid', this.configuration.apiKeys["connect.sid"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ComposedConsumptionList>('get',`${this.basePath}/home/${encodeURIComponent(String(homeId))}/consumption/today`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
