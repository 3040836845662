import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AirDataService, HourAndConsumption } from 'src/app/api';

@Component({
  selector: 'app-cold-accumulated',
  templateUrl: './cold-accumulated.component.html',
  styleUrls: ['./cold-accumulated.component.scss'],
})
export class ColdAccumulatedComponent implements OnInit {
  @Input() homeId!: string;
  @Input() deviceId!: string;
  @Input() dateRange!: FormGroup;
  @Input() response!: HourAndConsumption | undefined;
  @Input() loading!: boolean;
  @Input() error: any;
  @Input() type: any;
  data: any = [];
  names: any = [];
  options: any;

  constructor(private homeApi: AirDataService) {
    this.error = 0;
  }
  ngOnInit(): void {
    this.loading = true;
    this.getData();
  }

  getData(): void {
    if (this.type == 'home') {
      this.homeApi
        .climaHomeHomeIdAirConsumptionThisMonthGet(this.homeId)
        .subscribe(
          (response) => {
            this.response = response;
            this.loading = false;
          },
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.homeApi
                .climaHomeHomeIdAirConsumptionThisMonthGet(this.homeId)
                .subscribe(
                  (response) => {
                    this.response = response;
                    this.loading = false;
                  },
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    } else if (this.type == 'device') {
      this.homeApi
        .climaDeviceDeviceIdAirConsumptionThisMonthGet(this.deviceId)
        .subscribe(
          (response) => {
            this.response = response;
            this.loading = false;
          },
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.homeApi
                .climaDeviceDeviceIdAirConsumptionThisMonthGet(this.deviceId)
                .subscribe(
                  (response) => {
                    this.response = response;
                    this.loading = false;
                  },
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    }
  }
}
