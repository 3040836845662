import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, UserFromAuthCamel, UserInfoCamel } from 'src/app/api';
import { UserSettingsComponent } from '../user-settings/user-settings.component';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  constructor(public dialog: MatDialog, private api: AuthService) {}
  user!: UserFromAuthCamel;
  error: any;
  loading!: boolean;
  ngOnInit(): void {
    this.loading = true;
    this.getUserInfo();
  }

  openDialog(): void {
    this.dialog
      .open(UserSettingsComponent, {
        data: this.user,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getUserInfo();
        }
      });
  }

  getUserInfo(): void {
    this.api.authMeGet().subscribe(
      (response) => {
        let responseData: UserInfoCamel = response;
        this.user = responseData.user!;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.error = error.status;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.api.authMeGet().subscribe(
            (response) => {
              let responseData: any = response;
              this.user = responseData.user;
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              this.error = error.status;
            }
          );
        }, 15000);
      }
    );
  }
}
