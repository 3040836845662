/**
 * Hobeen-Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2.0.0
 * Contact: eromero@eficaesoluciones.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Device { 
    id?: string;
    api?: string;
    idDevice?: string;
    userId?: string;
    productModel?: string;
    name?: string;
    apiHome?: string;
    apiRoom?: string;
    roomId?: string;
    lastRecordId?: string;
    apiBridge?: string;
    modulesBridged?: Array<string>;
    savingMode?: string;
    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;
    visible?: boolean;
    peopleShower?: number;
    warmWater?: string;
}