import { Injectable } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';
import { HexColor } from '../models/hex-color.model';

@Injectable({
  providedIn: 'root',
})
export class PaletteGeneratorService {
  constructor() {}

  public generatePaletteFromHex(hex: string): HexColor[] {
    console.log(this.generatePalette(new TinyColor(hex)));
    return this.generatePalette(new TinyColor(hex));
  }

  private generatePalette(color: TinyColor): HexColor[] {
    return [
      this.toHexColor('50', color.lighten(52)),
      this.toHexColor('100', color.lighten(37)),
      this.toHexColor('200', color.lighten(26)),
      this.toHexColor('300', color.lighten(12)),
      this.toHexColor('400', color.lighten(6)),
      this.toHexColor('500', color),
      this.toHexColor('600', color.darken(6)),
      this.toHexColor('700', color.darken(12)),
      this.toHexColor('800', color.darken(18)),
      this.toHexColor('900', color.darken(24)),
      this.toHexColor('A100', color.lighten(50).saturate(30)),
      this.toHexColor('A200', color.lighten(30).saturate(30)),
      this.toHexColor('A400', color.lighten(10).saturate(15)),
      this.toHexColor('A700', color.lighten(5).saturate(5)),
    ];
  }

  private toHexColor(name: string, color: TinyColor): HexColor {
    return {
      Name: name,
      Color: color.toHexString(),
      ContrastColor: color.isLight() ? '#000000' : '#ffffff',
    };
  }

  public toCssVarsAux(
    palette: HexColor[],
    prefix = '',
    contrastSuffix = 'ct',
    root = ':root'
  ): void {
    for (const color of palette) {
      document.body.style.setProperty(`--${prefix}${color.Name}`, color.Color);

      if (color.Name == '500') {
        document.body.style.setProperty(
          prefix.includes('primary') ? '--primary' : '--secondary',
          color.Color
        );
        console.log(
          prefix.includes('primary') ? '--primary' : '--secondary',
          color.Color
        );
      }

      console.log(`--${prefix}${color.Name}`, color.Color);
    }
  }
  public toCssVars(
    palette: HexColor[],
    prefix = '',
    contrastSuffix = '',
    root = ':root'
  ): string {
    let css = `${root} {\n`;
    for (const color of palette) {
      css += `  --${prefix}${color.Name}: ${color.Color},\n`;
    }
    for (const color of palette) {
      css += `  --${prefix}${color.Name}: ${color.ContrastColor},\n`;
    }
    css += '}';

    //  css += `  --${prefix}${color.Name}${contrastSuffix}: ${color.ContrastColor};\n`;
    return css;
  }
}
