// Automatically generated - do not modify!

import { createParamString } from '../internals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IAuthApiService {
  /**
   * @method
   * @name  sendAuthLogin
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendAuthLogin(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendAuthLogout
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendAuthLogout(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getAuthMe
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getAuthMe(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getAuthVerificationCode
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getAuthVerificationCode(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  updateAuthVerificationCode
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  updateAuthVerificationCode(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendAuthPasswordSendCode
   * @description
   * @param {string} email
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendAuthPasswordSendCode(
    email?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  updateAuthPasswordCode
   * @description
   * @param {number} code
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  updateAuthPasswordCode(
    code?: number,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class AuthApiService implements IAuthApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'AuthApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  sendAuthLogin
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendAuthLogin(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/auth/login`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  sendAuthLogout
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendAuthLogout(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/auth/logout`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  getAuthMe
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getAuthMe(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/auth/me`,
      options
    );
  }

  /**
   * @method
   * @name  getAuthVerificationCode
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getAuthVerificationCode(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/auth/verification/code`,
      options
    );
  }

  /**
   * @method
   * @name  updateAuthVerificationCode
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public updateAuthVerificationCode(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.put<Record<string, unknown> | unknown>(
      this.uri + `/auth/verification/code`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  sendAuthPasswordSendCode
   * @description
   * @param {string} email
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendAuthPasswordSendCode(
    email?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(['email', email]);
    options.params = new HttpParams({ fromString: paramString });
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/auth/password/sendCode`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  updateAuthPasswordCode
   * @description
   * @param {number} code
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public updateAuthPasswordCode(
    code?: number,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(['code', code]);
    options.params = new HttpParams({ fromString: paramString });
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.put<Record<string, unknown> | unknown>(
      this.uri + `/auth/password/code`,
      JSON.stringify(payload),
      options
    );
  }
}
