import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Home, ManagmentService } from 'src/app/api';
@Component({
  selector: 'app-home-settings',
  templateUrl: './home-settings.component.html',
  styleUrls: ['./home-settings.component.scss'],
})
export class HomeSettingsComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ManagmentService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<HomeSettingsComponent>
  ) {}

  home!: Home;

  closeDialog() {
    this.dialogRef.close();
  }
  loading!: boolean;
  saveText: string = 'Guardar';
  text!: string;
  editHome!: any;
  ngOnInit(): void {
    this.loading = false;
    this.editHome = JSON.stringify(this.data);
    this.editHome = JSON.parse(this.editHome);
  }

  updateHome(): void {
    this.saveText = 'Guardando...';

    this.api.userHomePut(this.editHome).subscribe(
      (response) => {
        this.data.city = this.editHome.city;
        this.data.homeName = this.editHome.homeName;
        this.data.address = this.editHome.address;
        this.data.surface = this.editHome.surface;
        this.data.year = this.editHome.year;
        (this.data.orientation = this.editHome.orientation),
          (this.data.people = this.editHome.people);
        this.closeDialog();
      },
      (error) => {
        this.saveText = 'No se ha podido guardar';
        setTimeout(() => {
          this.saveText = 'Guardar';
        }, 3000);
      }
    );
  }
}
