import { Component, OnInit, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AirControlService, PodInfoRoot } from 'src/app/api';
import { environment } from 'src/environments/environment';
import { NewProgrammingWizardComponent } from '../../components/temperature/new-programming-wizard/new-programming-wizard.component';

@Component({
  selector: 'app-sensibo',
  templateUrl: './sensibo.component.html',
  styleUrls: ['./sensibo.component.scss'],
})
export class SensiboComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private airApi: AirControlService,
    public dialog: MatDialog
  ) {
    this.error = 0;
  }
  loading!: boolean;
  error: any;
  homeId!: string;
  dateRange!: FormGroup;
  deviceId!: string;
  deviceInfo: PodInfoRoot | undefined;
  ngOnInit(): void {
    this.loading = true;
    if (this.route.snapshot.paramMap.get('deviceName') === 'null') {
      environment.current = 'Aire acondicionado';
    } else {
      environment.current =
        'Aire acondicionado - ' +
        this.route.snapshot.paramMap.get('deviceName');
    }
    environment.currentRoute = this.route;
    this.homeId = this.route.snapshot.paramMap.get('homeId')!;
    environment.homeId = this.homeId;
    this.deviceId = this.route.snapshot.paramMap.get('deviceId')!;

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(environment.startDate)),
      end: new UntypedFormControl(new Date(environment.endDate)),
    });

    this.getInfo();
  }

  openDialog(): void {
    if (this.deviceInfo)
      this.dialog.open(NewProgrammingWizardComponent, {
        data: {
          schedules: this.deviceInfo.result!.schedules,
          deviceId: this.deviceId,
          deviceInfo: this.deviceInfo,
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    environment.startDate = changes.dateRange.currentValue.value.start;
    environment.startDate = changes.dateRange.currentValue.value.end;
  }
  changeDate(event: any): void {
    this.dateRange = event;
  }

  getInfo(): void {
    this.airApi.climaDeviceDeviceIdAirInfoGet(this.deviceId).subscribe(
      (response) => {
        this.deviceInfo = response;
        this.loading = false;
      },
      (error) => {
        this.error = error.status;
        this.loading = false;
        setTimeout(() => {
          this.error = null;
          this.loading = true;
          this.airApi.climaDeviceDeviceIdAirInfoGet(this.deviceId).subscribe(
            (response) => {
              this.deviceInfo = response;
              this.loading = false;
            },
            (error) => {
              this.error = error.status;
              this.loading = false;
            }
          );
        }, 15000);
      }
    );
  }
}
