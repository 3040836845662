import { Component, Input, OnInit } from '@angular/core';
import { ElectricClassifiedDevices } from 'src/app/api';

@Component({
  selector: 'app-electricity-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
  @Input() devicesResponse!: ElectricClassifiedDevices;
  constructor() {}
  @Input() homeId!: string;
  @Input() loading!: boolean;
  @Input() error: any;

  ngOnInit(): void {}
}
