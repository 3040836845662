// Automatically generated - do not modify!

import { createParamString } from '../internals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IHeatingControlApiService {
  /**
   * @method
   * @name  getClimaHeatingTempPresets
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHeatingTempPresets(
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingInfo
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getClimaHomeHomeIdHeatingInfo(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingSetcontrolmode
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} controlMode
   * @param {number} setpointTemp
   * @param {number} offsetInMinutes
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaHomeHomeIdHeatingSetcontrolmode(
    homeId: Record<string, unknown> | unknown,
    controlMode?: string,
    setpointTemp?: number,
    offsetInMinutes?: number,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingScheduleAssistant
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaHomeHomeIdHeatingScheduleAssistant(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingSchedule
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaHomeHomeIdHeatingSchedule(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingScheduleScheduleIdActivate
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} scheduleId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendClimaHomeHomeIdHeatingScheduleScheduleIdActivate(
    homeId: Record<string, unknown> | unknown,
    scheduleId: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class HeatingControlApiService implements IHeatingControlApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'HeatingControlApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getClimaHeatingTempPresets
   * @description
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHeatingTempPresets(
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/heating/tempPresets`,
      options
    );
  }

  /**
   * @method
   * @name  getClimaHomeHomeIdHeatingInfo
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getClimaHomeHomeIdHeatingInfo(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/info`,
      options
    );
  }

  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingSetcontrolmode
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} controlMode
   * @param {number} setpointTemp
   * @param {number} offsetInMinutes
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaHomeHomeIdHeatingSetcontrolmode(
    homeId: Record<string, unknown> | unknown,
    controlMode?: string,
    setpointTemp?: number,
    offsetInMinutes?: number,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['controlMode', controlMode],
      ['setpointTemp', setpointTemp],
      ['offsetInMinutes', offsetInMinutes]
    );
    options.params = new HttpParams({ fromString: paramString });
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/setcontrolmode`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingScheduleAssistant
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaHomeHomeIdHeatingScheduleAssistant(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/schedule/assistant`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingSchedule
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaHomeHomeIdHeatingSchedule(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/clima/home/${homeId}/heating/schedule`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  sendClimaHomeHomeIdHeatingScheduleScheduleIdActivate
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} scheduleId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendClimaHomeHomeIdHeatingScheduleScheduleIdActivate(
    homeId: Record<string, unknown> | unknown,
    scheduleId: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
      withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri +
        `/clima/home/${homeId}/heating/schedule/${scheduleId}/activate`,
      JSON.stringify(payload),
      options
    );
  }
}
