// Automatically generated - do not modify!

import { createParamString } from '../internals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IElectricHomeApiService {
  /**
   * @method
   * @name  getElectricHomeHomeIdPrices
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdPrices(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  sendElectricHomeHomeIdPrice
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  sendElectricHomeHomeIdPrice(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdPricesCurrentperiod
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdPricesCurrentperiod(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdSensorLast
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdSensorLast(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdSensors
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdSensors(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdSensorsLast15m
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdSensorsLast15m(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyAbsolute
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyAbsolute(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyToday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyToday(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyByhour
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyByhour(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyByday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyByday(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyByweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyByweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyBymonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyBymonth(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyLast6month
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyLast6month(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyThisMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyLastMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyLastMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyByyear
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyByyear(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyAvgHourweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyAvgHourweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  getElectricHomeHomeIdEnergyAvgDay(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions?: Record<string, string>,
    customHeaders?: Record<string, string>
  ): Observable<Record<string, unknown> | unknown>;
}

@Injectable({ providedIn: 'root' })
export class ElectricHomeApiService implements IElectricHomeApiService {
  public serviceName: string;
  public uri: string;
  constructor(public http: HttpClient) {
    this.serviceName = 'ElectricHomeApi';
    this.uri = environment.apiUrl;
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdPrices
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdPrices(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/prices`,
      options
    );
  }

  /**
   * @method
   * @name  sendElectricHomeHomeIdPrice
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public sendElectricHomeHomeIdPrice(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    options.headers = new HttpHeaders(
      Object.assign(
        { 'Content-Type': 'application/json; charset=utf-8' },
        customHeaders
      )
    );
    let payload = customOptions;
    return this.http.post<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/price`,
      JSON.stringify(payload),
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdPricesCurrentperiod
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdPricesCurrentperiod(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/prices/currentperiod`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdSensorLast
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdSensorLast(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/sensor/last`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdSensors
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdSensors(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/sensors`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdSensorsLast15m
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdSensorsLast15m(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/sensors/last15m`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyAbsolute
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyAbsolute(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/absolute`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyToday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyToday(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/today`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyByhour
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyByhour(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/byhour`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyByday
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyByday(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/byday`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyByweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyByweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/byweek`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyBymonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyBymonth(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/bymonth`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyLast6month
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyLast6month(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/last6month`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyThisMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyThisMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/thisMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyLastMonth
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyLastMonth(
    homeId: Record<string, unknown> | unknown,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/lastMonth`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyByyear
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyByyear(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/byyear`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyAvgHourweek
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyAvgHourweek(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/avg/hourweek`,
      options
    );
  }

  /**
   * @method
   * @name  getElectricHomeHomeIdEnergyAvgDay
   * @description
   * @param {Record<string, unknown> | unknown} homeId
   * @param {string} utcStartTime
   * @param {string} utcEndTime
   * @param {Record<string, string>} customOptions
   * @param {Record<string, string>} customHeaders
   */
  public getElectricHomeHomeIdEnergyAvgDay(
    homeId: Record<string, unknown> | unknown,
    utcStartTime?: string,
    utcEndTime?: string,
    customOptions: Record<string, string> = {},
    customHeaders: Record<string, string> = {}
  ): Observable<Record<string, unknown> | unknown> {
    const options = {
      headers: new HttpHeaders(customHeaders),
withCredentials: true,
      params: new HttpParams(),
      ...customOptions,
    };
    const paramString = createParamString(
      ['utcStartTime', utcStartTime],
      ['utcEndTime', utcEndTime]
    );
    options.params = new HttpParams({ fromString: paramString });
    return this.http.get<Record<string, unknown> | unknown>(
      this.uri + `/electric/home/${homeId}/energy/avg/day`,
      options
    );
  }
}
