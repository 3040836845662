import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AirDataService, HourAndConsumption } from 'src/app/api';

@Component({
  selector: 'app-cold-average',
  templateUrl: './cold-average.component.html',
  styleUrls: ['./cold-average.component.scss'],
})
export class ColdAverageComponent implements OnInit {
  @Input() homeId!: string;
  @Input() deviceId!: string;
  @Input() dateRange!: FormGroup;
  @Input() response!: HourAndConsumption;
  @Input() loading!: boolean;
  @Input() error: any;
  @Input() type: any;
  data: any = [];
  names: any = [];
  options: any;

  constructor(private api: AirDataService) {
    this.error = 0;
  }
  ngOnInit(): void {
    this.loading = true;
    console.log(this.response);
    this.getData();
  }

  getData(): void {
    if (this.type == 'home') {
      this.api
        .climaHomeHomeIdAirConsumptionAvgDayGet(
          this.homeId,
          this.dateRange.value.start,
          new Date(this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000)
        )
        .subscribe(
          (response) => {
            this.response = response;
            this.loading = false;
          },
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.api
                .climaHomeHomeIdAirConsumptionAvgDayGet(
                  this.homeId,
                  this.dateRange.value.start,
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => {
                    this.response = response;
                    this.loading = false;
                  },
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    } else if ((this.type = 'device')) {
      this.api
        .climaDeviceDeviceIdAirConsumptionAvgDayGet(
          this.deviceId,
          this.dateRange.value.start,
          new Date(this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000)
        )
        .subscribe(
          (response) => {
            this.response = response;
            this.loading = false;
          },
          (error) => {
            this.error = error.status;
            this.loading = false;
            setTimeout(() => {
              this.error = null;
              this.loading = true;
              this.api
                .climaDeviceDeviceIdAirConsumptionAvgDayGet(
                  this.deviceId,
                  this.dateRange.value.start,
                  new Date(
                    this.dateRange.value.end.getTime() + 24 * 60 * 60 * 1000
                  )
                )
                .subscribe(
                  (response) => {
                    this.response = response;
                    this.loading = false;
                  },
                  (error) => {
                    this.error = error.status;
                    this.loading = false;
                  }
                );
            }, 15000);
          }
        );
    }
  }
}
