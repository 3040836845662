<div class="widget extend" *ngIf="!error">
  <div class="widget-content" *ngIf="!loading">
    <h5 class="header">Hoy</h5>
    <div class="body">
      <h1 class="consumption">
        {{ response?.energy || 0 | number : "1.0-2" }} kWh
      </h1>
      <h5 class="cost">
        {{ response?.cost || 0 | number : "1.2-2" }} €
        <b class="label">(exc. Imp)</b>
      </h5>
    </div>
    <div class="chart" *ngIf="!loading">
      <div
        class="chart"
        echarts
        [options]="options"
        [loading]="loading"
        [merge]="options"
      ></div>
    </div>
  </div>
  <div class="widget-content" *ngIf="loading">
    <ng-container *ngIf="!noContent"
      ><h5 class="header">Hoy</h5>
      <div class="loading-spinner" *ngIf="loading"></div
    ></ng-container>
    <ng-container *ngIf="noContent">
      <h5 class="header">Hoy</h5>
      <div class="body">
        <h1 class="consumption">--</h1>
        <h5 class="cost">
          --
          <b class="label">(exc. Imp)</b>
        </h5>
      </div>
      <div class="chart" *ngIf="!loading">
        <div
          class="chart"
          echarts
          [options]="options"
          [loading]="loading"
          [merge]="options"
        ></div></div
    ></ng-container>
  </div>
</div>

<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
