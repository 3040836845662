<div class="widget" *ngIf="!error">
  <div class="widget-content" *ngIf="!loading">
    <h5 class="header">Este mes</h5>
    <div class="content">
      <div class="icon">
        <img src="/assets/img/icons/widget/footprint.svg" alt="" />
      </div>
      <div class="data">
        <h2 class="amount primary">
          {{ data?.kgCO2 || 0 | number : "1.2-2" : "es" }} kg de CO2
        </h2>
        <h5 class="consumption">
          {{ data?.energy || 0 | number : "1.2-2" : "es" }} kWh
        </h5>
      </div>
    </div>
    <div class="icons" *ngIf="type === 'total'">
      <div class="electricity">
        <div class="icon">
          <img src="/assets/img/icons/widget/electricity.svg" alt="" />
        </div>
        <div class="amount body-1">{{ 50 }}kg de CO2</div>
      </div>
      <div class="water">
        <div class="icon">
          <img src="/assets/img/icons/widget/water.svg" alt="" />
        </div>
        <div class="amount body-1">{{ 0 }}kg de CO2</div>
      </div>
      <div class="temperature">
        <div class="icon">
          <img src="/assets/img/icons/widget/temperature.svg" alt="" />
        </div>
        <div class="amount body-1">{{ 0 }}kg de CO2</div>
      </div>
    </div>
  </div>

  <div class="widget-content" *ngIf="loading">
    <div class="loading-spinner"></div>
  </div>
</div>
<div class="widget error" *ngIf="error">
  <app-error [error]="error"></app-error>
</div>
