import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComparedWaterConsumption, WaterConsumption } from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-water-accumulated',
  templateUrl: './accumulated.component.html',
  styleUrls: ['./accumulated.component.scss'],
})
export class AccumulatedWaterComponent implements OnChanges {
  @Input() homeId!: string;
  @Input() dateRange!: FormGroup;
  @Input() response!: ComparedWaterConsumption;
  @Input() response2!: ComparedWaterConsumption;
  @Input() loading!: boolean;
  @Input() error: any;
  responseData!: WaterConsumption | undefined;
  responseData2!: WaterConsumption | undefined;
  data: any = [];
  names: any = [];
  options: any;

  consumptionResponse: any;
  costResponse: any;

  constructor() {
    this.error = 0;
  }

  ngOnChanges(): void {
    if (!this.loading) this.adaptData();
  }

  adaptData(): void {
    const colors = ['#EB4747', '#FA7921', '#30AF43'];
    this.responseData = this.response?.thisMonth!;
    this.responseData2 = this.response2?.thisMonth!;
    this.names = [];
    let index = 0;

    let info = [2.99956, 3.93099];

    let cold = true;
    for (const periodo of info) {
      if (cold) {
        this.data.push({
          value: this.responseData?.m3?.toFixed(2),
          name: 'Agua fría sanitaria',
          itemStyle: {
            color: '#73C0DE',
          },
        });
        this.names.push('Caliente');

        index++;
      } else {
        this.data.push({
          value: this.responseData2?.m3?.toFixed(2),
          name: 'Agua caliente sanitaria',
          itemStyle: {
            color: '#F17E7E',
          },
        });
        this.names.push('Fría');

        index++;
      }
      cold = false;
    }

    this.setOptions();
  }
  setOptions(): void {
    let max = 0;
    for (const valor of this.data) {
      if (valor.value > max) {
        max = valor.value;
      }
    }
    const options = {
      tooltip: {
        position: 'top',
        formatter: function (params: {
          color: string;
          name: string;
          value: string;
        }) {
          return (
            '<span style="height: 0px; width: 5px; padding: 0px 7.5px; margin-right:5px; border-radius: 25px; background-color:' +
            params.color +
            '"></span>  <a style="font-size: 1rem"> ' +
            params.name +
            ' <br> <a/> <b style="font-size: 1.25rem"> ' +
            params.value.replace('.', ',') +
            ' m3' +
            '  </b> '
          );
        },

        textStyle: {
          fontFamily: environment.fontFamily,
        },
      },
      xAxis: {
        type: 'category',
        name: 'Periodo',
        data: ['Fría', 'Caliente'],
        axisLabel: {
          fontFamily: environment.fontFamily,
        },

        splitLine: {
          show: false,
        },
      },

      yAxis: {
        type: 'value',
        show: false,
        name: '€',
      },
      series: [
        {
          name: 'Consumo por periodo',
          type: 'bar',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
          },
          data: this.data,
          label: {
            fontFamily: environment.fontFamily,
            show: true,
            formatter(value: { value: number }): any {
              if (value.value < max / 8) {
                return '';
              }
              return ''; //value.value + '€';
            },
            position: 'insideTop',
          },

          barGap: '4px',
          barWidth: '99%',
        },
      ],

      grid: {
        top: '4px',
        left: 0,
        right: 0,
        bottom: '20px',
      },
    };

    this.options = options;
    this.loading = false;
  }
}
