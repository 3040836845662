<div class="combination">
  <div class="widget extend column" *ngIf="loading">
    <div class="content extend" style="width: 100%" *ngIf="loading">
      <div class="loading-spinner"></div>
    </div>
  </div>
  <div
    class="widget extend column"
    *ngIf="deviceInfo?.result?.connectionStatus?.isAlive"
  >
    <h4 class="switch" style="height: 200px; width: 100%; text-align: center; padding-top: 4px">
      <div class="text" *ngIf="!power" (click)="switchOnOff()">ENCENDER</div>
      <div class="text" *ngIf="power" (click)="switchOnOff()">APAGAR</div>
    </h4>
    <div class="content remote extend">
      <div class="button button-compact-primary" (click)="temperatureDown()">
        -
      </div>
      <div class="chart-container">
        <div
          class="chart"
          echarts
          [options]="options"
          [loading]="loading"
          [merge]="options"
          *ngIf="!loading"
        ></div>
      </div>
      <div class="button button-compact-primary" (click)="temperatureUp()">
        +
      </div>
    </div>
  </div>

  <div
    class="widget extend column"
    *ngIf="!deviceInfo?.result?.connectionStatus?.isAlive && !loading"
  >
    <h2>El dispositivo está actualmente desconectado</h2>
  </div>
  <div
    class="widget extend column"
    *ngIf="deviceInfo?.result?.connectionStatus?.isAlive"
  >
    <div class="modes extend column">
      <h5 class="title" *ngIf="deviceInfo.result?.acState?.on">Modos</h5>
      <div
        class="nav-up"
        style="text-align: center; margin: auto"
        *ngIf="!deviceInfo.result?.acState?.on"
      >
        Enciende tu dispositivo para poder acceder a los distintos modos
        disponibles.
      </div>
      <mat-button-toggle-group
        class="icons"
        [(value)]="deviceInfo.result!.acState!.mode"
        (change)="updateAcState()"
        [disabled]="!deviceInfo.result?.acState?.on"
      >
        <mat-button-toggle
          [disabled]="!(deviceInfo.result?.remoteCapabilities)!.modes!.cool!"
          value="cool"
          ><img src="/assets/img/icons/temperature/cold.svg" alt=""
        /></mat-button-toggle>
        <mat-button-toggle
          [disabled]="!(deviceInfo.result?.remoteCapabilities)!.modes!.heat"
          value="heat"
          ><img src="/assets/img/icons/temperature/heat.svg" alt=""
        /></mat-button-toggle>
        <mat-button-toggle
          [disabled]="!(deviceInfo.result?.remoteCapabilities)!.modes!.dry"
          value="dry"
          ><img src="/assets/img/icons/temperature/water.svg" alt=""
        /></mat-button-toggle>
        <mat-button-toggle
          [disabled]="!(deviceInfo.result?.remoteCapabilities)!.modes!.fan"
          value="fan"
          ><img src="/assets/img/icons/temperature/fan.svg" alt=""
        /></mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="fan extend column" *ngIf="deviceInfo.result!.acState!.on!">
      <h5 class="title" *ngIf="deviceInfo.result?.acState?.on">Ventilador</h5>

      <mat-button-toggle-group
        class="icons"
        [(value)]="deviceInfo.result!.acState!.fanLevel"
        (change)="updateAcState()"
      >
        <mat-button-toggle
          value="auto"
          [disabled]="
            !deviceInfo.result?.acState?.on ||
            !(
              deviceInfo.result?.remoteCapabilities?.modes?.heat?.auto ||
              deviceInfo.result?.remoteCapabilities.modes.cool?.auto ||
              deviceInfo.result?.remoteCapabilities.modes.dry?.auto ||
              deviceInfo.result?.remoteCapabilities.modes.fan?.auto
            )
          "
        >
          <img src="/assets/img/icons/temperature/fan-1.svg" alt=""
        /></mat-button-toggle>
        <mat-button-toggle
          value="low"
          [disabled]="
            !deviceInfo.result?.acState?.on ||
            !(
              deviceInfo.result?.remoteCapabilities.modes.heat?.low ||
              !deviceInfo.result?.remoteCapabilities.modes.cool?.low ||
              !deviceInfo.result?.remoteCapabilities.modes.dry?.low ||
              !deviceInfo.result?.remoteCapabilities.modes.fan?.low
            )
          "
        >
          <img src="/assets/img/icons/temperature/fan-2.svg" alt=""
        /></mat-button-toggle>
        <mat-button-toggle
          value="medium"
          [disabled]="
            !deviceInfo.result?.acState?.on ||
            !(
              deviceInfo.result?.remoteCapabilities.modes.heat?.medium ||
              !deviceInfo.result?.remoteCapabilities.modes.cool?.medium ||
              !deviceInfo.result?.remoteCapabilities.modes.dry?.medium ||
              !deviceInfo.result?.remoteCapabilities.modes.fan?.medium
            )
          "
        >
          <img src="/assets/img/icons/temperature/fan-3.svg" alt=""
        /></mat-button-toggle>
        <mat-button-toggle
          value="high"
          [disabled]="
            !deviceInfo.result?.acState?.on ||
            !(
              deviceInfo.result?.remoteCapabilities.modes.heat?.high ||
              !deviceInfo.result?.remoteCapabilities.modes.cool?.high ||
              !deviceInfo.result?.remoteCapabilities.modes.dry?.high ||
              !deviceInfo.result?.remoteCapabilities.modes.fan?.high
            )
          "
        >
          <img src="/assets/img/icons/temperature/fan-4.svg" alt=""
        /></mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>
