import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeatingControlService } from 'src/app/api';

@Component({
  selector: 'app-netatmo-assistant',
  templateUrl: './netatmo-assistant.component.html',
  styleUrls: ['./netatmo-assistant.component.scss'],
})
export class NetatmoAssistantComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NetatmoAssistantComponent>,
    private api: HeatingControlService
  ) {}
  currentPage!: number;

  hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
  minutes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59,
  ];

  temperatures = [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

  requestObject: any = {
    dayWakeUpTimeHour: 7,
    dayWakeUpTimeMinute: 0,
    dayBedTimeHour: 23,
    dayBedTimeMinute: 0,
    dayAtWork: true,
    dayWorkArrivalHour: 17,
    dayWorkArrivalMinute: 0,
    dayWorkDepartureHour: 9,
    dayWorkDepartureMinute: 0,
    dayWorkLunch: false,
    dayWorkLunchArrivalHour: 14,
    dayWorkLunchArrivalMinute: 0,
    dayWorkLunchDepartureHour: 16,
    dayWorkLunchDepartureMinute: 0,
    weekendWakeupHour: 9,
    weekendWakeupMinute: 0,
    weekendBedHour: 23,
    weekendBedMinute: 30,
    saturdayDeparture: false,
    saturdayDepartureHour: 15,
    saturdayDepartureMinute: 0,
    saturdayArrivalHour: 21,
    saturdayArrivalMinute: 0,
    sundayDeparture: false,
    sundayDepartureHour: 15,
    sundayDepartureMinute: 0,
    sundayArrivalHour: 21,
    sundayArrivalMinute: 0,
    idealTemperature: 20,
  };
  ngOnInit(): void {
    this.currentPage = 0;
  }

  saveChanges() {
    this.api
      .climaHomeHomeIdHeatingScheduleAssistantPost(
        this.data.homeId,
        this.requestObject
      )
      .subscribe((response) => this.closeDialog());
  }

  pageUp() {
    this.currentPage = this.currentPage + 1;
  }

  pageDown() {
    this.currentPage = this.currentPage - 1;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
