<div class="widget extend column">
  <h5 class="header">
    <img src="/assets/img/temperature/heatingdevice.svg" alt="" /> Calefacción
  </h5>
  <div class="devices extend" *ngIf="loading">
    <div class="chart extend" *ngIf="loading">
      <div class="loading-spinner"></div>
    </div>
  </div>
  <div class="devices" *ngIf="!loading">
    <a
      class="device"
      *ngFor="let device of devicesResponse?.data"
      [routerLink]="['/home', homeId, 'temperature', 'device', 'netatmo']"
    >
      <div class="icon">
        <img
          src="/assets/img/temperature/heatingdevice-on.svg"
          *ngIf="device.active"
          alt=""
        />
        <img
          src="/assets/img/temperature/heatingdevice.svg"
          *ngIf="!device.active"
          alt=""
        />
      </div>
      <div class="name caption bold">
        {{ device.device?.name || "Termostato" }}
      </div>
      <div class="data caption">
        <div class="temperature">{{ device.temp || "--" }} ºC</div>
      </div>
      <div class="arrow"><img src="/assets/img/new-arrow.svg" alt="" /></div>
    </a>
  </div>
</div>
